import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {paymentHistoryAction} from "./paymentHistorySlice";
import {paymentHistoryApi} from "../../apis/paymentHistoryApi";
import { PaymentHistoryModel } from '../../models/paymentHistory.model';


function* addPaymentHistory(action: PayloadAction< { historyPaymentId: string; reason: string }>) {
  try {
    const refund: {description: string} = yield call(paymentHistoryApi.refundPayment, action.payload)
    message.success(refund.description).then()
    yield put (paymentHistoryAction.success())
  } catch (error: any) {
    yield put (paymentHistoryAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}

export default function* paymentHistorySaga() {
  yield takeEvery(paymentHistoryAction.addPaymentHistory.type, addPaymentHistory)
}
