import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {FeedbackModel} from "../../models/feedback.model";

export interface FeedbackState {
  loadding: boolean
  listFeedback: FeedbackModel[] | undefined
  itemCount: number | undefined
  feedback : FeedbackModel | undefined
}
const initialState: FeedbackState = {
  loadding: false,
  listFeedback: undefined,
  itemCount: undefined,
  feedback: undefined,
}

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getListFeedback(state, action: PayloadAction<{
      page: number | undefined,
      limit: number | undefined,
      keyword?: string,
      fleet?: string;
      startTime?: Date;
      endTime?: Date;
      'orderBy[id]'?: 'ASC' | 'DESC',
      'orderBy[title]'?: 'ASC' | 'DESC',
    }>) {
      state.loadding = true
    },
    getListFeedbackSuccess(state, action: PayloadAction<FeedbackModel[]>) {
      state.listFeedback = action.payload
      state.loadding = false
    },
    getListFeedbackFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateFeedback(
      state,
      action: PayloadAction<Partial<FeedbackModel>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addFeedback(
      state,
      action: PayloadAction<Partial<FeedbackModel>>
    ) {
      state.loadding = true
    },
    detailFeedback(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailFeedbackSuccess(state, action : PayloadAction<FeedbackModel> ) {
      state.loadding = true
      state.feedback = action.payload
    },
    deleteFeedback(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
  },
})
export const feedbackAction = feedbackSlice.actions
export const selectItemCount = (state: RootState) => state.feedback.itemCount
export const selectListFeedback = (state: RootState) => state.feedback.listFeedback
export const selectFeedback = (state: RootState) => state.feedback.feedback
export const selectLoading = (state: RootState) => state.feedback.loadding

export const feedbackReducer = feedbackSlice.reducer
