import NaverMaps, {NaverProps} from "../naverMaps";
import {memo} from "react";

 const CustomMaps = (props : NaverProps) => {
    return (
      <>
        <NaverMaps {...props}></NaverMaps>
      </>
    );
};
export default memo(CustomMaps)