import { Radio, Space, Switch } from "antd";
import styled from "styled-components";
import { AppColors } from "../../../utils/appColors";
import BBtn from "../../../components/button";
import InputGroup from "../../../components/textInput";
import { SettingModel } from "../../../models/setting.model";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { settingAction } from "../../../features/setting/settingSlice";
import { useAppDispatch } from "../../../app/hooks";

interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabDashboard = (props: IProps) => {
  const dispatch = useAppDispatch();
  const initState = props.setting.dashboard || {};
  const [data, setData] = useState<SettingModel["dashboard"]>(
    initState as unknown as SettingModel["dashboard"]
  );
  const [openColor, setOpenColor] = useState<{
    parkingAreaColor: boolean;
    restrictedAreaColor: boolean;
    serviceAreaColor: boolean;
    speedzoneAreaColor: boolean;
    stationAreaColor: boolean;
  }>({
    parkingAreaColor: false,
    restrictedAreaColor: false,
    serviceAreaColor: false,
    speedzoneAreaColor: false,
    stationAreaColor: false,
  });
  const [disableEdit, setDisableEdit] = useState<{
    parkingAreaColor: boolean;
    restrictedAreaColor: boolean;
    serviceAreaColor: boolean;
    speedzoneAreaColor: boolean;
    stationAreaColor: boolean;
  }>({
    parkingAreaColor: true,
    restrictedAreaColor: true,
    serviceAreaColor: true,
    speedzoneAreaColor: true,
    stationAreaColor: true,
  });

  /**
   * "If the disableEdit[params] is true, then set the disableEdit[params] to false, otherwise, update
   * the setting and set the disableEdit[params] to true."
   * </code>
   *       | "restrictedAreaColor"
   *       | "serviceAreaColor"
   *       | "speedzoneAreaColor"}
   * @param params
   */
  const handleEdit = (
    params:
      | "parkingAreaColor"
      | "restrictedAreaColor"
      | "serviceAreaColor"
      | "speedzoneAreaColor"
      | "stationAreaColor"
  ) => {
    if (disableEdit[params]) {
      setDisableEdit((pre) => ({ ...pre, [params]: false }));
    } else {
      props.setting.dashboard &&
        dispatch(
          settingAction.updateSetting({
            dashboard: data,
            _id: props.setting._id,
          })
        );
      setDisableEdit((pre) => ({ ...pre, [params]: true }));
      props.onUpdate();
    }
  };
  return (
    <div className="c-row">
      <div className="content">
        <div className="sub-title">Dashboard Configuration</div>
        <div className="divider"></div>
        <div className="c-row">
          {/* Seeting Theme. */}
          <div className="c-row w-500">
            <InputGroup
              onChange={() => {}}
              name="Theme"
              title="Theme"
              value={data.theme}
              option={[
                { text: "Orange", value: "Orange" },
                { text: "Red", value: "Red" },
              ]}
            />
          </div>
          <div style={{ width: 30 }}></div>
          {/* Setting parkingAreaColor. */}

          <div className="c-row w-500" style={{ position: "relative" }}>
            <InputGroup
              disabled={disableEdit["parkingAreaColor"]}
              onChange={() => {}}
              name="Parking area color"
              title="Parking area color"
              value={data.parkingAreaColor}
              background={data.parkingAreaColor}
              onClick={() =>
                setOpenColor((pre) => ({ ...pre, parkingAreaColor: true }))
              }
              textStyle={{ color: "white" }}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["parkingAreaColor"] ? "Edit" : "Save"}
              onClick={() => handleEdit("parkingAreaColor")}
              color={AppColors.green}
            />
            {openColor.parkingAreaColor && (
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                  zIndex: 11,
                }}
              >
                <i
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-10px",
                    cursor: "pointer",
                  }}
                  className="fa-solid fa-xmark"
                  onClick={() =>
                    setOpenColor((pre) => ({ ...pre, parkingAreaColor: false }))
                  }
                ></i>

                <HexColorPicker
                  color={data.parkingAreaColor}
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, parkingAreaColor: e }))
                  }
                  style={{ width: "200px", height: "100px" }}
                />
              </div>
            )}
          </div>
        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          {/* Setting restrictedAreaColor. */}
          <div className="c-row w-500" style={{ position: "relative" }}>
            <InputGroup
              disabled={disableEdit["restrictedAreaColor"]}
              onChange={() => {}}
              name="Restricted area color"
              title="Restricted area color"
              value={data.restrictedAreaColor}
              background={data.restrictedAreaColor}
              textStyle={{ color: "white" }}
              onClick={() =>
                setOpenColor((pre) => ({ ...pre, restrictedAreaColor: true }))
              }
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["restrictedAreaColor"] ? "Edit" : "Save"}
              onClick={() => handleEdit("restrictedAreaColor")}
              color={AppColors.green}
            />
            {openColor.restrictedAreaColor && (
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                  zIndex: 10,
                }}
              >
                <i
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-10px",
                    cursor: "pointer",
                  }}
                  className="fa-solid fa-xmark"
                  onClick={() =>
                    setOpenColor((pre) => ({
                      ...pre,
                      restrictedAreaColor: false,
                    }))
                  }
                ></i>

                <HexColorPicker
                  color={data.restrictedAreaColor}
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, restrictedAreaColor: e }))
                  }
                  style={{ width: "200px", height: "100px" }}
                />
              </div>
            )}
          </div>
          <div style={{ width: 30 }}></div>
          {/* Setting serviceAreaColor. */}
          <div className="c-row w-500" style={{ position: "relative" }}>
            <InputGroup
              disabled={disableEdit["serviceAreaColor"]}
              onChange={() => {}}
              name="Service area color"
              title="Service area color"
              value={data.serviceAreaColor}
              background={data.serviceAreaColor}
              textStyle={{ color: "white" }}
              onClick={() =>
                setOpenColor((pre) => ({ ...pre, serviceAreaColor: true }))
              }
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["serviceAreaColor"] ? "Edit" : "Save"}
              onClick={() => handleEdit("serviceAreaColor")}
              color={AppColors.green}
            />
            {openColor.serviceAreaColor && (
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                  zIndex: 10,
                }}
              >
                <i
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-10px",
                    cursor: "pointer",
                  }}
                  className="fa-solid fa-xmark"
                  onClick={() =>
                    setOpenColor((pre) => ({
                      ...pre,
                      serviceAreaColor: false,
                    }))
                  }
                ></i>

                <HexColorPicker
                  color={data.serviceAreaColor}
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, serviceAreaColor: e }))
                  }
                  style={{ width: "200px", height: "100px" }}
                />
              </div>
            )}
          </div>
        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          {/* Setting speedzoneAreaColor. */}
          <div className="c-row w-500" style={{ position: "relative" }}>
            <InputGroup
              disabled={disableEdit["speedzoneAreaColor"]}
              onChange={() => {}}
              name="Speedzone area color"
              title="Speedzone area color"
              value={data.speedzoneAreaColor}
              background={data.speedzoneAreaColor}
              textStyle={{ color: "white" }}
              onClick={() =>
                setOpenColor((pre) => ({ ...pre, speedzoneAreaColor: true }))
              }
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["speedzoneAreaColor"] ? "Edit" : "Save"}
              onClick={() => handleEdit("speedzoneAreaColor")}
              color={AppColors.green}
            />
            {openColor.speedzoneAreaColor && (
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                }}
              >
                <i
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-10px",
                    cursor: "pointer",
                  }}
                  className="fa-solid fa-xmark"
                  onClick={() =>
                    setOpenColor((pre) => ({
                      ...pre,
                      speedzoneAreaColor: false,
                    }))
                  }
                ></i>

                <HexColorPicker
                  color={data.speedzoneAreaColor}
                  onChange={(e) =>
                    setData((pre) => ({ ...pre, speedzoneAreaColor: e }))
                  }
                  style={{ width: "200px", height: "100px", zIndex: 10 }}
                />
              </div>
            )}
          </div>

          <div style={{ width: 30 }}></div>

          {/* Setting stationAreaColor. */}
          <div className="c-row w-500" style={{ position: "relative" }}>
            <InputGroup
                disabled={disableEdit["stationAreaColor"]}
                onChange={() => {}}
                name="station area color"
                title="station area color"
                value={data.stationAreaColor}
                background={data.stationAreaColor}
                textStyle={{ color: "white" }}
                onClick={() =>
                    setOpenColor((pre) => ({ ...pre, stationAreaColor: true }))
                }
            />
            <div style={{ width: 10 }}></div>
            <BBtn
                title={disableEdit["stationAreaColor"] ? "Edit" : "Save"}
                onClick={() => handleEdit("stationAreaColor")}
                color={AppColors.green}
            />
            {openColor.stationAreaColor && (
                <div
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "100%",
                    }}
                >
                  <i
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "-10px",
                        cursor: "pointer",
                      }}
                      className="fa-solid fa-xmark"
                      onClick={() =>
                          setOpenColor((pre) => ({
                            ...pre,
                            stationAreaColor: false,
                          }))
                      }
                  ></i>

                  <HexColorPicker
                      color={data.stationAreaColor}
                      onChange={(e) =>
                          setData((pre) => ({ ...pre, stationAreaColor: e }))
                      }
                      style={{ width: "200px", height: "100px", zIndex: 10 }}
                  />
                </div>
            )}
          </div>
          {/*<div style={{ width: 30 }}></div>*/}

        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          {/* Setting Timezone. */}
          <div className="c-row w-500" style={{ position: "relative" }}>
            <InputGroup
                onChange={(e) => {
                  dispatch(
                      settingAction.updateSetting({
                        dashboard: { ...data, timezone: e },
                        _id: props.setting._id,
                      })
                  );
                  props.onUpdate();
                }}
                name="Timezone"
                title="Timezone"
                value={data.timezone}
                option={[
                  { text: "(GMT/UTC + 09:00) Seoul", value: 9 },
                  { text: "(GMT/UTC + 07:00) Hanoi", value: 7 },
                ]}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default TabDashboard;
