import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { FleetModel } from "../../models/fleet.model";

export interface FleetState {
  loadding: boolean;
  listFleet: FleetModel[] | undefined;
  itemCount: number | undefined;
  fleet: FleetModel | undefined;
}

const initialState: FleetState = {
  loadding: false,
  listFleet: undefined,
  itemCount: undefined,
  fleet: undefined,
};

const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {
    getListFleet(
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        keyword?: string;
        "orderBy[id]"?: "ASC" | "DESC";
        "orderBy[name]"?: "ASC" | "DESC";
      }>
    ) {
      state.loadding = true;
    },
    getListFleetSuccess(state, action: PayloadAction<FleetModel[]>) {
      state.listFleet = action.payload;
      state.loadding = false;
    },
    getListFleetFail(state) {
      state.loadding = false;
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload;
    },
    addFleet(state, action: PayloadAction<Partial<FleetModel>>) {
      state.loadding = true;
    },
    success(state) {
      state.loadding = false;
    },
    fail(state) {
      state.loadding = false;
    },
    updateBasic(state, action: PayloadAction<Partial<FleetModel>>) {
      state.loadding = true;
    },
    updateCompany(state, action: PayloadAction<Partial<FleetModel>>) {
      state.loadding = true;
    },
    updateConfigMail(
      state,
      action: PayloadAction<Pick<FleetModel,"mailConfiguration"|"_id">>
    ) {
      state.loadding = true;
    },
    updateOrganisation(state, action: PayloadAction<Partial<FleetModel>>) {
      state.loadding = true;
    },
    updatePreRideScreen(state, action: PayloadAction<Partial<FleetModel>>) {
      state.loadding = true;
    },
    updateOperationHour(state, action: PayloadAction<Partial<FleetModel>>) {
      state.loadding = true;
    },
    deleteFleet(state, action: PayloadAction<string>) {
      state.loadding = true;
    },
    detailFleet(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    detailFleetSuccess(state, action: PayloadAction<FleetModel>) {
      state.loadding = false;
      state.fleet = action.payload;
    },
  },
});

export const fleetAction = fleetSlice.actions;

export const selectListFleet = (state: RootState) => state.fleet.listFleet;

export const selectLoadingFleet = (state: RootState) => state.fleet.loadding;
export const selectFleet = (state: RootState) => state.fleet.fleet;
export const selectItemCount = (state: RootState) => state.fleet.itemCount;

export const fleetReducer = fleetSlice.reducer;
