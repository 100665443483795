import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import BBtn from "../../button";
import ISelect from "../../select";
import InputGroup from "../../textInput";
import "./deleteDialog.css";

export function DeleteDialog(props: {
  open: boolean;
  setOpen: () => void;
  handleDelete: () => void;
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);

  return (
    <>
      <Modal
        title="Confirm Delete"
        open={props.open}
        style={{ top: 20 }}
        confirmLoading={confirmLoading}
        okText={<></>}
        cancelText={<></>}
        onCancel={() => props.setOpen()}
        width={600}
      >
        <div style={{ padding: "0.8rem", height: "80px", fontSize: "16px" }}>
          Do you want to proceed?
        </div>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "end",
            marginRight: "12px",
          }}
        >
          <BBtn
            title="Delete"
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-trash"></i>}
            onClick={() => {
              props.setOpen();
              props.handleDelete();
            }}
          />
        </div>
        <br />
      </Modal>
    </>
  );
}
