import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {PermissionsModel} from '../../models/permissionsModel'
import {RoleModel} from '../../models/roleModel'

export interface RoleState {
  loadding: boolean
  listRole: RoleModel[] | undefined
  listpermissions: PermissionsModel[] | undefined
  itemCount: number | undefined
  listRolePermission: RoleModel[] | undefined
}

const initialState: RoleState = {
  loadding: false,
  listRole: undefined,
  listRolePermission: undefined,
  listpermissions: undefined,
  itemCount: undefined,
}

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getListRole(state) {
      state.loadding = true
    },
    getListRoleSuccess(state, action: PayloadAction<RoleModel[]>) {
      state.listRole = action.payload
      state.listRolePermission = action.payload
      state.loadding = false
    },
    getListRoleFail(state) {
      state.loadding = false
    },
    getlistRolePagination(
      state,
      action: PayloadAction<{
        page: number
        limit: number
        keyword?: string
        'orderBy[id]'?: 'ASC' | 'DESC'
        'orderBy[name]'?: 'ASC' | 'DESC'
      }>
    ) {
      state.loadding = true
    },
    getlistRolePaginationSuccess(state, action: PayloadAction<RoleModel[]>) {
      state.loadding = false
      state.listRole = action.payload
    },
    getlistRolePaginationFail(state) {
      state.loadding = false
    },
    updateRole(state, action: PayloadAction<{id: string; name: string}>) {
      state.loadding = true
    },
    updateRoleSuccess(state) {
      state.loadding = false
    },
    updateRoleFail(state) {
      state.loadding = false
    },
    addRole(state, action: PayloadAction<{name: string}>) {
      state.loadding = true
    },
    addRoleSuccess(state) {
      state.loadding = false
    },
    addRoleFail(state) {
      state.loadding = false
    },
    getAllPermisstion(state) {
      state.loadding = true
    },
    getAllPermisstionSuccess(state, action: PayloadAction<PermissionsModel[]>) {
      state.listpermissions = action.payload
      state.loadding = false
    },
    getAllPermisstionFail(state) {
      state.loadding = false
    },
    updatePermissions(
      state,
      action: PayloadAction<{id: string; permission_id: string; type: boolean}>
    ) {
      state.loadding = true
    },
    updatePermissionsSuccess(state) {
      state.loadding = false
    },
    updatePermissionsFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
  },
})

export const roleAction = roleSlice.actions

export const selectListRole = (state: RootState) => state.role.listRole

export const selectListRolePermission = (state: RootState) =>
  state.role.listRolePermission
export const selectListPermissons = (state: RootState) =>
  state.role.listpermissions
export const selectItemCount = (state: RootState) => state.role.itemCount
export const selectLoaddingRole = (state: RootState) => state.role.loadding

export const roleReducer = roleSlice.reducer
