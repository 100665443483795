import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {User} from '../../models/userModel'

export interface LoginPayload {
  email: string
  password: string
}

export interface Auth {
  loadding: boolean
  login: boolean
  data:
    | {
        access_token: string
        refresh_token: string
        user: User
      }
    | undefined
}

const initialState: Auth = {
  loadding: false,
  login: false,
  data: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.loadding = true
    },
    loginSuccess(
      state,
      action: PayloadAction<{
        access_token: string
        refresh_token: string
        user: User
      }>
    ) {
      state.data = action.payload
      console.log(444, state.data)
      state.loadding = false
      state.login = true
    },
    loginFail(state) {
      state.loadding = false
    },
    setLogin(state) {
      state.login = false
    },
  },
})

export const authAction = authSlice.actions

export const selectDataAuth = (state: RootState) => state.auth.data
export const selectLoginStatus = (state: RootState) => state.auth.login

export const authReducer = authSlice.reducer
