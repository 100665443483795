import InputGroup from "../../components/textInput";
import { use } from "i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectVersion,
  versionAction,
} from "../../features/version/versionSlice";
import { useEffect, useState } from "react";
import { VersionModel } from "../../models/version.model";

export default function Versions() {
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectVersion);
  const [notify, setNotify] = useState<
    Pick<VersionModel, "notifyAndroid" | "notifyIos">
  >({
    notifyIos:version ? version.notifyIos : "",
    notifyAndroid:version ?  version.notifyAndroid : "",
  });
  useEffect(() => {
    dispatch(versionAction.detailVersion());
  }, []);

  const handleSubmit = () => {
    dispatch(versionAction.updateVersion({...version , ...notify }));
    dispatch(versionAction.detailVersion());
  };
  return (
    <div
      style={{
        height: 200,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 16,
        display: "inline-flex",
        padding: "15px 30px",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          paddingTop: 24,
          paddingBottom: 32,
          paddingLeft: 24,
          paddingRight: 24,
          background: "white",
          boxShadow: "0px 2px 4px rgba(200.82, 200.82, 200.82, 0.32)",
          borderRadius: 12,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 10,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            height: 150,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 24,
            display: "flex",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 46,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 12,
                display: "flex",
              }}
            >
              <img src="/img/android.png" alt="android" />
              <div
                style={{
                  color: "#1C1C28",
                  fontSize: 24,
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  wordWrap: "break-word",
                }}
              >
                Android version
              </div>
            </div>
            <div>
              <span
                style={{
                  color: "#28293D",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Current version:
              </span>
              <span
                style={{
                  color: "#555770",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {" "}
              </span>
              <span
                style={{
                  color: "#0063F7",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  wordWrap: "break-word",
                }}
              >
                {version ? version.android : ""}
              </span>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 79,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 12,
              display: "flex",
            }}
          >
            <div
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 12,
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "black",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Notification to edit
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <InputGroup
                name="versionAdroid"
                onChange={(e) => {
                  setNotify((pre) => ({ ...pre, notifyAndroid: e }));
                }}
                type="string"
                placeHolder="Please enter..."
                textStyle={{
                  height: 48,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#FAFAFC",
                  borderRadius: 10,
                  border: "0.50px #C7C9D9 solid",
                }}
              />
              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#F2F2F5",
                  borderRadius: 12,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              >
                <div
                  style={{
                    color: "#8F90A6",
                    fontSize: 16,
                    fontFamily: "Work Sans",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Upload
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: "1 1 0",
          paddingTop: 24,
          paddingBottom: 32,
          paddingLeft: 24,
          paddingRight: 24,
          background: "white",
          boxShadow: "0px 2px 4px rgba(200.82, 200.82, 200.82, 0.32)",
          borderRadius: 12,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 10,
          display: "inline-flex",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            height: 150,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 24,
            display: "flex",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 46,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 12,
                display: "flex",
              }}
            >
              <img src="/img/apple.png" alt="apple" />
              <div
                style={{
                  color: "#1C1C28",
                  fontSize: 24,
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  wordWrap: "break-word",
                }}
              >
                IOS Version
              </div>
            </div>
            <div>
              <span
                style={{
                  color: "#28293D",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Current version:
              </span>
              <span
                style={{
                  color: "#555770",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {" "}
              </span>
              <span
                style={{
                  color: "#0063F7",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                  wordWrap: "break-word",
                }}
              >
                {version ? version.ios : ""}
              </span>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 79,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 12,
              display: "flex",
            }}
          >
            <div
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 12,
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "black",
                  fontSize: 16,
                  fontFamily: "Work Sans",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Notification to edit
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 8,
                display: "flex",
                height: 50,
              }}
            >
              <InputGroup
                name="versionIos"
                onChange={(e) => {
                  setNotify((pre) => ({ ...pre, notifyIos: e }));
                }}
                type="string"
                placeHolder="Please enter..."
                textStyle={{
                  height: 48,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#FAFAFC",
                  borderRadius: 10,
                  border: "0.50px #C7C9D9 solid",
                }}
              />

              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#F2F2F5",
                  borderRadius: 12,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              >
                <div
                  style={{
                    color: "#8F90A6",
                    fontSize: 16,
                    fontFamily: "Work Sans",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Upload
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
