import { Radio, Space, Switch } from "antd";
import styled from "styled-components";
import { AppColors } from "../../../utils/appColors";
import BBtn from "../../../components/button";
import InputGroup from "../../../components/textInput";
import { SettingModel } from "../../../models/setting.model";
import { useAppDispatch } from "../../../app/hooks";
import { settingAction } from "../../../features/setting/settingSlice";
import { useState } from "react";
interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabMobile = (props: IProps) => {
  const dispatch = useAppDispatch();
  const initState = props.setting.mobile || {};
  const [data, setData] = useState<SettingModel["mobile"]>(
    initState as unknown as SettingModel["mobile"]
  );
  const [disableEditFaqUrl, setDisableEditFaqUrl] = useState(true);
  const [disableEditProxy, setDisableEditProxy] = useState(true);
  const hanldeChange = (params: {
    value: any;
    key: keyof SettingModel["mobile"];
  }) => {
    if (props.setting.mobile) {
      setData({ ...props.setting.mobile, [params.key]: params.value });

      if (params.key !== "faqUrl" && params.key !== "proximityRadiusMeters") {
        dispatch(
          settingAction.updateSetting({
            mobile: {
              ...props.setting.mobile,
              [params.key]: params.value,
            },
            _id: props.setting._id,
          })
        );
        props.onUpdate();
      }
    }
  };

  const handleEdit = () => {
    if (disableEditFaqUrl) {
      setDisableEditFaqUrl(false);
    } else {
      // data.proximityRadiusMeters = Number(data.proximityRadiusMeters);
      dispatch(
        settingAction.updateSetting({ mobile: data, _id: props.setting._id })
      );
      setDisableEditFaqUrl(true);
      props.onUpdate();
    }
  };
  const handleEditProxy = () => {
    if (disableEditProxy) {
      setDisableEditProxy(false);
    } else {
      dispatch(
        settingAction.updateSetting({
          mobile: {
            ...data,
            proximityRadiusMeters: Number(data.proximityRadiusMeters),
          },
          _id: props.setting._id,
        })
      );
      setDisableEditProxy(true);
      props.onUpdate();
    }
  };

  return (
    <div className="c-row">
      <div className="content">
        <div className="sub-title">Mobile Configuration</div>
        <div className="divider"></div>
        <div className="c-row">
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEditFaqUrl}
              onChange={(e) => hanldeChange({ key: "faqUrl", value: e })}
              name="FAQ URL"
              title="FAQ URL"
              value={props.setting.mobile?.faqUrl}
              background={"#ECF0F3"}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEditFaqUrl ? "Edit" : "Save"}
              color={AppColors.green}
              onClick={handleEdit}
            />
          </div>
          <div style={{ width: 30 }}></div>
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEditProxy}
              onChange={(e) =>
                hanldeChange({ key: "proximityRadiusMeters", value: e })
              }
              name="Proximity Radius (In Meter)"
              title="Proximity Radius (In Meter)"
              value={props.setting.mobile?.proximityRadiusMeters}
              background={"#ECF0F3"}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEditProxy ? "Edit" : "Save"}
              color={AppColors.green}
              onClick={handleEditProxy}
            />
          </div>
        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          <InputGroup
            onChange={(e) =>
              hanldeChange({ key: "lowBattery", value: Number(e) })
            }
            name="Low Battery"
            title="Low Battery"
            value={props.setting.mobile?.lowBattery}
            option={[
              { text: "10", value: 10 },
              { text: "20", value: 20 },
              { text: "30", value: 30 },
              { text: "40", value: 40 },
            ]}
          />
          <div style={{ width: 30 }}></div>
          <InputGroup
            onChange={(e) =>
              hanldeChange({ key: "multiRide", value: Number(e) })
            }
            name="Multi Ride"
            title="Multi Ride"
            value={props.setting.mobile?.multiRide}
            option={[
              { text: "1", value: 1 },
              { text: "2", value: 2 },
            ]}
          />
        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          <InputGroup
            onChange={(e) =>
              hanldeChange({
                key: "idleVehicleLocationUpdate",
                value: {
                  isCheck: e !== "None",
                  ...(e !== "None" ? { minute: Number(e) } : {}),
                },
              })
            }
            name="Idle vehicle location upadate (In min.)"
            title="Idle vehicle location upadate (In min.)"
            value={props.setting.mobile?.idleVehicleLocationUpdate.minute}
            option={[
              { text: "10", value: 10 },
              { text: "20", value: 20 },
            ]}
          />
          <div style={{ width: 30 }}></div>
          <div
            style={{
              height: 40,
            }}
            className="flex-1 c-row space-between center"
          >
            <span className="c-title">External in-app support integration</span>
            <Switch
              checked={props.setting.mobile?.inAppSupportIntegration}
              onChange={(e: any) =>
                hanldeChange({ key: "inAppSupportIntegration", value: e })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabMobile;
