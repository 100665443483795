import { Switch, TimePicker } from "antd";
import moment from "moment";
import BBtn from "../../../components/button";
import InputGroup from "../../../components/textInput";
import { AppColors } from "../../../utils/appColors";
import styled from "styled-components";
import { SettingModel } from "../../../models/setting.model";
import { useAppDispatch } from "../../../app/hooks";
import { settingAction } from "../../../features/setting/settingSlice";
import { useState } from "react";

const StyleDiv = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  //padding: 20px 30px;
  background: ${AppColors.background};

  .wrap-container {
    //display: flex;
    display: grid;
    //flex-wrap: wrap;
    gap: 20px 46px;
    grid-template-columns: 1fr 1fr;
    .wrap-item {
      margin-bottom: 40px;
      margin-right: 100px;
    }

    .item-container {
      //width: 50%;
      //margin-right: 60px;
      padding-bottom: 20px;
      //padding-top: 20px;
      border-bottom: 1px solid ${AppColors.divider};
      justify-content: space-between;
    }
  }

  .custom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    //gap : 50px
    // nãy e tự thêm chỗ này
  }

  .flex-start {
    align-items: flex-start;
  }

  .c-column {
    display: flex;
    flex-direction: column;
  }

  .c-title {
    color: ${AppColors.textPrimary};
    font-weight: 600;
    font-size: 14px;
  }

  .flex-1 {
    flex: 1;
  }

  .center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${AppColors.textPrimary};
  }

  .w-500 {
    width: 500px;
  }

  .t-medium {
    font-size: 14px;
    font-weight: 500;
    color: ${AppColors.textPrimary};
  }

  .t-semibold {
    font-size: 14px;
    font-weight: 600;
    color: ${AppColors.textPrimary};
  }

  .content {
    background: white;
    padding: 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 50px;

    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 1px;
      background: ${AppColors.divider};
    }
  }
  .sub-title {
    font-size: 18px;
    color: ${AppColors.textPrimary};
    font-weight: 600;
  }
  .timer-item {
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }

  @media only screen(max-width: 767 px) {
    .wrap {
      grid-template-columns: 1fr;
    }
  }
`;
interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabSystem = (props: IProps) => {
  const dispatch = useAppDispatch();
  const initState = props.setting.system || {};
  const [data, setData] = useState<SettingModel["system"]>(
    initState as unknown as SettingModel["system"]
  );
  const [disableEdit, setDisableEdit] = useState<{
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  }>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });

  const dayOfTheWeek: {
    id: number;
    day: string;
    name: keyof SettingModel["system"];
  }[] = [
    {
      id: 1,
      day: "Monday",
      name: "monday",
    },
    {
      id: 2,
      day: "Tuesday",
      name: "tuesday",
    },
    {
      id: 3,
      day: "Wednesday",
      name: "wednesday",
    },
    {
      id: 4,
      day: "Thursday",
      name: "thursday",
    },
    {
      id: 5,
      day: "Friday",
      name: "friday",
    },
    {
      id: 6,
      day: "Saturday",
      name: "saturday",
    },
    {
      id: 7,
      day: "Sunday",
      name: "sunday",
    },
  ];
  const handleChange = (params: {
    value: any;
    key: keyof SettingModel["system"];
    type: "startTime" | "endTime";
  }) => {
    setData((pre) => ({
      ...pre,
      [params.key]: {
        ...data[params.key],
        [params.type]: moment(params.value).format("HH:mm:ss"),
      },
    }));
  };
  const handleEdit = (params: keyof SettingModel["system"]) => {
    if (disableEdit[params]) {
      setDisableEdit((pre) => ({ ...pre, [params]: false }));
    } else {
      props.setting.system &&
        dispatch(
          settingAction.updateSetting({
            system: data,
            _id: props.setting._id,
          })
        );
      setDisableEdit((pre) => ({ ...pre, [params]: true }));
      props.onUpdate();
    }
  };

  return (
    <StyleDiv>
      <div className="content">
        <div className="sub-title">System Hours</div>
        <div className="divider"></div>
        <div className="wrap-container">
          {dayOfTheWeek.map((item, index) => {
            return (
              <div key={index} className="item-container">
                <div className="custom-row">
                  <div>
                    <div className="t-semibold">{item.day}</div>
                    <div style={{ height: 16 }}></div>
                    <Switch
                      defaultChecked={data[item.name].status}
                      onChange={(checked) => {
                        setData((pre) => ({
                          ...pre,
                          [item.name]: {
                            ...data[item.name],
                            status: checked,
                          },
                        }));
                        props.setting.system &&
                          dispatch(
                            settingAction.updateSetting({
                              system: {
                                ...props.setting.system,
                                [item.name]: {
                                  ...props.setting.system[item.name],
                                  status: checked,
                                },
                              },
                              _id: props.setting._id,
                            })
                          );
                        props.onUpdate();
                      }}
                    />
                  </div>
                  {/*<div style={{ width: 58 }}></div>*/}
                  <div className="timer-item">
                    <div>
                      <div className="t-semibold">System Start Time</div>
                      <div style={{ height: 8 }}></div>
                      <TimePicker
                        disabled={disableEdit[item.name]}
                        onChange={(e) =>
                          handleChange({
                            key: item.name,
                            value: e,
                            type: "startTime",
                          })
                        }
                        defaultValue={
                          data[item.name].status
                            ? moment(data[item.name].startTime, "HH:mm:ss")
                            : undefined
                        }
                        size="large"
                      />
                    </div>
                    {/*<div style={{ width: 12 }}></div>*/}
                    <div>
                      <div className="t-semibold">System End Time</div>
                      <div style={{ height: 8 }}></div>
                      <TimePicker
                        disabled={disableEdit[item.name]}
                        onChange={(e) =>
                          handleChange({
                            key: item.name,
                            value: e,
                            type: "endTime",
                          })
                        }
                        defaultValue={
                          data[item.name].status
                            ? moment(data[item.name].endTime, "HH:mm:ss")
                            : undefined
                        }
                        size="large"
                      />
                    </div>
                    {/*<div style={{ width: 12 }}></div>*/}
                    <div>
                      {/*<div style={{ height: 32 }}></div>*/}
                      <BBtn
                        title={disableEdit[item.name] ? "Edit" : "Save"}
                        onClick={() => handleEdit(item.name)}
                        color={AppColors.green}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </StyleDiv>
  );
};

export default TabSystem;
