import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {BillingPlanModel} from '../../models/billingPlanModel'
import {PaginationModel} from '../../models/paginationModel'

export interface BillingPlanState {
  listBillingPlan: BillingPlanModel[] | undefined
  loadding: boolean
  itemCount: number | undefined
  billingPlan : BillingPlanModel | undefined
}

const initialState: BillingPlanState = {
  loadding: false,
  listBillingPlan: undefined,
  itemCount: undefined,
  billingPlan : undefined
}

const billingPlanSlice = createSlice({
  name: 'billingPlan',
  initialState,
  reducers: {
    getListBillingPlan(state, action: PayloadAction<PaginationModel>) {
      state.loadding = true
    },
    getListBillingPlanSuccess(
      state,
      action: PayloadAction<BillingPlanModel[]>
    ) {
      state.listBillingPlan = action.payload
      state.loadding = false
    },
    getListBillingPlanFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    createBillingPlan(state , action : PayloadAction<BillingPlanModel>) {
      state.loadding = true
    },
    updateBillingPlan(state , action : PayloadAction<Partial<BillingPlanModel>>) {
      state.loadding = true
    },
    detailBillingPlan(state , action : PayloadAction<{id : string}>) {
      state.loadding = true
    },
    detailBillingPlanSuccess(state , action : PayloadAction<BillingPlanModel>) {
      state.loadding = true
      state.billingPlan = action.payload
    },
    success(state){
      state.loadding = false
    },
    fail(state){
      state.loadding = false
    },
    deleteBillingPlan(state , action : PayloadAction<{id : string}>){
      state.loadding = true
    }
  },
})

export const billingPlanAction = billingPlanSlice.actions

export const billingPlanReducer = billingPlanSlice.reducer

export const selectListBillingPlan = (state: RootState) =>
  state.billingPlan.listBillingPlan
export const selectItemCount = (state: RootState) => state.billingPlan.itemCount
export const selectBillingPlan = (state: RootState) => state.billingPlan.billingPlan
