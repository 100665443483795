import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {feedbackAction} from "./feedbackSlice";
import {feedbackApi} from "../../apis/feedbackApi";
import {FeedbackModel} from "../../models/feedback.model";

function* getListFeedback(action: PayloadAction<{
  page: number | undefined,
  limit: number | undefined,
  keyword?: string,
  fleet?: string;
  startTime?: Date;
  endTime?: Date;
  'orderBy[id]'?: 'ASC' | 'DESC',
  'orderBy[title]'?: 'ASC' | 'DESC',
}>) {
  try {
    const listFeedback: {
      data: {
        nodes: FeedbackModel[]
        itemCount: number
      }
    } = yield call(feedbackApi.getAllFeedback, action.payload)
    yield put(feedbackAction.getListFeedbackSuccess(listFeedback.data.nodes))
    yield put(feedbackAction.setItemCount(listFeedback.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}
function* updateFeedback(
  action: PayloadAction<Partial<FeedbackModel>>
) {
  try {
    const feedback: {description: string} = yield call(
      feedbackApi.updateFeedback,
      action.payload
    )
    yield put (feedbackAction.success())
    message.success(feedback.description).then()
  } catch (error: any) {
    yield put (feedbackAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* addFeedback(action: PayloadAction<Partial<FeedbackModel>>) {
  try {
    const feedback: {description: string} = yield call(feedbackApi.createFeedback, action.payload)
    message.success(feedback.description).then()
    yield put (feedbackAction.success())
  } catch (error: any) {
    yield put (feedbackAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* detailFeedback(action: PayloadAction<{id : string}>) {
  try {
    const feedback: {description: string , data : FeedbackModel} = yield call(feedbackApi.detailFeedback, action.payload.id)
    yield put (feedbackAction.detailFeedbackSuccess(feedback.data))
    message.success(feedback.description).then()
  } catch (error: any) {
    yield put (feedbackAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* deleteFeedback(action: PayloadAction<{id : string}>) {
  try {
    const feedback: {description: string } = yield call(feedbackApi.deleteFeedback, action.payload.id)
    message.success(feedback.description).then()
    yield put (feedbackAction.success())
  } catch (error: any) {
    yield put (feedbackAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}


export default function* feedbackSaga() {
  yield takeEvery(feedbackAction.getListFeedback.type, getListFeedback)
  yield takeEvery(feedbackAction.updateFeedback.type, updateFeedback)
  yield takeEvery(feedbackAction.addFeedback.type, addFeedback)
  yield takeEvery(feedbackAction.detailFeedback.type, detailFeedback)
  yield takeEvery(feedbackAction.deleteFeedback.type, deleteFeedback)
}
