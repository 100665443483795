import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Config, LOCAL_STORAGE } from "../utils/constants";
import {login} from "../routers/paths/path";

const req = axios.create({
  baseURL: Config.HOST_API,
});

req.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
req.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.href = login
    } else return Promise.reject(error)
  },
);

const deleteService = async (
  url: string,
  params?: any,
  body?: object | null,
  isAuthorization = true,
) => {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  if (!!token && token !== "") {
    headers.Authorization =
      "Bearer " + localStorage.getItem(LOCAL_STORAGE.TOKEN);
  }

  const requestOptions: any = {
    method: "DELETE",
    headers: headers,
    params: params,
  };

  const response = req.delete(url, requestOptions);
  try {
    await response;
  } catch (error: any) {
    console.log("error", error.response);
    if ([401].includes(error.response.status)) {
      localStorage.setItem(LOCAL_STORAGE.TOKEN, "");
      // configureStore.store.dispatch(signOut());
    }
  }

  return response;
};

const postService = async (
  url: string,
  body?: object,
  isAuthorization = true,
  isFormData = false,
) => {
  const headers: any = isFormData
    ? { "Content-Type": "multipart/form-data" }
    : { Accept: "application/json", "Content-Type": "application/json" };

  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  if (!!token && token !== "") {
    headers.Authorization =
      "Bearer " + localStorage.getItem(LOCAL_STORAGE.TOKEN);
  }

  const requestOptions: any = {
    method: "POST",
    headers: headers,
    credentials: "include",
  };

  const response = req.post(url, JSON.stringify(body), requestOptions);
  try {
    await response;
  } catch (error: any) {
    console.log("error", error.response);
    if ([401].includes(error.response.status)) {
      localStorage.setItem(LOCAL_STORAGE.TOKEN, "");
      // configureStore.store.dispatch(signOut());
    }
  }

  return response;
};

const updateService = async (
  url: string,
  body?: object,
  isAuthorization = true,
  isFormData = false,
) => {
  const headers: any = isFormData
    ? { "Content-Type": "multipart/form-data" }
    : { Accept: "application/json", "Content-Type": "application/json" };
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  if (!!token && token !== "") {
    headers.Authorization =
      "Bearer " + localStorage.getItem(LOCAL_STORAGE.TOKEN);
  }

  const requestOptions: any = {
    method: "PUT",
    headers: headers,
    credentials: "include",
  };

  const response = req.put(url, JSON.stringify(body), requestOptions);
  try {
    await response;
  } catch (error: any) {
    console.log("error", error.response);
    if ([401].includes(error.response.status)) {
      localStorage.setItem(LOCAL_STORAGE.TOKEN, "");
      // configureStore.store.dispatch(signOut());
    }
  }

  return response;
};

const getService = async (
  url: string,
  params?: any,
  body?: object | null,
  isAuthorization = true,
) => {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  if (!!token && token !== "") {
    headers.Authorization =
      "Bearer " + localStorage.getItem(LOCAL_STORAGE.TOKEN);
  }

  const requestOptions: any = {
    method: "GET",
    headers: headers,
    params: params,
  };

  const response = req.get(url, requestOptions);
  try {
    await response;
  } catch (error: any) {
    console.log("error", error.response);
    if ([401].includes(error.response.status)) {
      localStorage.setItem(LOCAL_STORAGE.TOKEN, "");
      // configureStore.store.dispatch(signOut());
    }
  }

  return response;
};

const uploadService = async (
  url: string,
  formData: any,
  isAuthorization = true,
) => {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  };

  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  if (!!token && token !== "") {
    headers.Authorization =
      "Bearer " + localStorage.getItem(LOCAL_STORAGE.TOKEN);
  }

  const requestOptions: any = {
    method: "POST",
    headers: headers,
    credentials: "include",
  };

  const response = req.post(url, formData, requestOptions);
  return response;
};

export default {
  postService,
  getService,
  updateService,
  uploadService,
  deleteService,
};
