import {PaginationModel} from '../models/paginationModel'
import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'

export const taxApi = {
  getListTax(params: PaginationModel) {
    return axiosClient.getService(urlApi.TAX, params)
  },
  updateTax(params: {id: string; name: string; taxPercentage: number}) {
    return axiosClient.updateService(`${urlApi.TAX}/${params.id}`, {
      name: params.name,
      taxPercentage: params.taxPercentage,
    })
  },
  addTax(params: {name: string; taxPercentage: number}) {
    return axiosClient.postService(urlApi.TAX, params)
  },
  detailTax(id : string){
    return axiosClient.getService(`${urlApi.TAX}/${id}`)
  },
  deleteTax(id : string){
    return axiosClient.deleteService(`${urlApi.TAX}/${id}`)
  }
}
