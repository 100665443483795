import {PaginationModel} from '../models/paginationModel'
import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {BillingPlanModel} from "../models/billingPlanModel";

export const billingPlanApi = {
  getListBillingPlan(params: PaginationModel) {
    return axiosClient.getService(urlApi.BILLING_PLAN, params)
  },
  createBillingPlan(body: Partial<BillingPlanModel>){
    return axiosClient.postService(urlApi.BILLING_PLAN, body)
  },
  updateBillingPlan(body: Partial<BillingPlanModel>){
    return axiosClient.updateService(`${urlApi.BILLING_PLAN}/${body._id}`, body)
  },
  detailBillingPlan(id : string){
    return axiosClient.getService(`${urlApi.BILLING_PLAN}/${id}`)
  },
  deleteBillingPlan (id : string){
    return axiosClient.deleteService(`${urlApi.BILLING_PLAN}/{${id}`)
  },
}
