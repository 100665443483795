import { CheckboxOptionType, CheckboxValueType } from "antd/es/checkbox/Group";
import InputGroup from "../../../components/textInput";
import { SettingModel } from "../../../models/setting.model";
import { Checkbox, Radio, Space, Switch } from "antd";
import { useAppDispatch } from "../../../app/hooks";
import { settingAction } from "../../../features/setting/settingSlice";

interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabEvent = (props: IProps) => {
  const dispatch = useAppDispatch();

  /* list option. */
  const plainOptions: CheckboxOptionType[] = [
    { label: "Email", value: "email" },
    { label: "Web Notification", value: "web" },
    { label: "Slack", value: "slack" },
  ];
  /* list Tab */
  const Tabs: { title: string; name: keyof SettingModel["eventHandling"] }[] = [
    {
      title: "Leave service area",
      name: "leaveServiceArea",
    },
    {
      title: "Low speed zone",
      name: "lowSpeedZone",
    },
    {
      title: "Restricted area",
      name: "restrictedArea",
    },
    {
      title: "Negative balance",
      name: "negativeBalance",
    },
    {
      title: "Illegal move",
      name: "illegalMove",
    },
    {
      title: "Fallen down",
      name: "fallenDown",
    },
    {
      title: "IoT removed",
      name: "ioTRemoved",
    },
    {
      title: "Pull up",
      name: "pullUp",
    },
    {
      title: "Idle vehicle",
      name: "idleVehicle",
    },
    {
      title: "Critical battery vehicle",
      name: "criticalBatteryVehicle",
    },
  ];
  /**
   * HandleChange is a function that takes two arguments, e and itemName, and update setting.
   * @param {CheckboxValueType[]} e - CheckboxValueType[]
   * @param itemName - keyof SettingModel["eventHandling"]
   */
  const handleChange = (
    e: CheckboxValueType[],
    itemName: keyof SettingModel["eventHandling"]
  ) => {
    if (props.setting.eventHandling) {
      const temp = props.setting.eventHandling[itemName];
      const obj: any = {};
      Object.keys(temp).forEach((i) => {
        if (e.includes(i)) {
          obj[i] = { ...temp[i as keyof typeof temp], status: true };
        } else {
          obj[i] = { ...temp[i as keyof typeof temp], status: false };
        }
      });
      dispatch(
        settingAction.updateSetting({
          eventHandling: { ...props.setting.eventHandling, [itemName]: obj },
          _id: props.setting._id,
        })
      );
      props.onUpdate();
    }
  };
  return (
    <div className="content">
      <div className="sub-title">Event Handling (Beta)</div>
      <div className="divider"></div>
      <div className="wrap">
        {Tabs.map((item, index) => {
          /* To check if the checkbox is checked or not. */
          const listValue = [];
          if (props.setting.eventHandling) {
            if (props.setting.eventHandling[item.name].email?.status) {
              listValue.push("email");
            }
            if (props.setting.eventHandling[item.name].slack?.status) {
              listValue.push("slack");
            }
            if (props.setting.eventHandling[item.name].web?.status) {
              listValue.push("web");
            }
          }
          return (
            <div key={index} className="wrap-item">
              <span className="t-medium">{item.title}</span>
              <div style={{ height: 12 }}></div>
              <Checkbox.Group
                options={
                  item.name === "idleVehicle"
                    ? plainOptions.filter((tag) => tag.value !== "Email")
                    : plainOptions
                }
                defaultValue={listValue}
                onChange={(e) => handleChange(e, item.name)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabEvent;
