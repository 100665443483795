import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import InforBoxHomePage from "../../components/inforBoxHomePage";
import "./home.css";
import { useTranslation } from "react-i18next";
import { ChartCustom } from "../../components/chart";
import ISelect from "../../components/select";
import { Url } from "../../routers/paths";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  analyticRideAction,
  selectChartRideByDay,
  selectChartRideFleet,
  selectChartRidePerHour,
  selectChartUserSignUp,
  selectStatisticRide,
  selectStatisticRider,
  selectStatisticTicket,
  selectStatisticVehicle,
} from "../../features/analyticRide/analyticRideSlice";
import { fleetAction, selectListFleet } from "../../features/fleet/fleetSlice";
import numeral from "numeral";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [fleet, setFleet] = useState<string>("");
  const listFleet = useAppSelector(selectListFleet);
  const statisticRider = useAppSelector(selectStatisticRider);
  const statisticRide = useAppSelector(selectStatisticRide);
  const statisticTicket = useAppSelector(selectStatisticTicket);
  const statisticVehicle = useAppSelector(selectStatisticVehicle);
  const chartRidePerHour = useAppSelector(selectChartRidePerHour);
  const chartUserSignUp = useAppSelector(selectChartUserSignUp);
  const chartRideByDay = useAppSelector(selectChartRideByDay);
  const chartRideFleet = useAppSelector(selectChartRideFleet);
  useEffect(() => {
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 30));
    const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    dispatch(
      analyticRideAction.getStatisticRider({
        type: "TOTAL_RIDE",
        startLastMonth: priorDate.toISOString(),
        endLastMonth: today.toISOString(),
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getStatisticRide({
        type: "TOTAL_RIDE",
        startTime: priorDate.toISOString(),
        endTime: today.toISOString(),
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getStatisticTicket({
        type: "TOTAL_RIDE",
        startTime: yesterday.toISOString(),
        endTime: today.toISOString(),
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getStatisticVehicle({
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getChartRidePerHour({
        type: "TOTAL_RIDE",
        startTime: yesterday.toISOString(),
        endTime: today.toISOString(),
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getChartUserSignUp({
        type: "TOTAL_RIDE",
        startTime: priorDate.toISOString(),
        endTime: today.toISOString(),
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getChartRideByDay({
        type: "TOTAL_RIDE",
        startTime: priorDate.toISOString(),
        endTime: today.toISOString(),
        ...(fleet ? { fleet } : {}),
      })
    );
    dispatch(
      analyticRideAction.getChartRideFleet({
        ...(fleet ? { fleet } : {}),
      })
    );
  }, [dispatch, fleet]);

  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 15,
      })
    );
  }, [dispatch]);
  const listOptionFleet = listFleet?.map((item) => {
    return {
      value: item._id,
      text: item.name,
    };
  });

  const averageRide = statisticRide?.averageRide || 0;
  const averageRidesPerDay = statisticRide?.averageRidesPerDay || 0;
  return (
    <div>
      <br></br>
      <br></br>
      <Row justify="end">
        <Col>
          <ISelect
            name="selectFleets"
            placeHolder="All Fleets"
            option={[{ text: "All Fleets", value: "" }].concat(
              listOptionFleet || []
            )}
            onChange={(e) => {
              setFleet(e);
            }}
            style={{
                backgroundColor : "#ff5c6a",
                color : "#fff"
            }}
            width="120px"
          />
        </Col>
      </Row>

      <Row
        justify="space-between"
        className="home_row1"
        wrap={true}
        gutter={24}
      >
        <Col span={6}>
          <InforBoxHomePage
            type={1}
            text_1={t("Page.Analytics.monthlyActiveUsers")}
            text_3={t("Page.Analytics.totalUser")}
            text_4={t("Page.Analytics.newUsersInLast30Days")}
            title={t("inforBoxHomePage.riders")}
            icon={<i className="fa-solid fa-head-side-mask"></i>}
            navigatePath={Url.riders}
            number_1={statisticRider?.monthlyActiveUsers}
            number_3={statisticRider?.countUser}
            number_4={statisticRider?.usersInLastMonth}
          />
        </Col>
        <Col span={6}>
          <InforBoxHomePage
            type={2}
            text_1={t("Page.Analytics.averageRidesPerVehiclePerDay")}
            text_2={t("Page.Analytics.averageRidesPerDay")}
            text_3={t("Page.Analytics.averageUsagePerVehiclePerDay")}
            text_4={t("Page.Analytics.totalRides")}
            title={t("inforBoxHomePage.rides")}
            icon={<i className="fa-solid fa-person-biking"></i>}
            navigatePath={Url.ridesCompleted}
            number_1={numeral(averageRide).format("0[.]000")}
            number_2={numeral(averageRidesPerDay).format("0[.]000")}
            number_3={statisticRide?.averageUsingVehicle}
            number_4={statisticRide?.countTotalRides}
            note={"min"}
          />
        </Col>
        <Col span={6}>
          <InforBoxHomePage
            type={1}
            text_1={t("Page.Analytics.totalVehicles")}
            text_3={t("Page.Analytics.vehiclesWithLowBattery")}
            text_4={t("Page.Analytics.vehiclesOnRide")}
            title={t("inforBoxHomePage.vehicles")}
            icon={<i className="fa-solid fa-motorcycle"></i>}
            navigatePath={Url.vehiclesAll}
            number_1={statisticVehicle?.countVehicle}
            number_3={statisticVehicle?.vehicleLowBattery}
            number_4={statisticVehicle?.vehicleIsRental}
          />
        </Col>
        <Col span={6}>
          <InforBoxHomePage
            type={1}
            text_1={t("Page.Analytics.pendingTickets")}
            text_3={t("Page.Analytics.averageTicketsPerDay")}
            text_4={t("Page.Analytics.averageTicketsPerVehiclePerMonth")}
            title={t("inforBoxHomePage.tickets")}
            icon={<i className="fa-solid fa-ticket"></i>}
            navigatePath={Url.supportTickets}
            number_1={statisticTicket?.ticketPending}
            number_3={statisticTicket?.avgTicketPerDay}
            number_4={statisticTicket?.avgTicketPerVehiclePerMonth || 0}
          />
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row
        justify="space-between"
        className="home_row1"
        wrap={true}
        gutter={24}
      >
        <Col span={12}>
          <ChartCustom
            labels={[
              "0:00-1:00",
              "1:00-2:00",
              "2:00-3:00",
              "3:00-4:00",
              "4:00-5:00",
              "5:00-6:00",
              "6:00-7:00",
              "7:00-8:00",
              "8:00-9:00",
              "9:00-10:00",
              "10:00-11:00",
              "11:00-12:00",
              "12:00-13:00",
              "13:00-14:00",
              "14:00-15:00",
              "15:00-16:00",
              "16:00-17:00",
              "17:00-18:00",
              "18:00-19:00",
              "19:00-20:00",
              "20:00-21:00",
              "21:00-22:00",
              "22:00-23:00",
              "23:00-24:00",
            ]}
            datasets={[
              {
                label: "Rides",
                data: chartRidePerHour?.map((item) => item.totalRides) || [],
                backgroundColor: "#758D96",
              },
            ]}
            title={t("Page.Analytics.ridesPerHour")}
            type="Bar"
          />
        </Col>
        <Col span={12}>
          <ChartCustom
            labels={chartUserSignUp?.map((item) => item.date) || []}
            datasets={[
              {
                label: "Total User",
                data: chartUserSignUp?.map((item) => item.totalUser) || [],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "#758D96",
              },
            ]}
            type="Line"
            title={t("Page.Analytics.userSignups")}
          />
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row justify="space-between" className="home_row1" wrap={true}>
        <Col span={24}>
          <ChartCustom
            labels={chartRideByDay?.map((item) => item.date) || []}
            datasets={[
              {
                label: t("Page.Analytics.totalUser"),
                data: chartRideByDay?.map((item) => item.totalRides) || [],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "#758D96",
              },
            ]}
            type="Line"
            title={t("Page.Analytics.rides") + ":" + statisticRide?.countTotalRides}
          />
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row gutter={24}>
        <Col span={8}>
          <ChartCustom
            labels={["Idle Vehicle", "Used Vehicle"]}
            datasets={[
              {
                label: "# of Votes",
                data: [
                  chartRideFleet?.idleVehicle || 0,
                  chartRideFleet?.usedVehicle || 0,
                ],
                backgroundColor: ["#3980B5", "#95BBD7"],
                borderColor: ["white", "white"],
                borderWidth: 2,
              },
            ]}
            title={t("Page.Analytics.fleetProductivity")}
            type="Doughnut"
          />
        </Col>
        <Col span={8}>
          <ChartCustom
            labels={[
              "Unavailable",
              "Missing",
              "Available Vehicle",
              "Rebalance Pick Up",
            ]}
            datasets={[
              {
                label: "# of Votes",
                data: [
                  chartRideFleet?.unavailable || 0,
                  chartRideFleet?.missing || 0,
                  chartRideFleet?.availableVehicle || 0,
                  chartRideFleet?.rebalancePickUp || 0,
                ],
                backgroundColor: ["#3980B5", "#3980B5", "#95BBD7", "#679DC6"],
                borderColor: ["white", "white", "white", "white"],
                borderWidth: 2,
              },
            ]}
            title={t("Page.Analytics.fleetHeath")}
            type="Doughnut"
          />
        </Col>
        <Col span={8}>
          <ChartCustom
            labels={["Not Connected Vehicle", "Connected Vehicle"]}
            datasets={[
              {
                label: "# of Votes",
                data: [
                  chartRideFleet?.notConnectedVehicle || 0,
                  chartRideFleet?.connectedVehicle || 0,
                ],
                backgroundColor: ["#3980B5", "#95BBD7"],
                borderColor: ["white", "white"],
                borderWidth: 2,
              },
            ]}
            title={t("Page.Analytics.vehicleConnectivity")}
            type="Doughnut"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
