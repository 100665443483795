import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {offerAction} from "./offerSlice";
import {offerApi} from "../../apis/offerApi";
import {OfferModel} from "../../models/offer.model";

function* getListOffer(action: PayloadAction<{
  page: number | undefined,
  limit: number | undefined,
  keyword?: string,
  fleet?: string;
  startTime?: Date;
  endTime?: Date;
  'orderBy[id]'?: 'ASC' | 'DESC',
  'orderBy[title]'?: 'ASC' | 'DESC',
}>) {
  try {
    const listOffer: {
      data: {
        nodes: OfferModel[]
        itemCount: number
      }
    } = yield call(offerApi.getAllOffer, action.payload)
    yield put(offerAction.getListOfferSuccess(listOffer.data.nodes))
    yield put(offerAction.setItemCount(listOffer.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}
function* updateOffer(
  action: PayloadAction<Partial<OfferModel>>
) {
  try {
    const offer: {description: string} = yield call(
      offerApi.updateOffer,
      action.payload
    )
    yield put (offerAction.success())
    message.success(offer.description).then()
  } catch (error: any) {
    yield put (offerAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* addOffer(action: PayloadAction<Partial<OfferModel>>) {
  try {
    const offer: {description: string} = yield call(offerApi.createOffer, action.payload)
    message.success(offer.description).then()
    yield put (offerAction.success())
  } catch (error: any) {
    yield put (offerAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* detailOffer(action: PayloadAction<{id : string}>) {
  try {
    const offer: {description: string , data : OfferModel} = yield call(offerApi.detailOffer, action.payload.id)
    yield put (offerAction.detailOfferSuccess(offer.data))
    message.success(offer.description).then()
  } catch (error: any) {
    yield put (offerAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* deleteOffer(action: PayloadAction<{id : string}>) {
  try {
    const offer: {description: string } = yield call(offerApi.deleteOffer, action.payload.id)
    message.success(offer.description).then()
    yield put (offerAction.success())
  } catch (error: any) {
    yield put (offerAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}


export default function* offerSaga() {
  yield takeEvery(offerAction.getListOffer.type, getListOffer)
  yield takeEvery(offerAction.updateOffer.type, updateOffer)
  yield takeEvery(offerAction.addOffer.type, addOffer)
  yield takeEvery(offerAction.detailOffer.type, detailOffer)
  yield takeEvery(offerAction.deleteOffer.type, deleteOffer)
}
