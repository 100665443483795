import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import { PaymentHistoryModel } from '../../models/paymentHistory.model'

export interface paymentHistoryState {
  loadding: boolean
  listPaymentHistory: PaymentHistoryModel[] | undefined
  itemCount: number | undefined
  paymentHistory : PaymentHistoryModel | undefined
}
const initialState: paymentHistoryState = {
  loadding: false,
  listPaymentHistory: undefined,
  itemCount: undefined,
  paymentHistory: undefined,
}

const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState,
  reducers: {
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addPaymentHistory(
      state,
      action: PayloadAction<Partial<PaymentHistoryModel>>
    ) {
      state.loadding = true
    },
  },
})
export const paymentHistoryAction = paymentHistorySlice.actions
export const selectItemCount = (state: RootState) => state.paymentHistory.itemCount
export const selectListPaymentHistory = (state: RootState) => state.paymentHistory.listPaymentHistory
export const selectPaymentHistory = (state: RootState) => state.paymentHistory.paymentHistory
export const selectLoading = (state: RootState) => state.paymentHistory.loadding

export const paymentHistoryReducer = paymentHistorySlice.reducer
