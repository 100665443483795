import { Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Images from "../../../assets/gen";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import { UserModel } from "../../../models/user.model";
import { AppColors } from "../../../utils/appColors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px;
  background: ${AppColors.background};

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .wrap-item {
      margin-bottom: 40px;
      margin-right: 100px;
    }

    .wrap-item2 {
      margin-right: 36px;
      margin-bottom: 20px;
      margin-bottom: 10px;
      width: 200px;
    }
  }

  .c-row {
    display: flex;
    flex-direction: row;
  }

  .flex-start {
    align-items: flex-start;
  }

  .c-column {
    display: flex;
    flex-direction: column;
  }

  .c-title {
    color: ${AppColors.textPrimary};
    font-weight: 600;
    font-size: 14px;
  }

  .flex-1 {
    flex: 1;
  }

  .center {
    align-items: center;
    justify-content: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${AppColors.textPrimary};
  }

  .w-500 {
    width: 500px;
  }

  .t-medium {
    font-size: 14px;
    font-weight: 500;
    color: ${AppColors.textPrimary};
  }

  .t-16 {
    font-size: 16px;
    font-weight: 500;
    color: ${AppColors.textPlaceholder};
  }

  .t-semibold {
    font-size: 14px;
    font-weight: 600;
    color: ${AppColors.textPrimary};
  }

  .sub-title {
    font-size: 18px;
    color: ${AppColors.textPrimary};
    font-weight: 500;
    text-align: center;
  }

  .content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 30px;

    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 1px;
      background: ${AppColors.divider};
    }

    img {
      width: 20px;
      height: 20px;
    }

    .t-back {
      color: ${AppColors.red};
      font-weight: 600;
      font-size: 14px;
      margin-left: 4px;
      :hover {
        cursor: pointer;
      }
    }
  }
`;
const ThemesAdd = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <span className='title'>Themes</span>
      <div style={{ height: 20 }} />
      <div className='c-row'>
        <div className='content'>
          <span className='sub-title'>Add Theme</span>
          <div style={{ height: 20 }} />
          <InputGroup
            name='Name'
            title='Name'
            required
            placeHolder='Enter name'
            onChange={() => {}}
            width={400}
          />
          <div className='divider'></div>
          <div style={{ height: 10 }} />
          <div className='c-row'>
            <div
              className='c-row space-between'
              style={{ padding: 10 }}
              onClick={() => navigate(-1)}
            >
              <img src={Images.arrowLeft} alt='' />
              <span className='t-back'>Back</span>
            </div>
            <div style={{ width: 365 }}></div>
            <BBtn
              iconLeft={Images.icSave}
              title='Save'
              color={AppColors.green}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ThemesAdd;
