import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {IamportStoreModel} from "../models/IamportStore.model";

export const IamportStoreApi = {
    getAllIamportStore() {
        return axiosClient.getService(`${urlApi.IAMPORTSTORE}`)
    },
    createIamportStore(params: Partial<IamportStoreModel>) {
        return axiosClient.postService(`${urlApi.IAMPORTSTORE}`, {...params})
    },
    updateIamportStore(params: Partial<IamportStoreModel>) {
        return axiosClient.updateService(`${urlApi.IAMPORTSTORE}/${params._id}`, {...params})
    },
    deleteIamportStore(id: string) {
        return axiosClient.deleteService(`${urlApi.IAMPORTSTORE}/${id}`)
    },
    detailIamportStore(id: string) {
        return axiosClient.getService(`${urlApi.IAMPORTSTORE}/${id}`)
    },
}
