import styled from "styled-components";
import { AppColors } from "../../utils/appColors";
import BBtn from "../../components/button";
import { TabSetting, TAB_SETTING } from "../../utils/constants";
import { useEffect, useState } from "react";
import TabDashboard from "./dashboard-setting";
import TabMail from "./mail-setting";
import TabVehicles from "./vehicles-setting";
import TabMobile from "./mobile-setting";
import TabEvent from "./event-setting";
import TabPayment from "./payments-setting";
import TabSystem from "./system-setting";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectListSetting,
  settingAction,
} from "../../features/setting/settingSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px;
  background: ${AppColors.background};

  .wrap {
    display: flex;
    flex-wrap: wrap;

    .wrap-item {
      margin-bottom: 40px;
      margin-right: 100px;
    }

    .wrap-item2 {
      margin-right: 60px;
      padding-bottom: 20px;
      padding-top: 20px;
      border-bottom: 1px solid ${AppColors.divider};
    }
  }

  .c-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .flex-start {
    align-items: flex-start;
  }

  .c-column {
    display: flex;
    flex-direction: column;
  }

  .c-title {
    color: ${AppColors.textPrimary};
    font-weight: 600;
    font-size: 14px;
  }

  .flex-1 {
    flex: 1;
  }

  .center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${AppColors.textPrimary};
  }

  .w-500 {
    width: 500px;
  }

  .t-medium {
    font-size: 14px;
    font-weight: 500;
    color: ${AppColors.textPrimary};
  }

  .t-semibold {
    font-size: 14px;
    font-weight: 600;
    color: ${AppColors.textPrimary};
  }

  .content {
    background: white;
    padding: 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 50px;

    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 1px;
      background: ${AppColors.divider};
    }
  }
  .ant-switch-checked {
    background-color: ${AppColors.green};
  }
  .sub-title {
    font-size: 18px;
    color: ${AppColors.textPrimary};
    font-weight: 600;
  }
  .ant-radio-inner::after {
    background: ${AppColors.textPrimary} !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${AppColors.textPrimary};
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-group-item {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-checkbox-inner {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    //   background: ${AppColors.background};
  }

  .ant-checkbox {
    margin-top: -6px;
  }

  .ant-picker {
    background-color: ${AppColors.background};
    border-radius: 4px;
    border: 1px solid ${AppColors.border};
    padding: 0 0 0 16px !important;
    width: 165px;

    .ant-picker-suffix {
      height: 40px;
      width: 40px;
      background-color: ${AppColors.textSecondary};
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      svg {
        height: 20px;
        width: 20px;
        color: white;
      }
    }

    .ant-picker-clear {
      height: 40px;
      width: 40px;
      background-color: ${AppColors.textSecondary};
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      svg {
        height: 20px;
        width: 20px;
        color: white;
      }
    }
  }
`;

const Setting = () => {
  const dispatch = useAppDispatch();
  const listSetting = useAppSelector(selectListSetting);
  const [tabSelected, setTabSelected] = useState(TAB_SETTING[0]);
  useEffect(() => {
    dispatch(settingAction.getListSetting());
  }, [dispatch]);
  const getListSetting = () => {
    setTimeout(() => dispatch(settingAction.getListSetting()), 1000);
  };

  const _buildTab = () => {
    let tab;
    switch (tabSelected) {
      case TAB_SETTING[0]:
        tab = (
          <TabMobile
            setting={{
              mobile: listSetting ? listSetting[0].mobile : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      case TAB_SETTING[1]:
        tab = (
          <TabDashboard
            setting={{
              dashboard: listSetting ? listSetting[0].dashboard : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      case TAB_SETTING[2]:
        tab = (
          <TabMail
            setting={{
              mail: listSetting ? listSetting[0].mail : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      case TAB_SETTING[3]:
        tab = (
          <TabVehicles
            setting={{
              vehicle: listSetting ? listSetting[0].vehicle : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      case TAB_SETTING[4]:
        tab = (
          <TabEvent
            setting={{
              eventHandling: listSetting
                ? listSetting[0].eventHandling
                : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      case TAB_SETTING[5]:
        tab = (
          <TabPayment
            setting={{
              payment: listSetting ? listSetting[0].payment : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      case TAB_SETTING[6]:
        tab = (
          <TabSystem
            setting={{
              system: listSetting ? listSetting[0].system : undefined,
              _id: listSetting ? listSetting[0]._id : undefined,
            }}
            onUpdate={getListSetting}
          />
        );
        break;
      default:
        tab = <div></div>;
        break;
    }
    return tab;
  };

  return (
    <Container>
      <span className="title">Settings</span>
      <div style={{ height: 40 }} />
      <div className="c-row">
        {TAB_SETTING.map((item: TabSetting, index) => {
          return (
            <div key={index} style={{ marginRight: 8 }}>
              <BBtn
                title={item.title}
                onClick={() => setTabSelected(item)}
                color={
                  tabSelected === item ? AppColors.textPrimary : AppColors.red
                }
                styleText={{ fontSize: 16, fontWeight: "500" }}
              />
            </div>
          );
        })}
      </div>
      {_buildTab()}
    </Container>
  );
};

export default Setting;
