import React, {FC, useState} from "react";
import {AreaModel} from "../../../../models/area.model";
import {useAppDispatch} from "../../../../app/hooks";
import {Checkbox, Col, message, Row, Switch} from "antd";
import {areaAction} from "../../../../features/area/areaSlice";
import ISelect from "../../../../components/select";
import InputGroup from "../../../../components/textInput";
import TextArea from "antd/lib/input/TextArea";
import BBtn from "../../../../components/button";

interface InputAddAreaParkingProps {
    listOptionFleet: { text: string, value: any }[]
    handleGetPolygon: () => any
    setAddParkingArea: React.Dispatch<React.SetStateAction<boolean>>
    getListArea: () => void
}
export const InputAddAreaParking: FC<InputAddAreaParkingProps> = ({getListArea, setAddParkingArea,listOptionFleet,handleGetPolygon}) => {
    const [dataParkingArea, setDataParkingArea] = useState<AreaModel>({} as AreaModel);
    const dispatch = useAppDispatch();
    const handleAdd = () => {
        const location = handleGetPolygon()
        if (Object.keys(dataParkingArea).length){
            const dataAdd = {
                ...dataParkingArea,
                type : "PARKING" ,
                triggerAlarm : !!dataParkingArea.triggerAlarm,
                throttle : !!dataParkingArea.throttle,
                visible : !!dataParkingArea.visible,
                fleet : dataParkingArea.fleet._id,
                coordinates :location&&location.length ? [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])] : [[[]]]
            }
            if (location&&location.length > 1){
                message.error("Can not create more than one polygon").then();
            }
            dispatch(areaAction.addArea(dataAdd))
            getListArea()
            setAddParkingArea(false)
            setDataParkingArea({} as AreaModel)
        }
    }
    return (
        <div style={{ padding: "1rem" }}>
            <br />
            <p className="role_title">Add Parking</p>
            <Row gutter={16} style={{ padding: "1rem" }}>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Fleet</p>
                    <ISelect
                        name="Fleet"
                        option={listOptionFleet || []}
                        onChange={(e) =>
                            setDataParkingArea((prestate) => ({
                                ...prestate,
                                fleet: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Name"
                        name="Name"
                        onChange={(e) =>
                            setDataParkingArea((prestate) => ({
                                ...prestate,
                                name: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Type</p>
                    <ISelect
                        name="Type"
                        option={[{text :'circle', value : 'circle' },{text :'Point', value : 'Point' },{text :'Polygon', value : 'Polygon' }]}
                        onChange={(e) =>
                            setDataParkingArea((prestate) => ({
                                ...prestate,
                                locationType: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Minimum Vehicles"
                        type='number'
                        name="Minimum Vehicles"
                        onChange={(e) =>
                            setDataParkingArea((prestate) => ({
                                ...prestate,
                                minVehicle: Number(e),
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Maximum Vehicles"
                        name="Maximum Vehicles"
                        type='number'
                        onChange={(e) =>
                            setDataParkingArea((prestate) => ({
                                ...prestate,
                                maxVehicle: Number(e),
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <span className="org_address">Description</span>
                    <TextArea
                        maxLength={100}
                        style={{ height: 60, marginTop: "5px" }}
                        onChange={(e) =>
                            setDataParkingArea((prestate) => ({
                                ...prestate,
                                description: e.target.value,
                            }))
                        }
                    />
                </Col>
                <Col span={12}></Col>
                <Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                    }}
                >
                    <span className="org_address">visible</span>
                    <Switch onChange={(e) =>
                        setDataParkingArea((prestate) => ({
                            ...prestate,
                            visible: e,
                        }))
                    } />
                </Col>
            </Row>
            <br />
            <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
            <br />
            <div
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <BBtn
                    title="Back"
                    color="#FF5C6A"
                    onClick={() => {
                        setAddParkingArea(false)
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <BBtn title="Save Changes" onClick={handleAdd} color="#24BB69" />
                </div>
            </div>
        </div>
    );
};