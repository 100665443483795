import { Badge, Col, message, Rate, Row, Select, Switch } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
// import Map, { Layer, Marker, Source } from "react-map-gl";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Option } from "antd/lib/mentions";
import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import BBtn from "../../../components/button";
import { AddMultipleVehiclesDialog } from "../../../components/dialog/addMultipleVehicles";
import { AddVehicleDialog } from "../../../components/dialog/addVehicleDialog";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import { FlagImg } from "../../../utils/constants";
import "./all.css";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectListVehicle,
  selectItemCount,
  vehicleAction,
  selectTypeVehicle,
  selectError,
} from "../../../features/vehicle/vehicleSlice";
import { DEVICE_TYPE, vehicleModel } from "../../../models/vehicleModel";
import {
  fleetAction,
  selectListFleet,
} from "../../../features/fleet/fleetSlice";
import Images from "../../../assets/gen";
import { LOW_SPEED_TYPE } from "../../../models/area.model";
import MapVehicleDialog from "../../../components/dialog/mapVehicleDialog";
import { ErrorDialog } from "../../../components/dialog/errorDialog";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const VehicleAll = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listVehicle = useAppSelector(selectListVehicle);
  const typeVehicle = useAppSelector(selectTypeVehicle);
  const error = useAppSelector(selectError);

  const itemCount = useAppSelector(selectItemCount);
  const listFleet = useAppSelector(selectListFleet);
  const [dataVehiclesAll, setDataVehiclesAll] = useState<vehicleModel>(
    {} as unknown as vehicleModel,
  );
  const [size, setSize] = useState<SizeType>("middle");
  const [tab, setTab] = useState<string>("Flags");
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [limit, setLimit] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>(
    state && state.search ? state.search : undefined,
  );
  const [openMaps, setOpenMaps] = useState<boolean>(false);
  const [openDialogAddVehicle, setOpenDialogAddVehicle] =
    useState<boolean>(false);
  const [openAddMultipleVehiclesDialog, setOpenAddMultipleVehiclesDialog] =
    useState<boolean>(false);
  const [long, setLong] = useState<number>(0);
  const [lat, setLat] = useState<number>(0);
  const [lastConnect, setLastConnect] = useState<Date>(new Date());
  const [flag, setFlag] = useState<Record<string, boolean>>();

  useEffect(() => {
    dispatch(
      vehicleAction.getListVehicle({
        page,
        limit,
        keyword,
        ...flag,
      }),
    );
  }, [dispatch, page, limit, keyword, flag]);
  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 15,
      }),
    );
    dispatch(vehicleAction.getTypeVehicle());
  }, [dispatch]);

  const getListVehicle = () => {
    setTimeout(
      () =>
        dispatch(
          vehicleAction.getListVehicle({
            page,
            limit,
            keyword,
          }),
        ),
      500,
    );
  };
  const optionType = typeVehicle?.map((item) => {
    return { text: item.value, value: item.key };
  });
  const onChange = (checked: boolean, key: keyof vehicleModel) => {
    dispatch(
      vehicleAction.updateFlag({
        ...{
          maintenancePickup: false,
          stolen: false,
          rented: false,
          agencyPickup: false,
          usingService: false,
          serviceStartEnd: false,
          missing: false,
          ioTFault: false,
          batteryCritical: false,
          reBalancePickup: false,
          // available : true
        },
        [key]: checked,
        _id: dataVehiclesAll._id,
      }),
    );
    getListVehicle();
    setDataVehiclesAll((pre) => ({
      ...pre,
      ...{
        maintenancePickup: false,
        stolen: false,
        rented: false,
        agencyPickup: false,
        usingService: false,
        serviceStartEnd: false,
        missing: false,
        ioTFault: false,
        batteryCritical: false,
        reBalancePickup: false,
        // available : true
      },
      [key]: checked,
    }));
    // setTimeout(()=> window.location.reload(),1500)
  };
  const handleChange = (value: string | string[]) => {
    setLimit(Number(value));
  };
  const handleActionLock = (param: vehicleModel) => {
    dispatch(vehicleAction.actionLock({ id: param._id }));
    getListVehicle();
  };
  const handleOpenMaps = useCallback(() => setOpenMaps(!openMaps), [openMaps]);
  const listOptionFleet = listFleet?.map((item) => {
    return {
      text: item.name,
      value: item,
    };
  });
  const newListVehicle: any[] = [];
  if (listVehicle && listVehicle.length) {
    listVehicle.forEach((item) => {
      newListVehicle.push({
        ...item,
        flags: {
          missing: item.missing,
          serviceStartEnd: item.serviceStartEnd,
          usingService: item.usingService,
          agencyPickup: item.agencyPickup,
          rented: item.rented,
          stolen: item.stolen,
          maintenancePickup: item.maintenancePickup,
          ioTFault: item.ioTFault,
          batteryCritical: item.batteryCritical,
          reBalancePickup: item.reBalancePickup,
        },
      });
    });
  }

  const handleEditVehicle = () => {
    dispatch(
      vehicleAction.updateVehicle({
        ...dataVehiclesAll,
        fleet: dataVehiclesAll.fleet._id,
      }),
    );
    getListVehicle();
  };

  const handleEditThrottle = (throttle: boolean) => {
    dispatch(
      vehicleAction.updateVehicle({ throttle, _id: dataVehiclesAll._id }),
    );
    setDataVehiclesAll((pre) => ({ ...pre, throttle }));
    getListVehicle();
  };
  const handleEditSpeedMode = () => {
    dispatch(
      vehicleAction.updateVehicle({
        speedMode: dataVehiclesAll.speedMode,
        _id: dataVehiclesAll._id,
      }),
    );
    getListVehicle();
  };

  const handleSpeed = () => {
    dispatch(
      vehicleAction.updateConfigure({
        configuration: dataVehiclesAll.configuration,
        _id: dataVehiclesAll._id,
      }),
    );
    getListVehicle();
  };

  const handleUpdateRide = () => {
    dispatch(
      vehicleAction.updateConfigure({
        configuration: dataVehiclesAll.configuration,
        _id: dataVehiclesAll._id,
      }),
    );
    getListVehicle();
  };

  const refreshLocation = useCallback(() => {
    dispatch(vehicleAction.refreshLocation({ id: dataVehiclesAll._id }));
    getListVehicle();
    setLastConnect(dataVehiclesAll.lastConnect);
    setLong(dataVehiclesAll.location.coordinates[0]);
    setLat(dataVehiclesAll.location.coordinates[1]);
  }, [dataVehiclesAll.lastConnect, dataVehiclesAll.location]);

  const resetError = () => {
    dispatch(vehicleAction.resetError());
  };

  const handleChangeFlagList = (checked: boolean, key: keyof vehicleModel) => {
    if (selectedRowKeys.length) {
      selectedRowKeys.forEach((item) =>
        dispatch(
          vehicleAction.updateFlag({
            ...{
              maintenancePickup: false,
              stolen: false,
              rented: false,
              agencyPickup: false,
              usingService: false,
              serviceStartEnd: false,
              missing: false,
              ioTFault: false,
              batteryCritical: false,
              reBalancePickup: false,
              // available : true
            },
            [key]: checked,
            _id: item,
          }),
        ),
      );
    }
  };

  return (
    <div>
      <br />
      <Row align="middle" justify="space-between">
        <span className="va_p_title">{t("Menu.5")}</span>
        <Col style={{ display: "flex" }}>
          <BBtn title={t("Page.Vehicles.getCsv")} color="#FF5C6A" />
          <span style={{ margin: "0 0.5rem" }}></span>
          <BBtn
            title={t("Page.Vehicles.addVehicle")}
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-plus"></i>}
            onClick={() => setOpenDialogAddVehicle(true)}
          />
          <span style={{ margin: "0 0.5rem" }}></span>
          <BBtn
            title={t("Page.Vehicles.addMultipleVehicles")}
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-plus"></i>}
            onClick={() => setOpenAddMultipleVehiclesDialog(true)}
          />
          <span style={{ margin: "0 0.5rem" }}></span>
          <BBtn
            title={t("Page.Vehicles.qRCodePDF")}
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-qrcode"></i>}
          />
          <span style={{ margin: "0 0.5rem" }}></span>
          <BBtn
            title={t("allFleet")}
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-sort-down"></i>}
            icon1left={true}
          />
        </Col>
      </Row>
      <br />
      <Row justify="space-between" align="middle">
        <ISelect
          name="selectFlags"
          placeHolder="All Flags"
          option={[
            { text: "All Flags", value: {} },
            { text: "Service On", value: { serviceStartEnd: true } },
            { text: "Service Off", value: { serviceStartEnd: false } },
            { text: "Rebalance Pickup", value: { rebalancePickup: true } },
            { text: "Maintenance Pickup", value: { maintenancePickup: true } },
            { text: "Battery Critical", value: { batteryCritical: true } },
            { text: "Unavailable", value: { available: true } },
            { text: "Missing", value: { missing: true } },
            { text: "IoT Fault", value: { ioTFault: true } },
            { text: "Agency Pickup", value: { agencyPickup: true } },
            { text: "Stolen", value: { stolen: true } },
            { text: "Rented", value: { rented: true } },
          ]}
          onChange={(e) => {
            setFlag(e);
            setDataVehiclesAll({} as vehicleModel);
          }}
          style={{
            backgroundColor: "#ff5c6a",
            color: "#fff",
          }}
          width="95px"
        />
        <Col style={{ display: "flex", alignItems: "center" }}>
          <span className="va_p1">{t("Page.Vehicles.open")}</span>
          <span style={{ margin: "0 0.5rem" }}></span>
          <Switch defaultChecked />
          <span style={{ margin: "0 0.5rem" }}></span>
          <span className="va_p1">{t("Page.Vehicles.openwithoutRide")}</span>
          <span style={{ margin: "0 0.5rem" }}></span>
          <Switch defaultChecked />
          <span style={{ margin: "0 0.5rem" }}></span>
          <BBtn
            title="CSV"
            color="#24BB69"
            icon1={<i className="fa-solid fa-file-lines"></i>}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "1rem 0",
            marginLeft: "1rem",
          }}
        >
          <span style={{ marginRight: "1rem" }}>{t("show")}</span>
          <Select
            size={size}
            defaultValue={t("show").toString()}
            onChange={handleChange}
          >
            <Option value="15">10</Option>
            <Option value="20">20</Option>
            <Option value="30">30</Option>
            <Option value="40">40</Option>
            <Option value="50">50</Option>
          </Select>
          <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
            {t("entries")}
          </span>
          <ISearch
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <Col span={24}>
          <ITable
            onClickRow={(params: any) => setDataVehiclesAll(params)}
            rowSelection={true}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            column={[
              {
                title: t("Page.Vehicles.vehicleID"),
                dataIndex: "_id",
                render: (text: string, record: vehicleModel) => (
                  <span
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>{text}</span>
                    {record.online && !record.locked && (
                      <Badge color="#24BB69" />
                    )}
                  </span>
                ),
              },
              {
                title: t("Page.Vehicles.vehicleName"),
                dataIndex: "name",
                // sorter: (a: any, b: any) => a.name.length - b.name.length,
              },
              {
                title: t("Page.Vehicles.battery"),
                dataIndex: "battery",
                render: (text: string) => (
                  <div
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      border: "1px solid #434343",
                      borderRadius: "3px",
                      width: "46px",
                      textAlign: "center",
                      height: "20px",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        position: "absolute",
                        fontWeight: "500",
                        fontSize: "12px",
                        right: "9px",
                      }}
                    >
                      {text}%
                    </span>
                    <div
                      style={{
                        backgroundColor:
                          Number(text) > 50
                            ? "#1EA85D"
                            : Number(text) < 50 && Number(text) > 25
                            ? "#5d59a6"
                            : "#ff5c6a",
                        width: `${text}%`,
                        height: "100%",
                      }}
                    ></div>
                  </div>
                ),
              },
              {
                title: t("Page.Vehicles.type"),
                dataIndex: "typeDevice",
                render: (text: string) => DEVICE_TYPE[text],
              },
              {
                title: "IMEI",
                dataIndex: "imei",
              },
              {
                title: t("Page.Vehicles.qRCode"),
                dataIndex: "qrCode",
              },
              {
                title: t("Page.Vehicles.vehicleSN"),
                dataIndex: "vehicleSN",
              },
              {
                title: t("Page.Vehicles.odemeterDistance"),
                dataIndex: "odometer",
              },
              {
                title: t("Page.Vehicles.enabledFlags"),
                dataIndex: "flags",
                render: (text: Record<string, any>) => (
                  <span style={{ display: "flex" }}>
                    {text["missing"] && (
                      <img
                        src={FlagImg[`missing`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["stolen"] && (
                      <img
                        src={FlagImg[`stolen`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["agencyPickup"] && (
                      <img
                        src={FlagImg[`agencyPickup`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["batteryCritical"] && (
                      <img
                        src={FlagImg[`batteryCritical`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["serviceStartEnd"] && (
                      <img
                        src={FlagImg[`parked_vehicle`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["ioTFault"] && (
                      <img
                        src={FlagImg[`ioTFault`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["maintenancePickup"] && (
                      <img
                        src={FlagImg[`maintenancePickup`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["reBalancePickup"] && (
                      <img
                        src={FlagImg[`reBalancePickup`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["rented"] && (
                      <img
                        src={FlagImg[`rented`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                    {text["usingService"] && (
                      <img
                        src={FlagImg[`usingService`]}
                        alt="flag"
                        style={{ width: "20px" }}
                      />
                    )}
                  </span>
                ),
              },
              {
                title: t("Page.Vehicles.registerDate"),
                dataIndex: "createdAt",
                render: (text: Date) => {
                  return (
                    <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
                  );
                },
              },
              {
                title: t("Page.Vehicles.lastConnected"),
                dataIndex: "lastConnect",
                render: (text: Date) => {
                  return (
                    <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
                  );
                },
              },
              {
                title: t("Page.Vehicles.lastLockStatusUpdate"),
                dataIndex: "lastLock",
                render: (text: Date) => {
                  return (
                    <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
                  );
                },
              },
              {
                title: t("Page.Vehicles.lastRideTime"),
                dataIndex: "lastRide",
                render: (text: Date) => {
                  return (
                    <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
                  );
                },
              },
              {
                title: "",
                dataIndex: "map",
                render: (text: {}, record: vehicleModel) => (
                  <span
                    style={{
                      display: "flex",
                      color: "white",
                      backgroundColor: "#24BB69",
                      padding: "0.5rem 0 0.5rem 1.2rem",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setLong(record.location.coordinates[0]);
                      setLat(record.location.coordinates[1]);
                      setLastConnect(record.lastConnect);
                      setDataVehiclesAll({} as vehicleModel);
                      setOpenMaps(true);
                    }}
                  >
                    <i className="fa-solid fa-map" style={{ margin: 0 }}></i>
                  </span>
                ),
              },
              {
                title: t("Page.Vehicles.action"),
                dataIndex: "locked",
                render: (text: boolean, record: vehicleModel) => (
                  <span
                    style={{
                      display: "flex",
                      backgroundColor: text ? "#24BB69" : "#F44141",
                      color: "white",
                      justifyContent: "center",
                      borderRadius: "5px",
                      padding: "0.3rem 0",
                      cursor: "pointer",
                    }}
                    onClick={() => handleActionLock(record)}
                  >
                    {text ? (
                      <span>
                        <i className="fa-solid fa-lock-open"></i> UnLock
                      </span>
                    ) : (
                      <span>
                        <i className="fa-solid fa-lock"></i> Lock
                      </span>
                    )}
                  </span>
                ),
              },
            ]}
            total={itemCount || 0}
            data={newListVehicle}
            pageSize={limit}
            currentPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
          />
        </Col>
      </Row>
      <br />
      {Object.keys(dataVehiclesAll).length !== 0 && (
        <Row>
          <Col span={24} className="gutter-row">
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <BBtn
                title={t("Page.Vehicles.flags")}
                color={tab === "Flags" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Flags")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.configuration")}
                color={tab === "Configuration" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Configuration")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.editVehicle")}
                color={tab === "Edit Vehicle" ? "#2C405A" : "#FF5C6A"}
                // onClick={() => setTab('Company')}
                onClick={() => setTab("Edit Vehicle")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.log")}
                color={tab === "Console Logs" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Console Logs")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.rideHistory")}
                color={tab === "Ride History" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Ride History")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.timeline")}
                color={tab === "Timeline" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Timeline")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.battery")}
                color={tab === "Battery" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Battery")}
              />
              <span style={{ width: "0.5rem" }}></span>
              <BBtn
                title={t("Page.Vehicles.vehicleQR")}
                color={tab === "Vehicle QR" ? "#2C405A" : "#FF5C6A"}
                onClick={() => setTab("Vehicle QR")}
              />
            </div>
          </Col>
          {tab === "Flags" && (
            <>
              <Row style={{ backgroundColor: "white", padding: "1rem" }}>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.serviceStartEnd}
                      onChange={(e) => onChange(e, "serviceStartEnd")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["serviceStartEnd"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.serviceStartEnd")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.reBalancePickup}
                      onChange={(e) => onChange(e, "reBalancePickup")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["reBalancePickup"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.rebalancePickup")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.maintenancePickup}
                      onChange={(e) => onChange(e, "maintenancePickup")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["maintenancePickup"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.maintenancePickup")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.agencyPickup}
                      onChange={(e) => onChange(e, "agencyPickup")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["agencyPickup"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.agencyPickup")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.batteryCritical}
                      onChange={(e) => onChange(e, "batteryCritical")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["batteryCritical"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.batteryCritical")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch checked={!dataVehiclesAll?.available} disabled />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["unavailable"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.unavailable")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.stolen}
                      onChange={(e) => onChange(e, "stolen")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["stolen"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.stolen")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.ioTFault}
                      onChange={(e) => onChange(e, "ioTFault")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["ioTFault"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.ioTFault")}
                  </p>
                </Col>
                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.missing}
                      onChange={(e) => onChange(e, "missing")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["missing"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.missing")}
                  </p>
                </Col>

                <Col span={8}>
                  <p>
                    <Switch
                      checked={dataVehiclesAll?.rented}
                      onChange={(e) => onChange(e, "rented")}
                    />{" "}
                    <img
                      style={{ width: "25px" }}
                      src={FlagImg["rented"]}
                      alt=""
                    />{" "}
                    {t("Page.Maps.rented")}
                  </p>
                </Col>
              </Row>
            </>
          )}
          {tab === "Configuration" && (
            <>
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <p
                  className="va_p_title"
                  style={{ margin: "1rem 24px 0 24px" }}
                >
                  Filter By Type
                </p>
                <Row gutter={24} style={{ padding: "24px" }}>
                  {/*<Col span={8} style={{ margin: "12px 0" }}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: "#ECF0F3",*/}
                  {/*      padding: "1rem",*/}
                  {/*      height: "144px",*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="va_p1">Scooter Key</span>*/}
                  {/*    <InputGroup*/}
                  {/*      name="scooterKey"*/}
                  {/*      onChange={() => console.log(222)}*/}
                  {/*      placeHolder="Enter scooter key"*/}
                  {/*    />*/}
                  {/*    <div style={{ height: "0.5rem" }}></div>*/}
                  {/*    <BBtn title="Set key" color="#24BB69" />*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  {/*<Col span={8} style={{ margin: "12px 0" }}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: "#ECF0F3",*/}
                  {/*      padding: "1rem",*/}
                  {/*      height: "144px",*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="va_p1">Scooter Key</span>*/}
                  {/*    <div style={{ height: "0.5rem" }}></div>*/}
                  {/*    <BBtn title="Get key" color="#24BB69" />*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">Vehicle Speed Mode</span>
                      <ISelect
                        name="Vehicle Speed Mode"
                        onChange={(e) =>
                          setDataVehiclesAll((prestate) => ({
                            ...prestate,
                            speedMode: e,
                          }))
                        }
                        value={
                          dataVehiclesAll.speedMode === 0
                            ? "NONE"
                            : dataVehiclesAll.speedMode === 1
                            ? "LOW"
                            : dataVehiclesAll.speedMode === 2
                            ? "MEDIUM"
                            : "HIGH"
                        }
                        option={[
                          { text: "NONE", value: LOW_SPEED_TYPE.NONE },
                          { text: "LOW", value: LOW_SPEED_TYPE.LOW },
                          { text: "MEDIUM", value: LOW_SPEED_TYPE.MEDIUM },
                          { text: "HIGH", value: LOW_SPEED_TYPE.HIGH },
                        ]}
                      />
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn
                        title="Update"
                        onClick={handleEditSpeedMode}
                        color="#24BB69"
                      />
                    </div>
                  </Col>
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">
                        <Switch
                          checked={dataVehiclesAll.throttle}
                          onChange={(e) => handleEditThrottle(e)}
                        />{" "}
                        Throttle
                      </span>
                    </div>
                  </Col>
                  {/*<Col span={8} style={{ margin: "12px 0" }}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: "#ECF0F3",*/}
                  {/*      padding: "1rem",*/}
                  {/*      height: "144px",*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="va_p1">Current Scooter Speed</span>*/}
                  {/*    <div style={{ height: "0.5rem" }}></div>*/}
                  {/*    <BBtn title="Get Speed" color="#24BB69" />*/}
                  {/*      /!*<span className="va_p1">{dataVehiclesAll.speedMode}</span>*!/*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">Alarm</span>
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn title="Trigger Alarm" color="#24BB69" />
                    </div>
                  </Col>
                  {/*<Col span={8} style={{ margin: "12px 0" }}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: "#ECF0F3",*/}
                  {/*      padding: "1rem",*/}
                  {/*      height: "144px",*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="va_p1">Firmware Version</span>*/}
                  {/*    <div style={{ height: "0.5rem" }}></div>*/}
                  {/*    <BBtn title="Get Firmware" color="#24BB69" />*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  {/*<Col span={8} style={{ margin: "12px 0" }}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: "#ECF0F3",*/}
                  {/*      padding: "1rem",*/}
                  {/*      height: "144px",*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="va_p1">Duckt Scooter Registration</span>*/}
                  {/*    <div style={{ height: "0.5rem" }}></div>*/}
                  {/*    <BBtn title="Register" color="#24BB69" />*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  {/*<Col span={8} style={{ margin: "12px 0" }}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: "#ECF0F3",*/}
                  {/*      padding: "1rem",*/}
                  {/*      height: "144px",*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="va_p1">IOT Information</span>*/}
                  {/*    <div style={{ height: "0.5rem" }}></div>*/}
                  {/*    <BBtn title="Get IOT Info" color="#24BB69" />*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">Battery Unlock</span>
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn title="Unlock" color="#24BB69" />
                    </div>
                  </Col>
                </Row>
                <p
                  className="va_p_title"
                  style={{ margin: "1rem 24px 0 24px" }}
                >
                  Maximum Speed Limit For Speed Mode
                </p>
                <Row gutter={24} style={{ padding: "24px" }}>
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">Low Speed Limit (km/h)</span>
                      <ISelect
                        name="LowSpeedMode"
                        value={dataVehiclesAll.configuration.lowSpeed.toString()}
                        onChange={(e) =>
                          setDataVehiclesAll((pre) => ({
                            ...pre,
                            configuration: {
                              ...pre.configuration,
                              lowSpeed: Number(e),
                            },
                          }))
                        }
                        option={[
                          { text: "6", value: 6 },
                          { text: "10", value: 10 },
                          { text: "15", value: 15 },
                          { text: "20", value: 20 },
                          { text: "25", value: 25 },
                        ]}
                      />
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn
                        title="Update"
                        onClick={handleSpeed}
                        color="#24BB69"
                      />
                    </div>
                  </Col>
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">Medium Speed Limit (km/h)</span>
                      <ISelect
                        name="MediumSpeedMode"
                        onChange={(e) =>
                          setDataVehiclesAll((pre) => ({
                            ...pre,
                            configuration: {
                              ...pre.configuration,
                              mediumSpeed: Number(e),
                            },
                          }))
                        }
                        value={dataVehiclesAll.configuration.mediumSpeed.toString()}
                        option={[
                          { text: "6", value: 6 },
                          { text: "10", value: 10 },
                          { text: "15", value: 15 },
                          { text: "20", value: 20 },
                          { text: "25", value: 25 },
                        ]}
                      />
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn
                        title="Update"
                        onClick={handleSpeed}
                        color="#24BB69"
                      />
                    </div>
                  </Col>
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">High Speed Limit (km/h)</span>
                      <ISelect
                        name="HighSpeedMode"
                        onChange={(e) =>
                          setDataVehiclesAll((pre) => ({
                            ...pre,
                            configuration: {
                              ...pre.configuration,
                              highSpeed: Number(e),
                            },
                          }))
                        }
                        value={dataVehiclesAll.configuration.highSpeed.toString()}
                        option={[
                          { text: "6", value: 6 },
                          { text: "10", value: 10 },
                          { text: "15", value: 15 },
                          { text: "20", value: 20 },
                          { text: "25", value: 25 },
                        ]}
                      />
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn
                        title="Update"
                        onClick={handleSpeed}
                        color="#24BB69"
                      />
                    </div>
                  </Col>
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">
                        Location update frequency (Off Ride) (In Seconds)
                      </span>
                      <InputGroup
                        name="locationTimeUpdateOffRide"
                        type="number"
                        value={
                          dataVehiclesAll.configuration
                            .locationTimeUpdateOffRide
                        }
                        onChange={(e) =>
                          setDataVehiclesAll((pre) => ({
                            ...pre,
                            configuration: {
                              ...pre.configuration,
                              locationTimeUpdateOffRide: Number(e),
                            },
                          }))
                        }
                      />
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn
                        title="Edit"
                        disabled
                        onClick={handleUpdateRide}
                        color="#24BB69"
                      />
                    </div>
                  </Col>
                  <Col span={8} style={{ margin: "12px 0" }}>
                    <div
                      style={{
                        backgroundColor: "#ECF0F3",
                        padding: "1rem",
                        height: "144px",
                      }}
                    >
                      <span className="va_p1">
                        Location update frequency (On Ride) (In Seconds)
                      </span>
                      <InputGroup
                        name="locationTimeUpdateOnRide"
                        type="number"
                        value={
                          dataVehiclesAll.configuration.locationTimeUpdateOnRide
                        }
                        onChange={(e) =>
                          setDataVehiclesAll((pre) => ({
                            ...pre,
                            configuration: {
                              ...pre.configuration,
                              locationTimeUpdateOnRide: Number(e),
                            },
                          }))
                        }
                      />
                      <div style={{ height: "0.5rem" }}></div>
                      <BBtn
                        title="Edit"
                        disabled
                        onClick={handleUpdateRide}
                        color="#24BB69"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {tab === "Edit Vehicle" && (
            <>
              <Row
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "0.5rem",
                }}
              >
                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <span className="va_p1">Vehicle Type</span>
                    <div style={{ height: "4px" }}></div>
                    <ISelect
                      name="VehicleTypeUpdate"
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          typeDevice: e,
                        }))
                      }
                      value={dataVehiclesAll.typeDevice}
                      option={optionType}
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="vehicleName"
                      title="Vehicle Name"
                      required
                      value={dataVehiclesAll.name}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          name: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <span className="va_p1">Fleet</span>
                    <div style={{ height: "4px" }}></div>
                    <ISelect
                      name="Fleet"
                      option={listOptionFleet || []}
                      value={dataVehiclesAll.fleet.name}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          fleet: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="GPS IMEI"
                      title="GPS IMEI"
                      value={dataVehiclesAll.gpsImei}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          gpsImei: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="IMEI"
                      title="IMEI"
                      required
                      value={dataVehiclesAll.imei}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          imei: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      title="QR Code"
                      required
                      name="QR Code"
                      value={dataVehiclesAll.qrCode}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          qrCode: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <Row style={{ padding: "0.5rem", alignItems: "end" }}>
                    <InputGroup
                      name="Sim ICCID Number"
                      title="Sim ICCID Number"
                      value={dataVehiclesAll.iccid}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          iccid: e,
                        }))
                      }
                    />
                    <div style={{ width: "10px" }}></div>
                    <BBtn
                      icon1={<i className="fa-solid fa-download"></i>}
                      color="#24BB69"
                    />
                  </Row>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="Segway RFID"
                      title="Segway RFID"
                      value={dataVehiclesAll.xiaomiRfid}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          xiaomiRfid: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <Row style={{ padding: "0.5rem", alignItems: "end" }}>
                    <InputGroup
                      name="Mac Address"
                      title="Mac Address"
                      value={dataVehiclesAll.macAddress}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          macAddress: e,
                        }))
                      }
                    />
                    <div style={{ width: "10px" }}></div>
                    <BBtn
                      icon1={<i className="fa-solid fa-download"></i>}
                      color="#24BB69"
                    />
                  </Row>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="Device Notes"
                      title="Device Notes"
                      value={dataVehiclesAll.deviceNotes}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          deviceNotes: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                {/* <Col span={8}>
                  <div style={{padding: '0.5rem'}}>
                    <InputGroup
                      name='Domain'
                      title='Domain'
                      required
                      onChange={() => console.log(1111)}
                    />
                  </div>
                </Col> */}

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="Port"
                      title="Port"
                      required
                      value={dataVehiclesAll.port}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          port: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      disabled
                      name="Registered Date"
                      title="Registered Date"
                      value={moment(dataVehiclesAll.createdAt).format(
                        "YYYY/MM/DD HH:mm:ss",
                      )}
                      onChange={() => console.log(1111)}
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="Vehicle SN"
                      title="Vehicle SN"
                      value={dataVehiclesAll.vehicleSN}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          vehicleSN: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <InputGroup
                      name="Vehicle Lock Code "
                      title="Vehicle Lock Code "
                      value={dataVehiclesAll.vehicleLockCode}
                      onChange={(e) =>
                        setDataVehiclesAll((prestate) => ({
                          ...prestate,
                          vehicleLockCode: e,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div style={{ padding: "0.5rem" }}>
                    <span className="va_p1">Helmet Attached</span>
                    <div style={{ height: "4px" }}></div>
                    <ISelect
                      name="Helmet Attached"
                      onChange={() => console.log(111)}
                      option={[
                        {
                          text: "ACTON Nexus eBike IoT",
                          value: "ACTON Nexus eBike IoT",
                        },
                        { text: "AXA ERL2", value: "AXA ERL2" },
                        { text: "AXA", value: "AXA" },
                      ]}
                    />
                  </div>
                </Col>
                <Row
                  style={{ borderTop: "1px solid #E1E1E1", width: "100%" }}
                ></Row>
                <br />
                <Row justify="end" style={{ width: "100%" }}>
                  <BBtn title="Delete" color="#FF5C6A" />
                  <div style={{ width: "1rem" }}></div>
                  <BBtn
                    title="Save Changes"
                    onClick={handleEditVehicle}
                    color="#24BB69"
                  />
                </Row>
              </Row>
            </>
          )}
          {tab === "Console Logs" && (
            <>
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  padding: "1rem",
                }}
              >
                <BBtn title="Expand Logs" color="#2C405A" />
                <div
                  style={{
                    overflow: "auto",
                    width: "100%",
                    height: "200px",
                    border: "1px solid #E1E1E1",
                    marginTop: "1rem",
                  }}
                ></div>
              </div>
            </>
          )}
          {tab === "Ride History" && (
            <Col span={24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "1rem" }}>Show</span>
                    <Select
                      size={size}
                      defaultValue={"Show"}
                      onChange={handleChange}
                    >
                      {children}
                    </Select>
                    <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                      Entries
                    </span>
                    <ISearch onChange={() => console.log(222)} />
                  </div>
                  <div>
                    <BBtn
                      title="CSV"
                      color="#24BB69"
                      icon1={<i className="fa-solid fa-file-lines"></i>}
                    />
                  </div>
                </div>
                <br />
                <ITable
                  // onClickRow={(params: any) => setDataFleet(params)}
                  column={[
                    {
                      title: "ID",
                      dataIndex: "id",
                    },
                    {
                      title: "Rider",
                      dataIndex: "rider",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "Vehicle",
                      dataIndex: "vehicle",
                    },

                    {
                      title: "Amount",
                      dataIndex: "amount",
                    },
                    {
                      title: "Duration",
                      dataIndex: "duration",
                    },
                    {
                      title: "Pause Duration",
                      dataIndex: "pauseDuration",
                    },
                    {
                      title: "Ride Date",
                      dataIndex: "rideDate",
                    },
                    {
                      title: "Rating",
                      dataIndex: "rating",
                      render: (text: string) => (
                        <span>
                          <Rate allowHalf value={Number(text)} />
                        </span>
                      ),
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                    },
                  ]}
                  total={100}
                  data={
                    [
                      // {
                      //   key: "1",
                      //   id: "28987",
                      //   rider: "sadsda",
                      //   vehicle: "KICKS46",
                      //   amount: "0 CAD",
                      //   duration: "00:32:08",
                      //   pauseDuration:
                      //     "1901 Thornridge Cir. Shiloh, Hawaii 81063",
                      //   rideDate: "2021/07/15 18:31:18",
                      //   rating: "2",
                      //   status: "Completed",
                      // },
                    ]
                  }
                  pageSize={20}
                  onChangePage={(page) => {
                    console.log("page", page);
                  }}
                />
              </div>
            </Col>
          )}
          {tab === "Vehicle QR" && (
            <>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  textAlign: "center",
                  padding: "2rem",
                }}
              >
                <p style={{ textAlign: "center" }}>Deep Link:</p>
                <img src="/img/qrCode.svg" alt="" />
                <p>Https://campusride-tiny.joyridecity.bike/VahNv</p>
              </div>
            </>
          )}
          {tab === "Timeline" && (
            <>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "1rem",
                }}
              >
                <p>No timeline data available for vehicle</p>
              </div>
            </>
          )}
        </Row>
      )}
      {!!selectedRowKeys.length && (
        <Row>
          <Row style={{ backgroundColor: "white", padding: "1rem" }}>
            <Col span={8}>
              <p>
                <Switch
                  onChange={(e) => handleChangeFlagList(e, "serviceStartEnd")}
                />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["serviceStartEnd"]}
                  alt=""
                />{" "}
                {t("Page.Maps.serviceStartEnd")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch
                  onChange={(e) => handleChangeFlagList(e, "reBalancePickup")}
                />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["reBalancePickup"]}
                  alt=""
                />{" "}
                {t("Page.Maps.rebalancePickup")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch
                  onChange={(e) => handleChangeFlagList(e, "maintenancePickup")}
                />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["maintenancePickup"]}
                  alt=""
                />{" "}
                {t("Page.Maps.maintenancePickup")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch
                  onChange={(e) => handleChangeFlagList(e, "agencyPickup")}
                />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["agencyPickup"]}
                  alt=""
                />{" "}
                {t("Page.Maps.agencyPickup")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch
                  onChange={(e) => handleChangeFlagList(e, "batteryCritical")}
                />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["batteryCritical"]}
                  alt=""
                />{" "}
                {t("Page.Maps.batteryCritical")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch disabled />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["unavailable"]}
                  alt=""
                />{" "}
                {t("Page.Maps.unavailable")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch onChange={(e) => handleChangeFlagList(e, "stolen")} />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["stolen"]}
                  alt=""
                />{" "}
                {t("Page.Maps.stolen")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch onChange={(e) => handleChangeFlagList(e, "ioTFault")} />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["ioTFault"]}
                  alt=""
                />{" "}
                {t("Page.Maps.ioTFault")}
              </p>
            </Col>
            <Col span={8}>
              <p>
                <Switch onChange={(e) => handleChangeFlagList(e, "missing")} />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["missing"]}
                  alt=""
                />{" "}
                {t("Page.Maps.missing")}
              </p>
            </Col>

            <Col span={8}>
              <p>
                <Switch onChange={(e) => handleChangeFlagList(e, "rented")} />{" "}
                <img
                  style={{ width: "25px" }}
                  src={FlagImg["rented"]}
                  alt=""
                />{" "}
                {t("Page.Maps.rented")}
              </p>
            </Col>
          </Row>
        </Row>
      )}

      <AddVehicleDialog
        open={openDialogAddVehicle}
        setOpen={() => setOpenDialogAddVehicle(!openDialogAddVehicle)}
        setClose={() => setOpenDialogAddVehicle(false)}
      />
      <MapVehicleDialog
        open={openMaps}
        setOpen={handleOpenMaps}
        long={long}
        lat={lat}
        lastConnect={lastConnect}
        refreshLocation={refreshLocation}
        data={dataVehiclesAll}
      />
      <AddMultipleVehiclesDialog
        open={openAddMultipleVehiclesDialog}
        setOpen={() =>
          setOpenAddMultipleVehiclesDialog(!openAddMultipleVehiclesDialog)
        }
      />
      <ErrorDialog
        open={!!error}
        setOpen={resetError}
        error={error ? error.response.data : undefined}
      />
    </div>
  );
};

export default VehicleAll;
