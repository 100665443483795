import React, {FC, useState} from "react";
import {AreaModel} from "../../../../models/area.model";
import {useAppDispatch} from "../../../../app/hooks";
import {Checkbox, Col, message, Row} from "antd";
import {areaAction} from "../../../../features/area/areaSlice";
import ISelect from "../../../../components/select";
import InputGroup from "../../../../components/textInput";
import TextArea from "antd/lib/input/TextArea";
import BBtn from "../../../../components/button";

interface InputAddAreaProps {
    listOptionFleet: { text: string, value: any }[]
    handleGetPolygon: () => any
    setAddServiceArea: React.Dispatch<React.SetStateAction<boolean>>
    getListArea: () => void
}
export const InputAddArea: FC<InputAddAreaProps> = (props) => {
    const [dataServiceArea, setDataServiceArea] = useState<AreaModel>({} as AreaModel);
    const dispatch = useAppDispatch();
    const handleAdd = () => {
        const location = props.handleGetPolygon();
        if (Object.keys(dataServiceArea).length) {
            const dataAdd = {
                ...dataServiceArea,
                type: "SERVICE",
                fleet: dataServiceArea.fleet._id,
                triggerAlarm: !!dataServiceArea.triggerAlarm,
                throttle: !!dataServiceArea.throttle,
                coordinates:location&& location.length ? [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])] : [[[]]]
            };
            if (location&&location.length > 1) {
                message.error("Can not create more than one polygon").then();
            }

            dispatch(areaAction.addArea(dataAdd))
            props.getListArea()
            props.setAddServiceArea(false)
            setDataServiceArea({} as AreaModel)
        }
    };
    return (
        <div style={{backgroundColor: "white", padding: "1rem"}}>
            <p className="role_title">Service Area</p>
            <Row gutter={16}>
                <Col span={12} style={{paddingTop: "0.4rem"}}>
                    <p className="fleets-p1">Fleet</p>
                    <ISelect
                        name="Fleet"
                        option={props.listOptionFleet}
                        onChange={(e) =>
                            setDataServiceArea((prestate: AreaModel) => ({
                                ...prestate,
                                fleet: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Name"
                        name="Name"
                        onChange={(e) =>
                            setDataServiceArea((prestate) => ({
                                ...prestate,
                                name: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12} style={{paddingTop: "0.4rem"}}>
                    <p className="fleets-p1">Type</p>
                    <ISelect
                        name="Type"
                        option={[{text: "circle", value: "circle"}, {text: "Point", value: "Point"}, {
                            text: "Polygon",
                            value: "Polygon"
                        }]}
                        onChange={(e) =>
                            setDataServiceArea((prestate) => ({
                                ...prestate,
                                locationType: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <span className="org_address">Description</span>
                    <TextArea
                        maxLength={100}
                        style={{height: 60, marginTop: "5px"}}
                        onChange={(e) =>
                            setDataServiceArea((prestate) => ({
                                ...prestate,
                                description: e.target.value,
                            }))
                        }
                    />
                </Col>
                <Col>
                    <p className="fleets-p1">
                        Vehicles inside the restricted boundary should
                    </p>
                    <div>
                        <Checkbox onChange={(e) =>
                            setDataServiceArea((prestate) => ({
                                ...prestate,
                                throttle: e.target.checked,
                            }))
                        }>
                            Throttle
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox
                            onChange={(e) =>
                                setDataServiceArea((prestate) => ({
                                    ...prestate,
                                    triggerAlarm: e.target.checked,
                                }))
                            }
                        >
                            Trigger Alarm
                        </Checkbox>
                    </div>
                </Col>
            </Row>
            <br/>
            <Row style={{borderTop: "1px solid #E1E1E1"}}></Row>
            <br/>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <BBtn
                    title="Back"
                    color="#FF5C6A"
                    onClick={()=>props.setAddServiceArea(false)}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <BBtn title="Save Changes" onClick={handleAdd} color="#24BB69"/>
                </div>
            </div>
        </div>
    );
};