import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import { CampaignModel } from '../../models/campaign.model'
import {PaginationModel} from '../../models/paginationModel'

export interface CampaignState {
  loadding: boolean
  listCampaign: CampaignModel[] | undefined
  itemCount: number | undefined
  campaign : CampaignModel | undefined
}
const initialState: CampaignState = {
  loadding: false,
  listCampaign: undefined,
  itemCount: undefined,
  campaign: undefined,
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    getListCampaign(state, action: PayloadAction<PaginationModel>) {
      state.loadding = true
    },
    getListCampaignSuccess(state, action: PayloadAction<CampaignModel[]>) {
      state.listCampaign = action.payload
      state.loadding = false
    },
    getListCampaignFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateCampaign(
      state,
      action: PayloadAction<Partial<CampaignModel>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addCampaign(
      state,
      action: PayloadAction<Partial<CampaignModel>>
    ) {
      state.loadding = true
    },
    detailCampaign(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailCampaignSuccess(state, action : PayloadAction<CampaignModel> ) {
      state.loadding = true
      state.campaign = action.payload
    },
    deleteCampaign(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
  },
})
export const campaignAction = campaignSlice.actions
export const selectItemCount = (state: RootState) => state.campaign.itemCount
export const selectListCampaign = (state: RootState) => state.campaign.listCampaign
export const selectCampaign = (state: RootState) => state.campaign.campaign
export const selectLoading = (state: RootState) => state.campaign.loadding

export const campaignReducer = campaignSlice.reducer
