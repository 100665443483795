import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {OrgModel} from '../../models/org.model'
import {UserOrg} from "../../models/userOrg.model";

export interface OrgState {
  loadding: boolean
  listOrg: OrgModel[] | undefined
  itemCount: number | undefined
  listUserOrg : UserOrg[] | undefined
  itemCountUserOrg? : number
}

const initialState: OrgState = {
  loadding: false,
  listOrg: undefined,
  itemCount: undefined,
  listUserOrg: undefined,
  itemCountUserOrg : undefined
}

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    getListOrg(state) {
      state.loadding = true
    },
    getListOrgSuccess(state, action: PayloadAction<OrgModel[]>) {
      state.listOrg = action.payload
      state.loadding = false
    },
    getListOrgFail(state) {
      state.loadding = false
    },
    getListOrgPagination(
      state,
      action: PayloadAction<{
        page: number
        limit: number
        keyword?: string
        'orderBy[id]'?: 'ASC' | 'DESC'
        'orderBy[name]'?: 'ASC' | 'DESC'
      }>
    ) {
      state.loadding = true
    },
    getListOrgPaginationSuccess(state, action: PayloadAction<OrgModel[]>) {
      state.listOrg = action.payload
      state.loadding = false
    },
    getListOrgPaginationFail(state) {
      state.loadding = false
    },
    updateOrg(state, action: PayloadAction<Partial<OrgModel>>) {
      state.loadding = true
    },
    updateOrgSuccess(state) {
      state.loadding = false
    },
    updateOrgFail(state) {
      state.loadding = false
    },
    addOrg(state, action: PayloadAction<{name: string}>) {
      state.loadding = true
    },
    addOrgSuccess(state) {
      state.loadding = false
    },
    addOrgFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    setItemCountUserOrg(state, action: PayloadAction<number>) {
      state.itemCountUserOrg = action.payload
    },
    getListUserOrgPagination(state , action:PayloadAction<{
      page: number
      limit: number
      keyword?: string
      organisation_id : string
      'orderBy[id]'?: 'ASC' | 'DESC'
      'orderBy[name]'?: 'ASC' | 'DESC'
    }>){
      state.loadding = true
    },
    getListUserOrgPaginationSuccess(state ,  action: PayloadAction<UserOrg[]>) {
      state.listUserOrg = action.payload
      state.loadding = false
    } ,
    getListUserOrgPaginationFail(state) {
      state.loadding = false
    },
    updateUserOrg(state, action: PayloadAction<Partial<UserOrg>>) {
      state.loadding = true
    },
    updateUserOrgSuccess(state) {
      state.loadding = false
    },
    updateUserOrgFail(state) {
      state.loadding = false
    },
    addUserOrg(state, action: PayloadAction<Partial<UserOrg>>) {
      state.loadding = true
    },
    addUserOrgSuccess(state) {
      state.loadding = false
    },
    addUserOrgFail(state) {
      state.loadding = false
    },
  },
})

export const orgAction = orgSlice.actions



export const selectListOrg = (state: RootState) =>
  state.org.listOrg

export const selectListUserOrg = (state: RootState) => state.org.listUserOrg
export const selectItemCount = (state: RootState) => state.org.itemCount
export const selectItemCountUserOrg = (state: RootState) => state.org.itemCountUserOrg
export const selectLoadingOrg = (state: RootState) => state.org.loadding

export const orgReducer = orgSlice.reducer
