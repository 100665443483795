import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { PaginationModel } from "../../models/paginationModel";
import { AreaModel } from "../../models/area.model";
import { AreaPrams } from "../../apis/areaApi";

export interface AreaState {
  loadding: boolean;
  listArea: AreaModel[] | undefined;
  itemCount: number | undefined;
  area: AreaModel | undefined;
  error : any
}
const initialState: AreaState = {
  loadding: false,
  listArea: undefined,
  itemCount: undefined,
  area: undefined,
  error : undefined
};

const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    getListArea(state, action: PayloadAction<AreaPrams>) {
      state.loadding = true;
    },
    getListAreaSuccess(state, action: PayloadAction<AreaModel[]>) {
      state.listArea = action.payload;
      state.loadding = false;
    },
    getListAreaFail(state) {
      state.loadding = false;
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload;
    },
    updateArea(state, action: PayloadAction<Partial<AreaModel>>) {
      state.loadding = true;
    },
    success(state) {
      state.loadding = false;
    },
    fail(state,action: PayloadAction<any>) {
      state.loadding = false;
      state.error = action.payload
    },
    addArea(state, action: PayloadAction<Partial<AreaModel>>) {
      state.loadding = true;
    },
    detailArea(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    detailAreaSuccess(state, action: PayloadAction<AreaModel>) {
      state.loadding = true;
      state.area = action.payload;
    },
    deleteArea(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    resetError(state) {
      state.error = undefined
    }
  },
});
export const areaAction = areaSlice.actions;
export const selectItemCount = (state: RootState) => state.area.itemCount;
export const selectListArea = (state: RootState) => state.area.listArea;
export const selectArea = (state: RootState) => state.area.area;
export const selectLoading = (state: RootState) => state.area.loadding;
export const selectError= (state: RootState) => state.area.error;

export const areaReducer = areaSlice.reducer;
