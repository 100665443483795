import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {OfferModel} from "../models/offer.model";

export const offerApi = {
    getAllOffer(params: {
        page: number | undefined,
        limit: number | undefined,
        keyword?: string,
        fleet?: string;
        startTime?: Date;
        endTime?: Date;
        'orderBy[id]'?: 'ASC' | 'DESC',
        'orderBy[title]'?: 'ASC' | 'DESC',
    }) {
        return axiosClient.getService(`${urlApi.OFFER}`, {...params})
    },
    createOffer(params: Partial<OfferModel>) {
        return axiosClient.postService(`${urlApi.OFFER}`, {...params})
    },
    updateOffer(params: Partial<OfferModel>) {
        return axiosClient.updateService(`${urlApi.OFFER}/${params._id}`, {...params})
    },
    deleteOffer(id: string) {
        return axiosClient.deleteService(`${urlApi.OFFER}/${id}`)
    },
    detailOffer(id: string) {
        return axiosClient.getService(`${urlApi.OFFER}/${id}`)
    },
}
