import { Radio, Space, Switch } from "antd";
import styled from "styled-components";
import { AppColors } from "../../../utils/appColors";
import BBtn from "../../../components/button";
import InputGroup from "../../../components/textInput";
import { TabSetting, TAB_SETTING } from "../../../utils/constants";
import React, { useEffect, useState } from "react";
import ISelect from "../../../components/select";
import { SettingModel } from "../../../models/setting.model";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { settingAction } from "../../../features/setting/settingSlice";
import {
  selectTypeVehicle,
  vehicleAction,
} from "../../../features/vehicle/vehicleSlice";

interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabVehicles = (props: IProps) => {
  let initalValue = "isUser";
  if (props.setting.vehicle) {
    initalValue = Object.keys(
      props.setting.vehicle.rideEndLocationVerification
    ).filter(
      (v) =>
        props.setting.vehicle?.rideEndLocationVerification[
          v as keyof SettingModel["vehicle"]["rideEndLocationVerification"]
        ]
    )[0];
  }

  const dispatch = useAppDispatch();
  const typeVehicle = useAppSelector(selectTypeVehicle);
  const [value, setValue] = useState(initalValue);
  useEffect(() => {
    dispatch(vehicleAction.getTypeVehicle());
  }, [dispatch]);
  const optionType = typeVehicle?.map((item) => {
    return { text: item.value, value: item.key };
  });

  const handleChange = (value: string) => {
    const newObj: SettingModel["vehicle"]["rideEndLocationVerification"] = {
      isUser: false,
      isUserAndVehicle: false,
      isUserOrVehicle: false,
      isVehicle: false,
    };
    props.setting.vehicle &&
      dispatch(
        settingAction.updateSetting({
          vehicle: {
            ...props.setting.vehicle,
            rideEndLocationVerification: { ...newObj, [value]: true },
          },
          _id: props.setting._id,
        })
      );
    props.onUpdate();
  };

  return (
    <div className="content">
      <div className="sub-title">Scooter Configuration</div>
      <div className="divider"></div>
      <div className="c-row flex-start space-between">
        <div className="c-column">
          <span className="t-medium">Ride End Location Verification</span>
          <div style={{ height: 12 }}></div>
          <Radio.Group
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              handleChange(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio className="t-medium" value={"isUser"}>
                User
              </Radio>
              <Radio className="t-medium" value={"isVehicle"}>
                Vehicle
              </Radio>
              <Radio className="t-medium" value={"isUserAndVehicle"}>
                User & Vehicle
              </Radio>
              <Radio className="t-medium" value={"isUserOrVehicle"}>
                User or Vehicle
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="c-column">
          <div className="flex-1 c-row space-between">
            <span className="t-medium">Torch On Ride</span>
            <div style={{ width: 80 }}></div>
            <Switch
              defaultChecked={props.setting.vehicle?.isTorchOnRide}
              onChange={(checked) => {
                props.setting.vehicle &&
                  dispatch(
                    settingAction.updateSetting({
                      vehicle: {
                        ...props.setting.vehicle,
                        isTorchOnRide: checked,
                      },
                      _id: props.setting._id,
                    })
                  );
                props.onUpdate();
              }}
            />
          </div>
          <div className="divider"></div>
        </div>
        <div className="c-row">
          <InputGroup
            onChange={(e) => {
              props.setting.vehicle &&
                dispatch(
                  settingAction.updateSetting({
                    vehicle: {
                      ...props.setting.vehicle,
                      automaticLockVehicle: Number(e),
                    },
                    _id: props.setting._id,
                  })
                );
              props.onUpdate();
            }}
            name="Automatic Lock Vehicle (In Minutes)"
            title="Automatic Lock Vehicle (In Minutes)"
            value={props.setting.vehicle?.automaticLockVehicle}
            option={[
              { text: "10", value: 10 },
              { text: "20", value: 20 },
            ]}
            width={350}
          />
        </div>
      </div>
      <div style={{ height: 20 }}></div>
      <span style={{ fontSize: 18 }} className="t-medium">
        Filter By Type
      </span>
      <div style={{ height: 20 }}></div>
      <ISelect
        name="selectFleets"
        placeHolder="None"
        option={optionType || []}
        onChange={() => {
          console.log(111);
        }}
        width="374px"
      />
      <div className="divider"></div>
      <div className="c-column center">
        <span className="t-medium">
          No Configuration Found, Please try with another type
        </span>
      </div>
    </div>
  );
};

export default TabVehicles;
