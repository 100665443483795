import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {SettingModel} from "../../models/setting.model";

export interface SettingState {
  loadding: boolean
  listSetting: SettingModel[] | undefined
  setting : SettingModel | undefined
}
const initialState: SettingState = {
  loadding: false,
  listSetting: undefined,
  setting: undefined,
}

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    getListSetting(state) {
      state.loadding = true
    },
    getListSettingSuccess(state, action: PayloadAction<SettingModel[]>) {
      state.listSetting = action.payload
      state.loadding = false
    },
    getListSettingFail(state) {
      state.loadding = false
    },
    updateSetting(
      state,
      action: PayloadAction<Partial<SettingModel>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addSetting(
      state,
      action: PayloadAction<Partial<SettingModel>>
    ) {
      state.loadding = true
    },
    detailSetting(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailSettingSuccess(state, action : PayloadAction<SettingModel> ) {
      state.loadding = true
      state.setting = action.payload
    },
    deleteSetting(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
  },
})
export const settingAction = settingSlice.actions
export const selectListSetting = (state: RootState) => state.setting.listSetting
export const selectSetting = (state: RootState) => state.setting.setting
export const selectLoading = (state: RootState) => state.setting.loadding

export const settingReducer = settingSlice.reducer
