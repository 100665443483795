import { IamportStoreModel } from "../models/IamportStore.model";

export const urlApi = {
  AUTH_LOGIN: "/auth/login",
  ROLE: "/roles",
  PERMISSION: "/permissions",
  FLEET: "/fleet",
  ROLE_PERMISSION: "/roles/permission",
  BILLING_PLAN: "/billing-plan",
  TAX: "/tax",
  ORG: "/organisation",
  USER: "/users",
  COUNTRY: "/country",
  VEHICLE: "/vehicle",
  PROMOTION: "/promo",
  RIDER: "/rider",
  BANNER: "/banner",
  SETTING: "/setting",
  AREA: "/area",
  CAMPAIGN: "/campaign",
  OFFER: "/offer",
  ORDER: "/order",
  FEEDBACK: "/feedback",
  FEEDBACK_OPTION: "/feedback-option",
  REFUND: "/refund-payment",
  ANALYTIC_PAYMENT: "/analytic-payment",
  INVOICE: "/invoice",
  VERSION: "/version",
  ANALYTIC_RIDE: "/analytic-ride",
  IAMPORTSTORE: "/iamport-store",
  UPLOAD: "/uploads",
};
