import React, {FC, useState} from "react";
import {Checkbox, Col, message, Row, Switch} from "antd";
import ISelect from "../../../../components/select";
import InputGroup from "../../../../components/textInput";
import TextArea from "antd/lib/input/TextArea";
import BBtn from "../../../../components/button";
import {AreaModel} from "../../../../models/area.model";
import {areaAction} from "../../../../features/area/areaSlice";
import {useAppDispatch} from "../../../../app/hooks";

interface InputEditAreaParkingProps {
    listOptionFleet: { text: string, value: any }[]
    handleGetPolygon: () => any
    getListArea: () => void
    dataParkingArea : AreaModel
    setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>
    setDataParkingArea :  React.Dispatch<React.SetStateAction<AreaModel>>
}
export const InputEditAreaParking : FC<InputEditAreaParkingProps> = ({getListArea,listOptionFleet,dataParkingArea,handleGetPolygon,setOpenDelete,setDataParkingArea}) => {
    const [dataEdit,setDataEdit] = useState<AreaModel>(dataParkingArea)
    const dispatch = useAppDispatch();
    const handleEdit = () => {
        const location = handleGetPolygon();
        if (Object.keys(dataEdit).length) {
            const dataAdd = {
                ...dataEdit,
                type: "PARKING",
                fleet: dataEdit.fleet._id,
                ...(location && location.length
                    ? { coordinates: [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])] }
                    : {}),
            };
            if (location&&location.length > 1) {
                message.error("Can not update more than one polygon").then();
            }
            dispatch(areaAction.updateArea(dataAdd));
            getListArea();
            setDataParkingArea((pre) => ({
                ...pre,
                location: {
                    ...pre.location,
                    ...(location && location.length
                        ? { coordinates: [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])] }
                        : { coordinates: pre.location.coordinates }),
                },
            }));
        }
    };
    return (
        <div style={{ backgroundColor: "white", padding: "1rem" }}>
            <p className="role_title">Edit Parking</p>
            <Row gutter={16}>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Fleet</p>
                    <ISelect
                        name="Fleet"
                        option={listOptionFleet || []}
                        value={dataEdit.fleet.name}
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                fleet: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Name"
                        name="Name"
                        value={dataEdit.name}
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                name: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Type</p>
                    <ISelect
                        name="Fleet"
                        option={[
                            { text: "circle", value: "circle" },
                            {
                                text: "Point",
                                value: "Point",
                            },
                            { text: "Polygon", value: "Polygon" },
                        ]}
                        value={dataEdit.location.type}
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                locationType: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Minimum Vehicles"
                        name="Minimum Vehicles"
                        type="number"
                        value={dataEdit.minVehicle}
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                minVehicle: Number(e),
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Maximum Vehicles"
                        name="Maximum Vehicles"
                        value={dataEdit.maxVehicle}
                        type="number"
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                maxVehicle: Number(e),
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <span className="org_address">Description</span>
                    <TextArea
                        maxLength={100}
                        style={{ height: 60, marginTop: "5px" }}
                        value={dataEdit.description}
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                description: e.target.value,
                            }))
                        }
                    />
                </Col>
                <Col span={12}></Col>
                <Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                    }}
                >
                    <span className="org_address">visible</span>
                    <Switch
                        defaultChecked={dataEdit.visible}
                        onChange={(e) =>
                            setDataEdit((prestate) => ({
                                ...prestate,
                                visible: e,
                            }))
                        }
                    />
                </Col>
            </Row>
            <br />
            <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <BBtn
                    title="Back"
                    color="#FF5C6A"
                    onClick={() => setDataParkingArea({} as AreaModel)}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {/*<BBtn title="Deactivate" color="#FF5C6A" />*/}
                    {/*<div style={{ width: "16px" }}></div>*/}
                    <BBtn
                        title="Delete"
                        onClick={() => setOpenDelete(true)}
                        color="#FF5C6A"
                    />
                    <div style={{ width: "16px" }}></div>
                    <BBtn
                        title="Save Changes"
                        onClick={handleEdit}
                        color="#24BB69"
                    />
                </div>
            </div>
        </div>
    );
};
