import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {bannerAction} from "./bannerSlice";
import {bannerApi} from "../../apis/bannerApi";
import {BannerModel} from "../../models/banner.model";

function* getListBanner(action: PayloadAction<{
  page: number | undefined,
  limit: number | undefined,
  keyword?: string,
  status?: 'ACTIVE' | 'DE_ACTIVE',
  'orderBy[id]'?: 'ASC' | 'DESC',
  'orderBy[title]'?: 'ASC' | 'DESC',
}>) {
  try {
    const listBanner: {
      data: {
        nodes: BannerModel[]
        itemCount: number
      }
    } = yield call(bannerApi.getAllBanner, action.payload)
    yield put(bannerAction.getListBannerSuccess(listBanner.data.nodes))
    yield put(bannerAction.setItemCount(listBanner.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}
function* updateBanner(
  action: PayloadAction<Partial<BannerModel>>
) {
  try {
    const banner: {description: string} = yield call(
      bannerApi.updateBanner,
      action.payload
    )
    yield put (bannerAction.success())
    message.success(banner.description).then()
  } catch (error: any) {
    yield put (bannerAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* addBanner(action: PayloadAction<Partial<BannerModel>>) {
  try {
    const banner: {description: string} = yield call(bannerApi.createBanner, action.payload)
    message.success(banner.description).then()
    yield put (bannerAction.success())
  } catch (error: any) {
    yield put (bannerAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* detailBanner(action: PayloadAction<{id : string}>) {
  try {
    const banner: {description: string , data : BannerModel} = yield call(bannerApi.detailBanner, action.payload.id)
    yield put (bannerAction.detailBannerSuccess(banner.data))
    message.success(banner.description).then()
  } catch (error: any) {
    yield put (bannerAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* deleteBanner(action: PayloadAction<{id : string}>) {
  try {
    const banner: {description: string } = yield call(bannerApi.deleteBanner, action.payload.id)
    message.success(banner.description).then()
    yield put (bannerAction.success())
  } catch (error: any) {
    yield put (bannerAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}


export default function* bannerSaga() {
  yield takeEvery(bannerAction.getListBanner.type, getListBanner)
  yield takeEvery(bannerAction.updateBanner.type, updateBanner)
  yield takeEvery(bannerAction.addBanner.type, addBanner)
  yield takeEvery(bannerAction.detailBanner.type, detailBanner)
  yield takeEvery(bannerAction.deleteBanner.type, deleteBanner)
}
