import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import "./error.css";
import BBtn from "../../button";

export const ErrorDialog = (props: {
  open: boolean;
  setOpen: () => void;
  error?: any;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  return (
    <>
      <Modal
          className='modalDelete'
        title="Error"
        open={props.open}
        confirmLoading={confirmLoading}
        okText={<></>}
        cancelText={<></>}
        onCancel={() => props.setOpen()}
        width={400}

      >
        <div
          style={{
            display: "flex",
            width: "100%",
            // height: "180px",
            minHeight: "180px",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
            gap: "10px",
          }}
        >
          {props.error &&
            props.error.message &&
            Array.isArray(props.error.message) && (
              <ul >
                {props.error.message.map((item: any, index: number) => (
                  <li key={index} style={{ fontSize: "16px" }}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
          {props.error &&
            props.error.message &&
            !Array.isArray(props.error.message) && (
              <span style={{ fontSize: "16px" }}>{props.error.message}</span>
            )}
          {props.error && props.error.msg && (
            <span style={{ fontSize: "16px" }}>{props.error.msg}</span>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "end",
            }}
          >
            <BBtn title="Yes" onClick={() => props.setOpen()} color="#3c8dbc" />
            <BBtn title="No" onClick={() => props.setOpen()} color="#3c8dbc" />
          </div>
        </div>
      </Modal>
    </>
  );
};
