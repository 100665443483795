import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {analyticRideAction} from "./analyticRideSlice";
import {analyticRideApi} from "../../apis/analyticRideApi";
import {AnalyticRideModel} from "../../models/analyticRideModel";

function* getChartRidePerHour(action: PayloadAction<{
  startTime: string;
  endTime: string;
  fleet?: string;
  type: "RIDE" | "TOTAL_RIDE";
}>) {
  try {
    const chart: {
      data:  { hour: number; totalRides: number }[]
    } = yield call(analyticRideApi.getChartRidePerHour, action.payload)
    yield put(analyticRideAction.getChartRidePerHourSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}
function* getChartRideByDay(action: PayloadAction<{
  startTime: string;
  endTime: string;
  fleet?: string;
  type: "RIDE" | "TOTAL_RIDE";
}>) {
  try {
    const chart: {
      data: {date : string , totalRides : number}[]
    } = yield call(analyticRideApi.getChartRideByDay, action.payload)
    yield put(analyticRideAction.getChartRideByDaySuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}
function* getChartUserSignUp(action: PayloadAction<{
  startTime?: string;
  endTime?: string;
  fleet?: string;
  type: "RIDE" | "TOTAL_RIDE";
}>) {
  try {
    const chart: {
      data: {date : string , totalUser : number}[]
    } = yield call(analyticRideApi.getChartUserSignUp, action.payload)
    yield put(analyticRideAction.getChartUserSignUpSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}
function* getStatisticRider(action: PayloadAction<{
  startLastMonth: string;
  endLastMonth: string;
  fleet?: string;
  type: "RIDE" | "TOTAL_RIDE";
}>) {
  try {
    const chart: {
      data: {
        countUser : number,
        usersInLastMonth : number,
        monthlyActiveUsers : number,
      }
    } = yield call(analyticRideApi.getStatisticUser, action.payload)
    yield put(analyticRideAction.getStatisticRiderSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}
function* getStatisticRide(action: PayloadAction<{
  startTime: string;
  endTime: string;
  fleet?: string;
  type: "RIDE" | "TOTAL_RIDE";
}>) {
  try {
    const chart: {
      data: {
        "averageUsingVehicle": number,
        "averageRide": number,
        "countTotalRides": number,
        "averageRidesPerDay": number
      }
    } = yield call(analyticRideApi.getStatisticRide, action.payload)
    yield put(analyticRideAction.getStatisticRideSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}
function* getStatisticTicket(action: PayloadAction<{
  startTime: string;
  endTime: string;
  fleet?: string;
  type: "RIDE" | "TOTAL_RIDE";
}>) {
  try {
    const chart: {
      data: {
        ticketPending: number,
        avgTicketPerDay: number,
        avgTicketPerVehiclePerMonth:number,
      }
    } = yield call(analyticRideApi.getStatisticTicket, action.payload)
    yield put(analyticRideAction.getStatisticTicketSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}
function* getStatisticVehicle(action: PayloadAction<{
  fleet?: string;
}>) {
  try {
    const chart: {
      data: {
        "countVehicle": number,
        "vehicleIsRental": number,
        "vehicleLowBattery": number
      }
    } = yield call(analyticRideApi.getStatisticVehicle, action.payload)
    yield put(analyticRideAction.getStatisticVehicleSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}

function* getChartRideFleet(action: PayloadAction<{
  fleet?: string;
}>) {
  try {
    const chart: {
      data: {
        idleVehicle : number,
        usedVehicle : number,
        unavailable : number,
        rebalancePickUp : number,
        availableVehicle : number,
        missing : number,
        notConnectedVehicle : number,
        connectedVehicle : number,
      }
    } = yield call(analyticRideApi.getChartRideFleet, action.payload)
    yield put(analyticRideAction.getChartRideFleetSuccess(chart.data))
  } catch (error) {
    console.log(error)
  }
}

export default function* analyticRideSaga() {
  yield takeEvery(analyticRideAction.getChartRidePerHour.type, getChartRidePerHour)
  yield takeEvery(analyticRideAction.getStatisticRider.type, getStatisticRider)
  yield takeEvery(analyticRideAction.getStatisticRide.type, getStatisticRide)
  yield takeEvery(analyticRideAction.getStatisticTicket.type, getStatisticTicket)
  yield takeEvery(analyticRideAction.getChartRideByDay.type, getChartRideByDay)
  yield takeEvery(analyticRideAction.getChartUserSignUp.type, getChartUserSignUp)
  yield takeEvery(analyticRideAction.getStatisticVehicle.type, getStatisticVehicle)
  yield takeEvery(analyticRideAction.getChartRideFleet.type, getChartRideFleet)
}
