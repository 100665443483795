import { Col, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import {
  selectItemCountTax,
  selectListTax,
  taxAction,
} from "../../../features/tax/taxSlice";
import { TaxModel } from "../../../models/taxModel";

import "./tax.css";

const Tax = () => {
  const dispatch = useAppDispatch();
  const itemCount = useAppSelector(selectItemCountTax);
  const listTax = useAppSelector(selectListTax);
  const [limit, setLimit] = useState<string>("15");
  const [page, setPage] = useState<number>(1);
  const [openAdd, setOpenAdd] = useState(false);
  const [keyword, setKeyword] = useState<string>();
  const [dataTax, setDataTax] = useState<TaxModel>();
  const [data, setData] = useState<{ name: string; taxPercentage: number }>({
    name: "",
    taxPercentage: 0,
  });

  const handleChange = (value: string) => {
    setLimit(value);
  };

  const getListData = () => {
    setTimeout(() => {
      dispatch(
        taxAction.getListTax({
          page: page,
          limit: Number(limit),
          keyword: keyword,
        })
      );
    }, 500);
  };

  const updateTax = () => {
    dataTax?.name &&
      dataTax._id &&
      dataTax.taxPercentage &&
      dispatch(
        taxAction.updateTax({
          id: dataTax._id,
          name: dataTax.name,
          taxPercentage: Number(dataTax.taxPercentage),
        })
      );
    getListData();
  };

  useEffect(() => {
    dispatch(
      taxAction.getListTax({
        limit: Number(limit),
        page: page,
      })
    );

    console.log(788999);
  }, [dispatch, limit, page, openAdd]);
  console.log(67788, dataTax);
  return (
    <div>
      <br />
      <div className="role_div1">
        <p className="role_title">Taxes</p>
        <BBtn
          color="#FF5C6A"
          title="Add Tax"
          onClick={() => {
            setOpenAdd(true);
            setDataTax(undefined);
            setData({ name: "", taxPercentage: 0 });
          }}
        />
      </div>

      <Row gutter={24}>
        {openAdd ? (
          <Col span={12} className="gutter-row">
            <div className="org_div1">
              <p className="org_p1">Add Tax</p>
              <Row gutter={16}>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title="Name"
                    name="name"
                    value={dataTax && dataTax.name}
                    onChange={(e) => setData({ ...data, name: e })}
                  />
                  <InputGroup
                    required={true}
                    title="Tax Percentage"
                    name="Tax Percentage"
                    type="number"
                    value={dataTax?.taxPercentage.toString()}
                    iInfor="Number of Percentage"
                    onChange={(e) =>
                      setData({ ...data, taxPercentage: Number(e) })
                    }
                  />
                </Col>
              </Row>
              <br />
              <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BBtn
                  title="Back"
                  color="#FF5C6A"
                  onClick={() => {
                    setOpenAdd(false);
                    setDataTax(undefined);
                  }}
                />
                <BBtn
                  title="Save Changes"
                  color="#24BB69"
                  onClick={() => {
                    dispatch(taxAction.addTax(data));
                    if (data.name && data.taxPercentage) {
                      setOpenAdd(false);
                      setDataTax(undefined);
                    }
                    getListData();
                  }}
                />
              </div>
            </div>
          </Col>
        ) : (
          <>
            <Col span={dataTax ? 12 : 24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    value={limit}
                    defaultValue={"15"}
                    onChange={handleChange}
                  >
                    <Option value="15">15</Option>
                    <Option value="25">25</Option>
                    <Option value="50">50</Option>
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={(e) => setKeyword(e.target.value)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataTax(params)}
                  column={[
                    {
                      title: "ID",
                      dataIndex: "id",
                      sorter: (a: any, b: any) => a.id - b.id,
                    },
                    {
                      title: "Name",
                      dataIndex: "name",
                      sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "Percentage",
                      dataIndex: "taxPercentage",
                    },
                  ]}
                  total={Number(itemCount)}
                  data={listTax || []}
                  pageSize={Number(limit)}
                  onChangePage={(page) => {
                    setPage(page);
                  }}
                  currentPage={page}
                />
              </div>
            </Col>

            {dataTax && (
              <Col span={12} className="gutter-row">
                <div className="org_div1">
                  <p className="org_p1">Edit Tax</p>
                  <Row gutter={16}>
                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title="Name"
                        name="Name"
                        value={dataTax.name}
                        onChange={(e) => setDataTax({ ...dataTax, name: e })}
                      />
                      <InputGroup
                        required={true}
                        title="Tax Percentage"
                        name="Tax Percentage"
                        type="number"
                        value={dataTax.taxPercentage.toString()}
                        iInfor="Number of Percentage"
                        onChange={(e) =>
                          setDataTax({ ...dataTax, taxPercentage: e })
                        }
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <BBtn
                      title="Back"
                      color="#FF5C6A"
                      onClick={() => {
                        setDataTax(undefined);
                      }}
                    />
                    <BBtn
                      title="Save Changes"
                      color="#24BB69"
                      onClick={() => {
                        updateTax();
                        if (dataTax.name && dataTax.taxPercentage)
                          setDataTax(undefined);
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default Tax;
