import {Col, message, Row, Select, Steps, Switch} from 'antd'
import {SizeType} from 'antd/lib/config-provider/SizeContext'
import {Option} from 'antd/lib/mentions'
import React, {useEffect, useState} from 'react'
import BBtn from '../../../components/button'
import ISearch from '../../../components/search'
import ITable from '../../../components/table'
import 'mapbox-gl/dist/mapbox-gl.css'
import Map, {Marker} from 'react-map-gl'
import ISelect from '../../../components/select'
import InputGroup from '../../../components/textInput'
import { areaAction, selectItemCount, selectListArea } from '../../../features/area/areaSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import moment from "moment";
import {fleetAction, selectListFleet} from "../../../features/fleet/fleetSlice";
import {selectListSetting, settingAction} from "../../../features/setting/settingSlice";
import {AreaModel} from "../../../models/area.model";

const children: React.ReactNode[] = []
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
}

const Stations = () => {
  const dispatch = useAppDispatch()
  const itemCount = useAppSelector(selectItemCount)
  const listArea = useAppSelector(selectListArea)
  const listSetting = useAppSelector(selectListSetting);
  const listFleet = useAppSelector(selectListFleet);
  const [size, setSize] = useState<SizeType>('middle')
  const [dataStations, setDataStations] = useState<AreaModel>({} as AreaModel)
  const [tab, setTab] = useState<string>('Edit')
  const [page , setPage] = useState(1)
  const [limit , setLimit] = useState(15)
  const [keyword , setKeyword] = useState('')
  const [addStationsArea, setAddStationsArea] = useState<boolean>(false)
  const [features, setFeatures] = useState<Record<string,any>>({});

  useEffect(()=> {
    dispatch(areaAction.getListArea({
      limit,
      page,
      keyword,
      type : "STATIONS"
    }))
  },[page,limit,keyword , dispatch])

    useEffect(() => {
        dispatch(settingAction.getListSetting());
        dispatch(
            fleetAction.getListFleet({
                page: 1,
                limit: 15,
            })
        );
    }, [dispatch]);

    const listOptionFleet = listFleet?.map((item) => {
        return {
            text: item.name,
            value: item,
        };
    });

    const getListArea = () => {
        setTimeout(() => {
            dispatch(areaAction.getListArea({
                limit,
                page,
                keyword,
                type : "RESTRICT"
            }))
        },1000)
    }

    const handleAdd = () => {
        const location = Object.values(features)
        if (Object.keys(dataStations).length){
            const dataAdd = {
                ...dataStations,
                type : "RESTRICT" ,
                triggerAlarm : !!dataStations.triggerAlarm,
                throttle : !!dataStations.throttle,
                fleet : dataStations.fleet._id,
                coordinates : location.length ? [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])] : [[[]]]
            }
            if (location.length > 1){
                message.error("Can not create more than one polygon").then();
            }
            else {
                dispatch(areaAction.addArea(dataAdd))
                getListArea()
                setAddStationsArea(false)
                setFeatures({})
                setDataStations({} as AreaModel)
            }
        }
    }
    const handleEdit = () => {
        const location = Object.values(features)
        if (Object.keys(dataStations).length){
            const dataAdd = {
                ...dataStations,
                type : "RESTRICT" ,
                fleet : dataStations.fleet._id,
                ...(location.length ? {coordinates: [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])]} : {})
            }
            if (location.length > 1){
                message.error("Can not update more than one polygon").then();
            }
            dispatch(areaAction.updateArea(dataAdd))
            getListArea()
            setFeatures({})
            setDataStations(pre=> (
                {...pre,
                    location : {
                        ...pre.location ,
                        ...(location.length ? {coordinates: [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])]} : {coordinates : pre.location.coordinates})
                    }
                }))
        }
    }

    const handleDelete = () => {
        dataStations &&
        dataStations._id &&
        dispatch(areaAction.deleteArea({id : dataStations._id}));

        setDataStations({} as AreaModel);
        getListArea();
    }
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`)
  }

    const handleChangeLimit = (value: string | string[]) => {
        setLimit(Number(value));
    }
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`)
  }
  return (
    <div>
      <br />
      <div className='role_div1'>
        <p className='role_title'>Stations</p>
        <div style={{display: 'flex'}}>
          <BBtn
            title='Create Stations'
            color='#FF5C6A'
            icon1={<i className='fa-solid fa-plus'></i>}
          />
        </div>
      </div>
      <br />
      <Col span={24} className='gutter-row'>
        <div className='org_div1'>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{marginRight: '1rem'}}>Show</span>
              <Select size={size} defaultValue={'Show'} onChange={handleChangeLimit}>
                  <Option value="15">15</Option>
                  <Option value="25">25</Option>
                  <Option value="50">50</Option>
              </Select>
              <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                Entries
              </span>
                <ISearch onChange={(e) => setKeyword(e.target.value)} />
            </div>
            <p>
              Active <Switch defaultChecked onChange={onChange} />
            </p>
          </div>
          <br />
          <ITable
            onClickRow={(params: any) => setDataStations(params)}
            column={[
              {
                title: 'ID',
                dataIndex: 'ID',
              },
              {
                title: 'Station Name',
                dataIndex: 'stationName',
                // sorter: (a: any, b: any) => a.name.length - b.name.length,
              },
              {
                title: 'Station UUID',
                dataIndex: 'stationUUID',
              },

              {
                title: 'Status',
                dataIndex: 'status',
                // render: (text: string) => (
                //   <span
                //     style={{
                //       backgroundColor:
                //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                //       color: 'white',
                //       padding: '0.2rem 0.5rem',
                //       borderRadius: '2px',
                //     }}
                //   >
                //     {text}
                //   </span>
                // ),
              },
              {
                title: 'Total Ports',
                dataIndex: 'totalPorts',
              },
              {
                title: 'Available Vehicles',
                dataIndex: 'availableVehicles',
              },
              {
                title: 'Free Port',
                dataIndex: 'freePort',
              },
              {
                title: 'Date Created',
                dataIndex: 'createdAt',
                  render: (text : Date)=> {
                      return <span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>;
                  }
              },
            ]}
            total={itemCount || 0}
            data={listArea||[]}
            pageSize={limit}
            currentPage={page}
            onChangePage={(page) => {
                console.log('page', page)
                setPage(page)
            }}
          />
        </div>
      </Col>
      {!!Object.keys(dataStations).length && (
        <>
          <Row>
            <Col span={24} className='gutter-row'>
              <br />
              <br />
              <div style={{display: 'flex'}}>
                <BBtn
                  title='Edit'
                  color={tab === 'Edit' ? '#2C405A' : '#FF5C6A'}
                  onClick={() => setTab('Edit')}
                />
                <span style={{width: '0.5rem'}}></span>
                <BBtn
                  title='Vehicles'
                  color={tab === 'Vehicles' ? '#2C405A' : '#FF5C6A'}
                  onClick={() => setTab('Vehicles')}
                />
                <span style={{width: '0.5rem'}}></span>
                <BBtn
                  title='Timeline'
                  color={tab === 'Timeline' ? '#2C405A' : '#FF5C6A'}
                  // onClick={() => setTab('Company')}
                  onClick={() => setTab('Timeline')}
                />
              </div>
            </Col>
          </Row>
          {tab === 'Edit' && (
            <>
              <Row gutter={24}>
                <Col span={12}>
                  <div style={{backgroundColor: 'white', padding: '1rem'}}>
                    <p className='role_title'>Edit Parking</p>
                    <Row gutter={16}>
                      <Col span={12} style={{paddingTop: '0.4rem'}}>
                        <p className='fleets-p1'>Station Type *</p>
                        <ISelect
                          name='Station Type'
                          option={[{text :'Type1', value : 'Type1' },{text :'Type2', value : 'Type2' }]}
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title='Station UUID'
                          name='Station UUID'
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title='Latitude'
                          name='Latitude'
                          disabled={true}
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title='Longitude'
                          name='Longitude'
                          disabled
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title='Location'
                          name='Location'
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title='Station Name'
                          name='Station Name'
                          onChange={() => console.log(111)}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <BBtn title='Save' color='#24BB69' />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <Map
                    mapboxAccessToken='pk.eyJ1IjoiaHV5dGhhbmgxMzA4Ym4iLCJhIjoiY2w4NDNhY2hjMDdtNDN1dDlzeTh4YWEyaiJ9.1gQs8INwMvqKHmMTuMESYw'
                    initialViewState={{
                      longitude: -122.4,
                      latitude: 37.8,
                      zoom: 14,
                    }}
                    style={{
                      width: '100%',
                      height: 400,
                      borderRadius: '5px',
                    }}
                    mapStyle='mapbox://styles/mapbox/streets-v11'
                    // children={
                    //   <Marker
                    //     longitude={campaignDetail.longitude}
                    //     latitude={campaignDetail.latitude}
                    //     anchor='bottom'
                    //   >
                    //     <PlaceOutlinedIcon style={{color: 'red'}} />
                    //   </Marker>
                    // }
                  />
                </Col>
              </Row>
            </>
          )}
          {tab === 'Vehicles' && (
            <>
              <Col span={24} className='gutter-row'>
                <div className='org_div1' style={{padding: '2rem'}}>
                  <p className='role_title'>{dataStations.name}</p>
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{marginRight: '1rem'}}>Show</span>
                      <Select
                        size={size}
                        defaultValue={'Show'}
                        onChange={handleChange}
                      >
                        {children}
                      </Select>
                      <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                        Entries
                      </span>
                      <ISearch onChange={() => console.log(222)} />
                    </div>
                  </div>
                  <br />
                  <ITable
                    onClickRow={(params: any) => setDataStations(params)}
                    column={[
                      {
                        title: 'Vehicle Name',
                        dataIndex: 'vehicleName',
                      },
                      {
                        title: 'Docked By',
                        dataIndex: 'dockedBy',
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: 'Docked Time',
                        dataIndex: 'dockedTime',
                      },

                      {
                        title: 'Battery Level',
                        dataIndex: 'batteryLevel',
                        // render: (text: string) => (
                        //   <span
                        //     style={{
                        //       backgroundColor:
                        //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                        //       color: 'white',
                        //       padding: '0.2rem 0.5rem',
                        //       borderRadius: '2px',
                        //     }}
                        //   >
                        //     {text}
                        //   </span>
                        // ),
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                      },
                    ]}
                    total={100}
                    data={
                      [
                        // {
                        //   key: '1',
                        //   ID: '1',
                        //   stationName: 'sadsda',
                        //   stationUUID: 'alma',
                        //   status: 'Available',
                        //   totalPorts: '0',
                        //   availableVehicles: '0',
                        //   freePort: '0',
                        //   dateCreated: '2014/12/24 23:12:00',
                        // },
                      ]
                    }
                    pageSize={20}
                    onChangePage={(page) => {
                      console.log('page', page)
                    }}
                  />
                </div>
              </Col>
            </>
          )}
          {tab === 'Timeline' && (
            <>
              <Col span={24} className='gutter-row'>
                <div className='org_div1'>
                  <div style={{display: 'flex'}}>
                    <p className='fleets-title'>Timeline</p>
                    {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                  </div>
                  <Row gutter={16} style={{paddingLeft: '2rem'}}>
                    <Col span={8}>
                      <Steps direction='vertical' current={1}>
                        <Steps.Step
                          icon={
                            <i
                              className='fa-solid fa-tag'
                              style={{
                                color: '#333',
                                fontSize: '40px',
                              }}
                            ></i>
                          }
                          title='Finished'
                          description='This is a description.'
                        />
                        <Steps.Step
                          icon={
                            <i
                              className='fa-solid fa-tag'
                              style={{
                                color: '#333',
                                fontSize: '40px',
                              }}
                            ></i>
                          }
                          title='Finished'
                          description='This is a description.'
                        />
                        <Steps.Step
                          icon={
                            <i
                              className='fa-solid fa-tag'
                              style={{
                                color: '#333',
                                fontSize: '40px',
                              }}
                            ></i>
                          }
                          title='Finished'
                          description='This is a description.'
                        />
                      </Steps>
                    </Col>
                  </Row>
                </div>
              </Col>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Stations
