import CircleOptions = naver.maps.CircleOptions;
import MarkerOptions = naver.maps.MarkerOptions;
import PolygonOptions = naver.maps.PolygonOptions;
import PolylineOptions = naver.maps.PolylineOptions;
import RectangleOptions = naver.maps.RectangleOptions;
import InfoWindowOptions = naver.maps.InfoWindowOptions;

export interface IMarkerOptions extends Omit<MarkerOptions, 'position'| 'map'>{
    latitude: number;
    longitude: number;
    onClick?: ()=> void
    // icon ? :{
    //     url : string,
    //     width : number,
    //     height : number
    // }
}
export interface IInfoWindowOptions extends Omit<InfoWindowOptions, 'position'|'content'>{
    latitude: number;
    longitude: number;
    title: string;
    fleet : string;
    name : string;
    description: string;
    onClick?: ()=> void
}
export interface ICircleOptions extends Omit<CircleOptions,'center' >{
    position : {
        latitude: number;
        longitude: number;
    }
    onClick?: ()=> void
}
export interface IPolygonOptions extends Omit<PolygonOptions,'paths'>{
    paths : number[][][]
    edit ? :boolean
    onClick?: (e : any)=> any
}
export interface IPolylineOptions extends Omit<PolylineOptions,'path'>{
    path : number[][]
    onClick?: ()=> void
}
export interface IRectangleOptions extends RectangleOptions{
    onClick?: ()=> void
}
export enum MapType {
    NORMAL = 'normal',
    TERRAIN = 'terrain',
    SATELLITE = 'satellite',
    HYBRID = 'hybrid',
}