import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {User} from "../../models/userModel";

export interface RiderState {
  loadding: boolean
  listRider: User[] | undefined
  itemCount: number | undefined
}
const initialState: RiderState = {
  loadding: false,
  listRider: undefined,
  itemCount: undefined,
}

const riderSlice = createSlice({
  name: 'rider',
  initialState,
  reducers: {
    getListRider(state, action: PayloadAction<{
      page: number | undefined,
      limit: number | undefined,
      keyword?: string,
      fleet?: string,
      payment_card?: boolean,
      status?: 'ACTIVE' | 'DE_ACTIVE',
      'orderBy[id]'?: 'ASC' | 'DESC',
      'orderBy[name]'?: 'ASC' | 'DESC',
    }>) {
      state.loadding = true
    },
    getListRiderSuccess(state, action: PayloadAction<User[]>) {
      state.listRider = action.payload
      state.loadding = false
    },
    getListRiderFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateRider(
      state,
      action: PayloadAction<Partial<User>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    deleteRider(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    restoreRider(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    getCsv(state , action : PayloadAction<{
      page: number | undefined,
      limit: number | undefined,
      keyword?: string,
      fleet?: string,
      payment_card?: boolean,
      status?: 'ACTIVE' | 'DE_ACTIVE',
      'orderBy[id]'?: 'ASC' | 'DESC',
      'orderBy[name]'?: 'ASC' | 'DESC',
    }> ) {
      state.loadding = true
    }
  },
})
export const riderAction = riderSlice.actions
export const selectItemCount = (state: RootState) => state.rider.itemCount
export const selectListRider = (state: RootState) => state.rider.listRider
export const selectLoading = (state: RootState) => state.rider.loadding

export const riderReducer = riderSlice.reducer
