import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {CountryModel} from '../../models/country.model'

export interface CountryState {
  loadding: boolean
  listCountry: CountryModel[] | undefined
}

const initialState: CountryState = {
  loadding: false,
  listCountry: undefined,
}

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    getListCountry(state) {
      state.loadding = true
    },
    getListCountrySuccess(state, action: PayloadAction<CountryModel[]>) {
      state.listCountry = action.payload
      state.loadding = false
    },
    getListCountryFail(state) {
      state.loadding = false
    },
  },
})

export const countryAction = countrySlice.actions



export const selectListCountry = (state: RootState) =>
  state.country.listCountry


export const selectLoadingCountry = (state: RootState) => state.country.loadding

export const countryReducer = countrySlice.reducer
