import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {PromotionModel} from "../../models/promotion.model";

export interface promotionState {
    loadding: boolean
    listPromotion: PromotionModel[] | undefined
    itemCount: number | undefined
    promotion: PromotionModel | undefined
}

const initialState: promotionState = {
    loadding: false,
    listPromotion: undefined,
    itemCount: undefined,
    promotion: undefined
}

const promotionSlice = createSlice({
    name: 'promotion',
    initialState,
    reducers: {
        getListPromotion(state, action: PayloadAction<{
            page: number
            limit: number
            keyword?: string
            'orderBy[id]'?: 'ASC' | 'DESC'
            'orderBy[name]'?: 'ASC' | 'DESC'
        }>) {
            state.loadding = true
        },
        getListPromotionSuccess(state, action: PayloadAction<PromotionModel[]>) {
            state.listPromotion = action.payload
            state.loadding = false
        },
        getListPromotionFail(state) {
            state.loadding = false
        },
        setItemCount(state, action: PayloadAction<number>) {
            state.itemCount = action.payload
        },
        addPromotion(state, action: PayloadAction<Partial<PromotionModel>>) {
            state.loadding = true
        },
        success(state) {
            state.loadding = false
        },
        fail(state) {
            state.loadding = false
        },
        updatePromotion(state, action: PayloadAction<Partial<PromotionModel>>) {
            state.loadding = true
        },
        detailPromotion(state, action: PayloadAction<{ id: string }>) {
            state.loadding = true
        },
        detailPromotionSuccess(state, action: PayloadAction<PromotionModel>) {
            state.promotion = action.payload
            state.loadding = false
        }
    },
})

export const promotionAction = promotionSlice.actions


export const selectListPromotion = (state: RootState) =>
    state.promotion.listPromotion


export const selectLoadingPromotion = (state: RootState) => state.promotion.loadding
export const selectPromotion = (state: RootState) => state.promotion.promotion
export const selectItemCount = (state: RootState) => state.promotion.itemCount

export const promotionReducer = promotionSlice.reducer
