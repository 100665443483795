import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {riderAction} from "./riderSlice";
import {riderApi} from "../../apis/riderApi";
import {User} from "../../models/userModel";

function* getListRider(action: PayloadAction<{
  page: number | undefined,
  limit: number | undefined,
  keyword?: string,
  fleet?: string,
  payment_card?: boolean,
  status?: 'ACTIVE' | 'DE_ACTIVE',
  'orderBy[id]'?: 'ASC' | 'DESC',
  'orderBy[name]'?: 'ASC' | 'DESC',
}>) {
  try {
    const listRider: {
      data: {
        nodes: User[]
        itemCount: number
      }
    } = yield call(riderApi.getAllRider, action.payload)
    yield put(riderAction.getListRiderSuccess(listRider.data.nodes))
    yield put(riderAction.setItemCount(listRider.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}
function* updateRider(
  action: PayloadAction<Partial<User>>
) {
  try {
    const rider: {description: string} = yield call(
      riderApi.updateRider,
      action.payload
    )
    yield put (riderAction.success())
    message.success(rider.description).then()
  } catch (error: any) {
    yield put (riderAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* deleteRider(action: PayloadAction<{id : string}>) {
  try {
    const rider: {description: string } = yield call(riderApi.deleteRider, action.payload.id)
    message.success(rider.description).then()
    yield put (riderAction.success())
  } catch (error: any) {
    yield put (riderAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* restoreRider(action: PayloadAction<{id : string}>) {
  try {
    const rider: {description: string } = yield call(riderApi.restoreRider, action.payload.id)
    message.success(rider.description).then()
    yield put (riderAction.success())
  } catch (error: any) {
    yield put (riderAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* getCsv(action: PayloadAction<{
  page: number | undefined,
  limit: number | undefined,
  keyword?: string,
  fleet?: string,
  payment_card?: boolean,
  status?: 'ACTIVE' | 'DE_ACTIVE',
  'orderBy[id]'?: 'ASC' | 'DESC',
  'orderBy[name]'?: 'ASC' | 'DESC',
}>) {
  try {
   const data : [] = yield call(riderApi.getCsv, action.payload)
    console.log({data})
    yield put (riderAction.success())
  } catch (error) {
    console.log(error)
  }
}

export default function* riderSaga() {
  yield takeEvery(riderAction.getListRider.type, getListRider)
  yield takeEvery(riderAction.updateRider.type, updateRider)
  yield takeEvery(riderAction.deleteRider.type, deleteRider)
  yield takeEvery(riderAction.restoreRider.type, restoreRider)
  yield takeEvery(riderAction.getCsv.type, getCsv)
}
