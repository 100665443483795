import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {OfferModel} from "../../models/offer.model";

export interface OfferState {
  loadding: boolean
  listOffer: OfferModel[] | undefined
  itemCount: number | undefined
  offer : OfferModel | undefined
}
const initialState: OfferState = {
  loadding: false,
  listOffer: undefined,
  itemCount: undefined,
  offer: undefined,
}

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    getListOffer(state, action: PayloadAction<{
      page: number | undefined,
      limit: number | undefined,
      keyword?: string,
      fleet?: string;
      startTime?: Date;
      endTime?: Date;
      'orderBy[id]'?: 'ASC' | 'DESC',
      'orderBy[title]'?: 'ASC' | 'DESC',
    }>) {
      state.loadding = true
    },
    getListOfferSuccess(state, action: PayloadAction<OfferModel[]>) {
      state.listOffer = action.payload
      state.loadding = false
    },
    getListOfferFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateOffer(
      state,
      action: PayloadAction<Partial<OfferModel>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addOffer(
      state,
      action: PayloadAction<Partial<OfferModel>>
    ) {
      state.loadding = true
    },
    detailOffer(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailOfferSuccess(state, action : PayloadAction<OfferModel> ) {
      state.loadding = true
      state.offer = action.payload
    },
    deleteOffer(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
  },
})
export const offerAction = offerSlice.actions
export const selectItemCount = (state: RootState) => state.offer.itemCount
export const selectListOffer = (state: RootState) => state.offer.listOffer
export const selectOffer = (state: RootState) => state.offer.offer
export const selectLoading = (state: RootState) => state.offer.loadding

export const offerReducer = offerSlice.reducer
