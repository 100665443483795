import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {campaignAction} from "./campaignSlice";
import {campaignApi} from "../../apis/campaignApi";
import { CampaignModel } from '../../models/campaign.model';
import {PaginationModel} from "../../models/paginationModel";

function* getListCampaign(action: PayloadAction<PaginationModel>) {
  try {
    const listCampaign: {
      data: {
        nodes: CampaignModel[]
        itemCount: number
      }
    } = yield call(campaignApi.getAllCampaign, action.payload)
    yield put(campaignAction.getListCampaignSuccess(listCampaign.data.nodes))
    yield put(campaignAction.setItemCount(listCampaign.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}
function* updateCampaign(
  action: PayloadAction<Partial<CampaignModel>>
) {
  try {
    const campaign: {description: string} = yield call(
      campaignApi.updateCampaign,
      action.payload
    )
    yield put (campaignAction.success())
    message.success(campaign.description).then()
  } catch (error: any) {
    yield put (campaignAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* addCampaign(action: PayloadAction<Partial<CampaignModel>>) {
  try {
    const campaign: {description: string} = yield call(campaignApi.createCampaign, action.payload)
    message.success(campaign.description).then()
    yield put (campaignAction.success())
  } catch (error: any) {
    yield put (campaignAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* detailCampaign(action: PayloadAction<{id : string}>) {
  try {
    const campaign: {description: string , data : CampaignModel} = yield call(campaignApi.detailCampaign, action.payload.id)
    yield put (campaignAction.detailCampaignSuccess(campaign.data))
    message.success(campaign.description).then()
  } catch (error: any) {
    yield put (campaignAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* deleteCampaign(action: PayloadAction<{id : string}>) {
  try {
    const campaign: {description: string } = yield call(campaignApi.deleteCampaign, action.payload.id)
    message.success(campaign.description).then()
    yield put (campaignAction.success())
  } catch (error: any) {
    yield put (campaignAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}


export default function* campaignSaga() {
  yield takeEvery(campaignAction.getListCampaign.type, getListCampaign)
  yield takeEvery(campaignAction.updateCampaign.type, updateCampaign)
  yield takeEvery(campaignAction.addCampaign.type, addCampaign)
  yield takeEvery(campaignAction.detailCampaign.type, detailCampaign)
  yield takeEvery(campaignAction.deleteCampaign.type, deleteCampaign)
}
