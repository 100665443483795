import { Dropdown, Menu, Popover } from "antd";
import i18next from "i18next";
import React, { useState } from "react";
import Images from "../../assets/gen";
import CardGift from "../cardGift";
import CardHelp from "../cardHelp";
import CardInfor from "../cardInfor";
import { ReactComponent as LanguageSvg } from "../../assets/images/language.svg";
import { ReactComponent as EnUsSvg } from "../../assets/images/en_US.svg";
import { ReactComponent as KrSvg } from "../../assets/images/south-korea-flag-icon.svg";
import "./header.css";
import type { MenuProps } from "antd";
import { GlobalOutlined } from "@ant-design/icons";

const content = <CardInfor />;
const content1 = <CardHelp />;
const content2 = <CardGift />;

const buttonWidth = 70;

const HeaderComponent = () => {
  const [lang, setLang] = useState<string>(i18next.language);
  const items = [
    {
      key: "ko",
      icon: <KrSvg className="flag-img" />,
      disabled: lang === "ko",
      label: "한국어",
    },
    {
      key: "en",
      icon: <EnUsSvg className="flag-img" />,
      disabled: lang === "en",
      label: "English",
    },
  ];
  const menu = (
    <Menu
      items={items}
      onClick={(e) => {
        i18next.changeLanguage(e.key);
        setLang(e.key);
      }}
    />
  );
  return (
    <div className="header-container">
      <Popover placement="top" content={content1} trigger="click">
        <img className="header-img" src={Images.headerIcon} alt="" />
      </Popover>
      <Popover placement="top" content={content2} trigger="click">
        <img className="header-img" src={Images.gift} alt="" />
      </Popover>
      <div className="header-div1">
        <Popover placement="top" content={content1} trigger="click">
          <img className="header-img" src={Images.sms} alt="" />
          <img className="header-img1" src={Images.headerIcon1} alt="" />
          <span className="header-span1">11</span>
        </Popover>
      </div>

      {/*<Popover placement='top' content={content} trigger='click'>*/}
      {/*    <GlobalOutlined className='header-img' />*/}
      {/*</Popover>*/}

      <Dropdown overlay={menu}>
        <LanguageSvg
          className="header-img"
          style={{ height: "24px", width: "auto" }}
        />
      </Dropdown>

      <Popover placement="bottomRight" content={content} trigger="click">
        <p className="header-p1">
          GreenApp <i className="fa-solid fa-angle-down"></i>
        </p>
      </Popover>
    </div>
  );
};

export default HeaderComponent;
