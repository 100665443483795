import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {PaginationModel} from "../models/paginationModel";
import {CampaignModel} from "../models/campaign.model";

export const campaignApi = {
    getAllCampaign(params: PaginationModel) {
        return axiosClient.getService(`${urlApi.CAMPAIGN}`, {...params})
    },
    createCampaign(params: Partial<CampaignModel>) {
        return axiosClient.postService(`${urlApi.CAMPAIGN}`, {...params})
    },
    updateCampaign(params: Partial<CampaignModel>) {
        return axiosClient.updateService(`${urlApi.CAMPAIGN}/${params._id}`, {...params})
    },
    deleteCampaign(id: string) {
        return axiosClient.deleteService(`${urlApi.CAMPAIGN}/${id}`)
    },
    detailCampaign(id: string) {
        return axiosClient.getService(`${urlApi.CAMPAIGN}/${id}`)
    },
    getPricingCampaign(params : CampaignModel["users"]) {
        return axiosClient.postService(`${urlApi.CAMPAIGN}/pricing`,params)
    },
}
