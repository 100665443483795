import { useState, useEffect } from "react";
import axios from "axios";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const useReverseGeocode = (latitude: number, longitude: number) => {
  const [address, setAddress] = useState<any>("");

  useEffect(() => {
    const url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      longitude +
      ", " +
      latitude +
      ".json?access_token=" +
      "pk.eyJ1IjoiaHV5dGhhbmgxMzA4Ym4iLCJhIjoiY2w4NDNhY2hjMDdtNDN1dDlzeTh4YWEyaiJ9.1gQs8INwMvqKHmMTuMESYw";

    axios
      .get(url)
      .then((res) => {
        setAddress(res.data.features[0].place_name);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [latitude, longitude]);

  return address;
};
export default useReverseGeocode;
