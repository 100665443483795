import { Radio, Space, Switch } from "antd";
import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import BBtn from "../../../components/button";
import InputGroup from "../../../components/textInput";
import { settingAction } from "../../../features/setting/settingSlice";
import { SettingModel } from "../../../models/setting.model";
import { AppColors } from "../../../utils/appColors";

interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabPayment = (props: IProps) => {
  const dispatch = useAppDispatch();
  const initState = props.setting.payment || {};
  const [data, setData] = useState<SettingModel["payment"]>(
    initState as unknown as SettingModel["payment"]
  );
  const [disableEdit, setDisableEdit] = useState<{
    redirectDeeplink: boolean;
    deposit: boolean;
    minimumTopUpAmoun: boolean;
    maximumTopUpAmount: boolean;
    minimumBalance: boolean;
  }>({
    redirectDeeplink: true,
    deposit: true,
    minimumTopUpAmoun: true,
    maximumTopUpAmount: true,
    minimumBalance: true,
  });
  const hanldeChange = (params: {
    value: any;
    key: keyof SettingModel["payment"];
  }) => {
    if (props.setting.payment) {
      setData({ ...props.setting.payment, [params.key]: params.value });
    }
  };
  const handleEdit = (params: keyof SettingModel["payment"]) => {
    if (disableEdit[params]) {
      setDisableEdit((pre) => ({ ...pre, [params]: false }));
    } else {
      props.setting.payment &&
        dispatch(
          settingAction.updateSetting({
            payment: data,
            _id: props.setting._id,
          })
        );
      setDisableEdit((pre) => ({ ...pre, [params]: true }));
      props.onUpdate();
    }
  };

  return (
    <div className="content">
      <div className="c-row w-500">
        <InputGroup
          disabled={disableEdit.redirectDeeplink}
          onChange={(e) => hanldeChange({ key: "redirectDeeplink", value: e })}
          value={props.setting.payment?.redirectDeeplink}
          name="Redirect Deeplink"
          title="Redirect Deeplink"
          placeHolder="Enter redirect deeplink"
          required
        />
        <div style={{ width: 10 }}></div>
        <BBtn
          title={disableEdit["redirectDeeplink"] ? "Edit" : "Save"}
          onClick={() => handleEdit("redirectDeeplink")}
          color={AppColors.green}
        />
      </div>
      <div className="divider"></div>
      <div className="c-row">
        <div className="c-row w-500">
          <InputGroup
            disabled={disableEdit.deposit}
            onChange={(e) => hanldeChange({ key: "deposit", value: Number(e) })}
            name="Deposit"
            title="Deposit"
            value={props.setting.payment?.deposit}
            required
          />
          <div style={{ width: 10 }}></div>
          <BBtn
            title={disableEdit["deposit"] ? "Edit" : "Save"}
            onClick={() => handleEdit("deposit")}
            color={AppColors.green}
          />
        </div>
        <div style={{ width: 30 }}></div>
        <div className="c-row w-500">
          <InputGroup
            disabled={disableEdit.minimumTopUpAmoun}
            onChange={(e) =>
              hanldeChange({ key: "minimumTopUpAmoun", value: Number(e) })
            }
            name="Minimum Top Up Amount"
            title="Minimum Top Up Amount"
            value={props.setting.payment?.minimumTopUpAmoun}
            required
          />
          <div style={{ width: 10 }}></div>
          <BBtn
            title={disableEdit["minimumTopUpAmoun"] ? "Edit" : "Save"}
            onClick={() => handleEdit("minimumTopUpAmoun")}
            color={AppColors.green}
          />
        </div>
      </div>
      <div style={{ height: 20 }}></div>
      <div className="c-row">
        <div className="c-row w-500">
          <InputGroup
            disabled={disableEdit.maximumTopUpAmount}
            onChange={(e) => hanldeChange({ key: "deposit", value: Number(e) })}
            name="Maximum Top Up Amount"
            title="Maximum Top Up Amount"
            value={props.setting.payment?.maximumTopUpAmount}
            required
          />
          <div style={{ width: 10 }}></div>
          <BBtn
            title={disableEdit["maximumTopUpAmount"] ? "Edit" : "Save"}
            onClick={() => handleEdit("maximumTopUpAmount")}
            color={AppColors.green}
          />
        </div>
        <div style={{ width: 30 }}></div>
        <div className="c-row w-500">
          <InputGroup
            disabled={disableEdit.minimumBalance}
            onChange={(e) => hanldeChange({ key: "deposit", value: Number(e) })}
            name="Minimum Balance"
            title="Minimum Balance"
            value={props.setting.payment?.minimumBalance}
            required
          />
          <div style={{ width: 10 }}></div>
          <BBtn
            title={disableEdit["minimumBalance"] ? "Edit" : "Save"}
            onClick={() => handleEdit("minimumBalance")}
            color={AppColors.green}
          />
        </div>
      </div>
    </div>
  );
};

export default TabPayment;
