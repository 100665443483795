import type { MenuProps, MenuTheme } from "antd";
import { Button, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Url } from "../../routers/paths";
import "./menu.css";
import { menuList, menuListSwap } from "./menuList";

type MenuItem = Required<MenuProps>["items"][number];

const MenuHome: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [mode, setMode] = useState<"vertical" | "inline">("inline");
  const [theme, setTheme] = useState<MenuTheme>("light");
  const [key1, setKey1] = useState("1");

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick: () => {
        // switch (key) {
        //   case '2':
        //     navigate(Url.oOrg)
        //     break
        //   case '5':
        //     navigate(Url.map)
        //     break
        //   case '1':
        //     navigate(Url.home)
        //     break
        //   case '29':
        //     navigate(Url.setting)
        //     break
        //   case '3':
        //     navigate(Url.oRole)
        //     break
        //   case '4':
        //     navigate(Url.oPermission)
        //     break
        //   case '6':
        //     navigate(Url.vehiclesAll)
        //     break
        //   case '7':
        //     navigate(Url.vehiclesReserved)
        //     break
        //  case '8':
        //    navigate(Url.vehicleHistory)
        //    break
        //  case '9':
        //     navigate(Url.geofenceParkingZones)
        //     break
        //   case '10':
        //     navigate(Url.geofenceRestrictedZones)
        //     break
        //   case '11':
        //     navigate(Url.geofenceServiceAreas)
        //     break
        //   case '12':
        //     navigate(Url.geofenceSlowSpeedZones)
        //     break
        //   case '13':
        //     navigate(Url.geofenceStations)
        //     break
        //   case '14':
        //     navigate(Url.riders)
        //     break
        //   case '15':
        //     navigate(Url.ridesCompleted)
        //     break
        //   case '16':
        //     navigate(Url.ridesRunning)
        //     break
        //   case '17':
        //     navigate(Url.payments)
        //     break
        //   case '18':
        //     navigate(Url.pricingPaymentGateway)
        //     break
        //   case '19':
        //     navigate(Url.pricingBillingPlan)
        //     break
        //   case '20':
        //     navigate(Url.pricingTax)
        //     break
        //   case '21':
        //     navigate(Url.pricingPromos)
        //     break
        //   case '22':
        //     navigate(Url.pricingOffer)
        //     break
        //   case '23':
        //     navigate(Url.supportTickets)
        //     break
        //   case '24':
        //     navigate(Url.supportFeedbackOption)
        //     break
        //   case '25':
        //     navigate(Url.campaignsNotifications)
        //     break
        //   case '26':
        //     navigate(Url.campaignsBanner)
        //     break
        //   case 'sub2':
        //     navigate(Url.fleets)
        //     break
        //   case '28':
        //     navigate(Url.events)
        //     break
        //   case '27':
        //     navigate(Url.themes)
        //     break
        //   default:
        //     break
        // }
        if (menuList[key]) {
          navigate(menuList[key]);
        }
      },
      style: { color: "#758D96" },
    } as MenuItem;
  }

  const items: MenuItem[] = [
    //home
    getItem(t("Menu.1"), "1", <i className="fa-solid fa-house"></i>),
    //org
    getItem(t("Menu.2"), "syb1", <i className="fa-solid fa-people-group"></i>, [
      getItem(t("Menu.2_1"), "2", <i className="fa-solid fa-sitemap"></i>),
      getItem(t("Menu.2_2"), "3", <i className="fa-solid fa-circle-user"></i>),
      getItem(t("Menu.2_3"), "4", <i className="fa-solid fa-key"></i>),
    ]),
    //map
    getItem(t("Menu.3"), "5", <i className="fa-solid fa-location-dot"></i>),
    //Fleets
    getItem(
      t("Menu.4"),
      "sub2",
      <i className="fa-solid fa-map-location-dot"></i>
    ),
    //Vehicles
    getItem(t("Menu.5"), "sub3", <i className="fa-solid fa-motorcycle"></i>, [
      getItem(t("Menu.5_1"), "6"),
      getItem(t("Menu.5_2"), "7"),
      getItem(t("Menu.5_3"), "8"),
    ]),
    //Geofence
    getItem(
      t("Menu.6"),
      "sub4",
      <i className="fa-brands fa-product-hunt"></i>,
      [
        getItem(t("Menu.6_1"), "9"),
        getItem(t("Menu.6_2"), "10"),
        getItem(t("Menu.6_3"), "11"),
        getItem(t("Menu.6_4"), "12"),
        getItem(t("Menu.6_5"), "13"),
      ]
    ),
    //Riders
    getItem(t("Menu.7"), "14", <i className="fa-solid fa-head-side-mask"></i>),
    //Rides
    getItem(
      t("Menu.8"),
      "sub5",
      <i className="fa-solid fa-person-biking"></i>,
      [getItem(t("Menu.8_1"), "15"), getItem(t("Menu.8_2"), "16")]
    ),
    //Payments
    getItem(t("Menu.9"), "17", <i className="fa-solid fa-credit-card"></i>),
    //Pricing
    getItem(
      t("Menu.10"),
      "sub5 sub6",
      <i className="fa-solid fa-circle-dollar-to-slot"></i>,
      [
        getItem(t("Menu.10_1"), "18"),
        getItem(t("Menu.10_2"), "19"),
        getItem(t("Menu.10_3"), "20"),
        getItem(t("Menu.10_4"), "21"),
        getItem(t("Menu.10_5"), "22"),
      ]
    ),
    //Support
    getItem(t("Menu.11"), "sub7", <i className="fa-solid fa-headset"></i>, [
      getItem(t("Menu.11_1"), "23"),
      getItem(t("Menu.11_2"), "24"),
    ]),
    //Campaigns
    getItem(t("Menu.12"), "sub8", <i className="fa-solid fa-bullhorn"></i>, [
      getItem(t("Menu.12_1"), "25"),
      getItem(t("Menu.12_2"), "26"),
    ]),
    //Application Theme
    getItem(t("Menu.13"), "27", <i className="fa-solid fa-palette"></i>),
    //Events
    getItem(t("Menu.14"), "28", <i className="fa-solid fa-calendar"></i>),
    //Settings
    getItem(t("Menu.15"), "29", <i className="fa-solid fa-gear"></i>),

    //App Version
    getItem(t("Menu.16"), "30", <i className="fa-solid fa-mobile"></i>),
  ];

  useEffect(() => {
    setKey1(menuListSwap[location.pathname]);
  }, [location.pathname]);

  return (
    <Menu
      style={{
        color: "#758D96",
      }}
      defaultSelectedKeys={["1"]}
      selectedKeys={[key1]}
      mode={mode}
      theme={theme}
      items={items}
    />
  );
};

export default MenuHome;
