import { Col, Modal, Row, Select, Tooltip } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import Input from "antd/lib/input/Input";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import "./tickets.css";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const Tickets = () => {
  const dispatch = useAppDispatch();
  // const listTicket = useAppSelector()
  const [size, setSize] = useState<SizeType>("middle");
  const [dataTickets, setDataTickets] = useState<any>();
  const [status, setStatus] = useState<string>("All Ticket");
  const [openCreateTicket, setOpenCreateTicket] = useState(false);
  const [ticketReport, setTicketReport] = useState(false);
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
  };
  console.log(dataTickets);
  const handleOk = () => {
    console.log(1111);
  };

  const handleCancel = () => {
    setOpenCreateTicket(false);
  };
  return (
    <div>
      {!ticketReport ? (
        <Row>
          <Row align="middle" style={{ width: "100%", marginTop: "1rem" }}>
            <Col span={12}>
              <span className="va_p_title">Tickets</span>
            </Col>
            <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              <BBtn
                title="CSV"
                color="#FF5C6A"
                icon1={<i className="fa-solid fa-file-lines"></i>}
              />
              <span style={{ width: "10px" }}></span>
              <BBtn
                title="Ticket Report"
                icon1={<i className="fa-solid fa-file"></i>}
                color="#FF5C6A"
                onClick={() => setTicketReport(true)}
              />
              <span style={{ width: "10px" }}></span>
              <BBtn
                title="Create Ticket"
                icon1={<i className="fa-solid fa-plus"></i>}
                onClick={() => setOpenCreateTicket(true)}
                color="#FF5C6A"
              />
              <span style={{ width: "10px" }}></span>
              <BBtn
                title="Filter"
                color="#FF5C6A"
                icon1={<i className="fa-solid fa-calendar-days"></i>}
                icon1left
              />
              <span style={{ width: "10px" }}></span>
              <BBtn
                title="All Fleet"
                color="#FF5C6A"
                icon1={<i className="fa-solid fa-caret-down"></i>}
                icon1left
              />
            </Col>
          </Row>
          <Row align="middle" justify="center" style={{ width: "100%" }}>
            <BBtn
              title="All Ticket"
              color="#3c8dbc"
              styleText={{ cursor: "pointer" }}
              noBorderRadius
            />
            <BBtn
              title="Pending"
              color="#d58413"
              styleText={{ cursor: "pointer" }}
              noBorderRadius
            />
            <BBtn
              title="In Progress"
              color="#d73925"
              styleText={{ cursor: "pointer" }}
              noBorderRadius
            />
            <BBtn
              title="Done"
              color="#398439"
              styleText={{ cursor: "pointer" }}
              noBorderRadius
            />
          </Row>

          <Row style={{ width: "100%", marginTop: "1rem" }} gutter={24}>
            <Col span={dataTickets ? 14 : 24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    size={size}
                    defaultValue={"Show"}
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={() => console.log(222)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataTickets(params)}
                  column={[
                    {
                      title: "ID",
                      dataIndex: "id",
                    },
                    {
                      title: "Phone Number",
                      dataIndex: "phoneNumber",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "Vehicle QR Code",
                      dataIndex: "vehicleQRCode",
                      render: (text: string) => (
                        <Tooltip placement="right" title="테스트 (분당)">
                          {text} <i className="fa fa-info-circle"></i>
                        </Tooltip>
                      ),
                    },

                    {
                      title: "Date",
                      dataIndex: "date",
                    },
                    {
                      title: "Rider(R)/Dashboard User(D)",
                      dataIndex: "rd",
                    },
                    {
                      title: "Assign To",
                      dataIndex: "assignTo",
                      render: (text: []) => (
                        <Select
                          defaultValue="None"
                          style={{ width: 120 }}
                          onChange={handleChange}
                        >
                          {text.map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      ),
                    },
                    {
                      title: "Subject",
                      dataIndex: "subject",
                      render: (text: []) => (
                        <Select
                          defaultValue="None"
                          style={{ width: 120 }}
                          onChange={handleChange}
                        >
                          {text.map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      ),
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      render: (text: string) => (
                        <span
                          style={{
                            backgroundColor:
                              text === "Pending"
                                ? "#F8861D"
                                : text === "Done"
                                ? "#24BB69"
                                : "",
                            color: "white",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "2px",
                          }}
                        >
                          {text}
                        </span>
                      ),
                    },
                  ]}
                  total={100}
                  data={[
                    {
                      key: "1",
                      id: "1137",
                      phoneNumber: "0123456789",
                      vehicleQRCode: "88888000187",
                      date: "2022/07/05 17:20:24",
                      rd: "anu_amila@naver.com",
                      assignTo: ["None", "Kick test"],
                      subject: ["None", "Kick test"],
                      status: "Pending",
                    },
                    {
                      key: "2",
                      id: "1137",
                      phoneNumber: "01234567821",
                      vehicleQRCode: "888880001872",
                      date: "2022/07/05 17:20:24",
                      rd: "anu_amila@naver.com",
                      assignTo: ["None", "Kick test"],
                      subject: ["None", "Kick test"],
                      status: "Done",
                    },
                  ]}
                  pageSize={20}
                  onChangePage={(page) => {
                    console.log("page", page);
                  }}
                />
              </div>
            </Col>
            {dataTickets && (
              <Col span={10}>
                <Row gutter={24} style={{ backgroundColor: "white" }}>
                  <Col span={12}>
                    <InputGroup
                      title="Subject"
                      name="Subject"
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      title="Name"
                      name="Name"
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      title="Vehicle Name"
                      name="VehicleName"
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      title="From Email"
                      name="From Email"
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      title="Body"
                      name="Body"
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      title="Body"
                      name="Body"
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col span={12}>
                    <span className="org_address">Body</span>
                    <TextArea
                      maxLength={100}
                      style={{ height: 80, marginTop: "5px" }}
                      onChange={() => console.log(111)}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <span className="org_address">Reply Message *</span>
                    <TextArea
                      maxLength={100}
                      style={{ height: 80, marginTop: "5px" }}
                      onChange={() => console.log(111)}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "1rem",
                    }}
                  >
                    <BBtn
                      title="Mark as Done"
                      color="#24BB69"
                      icon1={<i className="fa-solid fa-check"></i>}
                    />
                    <span style={{ width: "1rem" }}></span>
                    <BBtn
                      title="Send Reply"
                      color="#FF5C6A"
                      icon1={<i className="fa-solid fa-reply"></i>}
                    />
                  </Col>

                  <Col span={24}>
                    <p className="tickets-mess">Support Image</p>
                    {/* <div style={{width: '100%'}}>
                  <span>green apps</span>
                  <Input value={'ádsdaasd'} readOnly />
                  <p style={{textAlign: 'end', margin: 0}}>
                    Oct 04, 2022 15:27
                  </p>
                </div>

                <div style={{width: '100%'}}>
                  <span>green apps</span>
                  <Input value={'ádsdaasd'} readOnly />
                  <p style={{textAlign: 'end', margin: 0}}>
                    Oct 04, 2022 15:27
                  </p>
                </div> */}
                  </Col>

                  <Col span={24}>
                    <p className="tickets-mess">Message Timeline</p>
                    <div style={{ width: "100%" }}>
                      <span>green apps</span>
                      <Input value={"ádsdaasd"} readOnly />
                      <p style={{ textAlign: "end", margin: 0 }}>
                        Oct 04, 2022 15:27
                      </p>
                    </div>

                    <div style={{ width: "100%" }}>
                      <span>green apps</span>
                      <Input value={"ádsdaasd"} readOnly />
                      <p style={{ textAlign: "end", margin: 0 }}>
                        Oct 04, 2022 15:27
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Row>
      ) : (
        <>
          <br />
          <span className="va_p_title">Ticket Report</span>
          <div style={{ backgroundColor: "white", padding: "1rem" }}>
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "1rem 0",
                  // marginLeft: '1rem',
                }}
              >
                <span style={{ marginRight: "1rem" }}>Show</span>
                <Select
                  size={size}
                  defaultValue={"Show"}
                  onChange={handleChange}
                >
                  {children}
                </Select>
                <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                  Entries
                </span>
                <ISearch onChange={() => console.log(222)} />
              </div>
            </div>
            <div>
              <ITable
                // onClickRow={(params: any) => setDataOffers(params)}
                // rowSelection={true}
                column={[
                  {
                    title: "ID",
                    dataIndex: "id",
                  },
                  {
                    title: "Title",
                    dataIndex: "Title",
                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                  },
                  {
                    title: "Body",
                    dataIndex: "Body",
                  },
                  {
                    title: "Start Time",
                    dataIndex: "Start Time",
                  },
                  {
                    title: "End Time",
                    dataIndex: "End Time",
                  },
                  {
                    title: "Duration",
                    dataIndex: "Duration",
                  },
                  {
                    title: "Date",
                    dataIndex: "Date",
                  },
                  {
                    title: "Total Hours",
                    dataIndex: "Total Hours",
                  },
                  {
                    title: "Total Expense",
                    dataIndex: "Total Expense",
                  },
                  {
                    title: "Status",
                    dataIndex: "Status",
                  },
                ]}
                total={100}
                data={
                  [
                    // {
                    //   key: '3',
                    //   id: '3',
                    //   fleet: 'Joyride_only',
                    //   title: 'dsaasd',
                    //   category: 'advert',
                    // },
                  ]
                }
                pageSize={20}
                onChangePage={(page) => {
                  console.log("page", page);
                }}
              />
            </div>
          </div>
        </>
      )}

      <Modal
        title="Create Ticket"
        open={openCreateTicket}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row style={{ padding: "1rem" }}>
          <InputGroup
            title="Vehicle QR"
            name="vehicleQR"
            onChange={(e) => {
              console.log(e);
            }}
          />
          <Col span={24}>
            <span className="org_address">Title * </span>
            <ISelect
              option={[
                { text: "Title1", value: "Title1" },
                { text: "Title2", value: "Title2" },
              ]}
              name="Title"
              onChange={() => console.log(1222)}
              placeHolder="Title"
            />
          </Col>
          <Col span={24}>
            <span className="org_address">Description *</span>
            <TextArea
              maxLength={100}
              style={{ height: 80, marginTop: "5px" }}
              onChange={() => console.log(111)}
            />
          </Col>
        </Row>
        <Row
          style={{
            borderTop: "1px solid #E1E1E1",
            padding: "1rem",
            justifyContent: "end",
          }}
        >
          <BBtn
            title="Create Ticket"
            color="#24BB69"
            icon1={<i className="fa-solid fa-floppy-disk"></i>}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default Tickets;
