import {Col, Row, Select, Steps, Switch} from 'antd'
import {SizeType} from 'antd/lib/config-provider/SizeContext'
import TextArea from 'antd/lib/input/TextArea'
import {Option} from 'antd/lib/mentions'
import React, {useEffect, useState} from 'react'
import BBtn from '../../components/button'
import CardRiders from '../../components/cardRiders'
import ISearch from '../../components/search'
import ISelect from '../../components/select'
import ITable from '../../components/table'
import InputGroup from '../../components/textInput'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {riderAction, selectListRider , selectItemCount} from "../../features/rider/riderSlice";

const children: React.ReactNode[] = []
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
}

const Riders = () => {
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`)
  }
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`)
  }
  const dispatch = useAppDispatch()
  const itemCount = useAppSelector(selectItemCount)
  const listRider = useAppSelector(selectListRider)
  const [dataRiders, setDataRiders] = useState<any>()
  const [size, setSize] = useState<SizeType>('middle')
  const [listActiveButtonFleetManager, setListActiveButtonFleetManager] =
    useState<string[]>(['Campusride'])

  const [showTable, setShowTable] = useState<string>('')
  const [activeButtonRH, setActiveButtonRH] = useState<string>('Wallet Creadit')
  const [page , setPage] = useState(1)
  const [limit , setLimit] = useState(15)
  const [keyword , setKeyword] = useState('')
  const handleGetCsv = () => {
      console.log('getCsv')
      dispatch(riderAction.getCsv({
          page : 1,
          limit : 10
      }))
  }
  console.log(888, showTable)
  useEffect(()=> {
    dispatch(riderAction.getListRider({
      page,
      limit,
      keyword
    }))
  },[dispatch,limit ,page , keyword])
  return (
    <div>
      <br />
      <Row align='middle' justify='space-between'>
        <span className='va_p_title'>Riders</span>
        <Col style={{display: 'flex'}}>
          {/* <BBtn title='Get CSV' color='#FF5C6A' />
          <span style={{margin: '0 0.5rem'}}></span> */}

          <BBtn title='Get CSV' color='#FF5C6A' onClick={handleGetCsv} />
          <span style={{margin: '0 0.5rem'}}></span>
          <BBtn
            title='Filter'
            color='#FF5C6A'
            icon1={<i className='fa-solid fa-sort-down'></i>}
            icon1left={true}
          />
          <span style={{margin: '0 0.5rem'}}></span>
          <BBtn
            title='All Fleets'
            color='#FF5C6A'
            icon1={<i className='fa-solid fa-sort-down'></i>}
            icon1left={true}
          />
        </Col>
      </Row>
      <br />
      <Row justify='space-between' align='middle'></Row>
      <br />
      <Row gutter={24}>
        <Col span={dataRiders ? 12 : 24}>
          <div style={{backgroundColor: 'white', padding: '1rem'}}>
            <div
              style={{
                display: dataRiders ? 'initial' : 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '1rem 0',
                  // marginLeft: '1rem',
                }}
              >
                <span style={{marginRight: '1rem'}}>Show</span>
                <Select
                  size={size}
                  defaultValue={'Show'}
                  onChange={handleChange}
                >
                  {children}
                </Select>
                <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                  Entries
                </span>
                <ISearch onChange={() => console.log(222)} />
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <ISelect
                  option={[{text : '1' , value : '1'},{text : '2' , value : '2'},{text : '3' , value : '3'}]}
                  onChange={() => console.log(111)}
                  name='all'
                  placeHolder='All'
                  width={100}
                />
                <span style={{margin: '0 0.5rem'}}></span>
                <span className='va_p1'>Active</span>
                <span style={{margin: '0 0.5rem'}}></span>
                <Switch defaultChecked onChange={onChange} />
                <span style={{margin: '0 0.5rem'}}></span>
                <span className='va_p1'>Deleted</span>
                <span style={{margin: '0 0.5rem'}}></span>
                <Switch defaultChecked onChange={onChange} />
                <span style={{margin: '0 0.5rem'}}></span>
                <BBtn
                  title='CSV'
                  color='#24BB69'
                  icon1={<i className='fa-solid fa-file-lines'></i>}
                />
              </div>
            </div>
            <div>
              <ITable
                onClickRow={(params: any) => setDataRiders(params)}
                // rowSelection={true}
                column={[
                  {
                    title: 'ID',
                    dataIndex: 'id',
                  },
                  {
                    title: 'User Name',
                    dataIndex: 'userName',
                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                  },
                  {
                    title: 'Balance',
                    dataIndex: 'balance',
                  },
                  {
                    title: 'Email',
                    dataIndex: 'email',
                  },
                  {
                    title: 'Country Code',
                    dataIndex: 'countryCode',
                  },
                  {
                    title: 'Phone Number',
                    dataIndex: 'phoneNumber',
                  },
                  {
                    title: 'Register Date',
                    dataIndex: 'registerDate',
                  },
                  {
                    title: 'Last login',
                    dataIndex: 'lastLogin',
                  },
                  {
                    title: 'App Version',
                    dataIndex: 'appVersion',
                  },
                  {
                    title: 'Platform',
                    dataIndex: 'platform',
                  },
                  {
                    title: 'Os Version',
                    dataIndex: 'osVersion',
                  },
                  {
                    title: 'OTP',
                    dataIndex: 'OTP',
                  },
                  {
                    title: 'Payment Card',
                    dataIndex: 'paymentCard',
                  },
                  {
                    title: 'Email Verification',
                    dataIndex: 'emailVerification',
                  },
                  {
                    title: 'Email OTP',
                    dataIndex: 'emailOTP',
                  },
                ]}
                total={itemCount || 0}
                data={listRider || []}
                pageSize={limit}
                currentPage={page}
                onChangePage={(page) => {
                  setPage(page)
                }}
              />
            </div>
          </div>
        </Col>
        {/* <Col span={1}></Col> */}
        {dataRiders && (
          <>
            <Col span={12}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '1rem',
                }}
              >
                <Col span={24} className='gutter-row'>
                  <div>
                    <p className='org_p1'>Edit Rider</p>
                    <Row gutter={16}>
                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title='First Name'
                          name='First Name'
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          title='Last Name'
                          name='Last Name'
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          title='Country Code'
                          name='Country Code'
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          title='Phone Number'
                          name='Phone Number'
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          title='Email'
                          name='Email'
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          title='User Name'
                          name='User Name'
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          title='Deposit'
                          name='Deposit'
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          title='Balance'
                          name='Balance'
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <span className='org_address'>Notes</span>
                        <TextArea
                          maxLength={100}
                          style={{height: 40, marginTop: '5px'}}
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <span className='org_address'>Default Fleet</span>
                        <ISelect
                          option={[{text : 'yes', value : 'yes'}, {text : 'no', value : 'no'}]}
                          name='Prepaid'
                          onChange={() => console.log(1222)}
                          placeHolder='Default Fleet'
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <BBtn
                        title='Back'
                        color='#FF5C6A'
                        onClick={() => setDataRiders(undefined)}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <BBtn title='Deactivate' color='#FF5C6A' />
                        <div style={{width: '16px'}}></div>
                        <BBtn title='Delete' color='#FF5C6A' />
                        <div style={{width: '16px'}}></div>
                        <BBtn title='Save Changes' color='#24BB69' />
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            <br />
            <Col span={24}>
              <p className='org_title'>Fleet Manager</p>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '1rem',
                  display: 'flex',
                }}
              >
                <BBtn title='Campusride' color='#FF5C6A' />
                <div style={{width: '1rem'}}></div>
                <BBtn title='Joyride_only' color='#FF5C6A' />
                <div style={{width: '1rem'}}></div>
                <BBtn
                  title='Joyride_only 1'
                  color='white'
                  boderColor='#758d96'
                  styleText={{color: '#758d96'}}
                />
              </div>
            </Col>
            <Col span={24} style={{marginTop: '3rem'}}>
              <Row gutter={24}>
                <Col span={8}>
                  <CardRiders
                    title='Ride History'
                    onClick={() => setShowTable('Ride History')}
                    icon={<i className='fa-solid fa-person-biking'></i>}
                  />
                </Col>
                <Col span={8}>
                  <CardRiders
                    title='Ride History'
                    onClick={() => setShowTable('Payment History')}
                    icon={<i className='fa-solid fa-credit-card'></i>}
                  />
                </Col>
                <Col span={8}>
                  <CardRiders
                    title='Promo History'
                    onClick={() => setShowTable('Promo History')}
                    icon={<i className='fa-solid fa-percent'></i>}
                  />
                </Col>
              </Row>
              <br />
              {showTable === 'Ride History' && (
                <Col span={24}>
                  <p className='org_title'>Ride History</p>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{marginRight: '1rem'}}>Show</span>
                      <Select
                        size={size}
                        defaultValue={'Show'}
                        onChange={handleChange}
                      >
                        {children}
                      </Select>
                      <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                        Entries
                      </span>
                      <ISearch onChange={() => console.log(222)} />
                    </div>
                    <br />
                    <ITable
                      // onClickRow={(params: any) => setDataFleet(params)}
                      column={[
                        {
                          title: 'Ride ID',
                          dataIndex: 'id',
                        },
                        {
                          title: 'Amount',
                          dataIndex: 'Amount',
                          // sorter: (a: any, b: any) => a.name.length - b.name.length,
                        },
                        {
                          title: 'Duration',
                          dataIndex: 'Duration',
                        },

                        {
                          title: 'Pause Duration',
                          dataIndex: 'Pause Duration',
                          // render: (text: string) => (
                          //   <span
                          //     style={{
                          //       backgroundColor:
                          //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                          //       color: 'white',
                          //       padding: '0.2rem 0.5rem',
                          //       borderRadius: '2px',
                          //     }}
                          //   >
                          //     {text}
                          //   </span>
                          // ),
                        },
                        {
                          title: 'Start Date',
                          dataIndex: 'Start Date',
                        },
                        {
                          title: 'End Date',
                          dataIndex: 'End Date',
                        },
                        // {
                        //   title: 'Created Date',
                        //   dataIndex: 'createdDate',
                        // },
                      ]}
                      total={100}
                      data={
                        [
                          // {
                          //   key: '3',
                          //   id: '3',
                          //   name: 'jsadjasd',
                          //   supportedEmail: 'alma.lawson@gmail.com',
                          //   visibility: 'Public',
                          //   location: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
                          //   billing: 'Joyride plan',
                          //   createdDate: '2014/12/24 23:12:00',
                          // },
                        ]
                      }
                      pageSize={20}
                      onChangePage={(page) => {
                        console.log('page', page)
                      }}
                    />
                  </div>
                </Col>
              )}

              {showTable === 'Promo History' && (
                <Col span={24}>
                  <p className='org_title'>Promo History</p>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{marginRight: '1rem'}}>Show</span>
                      <Select
                        size={size}
                        defaultValue={'Show'}
                        onChange={handleChange}
                      >
                        {children}
                      </Select>
                      <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                        Entries
                      </span>
                      <ISearch onChange={() => console.log(222)} />
                    </div>
                    <br />
                    <ITable
                      // onClickRow={(params: any) => setDataFleet(params)}
                      column={[
                        {
                          title: 'ID',
                          dataIndex: 'id',
                        },
                        {
                          title: 'Name',
                          dataIndex: 'Name',
                          // sorter: (a: any, b: any) => a.name.length - b.name.length,
                        },
                        {
                          title: 'Type',
                          dataIndex: 'Type',
                        },

                        {
                          title: 'Amount',
                          dataIndex: 'Amount',
                          // render: (text: string) => (
                          //   <span
                          //     style={{
                          //       backgroundColor:
                          //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                          //       color: 'white',
                          //       padding: '0.2rem 0.5rem',
                          //       borderRadius: '2px',
                          //     }}
                          //   >
                          //     {text}
                          //   </span>
                          // ),
                        },
                        {
                          title: 'Date',
                          dataIndex: 'Date',
                        },
                      ]}
                      total={100}
                      data={
                        [
                          // {
                          //   key: '3',
                          //   id: '3',
                          //   name: 'jsadjasd',
                          //   supportedEmail: 'alma.lawson@gmail.com',
                          //   visibility: 'Public',
                          //   location: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
                          //   billing: 'Joyride plan',
                          //   createdDate: '2014/12/24 23:12:00',
                          // },
                        ]
                      }
                      pageSize={20}
                      onChangePage={(page) => {
                        console.log('page', page)
                      }}
                    />
                  </div>
                </Col>
              )}

              {showTable === 'Payment History' && (
                <>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <BBtn
                      title='Wallet Creadit'
                      color={
                        activeButtonRH === 'Wallet Creadit'
                          ? '#FF5C6A'
                          : 'white'
                      }
                      styleText={{
                        color:
                          activeButtonRH === 'Wallet Creadit'
                            ? 'white'
                            : '#4E5A5F',
                      }}
                      onClick={() => setActiveButtonRH('Wallet Creadit')}
                    />
                    <BBtn
                      title='Wallet Debit'
                      color={
                        activeButtonRH === 'Wallet Debit' ? '#FF5C6A' : 'white'
                      }
                      styleText={{
                        color:
                          activeButtonRH === 'Wallet Debit'
                            ? 'white'
                            : '#4E5A5F',
                      }}
                      onClick={() => setActiveButtonRH('Wallet Debit')}
                    />
                  </div>
                  {activeButtonRH === 'Wallet Creadit' && (
                    <Col span={24}>
                      <p className='org_title'>Wallet Creadit</p>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span style={{marginRight: '1rem'}}>Show</span>
                          <Select
                            size={size}
                            defaultValue={'Show'}
                            onChange={handleChange}
                          >
                            {children}
                          </Select>
                          <span
                            style={{marginLeft: '1rem', marginRight: '3rem'}}
                          >
                            Entries
                          </span>
                          <ISearch onChange={() => console.log(222)} />
                        </div>
                        <br />
                        <ITable
                          // onClickRow={(params: any) => setDataFleet(params)}
                          column={[
                            {
                              title: 'Invoice ID',
                              dataIndex: 'Invoice ID',
                            },
                            {
                              title: 'Tax ID',
                              dataIndex: 'Tax ID',
                              // sorter: (a: any, b: any) => a.name.length - b.name.length,
                            },
                            {
                              title: 'Type',
                              dataIndex: 'Type',
                            },

                            {
                              title: 'Amount',
                              dataIndex: 'Amount',
                              // render: (text: string) => (
                              //   <span
                              //     style={{
                              //       backgroundColor:
                              //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                              //       color: 'white',
                              //       padding: '0.2rem 0.5rem',
                              //       borderRadius: '2px',
                              //     }}
                              //   >
                              //     {text}
                              //   </span>
                              // ),
                            },
                            {
                              title: 'Time',
                              dataIndex: 'Time',
                            },
                            {
                              title: 'Reason',
                              dataIndex: 'Reason',
                            },
                            // {
                            //   title: 'Created Date',
                            //   dataIndex: 'createdDate',
                            // },
                          ]}
                          total={100}
                          data={
                            [
                              // {
                              //   key: '3',
                              //   id: '3',
                              //   name: 'jsadjasd',
                              //   supportedEmail: 'alma.lawson@gmail.com',
                              //   visibility: 'Public',
                              //   location: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
                              //   billing: 'Joyride plan',
                              //   createdDate: '2014/12/24 23:12:00',
                              // },
                            ]
                          }
                          pageSize={20}
                          onChangePage={(page) => {
                            console.log('page', page)
                          }}
                        />
                      </div>
                    </Col>
                  )}
                  {activeButtonRH === 'Wallet Debit' && (
                    <Col span={24}>
                      <p className='org_title'>Wallet Debit</p>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span style={{marginRight: '1rem'}}>Show</span>
                          <Select
                            size={size}
                            defaultValue={'Show'}
                            onChange={handleChange}
                          >
                            {children}
                          </Select>
                          <span
                            style={{marginLeft: '1rem', marginRight: '3rem'}}
                          >
                            Entries
                          </span>
                          <ISearch onChange={() => console.log(222)} />
                        </div>
                        <br />
                        <ITable
                          // onClickRow={(params: any) => setDataFleet(params)}
                          column={[
                            {
                              title: 'Invoice ID',
                              dataIndex: 'Invoice ID',
                            },
                            {
                              title: 'Tax ID',
                              dataIndex: 'Tax ID',
                              // sorter: (a: any, b: any) => a.name.length - b.name.length,
                            },
                            {
                              title: 'Amount',
                              dataIndex: 'Amount',
                            },

                            {
                              title: 'Type',
                              dataIndex: 'Type',
                              // render: (text: string) => (
                              //   <span
                              //     style={{
                              //       backgroundColor:
                              //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                              //       color: 'white',
                              //       padding: '0.2rem 0.5rem',
                              //       borderRadius: '2px',
                              //     }}
                              //   >
                              //     {text}
                              //   </span>
                              // ),
                            },
                            {
                              title: 'Promo',
                              dataIndex: 'Promo',
                            },
                            {
                              title: 'Time',
                              dataIndex: 'Time',
                            },
                            {
                              title: 'Reason',
                              dataIndex: 'Reason',
                            },
                            {
                              title: 'Refunded By',
                              dataIndex: 'Refunded By',
                            },
                            {
                              title: 'Action',
                              dataIndex: 'Action',
                            },
                          ]}
                          total={100}
                          data={
                            [
                              // {
                              //   key: '3',
                              //   id: '3',
                              //   name: 'jsadjasd',
                              //   supportedEmail: 'alma.lawson@gmail.com',
                              //   visibility: 'Public',
                              //   location: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
                              //   billing: 'Joyride plan',
                              //   createdDate: '2014/12/24 23:12:00',
                              // },
                            ]
                          }
                          pageSize={20}
                          onChangePage={(page) => {
                            console.log('page', page)
                          }}
                        />
                      </div>
                    </Col>
                  )}
                </>
              )}
            </Col>
            <Col span={24} style={{marginTop: '3rem'}}>
              <div>
                <div style={{display: 'flex'}}>
                  <p className='org_title'>Timeline</p>
                  {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                </div>
                <Row gutter={16} style={{paddingLeft: '2rem'}}>
                  <Col span={8}>
                    <Steps direction='vertical' current={1}>
                      <Steps.Step
                        icon={
                          <i
                            className='fa-solid fa-tag'
                            style={{
                              color: '#333',
                              fontSize: '40px',
                            }}
                          ></i>
                        }
                        title='Finished'
                        description='This is a description.'
                      />
                      <Steps.Step
                        icon={
                          <i
                            className='fa-solid fa-tag'
                            style={{
                              color: '#333',
                              fontSize: '40px',
                            }}
                          ></i>
                        }
                        title='Finished'
                        description='This is a description.'
                      />
                      <Steps.Step
                        icon={
                          <i
                            className='fa-solid fa-tag'
                            style={{
                              color: '#333',
                              fontSize: '40px',
                            }}
                          ></i>
                        }
                        title='Finished'
                        description='This is a description.'
                      />
                    </Steps>
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}

export default Riders
