import React, { createContext, useContext } from "react";
import { io } from "socket.io-client";
import {Config, LOCAL_STORAGE} from "../utils/constants";

export const socket = io(Config.SOCKET_API, {
  // withCredentials: true,
  extraHeaders: {
    Authorization: "Bearer " + localStorage.getItem(LOCAL_STORAGE.TOKEN),
  },
  reconnection : true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: 99999
});

export const SocketContext = createContext(socket);

type Props = {
  children?: React.ReactNode;
};

export const SocketContextProvider: React.FC<Props> = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
