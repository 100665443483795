import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {AnalyticRideModel} from "../../models/analyticRideModel";

export interface AnalyticPaymentState {
    loadding: boolean
    chartRidePerHour :  { hour: number; totalRides: number }[] | undefined
    chartRideByDay : {date : string , totalRides : number}[] | undefined
    chartUserSignUp : {date : string , totalUser : number}[] | undefined
    statisticRide : {
        "averageUsingVehicle": number,
        "averageRide": number,
        "countTotalRides": number,
        "averageRidesPerDay": number
    } | undefined
    statisticRider : {
        countUser : number,
        usersInLastMonth : number,
        monthlyActiveUsers : number,
    } | undefined
    chartRideFleet : {
        idleVehicle : number,
        usedVehicle : number,
        unavailable : number,
        rebalancePickUp : number,
        availableVehicle : number,
        missing : number,
        notConnectedVehicle : number,
        connectedVehicle : number,
    } | undefined
    statisticTicket : {
        ticketPending: number,
        avgTicketPerDay: number,
        avgTicketPerVehiclePerMonth:number,
    } | undefined
    statisticVehicle ? : {
        "countVehicle": number,
        "vehicleIsRental": number,
        "vehicleLowBattery": number
    }
}

const initialState: AnalyticPaymentState = {
    loadding: false,
    statisticRide: undefined,
    chartRidePerHour : undefined,
    chartRideByDay : undefined,
    chartUserSignUp : undefined,
    statisticRider : undefined,
    chartRideFleet : undefined,
    statisticTicket : undefined,
    statisticVehicle : undefined
}

const analyticRideSlice = createSlice({
    name: 'analyticRide',
    initialState,
    reducers: {
        getChartRidePerHour(state, action: PayloadAction<{
            startTime: string;
            endTime: string;
            fleet?: string;
            type: "RIDE" | "TOTAL_RIDE";
        }>) {
            state.loadding = true
        },
        getChartRidePerHourSuccess(state, action: PayloadAction< { hour: number; totalRides: number }[]>) {
            state.loadding = false
            state.chartRidePerHour = action.payload

        },
        getChartRidePerHourFail(state ) {
            state.loadding = false
        },
        getChartRideByDay(state, action: PayloadAction<{
            startTime: string;
            endTime: string;
            fleet?: string;
            type: "RIDE" | "TOTAL_RIDE";
        }>) {
            state.loadding = true
        },
        getChartRideByDaySuccess(state, action: PayloadAction<{date : string , totalRides : number}[]>) {
            state.loadding = false
            state.chartRideByDay = action.payload

        },
        getChartRideByDayFail(state ) {
            state.loadding = false
        },
        getChartUserSignUp(state, action: PayloadAction<{
            startTime?: string;
            endTime?: string;
            fleet?: string;
            type: "RIDE" | "TOTAL_RIDE";
        }>) {
            state.loadding = true
        },
        getChartUserSignUpSuccess(state, action: PayloadAction<{date : string , totalUser : number}[]>) {
            state.loadding = false
            state.chartUserSignUp = action.payload

        },
        getChartUserSignUpFail(state ) {
            state.loadding = false
        },
        getChartRideFleet(state, action: PayloadAction<{
            fleet?: string;
        }>) {
            state.loadding = true
        },
        getChartRideFleetSuccess(state, action: PayloadAction<{
            idleVehicle : number,
            usedVehicle : number,
            unavailable : number,
            rebalancePickUp : number,
            availableVehicle : number,
            missing : number,
            notConnectedVehicle : number,
            connectedVehicle : number,
        }>) {
            state.loadding = false
            state.chartRideFleet = action.payload

        },
        getChartRideFleetFail(state ) {
            state.loadding = false
        },
        getStatisticRide(state, action: PayloadAction<{
            startTime: string;
            endTime: string;
            fleet?: string;
            type: "RIDE" | "TOTAL_RIDE";
        }>) {
            state.loadding = true
        },
        getStatisticRideSuccess(state , action : PayloadAction<{
            "averageUsingVehicle": number,
            "averageRide": number,
            "countTotalRides": number,
            "averageRidesPerDay": number
        }>){
            state.loadding = false
            state.statisticRide = action.payload
        },
        getStatisticRideFail(state){
            state.loadding = false
        },
        getStatisticRider(state, action: PayloadAction<{
            startLastMonth: string;
            endLastMonth: string;
            fleet?: string;
            type: "RIDE" | "TOTAL_RIDE";
        }>) {
            state.loadding = true
        },
        getStatisticRiderSuccess(state , action : PayloadAction<{
            countUser : number,
            usersInLastMonth : number,
            monthlyActiveUsers : number,
        }>){
            state.loadding = false
            state.statisticRider = action.payload
        },
        getStatisticRiderFail(state){
            state.loadding = false
        },
        getStatisticTicket(state, action: PayloadAction<{
            startTime: string;
            endTime: string;
            fleet?: string;
            type: "RIDE" | "TOTAL_RIDE";
        }>) {
            state.loadding = true
        },
        getStatisticTicketSuccess(state , action : PayloadAction<{
            ticketPending: number,
            avgTicketPerDay: number,
            avgTicketPerVehiclePerMonth:number,
        }>){
            state.loadding = false
            state.statisticTicket = action.payload
        },
        getStatisticTicketFail(state){
            state.loadding = false
        },
        getStatisticVehicle(state, action: PayloadAction<{
            fleet?: string;
        }>) {
            state.loadding = true
        },
        getStatisticVehicleSuccess(state , action : PayloadAction<{
            "countVehicle": number,
            "vehicleIsRental": number,
            "vehicleLowBattery": number
        }>){
            state.loadding = false
            state.statisticVehicle = action.payload
        },
        getStatisticVehicleFail(state){
            state.loadding = false
        }
    },
})
export const analyticRideAction = analyticRideSlice.actions
export const selectLoading = (state: RootState) => state.analyticRide.loadding
export const selectStatisticRider= (state: RootState) => state.analyticRide.statisticRider
export const selectStatisticRide= (state: RootState) => state.analyticRide.statisticRide
export const selectStatisticTicket= (state: RootState) => state.analyticRide.statisticTicket
export const selectStatisticVehicle= (state: RootState) => state.analyticRide.statisticVehicle
export const selectChartRidePerHour= (state: RootState) => state.analyticRide.chartRidePerHour
export const selectChartRideByDay= (state: RootState) => state.analyticRide.chartRideByDay
export const selectChartRideFleet= (state: RootState) => state.analyticRide.chartRideFleet
export const selectChartUserSignUp= (state: RootState) => state.analyticRide.chartUserSignUp

export const analyticRideReducer = analyticRideSlice.reducer
