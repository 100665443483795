import {Col, Rate, Row, Select, Steps, Switch, Tooltip} from "antd";
import {SizeType} from "antd/lib/config-provider/SizeContext";
import {Option} from "antd/lib/mentions";
import React, {useEffect, useState} from "react";
import BBtn from "../../../components/button";
import CardCompleted from "../../../components/cardCompleted";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import Map, {Marker} from "react-map-gl";
import {
    orderAction,
    selectItemCount,
    selectListOrder,
} from "../../../features/order/orderSlice";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {DEVICE_TYPE} from "../../../models/vehicleModel";
import {OrderModel} from "../../../models/order.model";
import moment from "moment/moment";

const Completed = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const listOrder = useAppSelector(selectListOrder);
    const itemCount = useAppSelector(selectItemCount);
    const [size, setSize] = useState<SizeType>("middle");
    const [buttonActive, setButtonActive] = useState("Rider Timeline");
    const [limit, setLimit] = useState<number>(15);
    const [page, setPage] = useState<number>(1);
    const [keyword, setKeyword] = useState<string>(undefined);
    const handleChange = (value: string | string[]) => {
        setLimit(Number(value));
    };
    useEffect(() => {
        dispatch(
            orderAction.getListOrder({
                page,
                limit,
                keyword,
                status: "COMPLETE",
                "orderBy[createdAt]": "DESC",
            }),
        );
    }, [page, limit, keyword]);
    const [dataRides, setDataRides] = useState<any>();
    const onChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
    };
    return (
        <div>
            <Row align="middle" justify="space-between" style={{margin: "1rem 0"}}>
                <span className="va_p_title">Rides</span>
                <div style={{display: "flex"}}>
                    <BBtn title="Get CSV"/>
                    <span style={{margin: "0 0.5rem"}}></span>
                    <BBtn
                        title="All currencies"
                        icon1={<i className="fa-solid fa-caret-down"></i>}
                        icon1left
                    />
                    <span style={{margin: "0 0.5rem"}}></span>
                    <BBtn
                        title="All Fleet"
                        icon1={<i className="fa-solid fa-caret-down"></i>}
                        icon1left
                    />
                </div>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <CardCompleted
                        name="In Use"
                        value="1"
                        icon={<i className="fa-solid fa-person-biking"></i>}
                    />
                </Col>

                <Col span={6}>
                    <CardCompleted
                        name="Average Ride Length "
                        value="8"
                        icon={<i className="fa-solid fa-users"></i>}
                    />
                </Col>

                <Col span={6}>
                    <CardCompleted
                        name="Total Users"
                        value="9087"
                        icon={<i className="fa-solid fa-head-side-mask"></i>}
                    />
                </Col>
                <Col span={6}>
                    <CardCompleted
                        name="Average Rides Per User"
                        value="9"
                        icon={<i className="fa-solid fa-motorcycle"></i>}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    style={{display: "flex", justifyContent: "end", margin: "1rem 0"}}
                >
                    <BBtn
                        title="Filter"
                        icon1={<i className="fa-solid fa-caret-down"></i>}
                        icon1left
                    />
                </Col>
                <Col span={24} className="gutter-row">
                    <div className="org_div1">
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "1rem 0",
                                    // marginLeft: '1rem',
                                }}
                            >
                                <span style={{marginRight: "1rem"}}>Show</span>
                                <Select
                                    size={size}
                                    defaultValue={t("show").toString()}
                                    onChange={handleChange}
                                >
                                    <Option value="15">10</Option>
                                    <Option value="25">20</Option>
                                    <Option value="50">30</Option>
                                    <Option value="50">40</Option>
                                    <Option value="50">50</Option>
                                </Select>
                                <span style={{marginLeft: "1rem", marginRight: "3rem"}}>
                  {t("entries")}
                </span>
                                <ISearch
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className="va_p1">Force Stop</span>
                                <span style={{margin: "0 0.25rem"}}></span>
                                <Switch defaultChecked onChange={onChange}/>
                                <span style={{margin: "0 0.25rem"}}></span>
                                <span className="va_p1">Km</span>
                                <span style={{margin: "0 0.25rem"}}></span>
                                <Switch defaultChecked onChange={onChange}/>
                                <span style={{margin: "0 0.25rem"}}></span>
                                <span className="va_p1">Mile</span>
                                <span style={{margin: "0 0.25rem"}}></span>
                                <BBtn
                                    title="CSV"
                                    color="#24BB69"
                                    icon1={<i className="fa-solid fa-file-lines"></i>}
                                />
                            </div>
                        </div>
                        <br/>
                        <ITable
                            onClickRow={(params: any) => setDataRides(params)}
                            column={[
                                {
                                    title: "Ride ID",
                                    dataIndex: "_id",
                                },
                                {
                                    title: "phone number",
                                    dataIndex: "customer",
                                    render: (text: Record<string, any>) =>
                                        text ? text.phoneNumber : "",
                                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                                },
                                {
                                    title: "User ID",
                                    dataIndex: "customer",
                                    render: (text: Record<string, any>) => (text ? text._id : ""),
                                },
                                {
                                    title: "Rider",
                                    dataIndex: "rider",
                                    // render: (text: string) => (
                                    //   <Tooltip placement='right' title='campusride'>
                                    //     {text} <i className='fa fa-info-circle'></i>
                                    //   </Tooltip>
                                    // ),
                                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                                },
                                {
                                    title: "Vehicle",
                                    dataIndex: "vehicles",
                                    render: (text: Record<string, any>[], record: OrderModel) =>
                                        text ? (
                                            <Tooltip
                                                placement="right"
                                                title="campusride"
                                                color={
                                                    record.isForceStop ? "#D92D20" : "rgba(0, 0, 0, 0.75)"
                                                }
                                            >
                                                {text[0].name}{" "}
                                                <i
                                                    style={{
                                                        color: record.isForceStop
                                                            ? "#D92D20"
                                                            : "rgba(0, 0, 0, 0.75)",
                                                    }}
                                                    className="fa fa-info-circle"
                                                ></i>
                                            </Tooltip>
                                        ) : (
                                            ""
                                        ),
                                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                                },
                                {
                                    title: "Qr Code",
                                    dataIndex: "vehicles",
                                    render: (text: Record<string, any>[]) =>
                                        text ? text[0].qrCode : "",
                                },
                                {
                                    title: "Vehicle Type",
                                    dataIndex: "vehicles",
                                    render: (text: Record<string, any>[]) =>
                                        text ? DEVICE_TYPE[text[0].typeDevice] : "",
                                },
                                {
                                    title: "Amount",
                                    dataIndex: "amount",
                                },
                                {
                                    title: "Distance (km)",
                                    dataIndex: "distance",
                                },
                                {
                                    title: "Odometer Distance (km)",
                                    dataIndex: "odometerDistance",
                                    render: (text: {}, record: OrderModel) =>
                                        record.odometerEndBooking - record.odometerBooking,
                                },
                                {
                                    title: "Duration",
                                    dataIndex: "rideDuration",
                                    render: (text: {}, record: OrderModel) => {
                                        const startTime = moment(record.startTime);
                                        const endTime = moment(record.endTime);
                                        const diff = moment
                                            .duration(endTime.diff(startTime))
                                            .asMilliseconds();
                                        return moment.utc(diff).format("HH:mm:ss");
                                    },
                                },
                                {
                                    title: "Pause Duration",
                                    dataIndex: "pauseDuration",
                                },
                                {
                                    title: "Ride Date",
                                    dataIndex: "createdAt",
                                    render: (text: string) =>
                                        moment(text).format("YYYY/MM/DD HH:mm:ss"),
                                },
                                {
                                    title: "Rating",
                                    dataIndex: "feedback",
                                    render: (text: Record<string, any>) => {
                                        return (
                                            <Rate
                                                allowHalf
                                                value={Number(text?.rating ? text.rating : 0)}
                                            />
                                        );
                                    },
                                },
                            ]}
                            total={itemCount || 0}
                            data={listOrder || []}
                            pageSize={limit}
                            currentPage={page}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                        />
                    </div>
                </Col>
            </Row>
            {dataRides && (
                <Row
                    style={{
                        backgroundColor: "white",
                        padding: "1rem",
                        marginTop: "2rem",
                    }}
                >
                    <Col span={24} style={{display: "flex"}}>
                        <BBtn
                            title="Rider Timeline"
                            styleText={{
                                color: buttonActive === "Rider Timeline" ? "white" : "black",
                            }}
                            color={buttonActive === "Rider Timeline" ? "#FF5C6A" : "white"}
                            onClick={() => setButtonActive("Rider Timeline")}
                            boderColor="white"
                        />
                        <BBtn
                            title="Vehicle Timeline"
                            styleText={{
                                color: buttonActive === "Vehicle Timeline" ? "white" : "black",
                            }}
                            color={buttonActive === "Vehicle Timeline" ? "#FF5C6A" : "white"}
                            onClick={() => setButtonActive("Vehicle Timeline")}
                        />
                        <span style={{margin: "0 13.5%"}}></span>
                        <BBtn
                            title="Show Parking Image"
                            color="white"
                            boderColor="#758D96"
                            styleText={{color: "#758D96", cursor: "pointer"}}
                            icon1={<i className="fa-solid fa-file-image"></i>}

                            // styleText={{
                            //   color: buttonActive === 'Vehicle Timeline' ? 'white' : 'black',
                            // }}
                            // color={buttonActive === 'Vehicle Timeline' ? '#FF5C6A' : 'white'}
                            // onClick={() => setButtonActive('Vehicle Timeline')}
                        />
                    </Col>
                    <Col span={24} style={{marginTop: "3rem"}}>
                        <div>
                            <div style={{display: "flex"}}>
                                <p className="org_title">Timeline</p>
                                {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                            </div>
                            <Row gutter={16} style={{paddingLeft: "2rem"}}>
                                <Col span={12}>
                                    <Steps direction="vertical" current={1}>
                                        <Steps.Step
                                            icon={
                                                <i
                                                    className="fa-solid fa-tag"
                                                    style={{
                                                        color: "#333",
                                                        fontSize: "40px",
                                                    }}
                                                ></i>
                                            }
                                            title="Finished"
                                            description="This is a description."
                                        />
                                        <Steps.Step
                                            icon={
                                                <i
                                                    className="fa-solid fa-tag"
                                                    style={{
                                                        color: "#333",
                                                        fontSize: "40px",
                                                    }}
                                                ></i>
                                            }
                                            title="Finished"
                                            description="This is a description."
                                        />
                                        <Steps.Step
                                            icon={
                                                <i
                                                    className="fa-solid fa-tag"
                                                    style={{
                                                        color: "#333",
                                                        fontSize: "40px",
                                                    }}
                                                ></i>
                                            }
                                            title="Finished"
                                            description="This is a description."
                                        />
                                    </Steps>
                                </Col>
                                <Col span={12}>
                                    <Map
                                        mapboxAccessToken="pk.eyJ1IjoiaHV5dGhhbmgxMzA4Ym4iLCJhIjoiY2w4NDNhY2hjMDdtNDN1dDlzeTh4YWEyaiJ9.1gQs8INwMvqKHmMTuMESYw"
                                        initialViewState={{
                                            longitude: -122.4,
                                            latitude: 37.8,
                                            zoom: 14,
                                        }}
                                        style={{
                                            width: "100%",
                                            height: 400,
                                            borderRadius: "5px",
                                        }}
                                        mapStyle="mapbox://styles/mapbox/streets-v11"
                                        // children={
                                        //   <Marker
                                        //     longitude={campaignDetail.longitude}
                                        //     latitude={campaignDetail.latitude}
                                        //     anchor='bottom'
                                        //   >
                                        //     <PlaceOutlinedIcon style={{color: 'red'}} />
                                        //   </Marker>
                                        // }
                                    ></Map>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default Completed;
