export const login = '/login'
export const home = '/home'
export const map = '/map'
export const org = '/org'
export const oOrg = '/org/org'
export const setting = '/setting'
export const oRole = '/org/role'
export const oPermission = '/org/permission'
export const fleets = '/fleets'
export const events = '/events'
export const themes = '/themes'
export const themesAdd = '/themes/add'
export const vehiclesAll = '/vehicles/all'
export const vehicles = '/vehicles'
export const vehiclesReserved = '/vehicles/reserved'
export const vehicleHistory = '/vehicles/history'

export const geofence = '/geofence'
export const geofenceParkingZones = '/geofence/parkingZones'
export const geofenceRestrictedZones = '/geofence/restrictedZones'
export const geofenceServiceAreas = '/geofence/serviceAreas'
export const geofenceSlowSpeedZones = '/geofence/slowSpeedZones'
export const geofenceStations = '/geofence/stations'

export const riders = '/riders'

export const pricing = '/pricing'
export const pricingPaymentGateway = '/pricing/paymentGateway'
export const pricingBillingPlan = '/pricing/billingPlan'
export const pricingTax = '/pricing/tax'
export const pricingPromos = '/pricing/promos'
export const pricingOffer = '/pricing/offer'

export const supportTickets = '/support/tickets'
export const supportFeedbackOption = '/support/feedbackOption'

export const campaignsNotifications = '/campaigns/notifications'
export const campaignsBanner = '/campaigns/banner'

export const payments = '/payments'
export const versions = '/versions'

export const ridesCompleted = '/rides/completed'
export const ridesRunning = '/rides/running'
