import {Col, Row, Space, Switch} from 'antd'
import React, {useState, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import ISearch from '../../components/search'
import {
  roleAction,
  selectListPermissons,
  selectListRole,
  selectListRolePermission,
} from '../../features/role/roleSlice'
import './permissions.css'
import {useTranslation} from "react-i18next";

const Permission = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const listRole = useAppSelector(selectListRolePermission)
  const listPermissions = useAppSelector(selectListPermissons)
  const [search, setSearch] = useState<string>()

  useEffect(() => {
    console.log(123231213)
    dispatch(roleAction.getListRole())
  }, [dispatch])
  useEffect(() => {
    dispatch(roleAction.getAllPermisstion())
  }, [dispatch])
  const onChangeSwitch = (params: {
    id: string
    permission_id: string
    type: boolean
  }) => {
    dispatch(roleAction.updatePermissions(params))
  }
  return (
    <div>
      <br />
      <p className='org_title'>{t('Page.Permissions.rolePermission')}</p>
      <Row gutter={24}>
        <Col span={24} className='gutter-row'>
          <div className='org_div1'>
            <div>
              <ISearch onChange={(e) => setSearch(e.target.value)} />
            </div>
            <br />

            <Row style={{width: '100%'}}>
              <Col>
                <p>Permissions</p>
                {listPermissions?.map((item) => {
                  if (search) {
                    if (`${item.action}${item.name}`.includes(search)) {
                      return (
                        <p key={`${item._id}1`} className='permission_p1'>
                          {item.action + ' ' + item.name}
                        </p>
                      )
                    }
                  }
                  if (!search)
                    return (
                      <p className='permission_p1' key={`${item._id}2`}>
                        {item.action + ' ' + item.name}
                      </p>
                    )
                })}
              </Col>
              {listRole?.length !== 0 &&
                listRole?.map((item) => {
                  return (
                    <Col key={item._id} style={{margin: '0 1rem'}}>
                      <p key={item._id}>{item.name}</p>
                      {listPermissions?.map((item1) => {
                        const check = item.permissions.find(
                          (item2) => item2._id === item1._id
                        )
                        if (search) {
                          if (
                            search &&
                            `${item1.action}${item1.name}`.includes(search)
                          ) {
                            return (
                              <p
                                key={`${item1.action}${item1.name}`}
                                className='permission_p1'
                              >
                                <Switch
                                  defaultChecked={true}
                                  onChange={() =>
                                    onChangeSwitch({
                                      id: item._id,
                                      permission_id: item1._id,
                                      type: !check,
                                    })
                                  }
                                />
                              </p>
                            )
                          }
                        }
                        if (!search) {
                          return (
                            <p
                              key={`${item1.action}${item1.name}1`}
                              className='permission_p1'
                            >
                              <Switch
                                key={`${item1.action}${item1.name}7`}
                                defaultChecked={check ? true : false}
                                onChange={() =>
                                  onChangeSwitch({
                                    id: item._id,
                                    permission_id: item1._id,
                                    type: !check,
                                  })
                                }
                              />
                            </p>
                          )
                        }
                      })}
                    </Col>
                  )
                })}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Permission
