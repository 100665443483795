import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {User} from "../../models/userModel";
import {userApi} from "../../apis/userApi";
import {userAction} from "./userSlice";

function* getInfo() {
  try {
    const user: {data: User} = yield call(userApi.getInfo)
    yield put(userAction.getInfoSuccess(user.data))
  } catch (error) {
    yield put(userAction.getInfoFail())
    console.log(error)
  }
}




export default function* userSaga() {
  yield takeEvery(userAction.getInfo.type, getInfo)
}
