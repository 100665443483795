import {Col, Radio, RadioChangeEvent, Row, Select, Steps} from 'antd'
import {Option} from 'antd/lib/mentions'
import React, {useState, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import BBtn from '../../../components/button'
import ISearch from '../../../components/search'
import ISelect from '../../../components/select'
import ITable from '../../../components/table'
import InputGroup from '../../../components/textInput'
import {
    billingPlanAction,
    selectItemCount,
    selectListBillingPlan,
} from '../../../features/billingPlan/billingPlanSlice'
import {BillingPlanModel} from '../../../models/billingPlanModel'

const children: React.ReactNode[] = []
for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
}

const PaymentGateway = () => {
    const [dataPaymentGateway, setDataPaymentGateway] = useState<BillingPlanModel>()
    const dispatch = useAppDispatch()
    const listPaymentGateway = useAppSelector(selectListBillingPlan)
    const itemCount = useAppSelector(selectItemCount)
    const [limit, setLimit] = useState('15')
    const [page, setPage] = useState<Number>(1)
    const handleChange = (value: string) => {
        setLimit(value)
    }
    useEffect(() => {
        dispatch(
            billingPlanAction.getListBillingPlan({
                limit: Number(limit),
                page: Number(page),
            })
        )
    }, [dispatch, limit, page])
    console.log('listbill', listPaymentGateway)
    console.log('data', dataPaymentGateway)
    const handleChangeTaxType = (value: string[]) => {
        console.log(`selected ${value}`)
    }
    // useEffect(() => {}, dataPaymentGateway)
    return (
        <div>
            <br/>
            <Row justify='space-between'>
                <span className='va_p_title'>Payment Gateway</span>
            </Row>

            <div style={{height: '1rem'}}></div>
            <Row gutter={24}>
                <Col span={dataPaymentGateway ? 12 : 24}>
                    <div style={{backgroundColor: 'white', padding: '1rem'}}>
                        <div
                            style={{
                                display: dataPaymentGateway ? 'initial' : 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '1rem 0',
                                    // marginLeft: '1rem',
                                }}
                            >
                                <span style={{marginRight: '1rem'}}>Show</span>
                                <Select
                                    value={limit}
                                    defaultValue={'10'}
                                    onChange={handleChange}
                                >
                                    <Option value='10'>15</Option>
                                    <Option value='25'>25</Option>
                                    <Option value='50'>50</Option>
                                </Select>
                                <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                    Entries
                  </span>
                                <ISearch onChange={() => console.log(222)}/>
                            </div>
                        </div>
                        <div>
                            <ITable
                                onClickRow={(params: any) => setDataPaymentGateway(params)}
                                // rowSelection={true}
                                column={[
                                    {
                                        title: 'ID',
                                        dataIndex: 'id',
                                    },
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                    },
                                    {
                                        title: 'Type',
                                        dataIndex: 'type',
                                        render: (text: boolean) => <>{text ? 'Yes' : 'No'}</>,
                                    },
                                    {
                                        title: 'Action',
                                        dataIndex: 'action',
                                        render: (text: boolean) => (
                                            <>{text ? 'active' : 'Deactive'}</>
                                        ),
                                    },
                                ]}
                                total={Number(itemCount)}
                                data={listPaymentGateway || []}
                                pageSize={Number(limit)}
                                onChangePage={(page) => {
                                    setPage(page)
                                }}
                                currentPage={Number(page)}
                            />
                        </div>
                    </div>
                </Col>
                {dataPaymentGateway && (
                    <>
                        <Col span={12}>
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    padding: '1rem',
                                }}
                            >
                                <Col span={24} className='gutter-row'>
                                    <div>
                                        <p className='org_p1'>Edit Payment Gateway</p>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <InputGroup
                                                    title='Payment Type'
                                                    name='Payment Type'
                                                    value={dataPaymentGateway.billingUnit.toString()}
                                                    onChange={(e) =>
                                                        setDataPaymentGateway({
                                                            ...dataPaymentGateway,
                                                            billingUnit: e,
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InputGroup
                                                    title='Name'
                                                    name='name'
                                                    value={dataPaymentGateway.name}
                                                    onChange={(e) =>
                                                        setDataPaymentGateway({...dataPaymentGateway, name: e})
                                                    }
                                                />
                                            </Col>


                                            <Col span={12}>
                                                <InputGroup
                                                    value={dataPaymentGateway.firstBillingUnitFree.toString()}
                                                    type='password'
                                                    title='Stripe Client Id'
                                                    name='Stripe Client Id'
                                                    onChange={(e) =>
                                                        setDataPaymentGateway({
                                                            ...dataPaymentGateway,
                                                            firstBillingUnitFree: e,
                                                        })
                                                    }
                                                />
                                            </Col>

                                            <Col span={12}>
                                                <InputGroup
                                                    title='Stripe Publishable Key'
                                                    name='Stripe Publishable Key'
                                                    type='password'
                                                    value={dataPaymentGateway.nextBillingUnitFree.toString()}
                                                    onChange={(e) =>
                                                        setDataPaymentGateway({
                                                            ...dataPaymentGateway,
                                                            nextBillingUnitFree: e,
                                                        })
                                                    }
                                                />
                                            </Col>

                                            <Col span={12}>
                                                <InputGroup
                                                    title='Stripe Secret Key'
                                                    name='Stripe Secret Key'
                                                    type='password'
                                                    value={dataPaymentGateway.taxPercent.toString()}
                                                    onChange={(e) =>
                                                        setDataPaymentGateway({
                                                            ...dataPaymentGateway,
                                                            taxPercent: e,
                                                        })
                                                    }
                                                />
                                            </Col>


                                            <Col span={12}>
                                            </Col>

                                            <Col span={12}>
                                                <InputGroup
                                                    title='Google Pay Merchant ID'
                                                    name='Google Pay Merchant ID'
                                                    placeHolder='Enter google pay merchant id'
                                                    value={dataPaymentGateway.description}
                                                    onChange={(e) =>
                                                        setDataPaymentGateway({
                                                            ...dataPaymentGateway,
                                                            description: e,
                                                        })
                                                    }
                                                />
                                            </Col>

                                            <Col span={12}>
                                                <InputGroup
                                                    title='Apple Pay Merchant ID'
                                                    name='Apple Pay Merchant ID'
                                                    placeHolder='Enter Apple pay merchant id'
                                                    onChange={() => console.log(111)}
                                                />
                                            </Col>
                                            <Col span={12}>
                                            </Col>
                                            <Col span={12}>
                                                <span className='org_address'>Default Fleet</span>
                                                <ISelect
                                                    option={[{text : 'Yes' , value : 'Yes'},{text : 'No' , value : 'No'}]}
                                                    name='Default Fleet'
                                                    value='Yes'
                                                    onChange={() => console.log(1222)}
                                                    placeHolder='Default Fleet'
                                                />
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
                                        <br/>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <BBtn
                                                title='Back'
                                                color='#FF5C6A'
                                                onClick={() => setDataPaymentGateway(undefined)}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <BBtn title='Delete' color='#FF5C6A'/>
                                                <div style={{width: '16px'}}></div>
                                                <BBtn title='Save' color='#24BB69'/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                        <Col span={24} style={{marginTop: '3rem'}}>
                            <div>
                                <div style={{display: 'flex'}}>
                                    <p className='org_title'>Timeline</p>
                                    {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                                </div>
                                <Row gutter={16} style={{paddingLeft: '2rem'}}>
                                    <Col span={8}>
                                        <Steps direction='vertical' current={1}>
                                            <Steps.Step
                                                icon={
                                                    <i
                                                        className='fa-solid fa-tag'
                                                        style={{
                                                            color: '#333',
                                                            fontSize: '40px',
                                                        }}
                                                    ></i>
                                                }
                                                title='Finished'
                                                description='This is a description.'
                                            />
                                            <Steps.Step
                                                icon={
                                                    <i
                                                        className='fa-solid fa-tag'
                                                        style={{
                                                            color: '#333',
                                                            fontSize: '40px',
                                                        }}
                                                    ></i>
                                                }
                                                title='Finished'
                                                description='This is a description.'
                                            />
                                            <Steps.Step
                                                icon={
                                                    <i
                                                        className='fa-solid fa-tag'
                                                        style={{
                                                            color: '#333',
                                                            fontSize: '40px',
                                                        }}
                                                    ></i>
                                                }
                                                title='Finished'
                                                description='This is a description.'
                                            />
                                        </Steps>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </>
                )}
            </Row>

        </div>
    )
}

export default PaymentGateway
