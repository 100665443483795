import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import { areaAction } from "./areaSlice";
import { areaApi, AreaPrams } from "../../apis/areaApi";
import { AreaModel } from "../../models/area.model";
import { PaginationModel } from "../../models/paginationModel";

function* getListArea(action: PayloadAction<AreaPrams>) {
  try {
    const listArea: {
      data: {
        nodes: AreaModel[];
        itemCount: number;
      };
    } = yield call(areaApi.getAllArea, action.payload);
    yield put(areaAction.getListAreaSuccess(listArea.data.nodes));
    yield put(areaAction.setItemCount(listArea.data.itemCount));
  } catch (error) {
    console.log(error);
  }
}
function* updateArea(action: PayloadAction<Partial<AreaModel>>) {
  try {
    const area: { description: string } = yield call(
      areaApi.updateArea,
      action.payload
    );
    yield put(areaAction.success());
    message.success(area.description).then();
  } catch (error: any) {
    yield put(areaAction.fail(error));
    // Array.isArray(error.response.data.message) &&
    //   error.response.data.message.forEach((item: string) => {
    //     message.error(item).then();
    //   });
  }
}
function* addArea(action: PayloadAction<Partial<AreaModel>>) {
  try {
    const area: { description: string } = yield call(
      areaApi.createArea,
      action.payload
    );
    message.success(area.description).then();
    yield put(areaAction.success());
  } catch (error: any) {
    yield put(areaAction.fail(error));
    // error.response.data.message&&message.error(error.response.data.message).then();
    // Array.isArray(error.response.data.message) &&
    //   error.response.data.message.forEach((item: string) => {
    //     message.error(item).then();
    //   });
  }
}
function* detailArea(action: PayloadAction<{ id: string }>) {
  try {
    const area: { description: string; data: AreaModel } = yield call(
      areaApi.detailArea,
      action.payload.id
    );
    yield put(areaAction.detailAreaSuccess(area.data));
    message.success(area.description).then();
  } catch (error: any) {
    yield put(areaAction.fail(error));
    // Array.isArray(error.response.data.message) &&
    //   error.response.data.message.forEach((item: string) => {
    //     message.error(item).then();
    //   });
  }
}
function* deleteArea(action: PayloadAction<{ id: string }>) {
  try {
    const area: { description: string } = yield call(
      areaApi.deleteArea,
      action.payload.id
    );
    message.success(area.description).then();
    yield put(areaAction.success());
  } catch (error: any) {
    yield put(areaAction.fail(error));
    // Array.isArray(error.response.data.message) &&
    //   error.response.data.message.forEach((item: string) => {
    //     message.error(item).then();
    //   });
  }
}

export default function* areaSaga() {
  yield takeEvery(areaAction.getListArea.type, getListArea);
  yield takeEvery(areaAction.updateArea.type, updateArea);
  yield takeEvery(areaAction.addArea.type, addArea);
  yield takeEvery(areaAction.detailArea.type, detailArea);
  yield takeEvery(areaAction.deleteArea.type, deleteArea);
}
