import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {VersionModel} from "../../models/version.model";

export interface VersionState {
  loadding: boolean;
  version: VersionModel | undefined;
}
const initialState: VersionState = {
  loadding: false,
  version: undefined,
};

const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    updateVersion(state, action: PayloadAction<Partial<VersionModel>>) {
      state.loadding = true;
    },
    success(state) {
      state.loadding = false;
    },
    fail(state) {
      state.loadding = false;
    },
    detailVersion(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    detailVersionSuccess(state, action: PayloadAction<VersionModel>) {
      state.loadding = true;
      state.version = action.payload;
    },
  },
});
export const versionAction = versionSlice.actions;
export const selectVersion = (state: RootState) => state.version.version;
export const selectLoading = (state: RootState) => state.version.loadding;

export const versionReducer = versionSlice.reducer;
