import {
  CSSProperties,
  FC,
  forwardRef, memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  ICircleOptions, IInfoWindowOptions,
  IMarkerOptions,
  IPolygonOptions,
  IPolylineOptions,
  IRectangleOptions,
  MapType,
} from "./navermaps.interface";
import ArrayOfCoords = naver.maps.ArrayOfCoords;
import GeoJSON = naver.maps.GeoJSON;

export interface NaverProps {
  latitude: number;
  longitude: number;
  mapsName: string;
  zoom: number;
  mapStyle?: CSSProperties;
  rectangles?: IRectangleOptions[];
  markers?: IMarkerOptions[];
  circles?: ICircleOptions[];
  polygons?: IPolygonOptions[];
  polylines?: IPolylineOptions[];
  isDrawing: boolean;
  mapTypeControl?: boolean;
  mapType?: MapType;
  textMarker?: IInfoWindowOptions;
  onblurPolygon?: () => void;
}

const NaverMaps = (
  {
    longitude,
    latitude,
    mapStyle,
    zoom,
    rectangles,
    markers,
    circles,
    polygons,
    polylines,
    mapsName,
    isDrawing,
    mapTypeControl = true,
    mapType = MapType.NORMAL,
    textMarker,
    onblurPolygon,
  }: NaverProps,
  ref: any,
) => {
  const [drawing, setDrawing] =
    useState<naver.maps.drawing.DrawingManager>(undefined);

  const [maps , setMaps] = useState<naver.maps.Map>(undefined)
  useEffect(() => {
    if (!naver.maps) window.location.reload();
    // Initialize Maps
    const map = new naver.maps.Map(mapsName, {
      center: new naver.maps.LatLng(latitude, longitude),
      zoom,
      mapTypeId: mapType,
      mapTypeControl,
    });
    setMaps(map)

  }, [
    latitude,
    longitude,
    mapStyle,
    circles,
    rectangles,
    markers,
    polygons,
    polylines,
    zoom,
    textMarker,
    isDrawing,
    mapType,
    onblurPolygon,
  ]);
  const style = mapStyle
    ? mapStyle
    : {
        width: "100%",
        height: 700,
        borderRadius: "5px",
      };
  useImperativeHandle(ref, () => ({
    handleGetGeoJSON(): GeoJSON {
      if (drawing) {
        return drawing.toGeoJson();
      }
    },
    handleGetCenter()  {
      const center = maps.getCenter()
      const zoom = maps.getZoom()
      return {
        _lat : center.y, _lng : center.x, zoom
      }
    }
  }));
  if (maps){
    if (textMarker) {
      const contentString = [
        '<div style="padding: 10px;min-width: 0;max-height: 569px;max-width: 500px;">',
        `   <h3 style="font-size: 16px; font-weight: 700;padding: 5px 0; border-bottom: 0.5px solid #777;">${textMarker.title} SERVICE</h3>`,
        `   <p style="margin: 8px 0 8px !important;">Fleet : ${textMarker.fleet}<br />`,
        '   </p>',
        `   <p style="margin: 8px 0 8px !important;">Name : ${textMarker.name}<br />`,
        '   </p>',
        `   <p style="margin: 8px 0 8px !important;">Description : ${textMarker.description}<br />`,
        '   </p>',
        '</div>'
      ].join('');
      const infoWindow = new naver.maps.InfoWindow({
        ...textMarker,
        position: new naver.maps.LatLng(
            textMarker.latitude,
            textMarker.longitude,
        ),
        content : contentString
      });
      infoWindow.open(maps)
      naver.maps.Event.addListener(maps, "click", () => {
        onblurPolygon && onblurPolygon()
      });
    }

    // Drawing rectangles
    if (rectangles && rectangles.length) {
      rectangles.forEach((item) => {
        const recMaps = new naver.maps.Rectangle({ ...item, map : maps});
        recMaps.addListener("click", () => {
          item.onClick && item.onClick();
        });
      });
    }
    // Drawing markers
    if (markers && markers.length) {
      markers.forEach((item) => {
        const markerMaps = new naver.maps.Marker({
          ...item,
          position: new naver.maps.LatLng(item.latitude, item.longitude),
          map : maps,
        });
        markerMaps.addListener("click", () => {
          item.onClick && item.onClick();
        });
      });
    }
    // Drawing circles
    if (circles && circles.length) {
      circles.forEach(
          (item) =>
              new naver.maps.Circle({
                ...item,
                center: new naver.maps.LatLng(
                    item.position.latitude,
                    item.position.longitude,
                ),
                map : maps,
              }),
      );
    }
    // Drawing polygons
    if (polygons && polygons.length) {
      polygons.forEach((item) => {
       if (!item.edit){
         const newPaths: ArrayOfCoords[] = [[]];
         item.paths[0].forEach((path) => {
           const point = new naver.maps.LatLng(path[1], path[0]);
           newPaths[0].push(point);
         });
         const polygonMaps = new naver.maps.Polygon({
           ...item,
           paths: newPaths,
           map : maps,
         });
         if (item.onClick) {
           naver.maps.Event.addListener(polygonMaps, "click", item.onClick);
         }
       }
      });
    }

    // Draw polylines
    if (polylines && polylines.length) {
      polylines.forEach((item) => {
        const newPaths: ArrayOfCoords = [];
        item.path.forEach((value) => {
          const point = new naver.maps.LatLng(value[1], value[0]);
          newPaths.push(point);
        });
        const polylineMaps = new naver.maps.Polyline({
          ...item,
          path: newPaths,
          map : maps,
        });
        polylineMaps.addListener("click", () => {
          item.onClick && item.onClick();
        });
      });
    }

    // drawingManager
    if (isDrawing) {
      let drawingManager: naver.maps.drawing.DrawingManager;
      naver.maps.Event.once(maps, "init", () => {
        drawingManager = new naver.maps.drawing.DrawingManager({
          map : maps,
          drawingControl: [
            naver.maps.drawing.DrawingMode.POLYGON,
            naver.maps.drawing.DrawingMode.ELLIPSE,
            naver.maps.drawing.DrawingMode.RECTANGLE,
            naver.maps.drawing.DrawingMode.MARKER,
            naver.maps.drawing.DrawingMode.POLYLINE,
            naver.maps.drawing.DrawingMode.HAND,
          ],
          drawingControlOptions: {
            position: naver.maps.Position.RIGHT_CENTER,
            style: naver.maps.drawing.DrawingStyle.VERTICAL,
          },
          controlPointOptions: {
            position: naver.maps.Position.RIGHT_CENTER,
            anchorPointOptions: {
              center: new naver.maps.LatLng(latitude, longitude),
              radius: 5,
              fillColor: "#ff0000",
              strokeColor: "#0000ff",
              strokeWeight: 2,
            },
            midPointOptions: {
              center: new naver.maps.LatLng(latitude, longitude),
              radius: 4,
              fillColor: "#ff0000",
              strokeColor: "#0000ff",
              strokeWeight: 2,
              fillOpacity: 0.5,
            },
          },
          rectangleOptions: {
            fillColor: "#ff0000",
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: "#ff0000",
          },
          ellipseOptions: {
            fillColor: "#ff25dc",
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: "#ff25dc",
          },
          polylineOptions: {
            // An option for arrow icon types is ignored.
            strokeColor: "#222",
            strokeWeight: 3,
          },
          arrowlineOptions: {
            // If the startIcon || endIcon option is omitted, set endIcon to BLOCK_OPEN.
            endIconSize: 16,
            strokeWeight: 3,
          },
          polygonOptions: {
            fillColor: "#ffc300",
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: "#ffc300",
          },
        });
        const GROUPED_EVENTS: naver.maps.drawing.DrawingEvents[] = [
          naver.maps.drawing.DrawingEvents.ADD,
          naver.maps.drawing.DrawingEvents.SELECT,
          naver.maps.drawing.DrawingEvents.REMOVE,
          naver.maps.drawing.DrawingEvents.START,
          naver.maps.drawing.DrawingEvents.CANCLE,
        ];

        GROUPED_EVENTS.forEach((item) =>
            drawingManager.addListener(item, (overlay) => {
              overlay.setEditable(true);
            }),
        );
        if (polygons && polygons.length){
          polygons.forEach((item) => {
            if (item.edit){
              const newPaths: ArrayOfCoords[] = [[]];
              item.paths[0].forEach((path) => {
                const point = new naver.maps.LatLng(path[1], path[0]);
                newPaths[0].push(point);
              });
              const polygonMaps = new naver.maps.Polygon({
                ...item,
                paths: newPaths,
                map : maps,
              }) ;
              drawingManager.addDrawing(polygonMaps as any, naver.maps.drawing.DrawingMode.POLYGON);
            }
          });
        }
        setDrawing(drawingManager);
      });
    }
  }

  return <div id={mapsName} style={style} />;
};
const NaverMapsRef = forwardRef(NaverMaps)
export default memo(NaverMapsRef)
