import {Col, Row} from 'antd'
import React from 'react'
import {useTranslation} from "react-i18next";
import {FlagImg} from "../../utils/constants";

const VehicleMarkerList = () => {
  const {t} = useTranslation()
  return (
    <Row>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["serviceStartEnd"]}
            alt=''
          />{' '}
            {t('Page.Maps.serviceStartEnd')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["reBalancePickup"]}
            alt=''
          />{' '}
            {t('Page.Maps.rebalancePickup')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["maintenancePickup"]}
            alt=''
          />{' '}
            {t('Page.Maps.maintenancePickup')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["agencyPickup"]}
            alt=''
          />{' '}
            {t('Page.Maps.agencyPickup')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["batteryCritical"]}
            alt=''
          />{' '}
            {t('Page.Maps.batteryCritical')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["unavailable"]}
            alt=''
          />{' '}
            {t('Page.Maps.unavailable')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["stolen"]}
            alt=''
          />{' '}
            {t('Page.Maps.stolen')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["ioTFault"]}
            alt=''
          />{' '}
            {t('Page.Maps.ioTFault')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src={FlagImg["missing"]}
            alt=''
          />{' '}
            {t('Page.Maps.missing')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='	https://campusride-dashboard.joyridecity.bike/map_markers/scooter/unlock-fail.svg'
            alt=''
          />{' '}
            {t('Page.Maps.unlockFail')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='https://campusride-dashboard.joyridecity.bike/map_markers/scooter/scooter-alert.svg'
            alt=''
          />{' '}
            {t('Page.Maps.scooterGeofenceAlert')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='	https://campusride-dashboard.joyridecity.bike/map_markers/bike/scooter-alert.svg'
            alt=''
          />{' '}
            {t('Page.Maps.bikeGeofenceAlert')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='https://campusride-dashboard.joyridecity.bike/map_markers/scooter/parked_vehicle.svg'
            alt=''
          />{' '}
            {t('Page.Maps.parkedScooter')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='https://campusride-dashboard.joyridecity.bike/map_markers/bike/parked_vehicle.svg'
            alt=''
          />{' '}
            {t('Page.Maps.parkedBike')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='https://campusride-dashboard.joyridecity.bike/map_markers/scooter/riding.svg'
            alt=''
          />{' '}
            {t('Page.Maps.scooterOnRide')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='	https://campusride-dashboard.joyridecity.bike/map_markers/bike/riding.svg'
            alt=''
          />{' '}
            {t('Page.Maps.bikeOnRide')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='https://campusride-dashboard.joyridecity.bike/map_markers/scooter/scooter-Pause.svg'
            alt=''
          />{' '}
            {t('Page.Maps.pausedScooter')}
        </p>
      </Col>
      <Col span={6}>
        <p>
          <img
            style={{width: '22px'}}
            src='https://campusride-dashboard.joyridecity.bike/map_markers/bike/bike-pause.svg'
            alt=''
          />{' '}
            {t('Page.Maps.pausedBike')}
        </p>
      </Col>
    </Row>
  )
}

export default VehicleMarkerList
