import { Col, Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ITable from "../../../components/table";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectItemCountHistory,
  selectListVehicleHistory,
  vehicleAction,
} from "../../../features/vehicle/vehicleSlice";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { DEVICE_TYPE } from "../../../models/vehicleModel";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const VehiclesHistory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listVehicleHistory = useAppSelector(selectListVehicleHistory);
  const itemCount = useAppSelector(selectItemCountHistory);
  const [size, setSize] = useState<SizeType>("middle");
  const [limit, setLimit] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>(undefined);
  const handleChange = (value: string | string[]) => {
    setLimit(Number(value));
  };
  useEffect(() => {
    dispatch(
      vehicleAction.getAllVehicleHistory({
        page,
        limit,
        keyword,
      }),
    );
  }, [dispatch, page, keyword, limit]);
  return (
    <div>
      <br />
      <div className="role_div1">
        <p className="role_title">Vehicles History</p>
        <div style={{ display: "flex" }}>
          <BBtn
            title="Fillter"
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-sort-down"></i>}
            icon1left={true}
          />
          <div style={{ width: "1rem" }}></div>
          <BBtn
            title="CSV"
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-file-lines"></i>}
            icon1left={true}
          />
          <div style={{ width: "1rem" }}></div>
          <BBtn
            title="All Fleets"
            color="#FF5C6A"
            icon1={<i className="fa-solid fa-sort-down"></i>}
            icon1left={true}
          />
        </div>
      </div>
      <br />
      <Col span={24} className="gutter-row">
        <div className="org_div1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "1rem" }}>{t("show")}</span>
            <Select
              size={size}
              defaultValue={t("show").toString()}
              onChange={handleChange}
            >
              <Option value="15">15</Option>
              <Option value="25">25</Option>
              <Option value="50">50</Option>
            </Select>
            <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
              {t("entries")}
            </span>
            <ISearch
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <br />
          <ITable
            // onClickRow={(params: any) => setDataFleet(params)}
            column={[
              {
                title: "User ID",
                dataIndex: "user",
                render: (text: Record<string, any>) => text._id,
              },
              {
                title: "User name",
                dataIndex: "user",
                render: (text: Record<string, any>) => text.lastName || "",
                // sorter: (a: any, b: any) => a.name.length - b.name.length,
              },
              {
                title: "Vehicle ID",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) => text._id,
              },

              {
                title: "Vehicle Name",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) => text.name,
                // render: (text: string) => (
                //   <span
                //     style={{
                //       backgroundColor:
                //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                //       color: 'white',
                //       padding: '0.2rem 0.5rem',
                //       borderRadius: '2px',
                //     }}
                //   >
                //     {text}
                //   </span>
                // ),
              },
              {
                title: "Type",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) =>
                  DEVICE_TYPE[text.typeDevice],
              },
              {
                title: "Qr Code",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) => text.qrCode,
              },
              {
                title: "IMEI",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) => text.imei,
              },
              {
                title: "Action",
                dataIndex: "action",
              },

              {
                title: "Last Connected",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) => text.lastConnect,
              },

              {
                title: "Last Lock Status Update",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) =>
                  moment(text.lastLock).format("YYYY/MM/DD HH:mm:ss"),
              },
              {
                title: "Last ride time",
                dataIndex: "vehicle",
                render: (text: Record<string, any>) =>
                  moment(text.lastRide).format("YYYY/MM/DD HH:mm:ss"),
              },
              {
                title: "Date",
                dataIndex: "createdAt",
                render: (text: string) =>
                  moment(text).format("YYYY/MM/DD HH:mm:ss"),
              },
              // {
              //   title: "Address",
              //   dataIndex: "Address",
              //   render: (text: {}) => (
              //     <span
              //       style={{
              //         display: "flex",
              //         color: "white",
              //         backgroundColor: "#24BB69",
              //         padding: "0.5rem 0 0.5rem 1.2rem",
              //         borderRadius: "5px",
              //         width: "55px",
              //       }}
              //     >
              //       <i className="fa-solid fa-map" style={{ margin: 0 }}></i>
              //     </span>
              //   ),
              // },
            ]}
            total={itemCount || 0}
            data={listVehicleHistory || []}
            pageSize={limit}
            currentPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
          />
        </div>
      </Col>
    </div>
  );
};

export default VehiclesHistory;
