import { Checkbox, Col, message, Row, Select, Switch } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Layer, Marker, Source } from "react-map-gl";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  areaAction, selectError,
  selectItemCount,
  selectListArea,
} from "../../../features/area/areaSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FleetModel } from "../../../models/fleet.model";
import {
  fleetAction,
  selectListFleet,
} from "../../../features/fleet/fleetSlice";
import { useTranslation } from "react-i18next";
import { AreaModel } from "../../../models/area.model";
import DrawControl from "../drawControl";
import {
  selectListSetting,
  settingAction,
} from "../../../features/setting/settingSlice";
import { DeleteDialog } from "../../../components/dialog/deleteDialog";
import moment from "moment";
import NaverMaps from "../../../components/naverMaps";
import { InputAddArea } from "./inputAdd";
import { InputEditArea } from "./editArea";
import {ErrorDialog} from "../../../components/dialog/errorDialog";

const ServiceAreas = () => {
  const dispatch = useAppDispatch();
  const listArea = useAppSelector(selectListArea);
  const itemCount = useAppSelector(selectItemCount);
  const error = useAppSelector(selectError)
  const listSetting = useAppSelector(selectListSetting);
  const listFleet = useAppSelector(selectListFleet);
  const [size, setSize] = useState<SizeType>("middle");
  const [dataServiceArea, setDataServiceArea] = useState<AreaModel>(
    {} as AreaModel,
  );
  const [addServiceArea, setAddServiceArea] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [keyword, setKeyword] = useState("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const { t } = useTranslation();
  const mapsRef = useRef(null);
  const listSettingNew = listSetting || [];
  useEffect(() => {
    dispatch(
      areaAction.getListArea({
        limit,
        page,
        keyword,
        type: "SERVICE",
      }),
    );
  }, [page, limit, keyword, dispatch]);
  useEffect(() => {
    dispatch(settingAction.getListSetting());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 15,
      }),
    );
  }, [dispatch]);
  const listOptionFleet = listFleet?.map((item) => {
    return {
      text: item.name,
      value: item,
    };
  });
  const getListArea = () => {
    setTimeout(() => {
      dispatch(
        areaAction.getListArea({
          limit,
          page,
          keyword,
          type: "SERVICE",
        }),
      );
    }, 1000);
  };
  const handleGetPolygon = () => mapsRef.current.handleGetGeoJSON().features;

  const handleDelete = () => {
    dataServiceArea &&
      dataServiceArea._id &&
      dispatch(areaAction.deleteArea({ id: dataServiceArea._id }));

    setDataServiceArea({} as AreaModel);
    getListArea();
  };
  const handleChangeLimit = (value: string | string[]) => {
    setLimit(Number(value));
  };

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  const resetError = ()=> {
    dispatch(areaAction.resetError())
  }
  return (
    <div>
      {addServiceArea ? (
        <>
          <div style={{ width: "100%" }}>
            <br />
            <Row gutter={24}>
              <Col span={12}>
                <InputAddArea
                  handleGetPolygon={handleGetPolygon}
                  setAddServiceArea={setAddServiceArea}
                  getListArea={getListArea}
                  listOptionFleet={listOptionFleet}
                />
              </Col>
              <Col span={12}>
                <NaverMaps
                  mapsName="createServiceArea"
                  isDrawing={true}
                  longitude={127.189585}
                  latitude={36.845667}
                  zoom={14}
                  ref={mapsRef}
                  mapStyle={{
                    width: "100%",
                    height: 400,
                    borderRadius: "5px",
                  }}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <br />
          <div className="role_div1">
            <p className="role_title">Service Area</p>
            <div style={{ display: "flex" }}>
              <BBtn
                title="Add Service Area"
                color="#FF5C6A"
                icon1={<i className="fa-solid fa-plus"></i>}
                onClick={() => setAddServiceArea(true)}
              />
              <div style={{ width: "1rem" }}></div>
              <BBtn
                title="All Fleets"
                color="#FF5C6A"
                icon1={<i className="fa-solid fa-sort-down"></i>}
                icon1left={true}
              />
            </div>
          </div>
          <br />
          <Col span={24} className="gutter-row">
            <div className="org_div1">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    size={size}
                    defaultValue={t("show").toString()}
                    onChange={handleChangeLimit}
                  >
                    <Option value="15">15</Option>
                    <Option value="25">25</Option>
                    <Option value="50">50</Option>
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={(e) => setKeyword(e.target.value)} />
                </div>
                <p>
                  Active <Switch defaultChecked onChange={onChange} />
                </p>
              </div>
              <br />
              <ITable
                onClickRow={(params: any) => setDataServiceArea(params)}
                column={[
                  {
                    title: "Area ID",
                    dataIndex: "_id",
                  },
                  {
                    title: "Fleet",
                    dataIndex: "fleet",
                    render: (text: FleetModel) => {
                      return <span>{text.name || ""}</span>;
                    },
                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                  },
                  {
                    title: "Name",
                    dataIndex: "name",
                  },
                  {
                    title: "Created Date",
                    dataIndex: "createdAt",
                    render: (text: Date) => {
                      return (
                        <span>
                          {moment(text).format("YYYY/MM/DD HH:mm:ss")}
                        </span>
                      );
                    },
                  },
                  {
                    title: "Modified Date",
                    dataIndex: "updatedAt",
                    render: (text: Date) => {
                      return (
                        <span>
                          {moment(text).format("YYYY/MM/DD HH:mm:ss")}
                        </span>
                      );
                    },
                  },
                ]}
                total={itemCount || 0}
                data={listArea || []}
                pageSize={limit}
                currentPage={page}
                onChangePage={(page) => {
                  setPage(page);
                }}
              />
            </div>
          </Col>

          {Object.keys(dataServiceArea).length !== 0 && (
            <>
              <div style={{ width: "100%" }}>
                <br />
                <Row gutter={24}>
                  <Col span={12}>
                    <InputEditArea
                      getListArea={getListArea}
                      listOptionFleet={listOptionFleet}
                      setDataServiceArea={setDataServiceArea}
                      dataServiceArea={dataServiceArea}
                      handleGetPolygon={handleGetPolygon}
                      setOpenDelete={setOpenDelete}
                    />
                  </Col>
                  <Col span={12}>
                    <NaverMaps
                      mapsName="editServiceArea"
                      isDrawing={true}
                      longitude={
                        (dataServiceArea.location.coordinates[0][0][0] +
                          dataServiceArea.location.coordinates[0][1][0]) /
                        2
                      }
                      latitude={
                        (dataServiceArea.location.coordinates[0][0][1] +
                          dataServiceArea.location.coordinates[0][1][1]) /
                        2
                      }
                      zoom={14}
                      polygons={[
                        {
                          edit : true,
                          paths: dataServiceArea.location.coordinates,
                          clickable: true,
                          fillColor:
                            listSettingNew[0].dashboard.serviceAreaColor,
                          fillOpacity: 0.01,
                          strokeColor:
                            listSettingNew[0].dashboard.serviceAreaColor,
                          strokeOpacity: 0.3,
                          strokeWeight: 1,
                        },
                      ]}
                      ref={mapsRef}
                      mapStyle={{
                        width: "100%",
                        height: 400,
                        borderRadius: "5px",
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        </>
      )}
      <DeleteDialog
        open={openDelete}
        setOpen={() => setOpenDelete(!openDelete)}
        handleDelete={handleDelete}
      />
      <ErrorDialog open={!!error} error={error?error.response.data : undefined} setOpen={resetError} />
    </div>
  );
};

export default ServiceAreas;
