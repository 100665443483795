import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'

export const roleApi = {
  getListRole() {
    return axiosClient.getService(`${urlApi.ROLE}/no-pagination`)
  },
  getlistRolePagination(params: {
    page: number
    limit: number
    keyword?: string
    'orderBy[id]'?: 'ASC' | 'DESC'
    'orderBy[name]'?: 'ASC' | 'DESC'
  }) {
    return axiosClient.getService(`${urlApi.ROLE}/pagination`, {
      ...params,
    })
  },
  creatRole(params: {name: string}) {
    return axiosClient.postService(urlApi.ROLE, params)
  },
  addPermissionRole(params: {}) {},
  updateRole(params: {name: string; id: string}) {
    return axiosClient.updateService(`${urlApi.ROLE}/${params.id}`, {
      name: params.name,
    })
  },
  addRole(params: {name: string}) {
    return axiosClient.postService(`${urlApi.ROLE}`, {name: params.name})
  },
  getlistPermissions() {
    return axiosClient.getService(urlApi.PERMISSION)
  },
  updatePermissions(params: {
    id: string
    permission_id: string
    type: boolean
  }) {
    return axiosClient.updateService(`${urlApi.ROLE_PERMISSION}/${params.id}`, {
      permission_id: params.permission_id,
      type: params.type,
    })
  },
}
