import { Col, Row, Select, Tooltip } from "antd";
import moment from "moment";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import React, { useState, useEffect } from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import Map, { Marker } from "react-map-gl";
import { BannerModel } from "../../../models/banner.model";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  bannerAction,
  selectListBanner,
  selectItemCount,
} from "../../../features/banner/bannerSlice";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import mapboxgl from "mapbox-gl";
import {
  fleetAction,
  selectListFleet,
} from "../../../features/fleet/fleetSlice";
import axiosClient from "../../../apis/axiosClient";
import { urlApi } from "../../../apis/urlConfig";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}
const Banner = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listBanner = useAppSelector(selectListBanner);
  const listFleet = useAppSelector(selectListFleet);
  const itemCount = useAppSelector(selectItemCount);
  const [size, setSize] = useState<SizeType>("middle");
  const [dataBanner, setDataBanner] = useState<BannerModel>(
    {} as unknown as BannerModel
  );
  const [addBanner, setAddBanner] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [fleet, setFleet] = useState<Array<string>>([]);
  useEffect(() => {
    dispatch(
      bannerAction.getListBanner({
        page,
        limit,
        keyword,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 25,
      })
    );
  }, [dispatch]);
  const listSelectFleet: React.ReactNode[] = listFleet
    ? listFleet.map((item, index) => (
        <Option key={item._id}>{item.name}</Option>
      ))
    : [];

  const handleSelectFleet = (e: string[]) => {
    setFleet(e);
  };
  const handleChangeImage = async (file: FileList | null) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file[0]);
      const res = await axiosClient.uploadService(
        `${urlApi.UPLOAD}/single-image`,
        formData
      );
      setImage(res.data.default);
    }
  };
  const getListBanner = () => {
    setTimeout(
      () =>
        dispatch(
          bannerAction.getListBanner({
            page,
            limit,
            keyword,
          })
        ),
      1000
    );
  };
  const handleAddBanner = () => {
    Object.keys(dataBanner).length &&
      dispatch(
        bannerAction.addBanner(Object.assign(dataBanner, { fleet, image }))
      );
    getListBanner();
  };

  return (
    <div>
      {!addBanner ? (
        <>
          <Row style={{ width: "100%" }}>
            <Row
              align="middle"
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <span className="va_p_title">Application Banner</span>
              <div style={{ display: "flex" }}>
                <BBtn
                  title="Add Banner"
                  onClick={() => setAddBanner(true)}
                  icon1={<i className="fa-solid fa-plus"></i>}
                />
                {/* <span style={{margin: '0 0.5rem'}}></span>
                <BBtn
                  title='Add Card'
                  onClick={() => setAddCard(true)}
                  icon1={<i className='fa-solid fa-plus'></i>}
                /> */}
              </div>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={Object.keys(dataBanner).length ? 12 : 24}>
                <div className="org_div1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "1rem" }}>{t("show")}</span>
                    <Select
                      size={size}
                      defaultValue={"Show"}
                      value={limit.toString()}
                      onChange={(e) => setLimit(Number(e))}
                    >
                      <Option value="15">15</Option>
                      <Option value="25">25</Option>
                      <Option value="50">50</Option>
                    </Select>
                    <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                      {t("entries")}
                    </span>
                    <ISearch onChange={(e) => setKeyword(e.target.value)} />
                  </div>
                  <br />
                  <ITable
                    onClickRow={(params: any) => setDataBanner(params)}
                    column={[
                      {
                        title: "ID",
                        dataIndex: "_id",
                      },
                      {
                        title: "Header",
                        dataIndex: "title",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Area Type",
                        dataIndex: "area",
                        // render: (text: string) => (
                        //   <div
                        //     style={{
                        //       width: '60px',
                        //       height: '25px',
                        //       borderRadius: '5px',
                        //       backgroundColor: text,
                        //     }}
                        //   ></div>
                        // ),
                      },
                      {
                        title: "Info Title",
                        dataIndex: "informationTitle",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Info Link",
                        dataIndex: "informationLink",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Date",
                        dataIndex: "createdAt",
                          render: (text : Date)=> {
                              return <span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>;
                          }
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Status",
                        dataIndex: "status",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                    ]}
                    total={itemCount || 0}
                    data={listBanner || []}
                    pageSize={limit}
                    currentPage={page}
                    onChangePage={(page) => {
                      setPage(page);
                    }}
                  />
                </div>
              </Col>
              {Object.keys(dataBanner).length > 0 && (
                <Col
                  span={12}
                  style={{ backgroundColor: "white", padding: "1rem" }}
                >
                  <div>
                    <p className="feedbackOption-p-title">Edit banner</p>
                    <Row>
                      <Col span={12}>
                        <InputGroup
                          title="Title"
                          required
                          name="title"
                          onChange={() => {
                            console.log(111);
                          }}
                        />
                      </Col>
                      <Col span={12}></Col>

                      <Col span={12}>
                        <InputGroup
                          title="Color"
                          required
                          name="title"
                          onChange={(e) => {
                            console.log(2);
                          }}
                        />
                      </Col>
                      <Col span={12}></Col>
                      {/* {openColor && (
                      <Col>
                        <HexColorPicker
                          color={color}
                          onChange={setColor}
                          style={{width: '200px', height: '100px'}}
                        />
                      </Col>
                    )} */}
                    </Row>
                    <Row
                      style={{
                        borderTop: "1px solid #E1E1E1",
                        marginTop: "2rem",
                      }}
                      justify="space-between"
                      align="middle"
                    >
                      <BBtn
                        title={t("button.back")}
                        onClick={() =>
                          setDataBanner({} as unknown as BannerModel)
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          padding: "1rem 0",
                        }}
                      >
                        <BBtn title={t("button.delete")} />
                        <span style={{ width: "1rem" }}></span>
                        <BBtn title="Inactive" />
                        <span style={{ width: "1rem" }}></span>
                        <BBtn title={t("button.saveChange")} color="#39a547" />
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            </Row>
          </Row>
        </>
      ) : (
        <>
          <div className="va_p_title" style={{ margin: "1rem 0" }}>
            Application Banner
          </div>
          <Row gutter={24}>
            <Col
              span={12}
              style={{ backgroundColor: "white", padding: "1rem" }}
            >
              <p className="feedbackOption-p-title">Add banner</p>
              <Row gutter={24}>
                <Col span={12}>
                  <InputGroup
                    title="Title"
                    required
                    name="title"
                    value={dataBanner.title}
                    onChange={(e) =>
                      setDataBanner((pre) => ({ ...pre, title: e }))
                    }
                    placeHolder="Campusride"
                  />
                </Col>
                <Col span={12}>
                  <span className="org_address">
                    Description *
                    <Tooltip
                      placement="right"
                      title="Enter description to be displayed in the app"
                    >
                      <i className="fa fa-info-circle"></i>
                    </Tooltip>
                  </span>
                  <TextArea
                    maxLength={100}
                    value={dataBanner.description}
                    style={{ height: 50, marginTop: "5px" }}
                    onChange={(e) =>
                      setDataBanner((pre) => ({
                        ...pre,
                        description: e.target.value,
                      }))
                    }
                  />
                </Col>

                <Col span={24} style={{ padding: "1rem" }}>
                  <div
                    style={{
                      borderTop: "1px solid #E1E1E1",
                      margin: "0.5rem 0",
                    }}
                  ></div>
                </Col>

                <Col span={12}>
                  <span className="org_address">Select Image</span>
                  <div style={{ height: "5px" }}></div>
                  <input
                    style={{
                      width: "100%",
                    }}
                    accept="image/*"
                    type="file"
                    name="upload-photo"
                    onChange={(e) => handleChangeImage(e.target.files)}
                  />
                </Col>

                <Col span={24} style={{ padding: "1rem" }}>
                  <div
                    style={{
                      borderTop: "1px solid #E1E1E1",
                      margin: "0.5rem 0",
                    }}
                  ></div>
                </Col>
                <Col span={12}>
                  <InputGroup
                    title="Info Title"
                    name="infoTitle"
                    value={dataBanner.informationTitle}
                    onChange={(e) =>
                      setDataBanner((pre) => ({ ...pre, informationTitle: e }))
                    }
                    placeHolder="Enter Info"
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title="Info Link"
                    name="infoLink"
                    value={dataBanner.informationLink}
                    onChange={(e) =>
                      setDataBanner((pre) => ({ ...pre, informationLink: e }))
                    }
                    placeHolder="http://example.com"
                  />
                </Col>
                <Col span={24} style={{ padding: "1rem" }}>
                  <div
                    style={{
                      borderTop: "1px solid #E1E1E1",
                      margin: "0.5rem 0",
                    }}
                  ></div>
                </Col>
                <Col span={12}>
                  <span className="org_address">Area Type</span>
                  <div style={{ height: "5px" }}></div>
                  <ISelect
                    name="areaType"
                    value={dataBanner.area}
                    onChange={(e) =>
                      setDataBanner((pre) => ({ ...pre, area: e }))
                    }
                    option={[
                      { text: "All", value: "All" },
                      { text: "Circle", value: "Circle" },
                      { text: "Polygon", value: "Polygon" },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <span className="org_address">Fleets *</span>
                  <div style={{ height: "5px" }}></div>
                  <Select
                    mode="tags"
                    size={size}
                    placeholder="Please select"
                    defaultValue={[]}
                    onChange={(e) => handleSelectFleet(e)}
                    style={{ width: "100%" }}
                  >
                    {listSelectFleet}
                  </Select>
                </Col>
              </Row>
              <Row
                style={{ borderTop: "1px solid #E1E1E1", marginTop: "2rem" }}
                justify="space-between"
                align="middle"
              >
                <BBtn
                  title={t("button.back")}
                  onClick={() => setAddBanner(false)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "1rem 0",
                  }}
                >
                  <BBtn
                    title={t("button.saveChange")}
                    onClick={handleAddBanner}
                    color="#39a547"
                  />
                </div>
              </Row>
              <Row>
                Your card will not be charged at this time, but will be set as
                default for future charges.
              </Row>
            </Col>
            <Col span={12}>
              <Map
                mapboxAccessToken={process.env.REACT_APP_MAP_BOX_TOKEN}
                initialViewState={{
                  longitude: -122.4,
                  latitude: 37.8,
                  zoom: 14,
                }}
                style={{
                  width: "100%",
                  height: 400,
                  borderRadius: "5px",
                }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                // children={
                //   <Marker
                //     longitude={campaignDetail.longitude}
                //     latitude={campaignDetail.latitude}
                //     anchor='bottom'
                //   >
                //     <PlaceOutlinedIcon style={{color: 'red'}} />
                //   </Marker>
                // }
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Banner;
