import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {promotionAction} from './promotionSlice'
import {PromotionModel} from "../../models/promotion.model";
import {promotionApi} from "../../apis/promotionApi";
import {PaginationModel} from "../../models/paginationModel";

function* getListPromotion(action: PayloadAction<PaginationModel>) {
    try {
        const listPromotion: {
            description: string
            data: { nodes: PromotionModel[]; itemCount: number }
        } = yield call(promotionApi.getListPromotion, action.payload)
        yield put(promotionAction.getListPromotionSuccess(listPromotion.data.nodes))
        yield put(promotionAction.setItemCount(listPromotion.data.itemCount))
    } catch (error) {
        yield put(promotionAction.getListPromotionFail())
        console.log(error)
    }
}

function* addPromotion(action: PayloadAction<Partial<PromotionModel>>) {
    try {
        const promotion: { description: string } = yield call(
            promotionApi.createPromotion, action.payload
        )
        yield put(promotionAction.success())
        message.success(promotion.description).then()
    } catch (error) {
        yield put(promotionAction.fail())
        console.log(error)
    }
}

function* updatePromotion(action: PayloadAction<Partial<PromotionModel>>) {
    try {
        const promotion: { description: string } = yield call(
            promotionApi.updatePromotion, action.payload
        )
        yield put(promotionAction.success())
        message.success(promotion.description).then()
    } catch (error) {
        yield put(promotionAction.fail())
        console.log(error)
    }
}

function* detailPromotion(action: PayloadAction<{ id: string }>) {
    try {
        const promotion: { description: string, data: PromotionModel } = yield call(
            promotionApi.detailPromotion, action.payload.id
        )
        yield put(promotionAction.detailPromotionSuccess(promotion.data))
        message.success(promotion.description).then()
    } catch (error) {
        yield put(promotionAction.fail())
        console.log(error)
    }
}


export default function* promotionSaga() {
    yield takeEvery(promotionAction.getListPromotion.type, getListPromotion)
    yield takeEvery(promotionAction.addPromotion.type, addPromotion)
    yield takeEvery(promotionAction.updatePromotion.type, updatePromotion)
    yield takeEvery(promotionAction.detailPromotion.type, detailPromotion)
}
