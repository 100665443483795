import {DownCircleOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import './cardRiders.css'
import React from 'react'

const CardRiders = (props: {
  icon: React.ReactNode
  onClick?: () => void
  title: string
}) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '1rem 2rem',
        borderRadius: '5px',
      }}
      onClick={() => props.onClick && props.onClick()}
    >
      <Row>
        <Col span={3} style={{fontSize: '28px', color: ' #FF5C6A'}}>
          {props.icon}
        </Col>
        <Col span={20}>
          <p className='cardRiders_p1'>{props.title}</p>
          <p className='cardRiders_p2'>Total: 0</p>
        </Col>
        <Col span={1}>
          <DownCircleOutlined style={{fontSize: '28px'}} />
        </Col>
      </Row>
    </div>
  )
}

export default CardRiders
