import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {SettingModel} from "../models/setting.model";

export const settingApi = {
    getAllSetting() {
        return axiosClient.getService(`${urlApi.SETTING}`)
    },
    createSetting(params: Partial<SettingModel>) {
        return axiosClient.postService(`${urlApi.SETTING}`, {...params})
    },
    updateSetting(params: Partial<SettingModel>) {
        return axiosClient.updateService(`${urlApi.SETTING}/${params._id}`, {...params})
    },
    deleteSetting(id: string) {
        return axiosClient.deleteService(`${urlApi.SETTING}/${id}`)
    },
    detailSetting(id: string) {
        return axiosClient.getService(`${urlApi.SETTING}/${id}`)
    },
}
