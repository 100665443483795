import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {PaginationModel} from "../models/paginationModel";
import {PromotionModel} from "../models/promotion.model";
export const promotionApi = {
    getListPromotion(params : PaginationModel) {
        return axiosClient.getService(`${urlApi.PROMOTION}` , {...params})
    },
    createPromotion(params : Partial<PromotionModel>){
        return axiosClient.postService(`${urlApi.PROMOTION}` , {...params})
    },
    updatePromotion(params : Partial<PromotionModel>){
        return axiosClient.updateService(`${urlApi.PROMOTION}/${params._id}` , {...params})
    },
    detailPromotion(id : string){
        return axiosClient.getService(`${urlApi.PROMOTION}/${id}`)
    },
}