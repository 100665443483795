import {Col, Row, Select} from 'antd'
import {Option} from 'antd/lib/mentions'
import React, {useState, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import BBtn from '../../components/button'
import ISearch from '../../components/search'
import ITable from '../../components/table'
import InputGroup from '../../components/textInput'
import {
  roleAction,
  selectItemCount,
  selectListRole,
} from '../../features/role/roleSlice'
import {RoleModel} from '../../models/roleModel'
import {dateFomat} from '../../utils/constants'
import './role.css'
import {useTranslation} from "react-i18next";

const Role = () => {
    const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const listRole = useAppSelector(selectListRole)
  const itemCount = useAppSelector(selectItemCount)
  const [dataRole, setDataRole] = useState<RoleModel | undefined>()
  const [limit, setLimit] = useState<string>('15')
  const [page, setPage] = useState<number>(1)
  const [openAdd, setOpenAdd] = useState(false)
  const [roleName, setRoleName] = useState<string>()
  const [keyword, setKeyword] = useState<string>()

  useEffect(() => {
    dispatch(
      roleAction.getlistRolePagination({
        page: page,
        limit: Number(limit),
        // 'orderBy[id]': 'ASC',
        // 'orderBy[name]': 'ASC',
        keyword: keyword,
      })
    )
  }, [dispatch, page, limit, keyword])

  useEffect(() => {
    setRoleName(dataRole?.name)
  }, [dataRole])

  const handleChange = (value: string) => {
    setLimit(value)
  }
  const getListData = () => {
    setTimeout(() => {
      dispatch(
        roleAction.getlistRolePagination({
          page: page,
          limit: Number(limit),
          keyword: keyword,
        })
      )
    }, 500)
  }

  const updateRole = () => {
    roleName &&
      dataRole &&
      dispatch(roleAction.updateRole({id: dataRole?._id, name: roleName}))
    getListData()
  }
  const addRole = () => {
    roleName && dispatch(roleAction.addRole({name: roleName}))
    getListData()
  }
  return (
    <div>
      <br />
      <div className='role_div1'>
        <p className='role_title'>{t('Page.Role.roleList')}</p>
        <BBtn
          color='#FF5C6A'
          title={t('button.addRole')}
          onClick={() => {
            setOpenAdd(true)
            setRoleName('')
          }}
        />
      </div>

      <Row gutter={24}>
        {openAdd ? (
          <Col span={12} className='gutter-row'>
            <div className='org_div1'>
              <p className='org_p1'>{t('Page.Role.addRole')}</p>
              <Row gutter={16}>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t('Page.Role.roleName')}
                    name='roleName'
                    value={roleName}
                    onChange={(e) => setRoleName(e)}
                  />
                </Col>
              </Row>
              <br />
              <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
              <br />
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <BBtn
                  title={t('button.back')}
                  color='#FF5C6A'
                  onClick={() => {
                    setOpenAdd(false)
                    setDataRole(undefined)
                  }}
                />
                <BBtn
                  title={t('button.saveChange')}
                  color='#24BB69'
                  onClick={() => {
                    addRole()
                    if (roleName) setOpenAdd(false)
                  }}
                />
              </div>
            </div>
          </Col>
        ) : (
          <>
            <Col span={dataRole ? 12 : 24} className='gutter-row'>
              <div className='org_div1'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{marginRight: '1rem'}}>{t('show')}</span>
                  <Select
                    value={limit}
                    defaultValue={'10'}
                    onChange={handleChange}
                  >
                    <Option value='10'>15</Option>
                    <Option value='25'>25</Option>
                    <Option value='50'>50</Option>
                  </Select>
                  <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                    {t('entries')}
                  </span>
                  <ISearch onChange={(e) => setKeyword(e.target.value)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataRole(params)}
                  column={[
                    {
                      title: 'ID',
                      dataIndex: '_id',
                      sorter: (a: any, b: any) => a.id - b.id,
                    },
                    {
                      title: t('name'),
                      dataIndex: 'name',
                      sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: t('created'),
                      dataIndex: 'createdAt',
                      render: (text: string) => <>{dateFomat(text)}</>,
                    },
                  ]}
                  total={Number(itemCount)}
                  data={listRole || []}
                  pageSize={Number(limit)}
                  onChangePage={(page) => {
                    setPage(page)
                  }}
                  currentPage={page}
                />
              </div>
            </Col>

            {dataRole && (
              <Col span={12} className='gutter-row'>
                <div className='org_div1'>
                  <p className='org_p1'>{t('Page.Role.editRole')}</p>
                  <Row gutter={16}>
                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title={t('Page.Role.roleName')}
                        name='roleName'
                        value={roleName}
                        onChange={(e) => setRoleName(e)}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
                  <br />
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                  >
                    <BBtn
                      title={t('button.back')}
                      color='#FF5C6A'
                      onClick={() => {
                        setDataRole(undefined)
                      }}
                    />
                    <BBtn
                      title={t('button.saveChange')}
                      color='#24BB69'
                      onClick={() => {
                        if (roleName) {
                          updateRole()
                          setDataRole(undefined)
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  )
}

export default Role
