import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import { fleetAction } from "./fleetSlice";
import { FleetModel } from "../../models/fleet.model";
import { FleetApi } from "../../apis/fleetApi";

function* getListFleet(
  action: PayloadAction<{
    page: number;
    limit: number;
    keyword?: string;
    "orderBy[id]"?: "ASC" | "DESC";
    "orderBy[name]"?: "ASC" | "DESC";
  }>
) {
  try {
    const listFleet: {
      description: string;
      data: { nodes: FleetModel[]; itemCount: number };
    } = yield call(FleetApi.getListFleet, action.payload);
    yield put(fleetAction.getListFleetSuccess(listFleet.data.nodes));
    yield put(fleetAction.setItemCount(listFleet.data.itemCount));
  } catch (error) {
    yield put(fleetAction.getListFleetFail());
    console.log(error);
  }
}

function* addFleet(action: PayloadAction<Partial<FleetModel>>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.createFleet,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}

function* updateBasic(action: PayloadAction<Partial<FleetModel>>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.updateBasic,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* updateCompany(action: PayloadAction<Partial<FleetModel>>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.updateCompany,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* updateOrganisation(action: PayloadAction<Partial<FleetModel>>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.updateOrganisation,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* updateConfigMail(action: PayloadAction<Pick<FleetModel,"mailConfiguration"|"_id">>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.updateConfigMail,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* updateOperationHour(action: PayloadAction<Partial<FleetModel>>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.updateOperationHour,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* updatePreRideScreen(action: PayloadAction<Partial<FleetModel>>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.updatePreRideScreen,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* deleteFleet(action: PayloadAction<string>) {
  try {
    const fleet: { description: string } = yield call(
      FleetApi.deleteFleet,
      action.payload
    );
    yield put(fleetAction.success());
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}
function* detailFleet(action: PayloadAction<{ id: string }>) {
  try {
    const fleet: { description: string; data: FleetModel } = yield call(
      FleetApi.detailFleet,
      action.payload.id
    );
    yield put(fleetAction.detailFleetSuccess(fleet.data));
    message.success(fleet.description).then();
  } catch (error) {
    yield put(fleetAction.fail());
    console.log(error);
  }
}

export default function* fleetSaga() {
  yield takeEvery(fleetAction.getListFleet.type, getListFleet);
  yield takeEvery(fleetAction.addFleet.type, addFleet);
  yield takeEvery(fleetAction.updateBasic.type, updateBasic);
  yield takeEvery(fleetAction.updateCompany.type, updateCompany);
  yield takeEvery(fleetAction.updateOrganisation.type, updateOrganisation);
  yield takeEvery(fleetAction.updateConfigMail.type, updateConfigMail);
  yield takeEvery(fleetAction.updateOperationHour.type, updateOperationHour);
  yield takeEvery(fleetAction.updatePreRideScreen.type, updatePreRideScreen);
  yield takeEvery(fleetAction.deleteFleet.type, deleteFleet);
  yield takeEvery(fleetAction.detailFleet.type, detailFleet);
}
