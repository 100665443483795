import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { PaginationModel } from "../../models/paginationModel";
import { FeedbackOptionModel } from "../../models/feedbackOption.model";

export interface FeedbackOptionState {
  loadding: boolean;
  listFeedbackOption: FeedbackOptionModel[] | undefined;
  itemCount: number | undefined;
  feedbackOption: FeedbackOptionModel | undefined;
}
const initialState: FeedbackOptionState = {
  loadding: false,
  listFeedbackOption: undefined,
  itemCount: undefined,
  feedbackOption: undefined,
};

const feedbackOptionSlice = createSlice({
  name: "feedbackOption",
  initialState,
  reducers: {
    getListFeedbackOption(state, action: PayloadAction<PaginationModel>) {
      state.loadding = true;
    },
    getListFeedbackOptionSuccess(
      state,
      action: PayloadAction<FeedbackOptionModel[]>
    ) {
      state.listFeedbackOption = action.payload;
      state.loadding = false;
    },
    getListFeedbackOptionFail(state) {
      state.loadding = false;
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload;
    },
    updateFeedbackOption(
      state,
      action: PayloadAction<Partial<FeedbackOptionModel>>
    ) {
      state.loadding = true;
    },
    success(state) {
      state.loadding = false;
    },
    fail(state) {
      state.loadding = false;
    },
    addFeedbackOption(
      state,
      action: PayloadAction<Partial<FeedbackOptionModel>>
    ) {
      state.loadding = true;
    },
    detailFeedbackOption(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    detailFeedbackOptionSuccess(
      state,
      action: PayloadAction<FeedbackOptionModel>
    ) {
      state.loadding = true;
      state.feedbackOption = action.payload;
    },
    deleteFeedbackOption(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
  },
});
export const feedbackOptionAction = feedbackOptionSlice.actions;
export const selectItemCount = (state: RootState) =>
  state.feedbackOption.itemCount;
export const selectListFeedbackOption = (state: RootState) =>
  state.feedbackOption.listFeedbackOption;
export const selectFeedbackOption = (state: RootState) =>
  state.feedbackOption.feedbackOption;
export const selectLoading = (state: RootState) =>
  state.feedbackOption.loadding;

export const feedbackOptionReducer = feedbackOptionSlice.reducer;
