import { Col, Row, Select, Tooltip } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import { HexColorPicker } from "react-colorful";
import "./feedbackOption.css";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  feedbackOptionAction,
  selectItemCount,
  selectListFeedbackOption,
} from "../../../features/feedbackOption/feedbackOptionSlice";
import { useTranslation } from "react-i18next";
import { FeedbackOptionModel } from "../../../models/feedbackOption.model";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}
const FeedbackOption = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listFBOption = useAppSelector(selectListFeedbackOption);
  const itemCount = useAppSelector(selectItemCount);
  const [size, setSize] = useState<SizeType>("middle");
  const [dataFeedback, setDataFeedback] = useState<FeedbackOptionModel>(
    {} as unknown as FeedbackOptionModel
  );
  const [addFeedbackOptions, setAddFeedbackOptions] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(15);
  const [keyword, setKeyword] = useState<string>("");
  /**
   * dispatch to get list feedback options when componet mouted
   */
  useEffect(() => {
    dispatch(
      feedbackOptionAction.getListFeedbackOption({
        page,
        limit,
        keyword,
      })
    );
  }, [dispatch, page, limit, keyword]);
  /**
   * Get list feedback option
   */
  const getListFeedbackOption = () => {
    setTimeout(() => {
      dispatch(
        feedbackOptionAction.getListFeedbackOption({
          page,
          limit,
          keyword,
        })
      );
    }, 1000);
  };

  const handleChange = (value: string | string[]) => {
    setLimit(Number(value));
  };
  /**
   * Edit one feedback option
   */
  const handleEditFBOption = () => {
    Object.keys(dataFeedback).length &&
      dispatch(feedbackOptionAction.updateFeedbackOption(dataFeedback));
    getListFeedbackOption();
    setDataFeedback({} as unknown as FeedbackOptionModel);
  };
  /**
   * Active or inactive status of the feedback option
   */
  const handleChangeStatus = () => {
    Object.keys(dataFeedback).length &&
      dispatch(
        feedbackOptionAction.updateFeedbackOption({
          ...dataFeedback,
          status: dataFeedback.status === "ACTIVE" ? "DEACTIVE" : "ACTIVE",
        })
      );
    getListFeedbackOption();
    setDataFeedback({} as unknown as FeedbackOptionModel);
  };
  /**
   * Delete One Feedback Option
   */
  const handleDelete = () => {
    Object.keys(dataFeedback).length &&
      dispatch(
        feedbackOptionAction.deleteFeedbackOption({ id: dataFeedback._id })
      );
    getListFeedbackOption();
    setDataFeedback({} as unknown as FeedbackOptionModel);
  };
  /**
   * Add new feedback option
   */
  const handleAdd = () => {
    Object.keys(dataFeedback).length &&
      dispatch(
        feedbackOptionAction.addFeedbackOption({
          ...dataFeedback,
          status: "ACTIVE",
        })
      );
    getListFeedbackOption();
    setAddFeedbackOptions(false);
    setDataFeedback({} as unknown as FeedbackOptionModel);
  };

  return (
    <div>
      {!addFeedbackOptions ? (
        <>
          <Row>
            <Row
              align="middle"
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <span className="va_p_title">Feedback Options</span>
              <BBtn
                title="Add Option"
                onClick={() => setAddFeedbackOptions(true)}
                icon1={<i className="fa-solid fa-plus"></i>}
              />
            </Row>
            <Row gutter={24} style={{ width: "100%" }}>
              <Col
                span={Object.keys(dataFeedback).length ? 12 : 24}
                className="gutter-row"
              >
                <div className="org_div1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "1rem" }}>{t("show")}</span>
                    <Select
                      size={size}
                      defaultValue={"Show"}
                      value={limit.toString()}
                      onChange={handleChange}
                    >
                      <Option value="15">15</Option>
                      <Option value="25">25</Option>
                      <Option value="50">50</Option>
                    </Select>
                    <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                      {t("entries")}
                    </span>
                    <ISearch
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <ITable
                    onClickRow={(params: any) => setDataFeedback(params)}
                    column={[
                      {
                        title: "ID",
                        dataIndex: "_id",
                      },
                      {
                        title: "Name",
                        dataIndex: "name",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Color",
                        dataIndex: "colour",
                        render: (text: string) => (
                          <div
                            style={{
                              width: "60px",
                              height: "25px",
                              borderRadius: "5px",
                              backgroundColor: text,
                            }}
                          ></div>
                        ),
                      },
                      {
                        title: "Status",
                        dataIndex: "status",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                    ]}
                    total={itemCount || 0}
                    data={listFBOption || []}
                    pageSize={limit}
                    currentPage={page}
                    onChangePage={(page) => {
                      setPage(page);
                      console.log("page", page);
                    }}
                  />
                </div>
              </Col>
              {Object.keys(dataFeedback).length > 0 && (
                <Col
                  span={12}
                  style={{ backgroundColor: "white", padding: "1rem" }}
                >
                  <p className="feedbackOption-p-title">Edit Option</p>

                  <Row>
                    <Col span={12}>
                      <InputGroup
                        title="Title"
                        required
                        name="title"
                        value={dataFeedback.name}
                        onChange={(e) => {
                          setDataFeedback((prestate) => ({
                            ...prestate,
                            name: e,
                          }));
                        }}
                      />
                    </Col>
                    <Col span={12}></Col>

                    <Col span={12}>
                      <InputGroup
                        title="Color"
                        textStyle={{ backgroundColor: dataFeedback.colour }}
                        value={dataFeedback.colour}
                        required
                        name="colour"
                        onChange={(e) => {
                          setDataFeedback((prestate) => ({
                            ...prestate,
                            colour: e,
                          }));
                        }}
                        onClick={() => setOpenColor(true)}
                      />
                      {openColor && (
                        <div
                          style={{ padding: "1rem 0", position: "relative" }}
                        >
                          <i
                            style={{
                              position: "absolute",
                              top: "7px",
                              right: "48%",
                              cursor: "pointer",
                            }}
                            className="fa-solid fa-xmark"
                            onClick={() => setOpenColor(!openColor)}
                          ></i>

                          <HexColorPicker
                            color={dataFeedback.colour}
                            onChange={(e) => {
                              setDataFeedback((prestate) => ({
                                ...prestate,
                                colour: e,
                              }));
                            }}
                            style={{ width: "200px", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col span={12}></Col>
                    {/* {openColor && (
                      <Col>
                        <HexColorPicker
                          color={color}
                          onChange={setColor}
                          style={{width: '200px', height: '100px'}}
                        />
                      </Col>
                    )} */}
                  </Row>
                  <Row
                    style={{
                      borderTop: "1px solid #E1E1E1",
                      marginTop: "2rem",
                    }}
                    justify="space-between"
                    align="middle"
                  >
                    <BBtn
                      title={t("button.back")}
                      onClick={() =>
                        setDataFeedback({} as unknown as FeedbackOptionModel)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "1rem 0",
                      }}
                    >
                      <BBtn title="Delete" onClick={handleDelete} />
                      <span style={{ width: "1rem" }}></span>
                      <BBtn
                        title={
                          dataFeedback.status === "ACTIVE"
                            ? "Inactive"
                            : "Active"
                        }
                        onClick={handleChangeStatus}
                      />
                      <span style={{ width: "1rem" }}></span>
                      <BBtn
                        title={t("button.saveChange")}
                        onClick={handleEditFBOption}
                        color="#39a547"
                      />
                    </div>
                  </Row>
                </Col>
              )}
            </Row>
          </Row>
        </>
      ) : (
        <>
          <div className="va_p_title" style={{ margin: "1rem 0" }}>
            Feedback Options
          </div>
          <Col span={12} style={{ backgroundColor: "white", padding: "1rem" }}>
            <p className="feedbackOption-p-title">Add Option</p>

            <Row>
              <Col span={12}>
                <InputGroup
                  title="Title"
                  required
                  name="title"
                  value={dataFeedback.name}
                  onChange={(e) => {
                    setDataFeedback((prestate) => ({
                      ...prestate,
                      name: e,
                    }));
                  }}
                />
              </Col>
              <Col span={12}></Col>

              <Col span={12}>
                <InputGroup
                  title="Color"
                  textStyle={{ backgroundColor: dataFeedback.colour }}
                  required
                  name="title"
                  value={dataFeedback.colour}
                  onChange={(e) => {
                    setDataFeedback((prestate) => ({
                      ...prestate,
                      colour: e,
                    }));
                  }}
                  onClick={() => setOpenColor(true)}
                />
                {openColor && (
                  <div style={{ padding: "1rem 0", position: "relative" }}>
                    <i
                      style={{
                        position: "absolute",
                        top: "7px",
                        right: "48%",
                        cursor: "pointer",
                      }}
                      className="fa-solid fa-xmark"
                      onClick={() => setOpenColor(!openColor)}
                    ></i>

                    <HexColorPicker
                      color={dataFeedback.colour}
                      onChange={(e) => {
                        setDataFeedback((prestate) => ({
                          ...prestate,
                          colour: e,
                        }));
                      }}
                      style={{ width: "200px", height: "100px" }}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row
              style={{ borderTop: "1px solid #E1E1E1", marginTop: "2rem" }}
              justify="space-between"
              align="middle"
            >
              <BBtn
                title={t("button.back")}
                onClick={() => {
                  setAddFeedbackOptions(false);
                  setDataFeedback({} as unknown as FeedbackOptionModel);
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem 0",
                }}
              >
                <BBtn
                  title={t("button.saveChange")}
                  onClick={handleAdd}
                  color="#39a547"
                />
              </div>
            </Row>
          </Col>
        </>
      )}
    </div>
  );
};

export default FeedbackOption;
