import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import DashboardLayout from "../components/layout/DashboardLayout";
import { authAction } from "../features/auth/authSlice";
import Login from "../pages/login";
import { Config, IS_LOGGED, LOCAL_STORAGE } from "../utils/constants";
import { Url } from "./paths";
import routes from "./routes";

const Router = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    if (!token) {
      navigate(Url.login);
    }
  }, [navigate, dispatch]);

  return (
    <Routes>
      {routes.map((item) =>
        item.children ? (
          <Route key={item.path} path={item.path}>
            {item.children.map((e) => {
              return (
                <Route
                  key={item.path + e.path}
                  path={e.path}
                  element={
                    item.path !== Url.login ? (
                      <DashboardLayout>{e.element}</DashboardLayout>
                    ) : (
                      e.element
                    )
                  }
                />
              );
            })}
          </Route>
        ) : (
          <Route
            key={item.path}
            path={item.path}
            element={
              item.path !== Url.login ? (
                <DashboardLayout>{item.element}</DashboardLayout>
              ) : (
                item.element
              )
            }
          />
        )
      )}
    </Routes>
  );
};
export default Router;
