import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {orderAction} from "./orderSlice";
import {orderApi} from "../../apis/orderApi";
import {OrderModel} from "../../models/order.model";
import {vehicleApi} from "../../apis/vehicleApi";
import {vehicleAction} from "../vehicle/vehicleSlice";

function* getListOrder(action: PayloadAction<{
  page: number | undefined,
  limit: number | undefined,
  keyword?: string,
  fleet?: string;
  _id?: string;
  status?: "ACTIVE"| "PAYMENT"| "ORDER" | "PAYMENT_SUCCESS"
  "orderBy[createdAt]"?: "ASC" | "DESC";
}>) {
  try {
    const listOrder: {
      data: {
        nodes: OrderModel[]
        itemCount: number
      }
    } = yield call(orderApi.getAllOrder, action.payload)
    yield put(orderAction.getListOrderSuccess(listOrder.data.nodes))
    yield put(orderAction.setItemCount(listOrder.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}

function* stopRide( action : PayloadAction<{id : string , lock : boolean}>){
  try {
    const order: { description: string } = yield call(
        orderApi.stopRide,
        action.payload,
    );
    message.success(order.description).then();
    yield put(orderAction.success());
  } catch (error: any) {
    error.response.data.message &&
    message.error(error.response.data.message).then();
    yield put(orderAction.fail(error));
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then();
    });
  }
}
function* getOrderByVehicle( action : PayloadAction<{id : string}>){
  try {
    const order: { description: string , data : OrderModel } = yield call(
        orderApi.getOrderByVehicle,
        action.payload.id,
    );
    yield put(orderAction.getOrderByVehicleSuccess(order.data));
  } catch (error: any) {

  }
}


export default function* orderSaga() {
  yield takeEvery(orderAction.getListOrder.type, getListOrder)
  yield takeEvery(orderAction.getOrderByVehicle.type, getOrderByVehicle)
  yield takeEvery(orderAction.stopRide.type, stopRide)
}
