import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import "./force-stop.css";
import BBtn from "../../button";

export const ForceStopDialog = (props: {
  open: boolean;
  setOpen: () => void;
  handleForceStopWithLock: () => void;
  handleForceStopWithoutLock: () => void;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  return (
    <>
      <Modal
        className="modalForce"
        title="Confirm Close"
        open={props.open}
        confirmLoading={confirmLoading}
        okText={<></>}
        cancelText={<></>}
        onCancel={() => props.setOpen()}
        width={600}
      >
        <div
          style={{
            height: "200px",
            padding: "15px",
            fontSize: 14,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <p>
            <span id="force_vehicleChk">
              This vehicle is still being used on a ride.
            </span>
            <span id="force_close_msg">Do you want to end the ride?</span>
          </p>
          <div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <BBtn
                title="End ride without locking vehicle"
                color="#adadad"
                icon1={<i className="fa-solid fa-lock-open"></i>}
                onClick={props.handleForceStopWithoutLock}
              />
              <BBtn
                title="End ride and lock vehicle"
                color="#39a547"
                icon1={<i className="fa-solid fa-lock"></i>}
                onClick={props.handleForceStopWithLock}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
