import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {authReducer} from '../features/auth/authSlice'
import {billingPlanReducer} from '../features/billingPlan/billingPlanSlice'
import {roleReducer} from '../features/role/roleSlice'
import {taxReducer} from '../features/tax/taxSlice'
import {orgReducer} from '../features/org/orgSlice'
import {countryReducer} from '../features/country/countrySlice'
// import {authReducer} from '../features/auth/authSLice'
import rootSaga from './rootSaga'
import {fleetReducer} from "../features/fleet/fleetSlice";
import {userReducer} from "../features/users/userSlice";
import {promotionReducer} from "../features/promotion/promotionSlice";
import {vehicleReducer} from "../features/vehicle/vehicleSlice";
import {riderReducer} from "../features/rider/riderSlice";
import {bannerReducer} from "../features/banner/bannerSlice";
import {settingReducer} from "../features/setting/settingSlice";
import {areaReducer} from "../features/area/areaSlice";
import {campaignReducer} from "../features/campaign/campaignSlice";
import {offerReducer} from "../features/offer/offerSlice";
import {orderReducer} from "../features/order/orderSlice";
import {feedbackReducer} from "../features/feedback/feedbackSlice";
import {feedbackOptionReducer} from "../features/feedbackOption/feedbackOptionSlice";
import {paymentHistoryReducer} from "../features/paymentHistory/paymentHistorySlice";
import {iamportStoreReducer} from "../features/iamportStore/iamportStoreSlice";
import {analyticPaymentReducer} from "../features/analyticPayment/analyticPaymentSlice";
import {analyticRideReducer} from "../features/analyticRide/analyticRideSlice";
import {versionReducer} from "../features/version/versionSlice";
// import counterReducer from '../features/counter/counterSlice'

const rootReducer = combineReducers({
  auth: authReducer,
  role: roleReducer,
  billingPlan: billingPlanReducer,
  tax: taxReducer,
  org : orgReducer,
  country : countryReducer,
  fleet : fleetReducer,
  user : userReducer,
  promotion : promotionReducer,
  vehicle : vehicleReducer,
  rider : riderReducer,
  banner : bannerReducer,
  setting : settingReducer,
  area : areaReducer,
  campaign : campaignReducer,
  offer : offerReducer,
  order : orderReducer,
  feedback : feedbackReducer,
  feedbackOption : feedbackOptionReducer,
  paymentHistory : paymentHistoryReducer,
  iamportStore : iamportStoreReducer,
  analyticPayment : analyticPaymentReducer,
  analyticRide : analyticRideReducer,
  version : versionReducer,
})

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({thunk: false}).concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
