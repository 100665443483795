import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { FeedbackOptionModel } from "../models/feedbackOption.model";
import { PaginationModel } from "../models/paginationModel";

export const feedbackOptionApi = {
  getAllFeedbackOption(params: PaginationModel) {
    return axiosClient.getService(`${urlApi.FEEDBACK_OPTION}`, { ...params });
  },
  createFeedbackOption(params: Partial<FeedbackOptionModel>) {
    return axiosClient.postService(`${urlApi.FEEDBACK_OPTION}`, { ...params });
  },
  updateFeedbackOption(params: Partial<FeedbackOptionModel>) {
    return axiosClient.updateService(
      `${urlApi.FEEDBACK_OPTION}/${params._id}`,
      { ...params }
    );
  },
  deleteFeedbackOption(id: string) {
    return axiosClient.deleteService(`${urlApi.FEEDBACK_OPTION}/${id}`);
  },
  detailFeedbackOption(id: string) {
    return axiosClient.getService(`${urlApi.FEEDBACK_OPTION}/${id}`);
  },
};
