import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { AreaModel } from "../models/area.model";
import { PaginationModel } from "../models/paginationModel";

interface Areatype {
  type?: "RESTRICT" | "PARKING" | "SERVICE" | "SLOW" | "STATIONS";
}

export interface AreaPrams extends PaginationModel, Areatype {}

export const areaApi = {
  getAllArea(params: AreaPrams) {
    return axiosClient.getService(`${urlApi.AREA}`, { ...params });
  },
  createArea(params: Partial<AreaModel>) {
    return axiosClient.postService(`${urlApi.AREA}`, { ...params });
  },
  updateArea(params: Partial<AreaModel>) {
    return axiosClient.updateService(`${urlApi.AREA}/${params._id}`, {
      ...params,
    });
  },
  deleteArea(id: string) {
    return axiosClient.deleteService(`${urlApi.AREA}/${id}`);
  },
  detailArea(id: string) {
    return axiosClient.getService(`${urlApi.AREA}/${id}`);
  },
};
