import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { PaginationModel } from "../models/paginationModel";

export const analyticPaymentApi = {
  getChartPayment(params: {
    startTime: string;
    endTime: string;
    fleet?: string;
    type: "PAYMENT" | "TOTAL_PAYMENT";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_PAYMENT}/chart`, {
      ...params,
    });
  },
  getStatisticPayment(params: {
    startTime: string;
    endTime: string;
    fleet?: string;
    type: "PAYMENT" | "TOTAL_PAYMENT";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_PAYMENT}/statistic`, {
      ...params,
    });
  },
};
