import { useSocketContext } from "../contexts/SocketContext";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { OrderModel } from "../models/order.model";
import { orderAction, selectListOrder } from "../features/order/orderSlice";
import { SOCKET_EVENT } from "../utils/constants";

export function useRunningVehicle() {
  const socket = useSocketContext();
  // const listOrder = useAppSelector(selectListOrder);

  const dispatch = useAppDispatch();
  useEffect(() => {
    socket?.on(SOCKET_EVENT.END_RIDE, (data: OrderModel) => {
      if (data && data.status !== "ACTIVE") {
        dispatch(orderAction.removeOrderFromState(data._id));
      }
    });
    socket?.on(SOCKET_EVENT.START_RIDE, (data: OrderModel) => {
      if (data && data.status === "ACTIVE") {
        // const listOrderId = listOrder ? listOrder.map((o) => o._id) : [];
        // if (!listOrderId.includes(data._id)) {
        // }
          dispatch(orderAction.addOrderFromState(data));
      }
    });

    return () => {
      socket?.off(SOCKET_EVENT.END_RIDE);
      socket?.off(SOCKET_EVENT.START_RIDE);
    };
  }, [socket]);
}
