import React, { useCallback, useEffect, useRef, useState } from "react";
import { Switch, Tabs } from "antd";
import "mapbox-gl/dist/mapbox-gl.css";
import "./map.css";
import VehicleMarkerList from "../../components/vehicleMarkerList";
import { LeftCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import mapboxgl from "mapbox-gl";
import BBtn from "../../components/button";
import { FlagImg } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectItemCount,
  selectListVehicle,
  vehicleAction,
} from "../../features/vehicle/vehicleSlice";
import { areaAction, selectListArea } from "../../features/area/areaSlice";
import {
  selectListSetting,
  settingAction,
} from "../../features/setting/settingSlice";
import ISelect from "../../components/select";
import { fleetAction, selectListFleet } from "../../features/fleet/fleetSlice";
import { FleetModel } from "../../models/fleet.model";
import NaverMaps from "../../components/naverMaps";
import {DEVICE_TYPE, vehicleModel} from "../../models/vehicleModel";
import {
  IInfoWindowOptions,
  IMarkerOptions,
  MapType,
} from "../../components/naverMaps/navermaps.interface";
import { AreaModel } from "../../models/area.model";
import moment from "moment/moment";
import { VehicleFilter } from "../../models/paginationModel";
import CustomMaps from "../../components/customMaps";
import {useNavigate} from "react-router-dom";
import {Url} from "../../routers/paths";
import {ridesRunning, vehiclesAll} from "../../routers/paths/path";
import {OrderModel} from "../../models/order.model";
import {orderAction, selectListOrder, selectOrder} from "../../features/order/orderSlice";
// @ts-ignore
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapPage = () => {
    const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listVehicle = useAppSelector(selectListVehicle);
  const totalVehicle = useAppSelector(selectItemCount);
  const listSetting = useAppSelector(selectListSetting);
  const listFleet = useAppSelector(selectListFleet);
  const listArea = useAppSelector(selectListArea);
  const order = useAppSelector(selectOrder);
  const [tab, setTab] = useState<string>("1");
  const [keyword, setKeyword] = useState<string>("");
  const [fleet, setFleet] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [dataVehicle, setDataVehicle] = useState<vehicleModel>(undefined);
  const [vehicleId , setVehicleId] = useState<string>(undefined);
  const [mapConfig, setMapConfig] = useState<{
    zoom: number;
    longitude: number;
    latitude: number;
  }>({
    zoom: 14,
    longitude: 127.189585,
    latitude: 36.845667,
  });
  const [textMarker, setTextMarker] = useState<IInfoWindowOptions>(undefined);
  const [filterData, setFilterData] = useState<VehicleFilter>();

  const mapsRef = useRef(null);
  const listOptionFleet = listFleet?.map((item) => {
    return {
      text: item.name,
      value: item,
    };
  });
  useEffect(() => {
    if (vehicleId){
      dispatch(
          orderAction.getOrderByVehicle({id : vehicleId})
      )
    }
  }, [vehicleId]);
  useEffect(() => {
    dispatch(
        vehicleAction.getListVehicle({
          page: 1,
          limit: 100,
          keyword,
          fleet,
        }),
    )
  }, [dispatch, keyword, fleet]);
  useEffect(() => {

    const interval = setInterval(() => {
      const result = mapsRef.current.handleGetCenter();
      setMapConfig(pre => ({...pre ,latitude : result._lat , longitude : result._lng , zoom : result.zoom}))
      dispatch(
          vehicleAction.getListVehicle({
            page: 1,
            limit: 100,
            keyword,
            fleet,
          }),
      )
    },30*1000)
    return () => {
      clearInterval(interval);
    }
  }, [dispatch, keyword, fleet]);
  useEffect(() => {
    dispatch(
      areaAction.getListArea({
        page: 1,
        limit: 100,
      }),
    );
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 100,
      }),
    );
    dispatch(settingAction.getListSetting());
  }, [dispatch]);
  const listVehicleNew = listVehicle || [];
  const listAreaNew = listArea || [];
  const listSettingNew = listSetting || [
    {
      dashboard: {
        theme: "#FFFF",
        restrictedAreaColor: "#7433ff",
        speedzoneAreaColor: "#33FFFF",
        parkingAreaColor: "#dcba08",
        serviceAreaColor: "#207590",
        stationAreaColor: "#c0168b",
        timezone: 9,
      },
    },
  ];
  const findImage = (item: vehicleModel): string => {
    if (item.missing) {
      return FlagImg["missing"];
    }
    if (item.stolen) {
      return FlagImg["stolen"];
    }
    if (item.agencyPickup) {
      return FlagImg["agencyPickup"];
    }
    if (item.batteryCritical) {
      return FlagImg["batteryCritical"];
    }
    if (item.serviceStartEnd && item.locked) {
      return FlagImg["parked_vehicle"];
    }
    if (item.serviceStartEnd && !item.locked) {
      return FlagImg["riding"];
    }
    if (item.ioTFault) {
      return FlagImg["ioTFault"];
    }
    if (item.maintenancePickup) {
      return FlagImg["maintenancePickup"];
    }
    if (item.reBalancePickup) {
      return FlagImg["reBalancePickup"];
    }
    if (item.rented) {
      return FlagImg["rented"];
    }
    if (item.usingService) {
      return FlagImg["usingService"];
    }
    return FlagImg["serviceStartEnd"];
  };

  const handleClickOnPolygon = (item: AreaModel, e: Record<string, any>) => {
    setMapConfig((pre) => ({
      ...pre,
      zoom: e.overlay.map.zoom,
      longitude: e.overlay.map.center._lng,
      latitude: e.overlay.map.center._lat,
    }));
    setTextMarker({
      longitude: e.coord._lng,
      latitude: e.coord._lat,
      title: item.type,
      fleet: item.fleet.name,
      name: item.name,
      description: item.description,
    });
  };
  const handleActionLock = (param: vehicleModel) => {
    dispatch(vehicleAction.actionLock({ id: param._id }));

  };

  return (
    <div>
      <br />
      <p className="map_title">{t("Page.Maps.maps")}</p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          defaultActiveKey="1"
          style={{ justifyContent: "center" }}
          onChange={(e) => setTab(e)}
        >
          <Tabs.TabPane tab={t("Page.Maps.liveMap")} key="1"></Tabs.TabPane>
          <Tabs.TabPane tab={t("Page.Maps.heatmap")} key="2"></Tabs.TabPane>
          <Tabs.TabPane tab={t("Page.Maps.timeline")} key="3"></Tabs.TabPane>
          <Tabs.TabPane tab={t("Page.Maps.map")} key="4"></Tabs.TabPane>
        </Tabs>
      </div>
      {tab === "1" && (
        <>
          <div style={{ position: "relative" }}>
            <NaverMaps
                ref={mapsRef}
              mapsName="liveMaps"
              isDrawing={false}
              onblurPolygon={() => setTextMarker(undefined)}
              mapTypeControl={false}
              zoom={mapConfig.zoom}
              textMarker={textMarker}
              longitude={mapConfig.longitude}
              latitude={mapConfig.latitude}
              mapStyle={{
                width: "100%",
                height: 700,
                borderRadius: "5px",
              }}
              markers={listVehicleNew.map((item) => ({
                longitude: item.location.coordinates[0],
                latitude: item.location.coordinates[1],
                onClick: () => {
                  setMapConfig({
                    zoom: 16,
                    longitude: item.location.coordinates[0],
                    latitude: item.location.coordinates[1],
                  });
                  setKeyword(item.qrCode);
                  setDataVehicle(item);
                  setVehicleId(item._id)
                  setFilter(true);
                  setHidden(false);
                },
                title: `${item.qrCode} ${moment(item.lastConnect).format(
                  "YYYY/MM/DD HH:mm:ss",
                )}`,
                icon: {
                  url: findImage(item),
                  size: {
                    width: 40,
                    height: 40,
                  },
                },
              }))}
              polygons={listAreaNew.map((item) => ({
                paths: item.location.coordinates,
                onClick: (e: any) => handleClickOnPolygon(item, e),
                clickable: true,
                fillColor:
                  item.type === "PARKING"
                    ? listSettingNew[0].dashboard.parkingAreaColor
                    : item.type === "SERVICE"
                    ? listSettingNew[0].dashboard.serviceAreaColor
                    : item.type === "RESTRICT"
                    ? listSettingNew[0].dashboard.restrictedAreaColor
                    : item.type === "SLOW"
                    ? listSettingNew[0].dashboard.speedzoneAreaColor
                    : listSettingNew[0].dashboard.stationAreaColor,
                fillOpacity: item.type === "SERVICE" ? 0.01 : 0.4,
                // strokeStyle : "dash",
                strokeColor:
                  item.type === "SERVICE"
                    ? listSettingNew[0].dashboard.serviceAreaColor
                    : "#ff0000",
                strokeWeight: item.type === "SERVICE" ? 3 : 1,
                strokeOpacity: 0.4,
                zIndex: item.type === "SERVICE" ? 1 : 20,
              }))}
            />
            {!filter ? (
              <div
                style={{
                  position: "absolute",
                  top: "2rem",
                  right: "2.5rem",
                  // width: "211px",
                  backgroundColor: "white",
                  padding: "0.3rem 0.5rem",
                  borderRadius: "8px",
                }}
              >
                <MenuOutlined
                  style={{
                    color: "#FF5C6A",
                    fontSize: "35px",
                    fontWeight: "bold",
                  }}
                  onClick={() => setFilter(true)}
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    height: "600px",
                    width: "280px",
                    top: "2rem",
                    right: "2.5rem",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#FF5C6A",
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingLeft: "8px",
                      gap: "0.8rem",
                      height: "90px",
                      width: "100%",
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "23px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      {totalVehicle || 0}
                    </div>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "23px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "white",
                      }}
                    >
                      Total Vehicles
                    </div>
                    <i
                      style={{
                        position: "absolute",
                        height: "14px",
                        color: "white",
                        width: "14px",
                        right: "20px",
                        top: "7px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFilter(false);
                        setKeyword(undefined);
                        setDataVehicle(undefined);
                        setVehicleId(undefined)
                      }}
                      className="fa-solid fa-xmark fa-2x"
                    ></i>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "100px",
                      padding: "10px",

                      width: "100%",
                      height: "calc(100% - 90px)",
                    }}
                  >
                    <div
                      className={hidden ? "hidden" : ""}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          height: "40px",
                          marginBottom: "20px",
                          padding: "12px 8px",
                          border: "1px solid #A5A5A5",
                          borderRadius: "4px",
                          textIndent: "10px",
                        }}
                        value={keyword}
                        placeholder="Search by Code , IMEI , Name"
                        onChange={(e) => {
                          if (!e.target.value) {
                            setMapConfig((pre) => ({ ...pre, zoom: 14 }));
                          }
                          setKeyword(e.target.value);
                        }}
                      />
                      <ISelect
                        name="Fleet"
                        placeHolder="All Fleet"
                        option={
                          [
                            {
                              text: "All Fleet",
                              value: {} as FleetModel,
                            },
                          ].concat(listOptionFleet) || []
                        }
                        style={{
                          marginBottom: "20px",
                        }}
                        onChange={(e: FleetModel) => {
                          if (e && e._id) {
                            setFleet(e._id);
                          } else {
                            setFleet(undefined);
                          }
                        }}
                      />
                      <ISelect
                        name="Vehicle"
                        placeHolder="All Vehicle"
                        option={[
                          { text: "Bike", value: "Bike" },
                          {
                            text: "Scooter",
                            value: "Scooter",
                          },
                        ]}
                        style={{
                          marginBottom: "20px",
                        }}
                        onChange={() => {}}
                      />
                      <BBtn
                        align="end"
                        title="Advance Filter"
                        color="#24BB69"
                        onClick={() => setHidden(true)}
                        icon1={<i className="fa-solid fa-plus"></i>}
                      />
                      <div className="line"></div>
                      <div
                        className={dataVehicle ? "" : "hidden"}
                        style={{
                          marginTop: "10px",
                          border: "1px solid #bbc6c9",
                          borderRadius: "5px",
                          padding: "10px",
                          width: "100%",
                          height: "200px",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            Vehicle Id :{" "}
                            {dataVehicle &&
                              dataVehicle._id.substring(
                                dataVehicle._id.length - 10,
                              )}
                          </span>
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "black",
                              border: "1px solid #434343",
                              borderRadius: "3px",
                              width: "46px",
                              textAlign: "center",
                              height: "20px",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                color: "white",
                                position: "absolute",
                                fontWeight: "500",
                                fontSize: "12px",
                                right: "9px",
                              }}
                            >
                              {dataVehicle && dataVehicle.battery}%
                            </span>
                            <div
                              style={{
                                backgroundColor:
                                  Number(66) > 50
                                    ? "#1EA85D"
                                    : Number(66) < 50 && Number(66) > 25
                                    ? "#5d59a6"
                                    : "#ff5c6a",
                                width: `${dataVehicle && dataVehicle.battery}%`,
                                height: "100%",
                              }}
                            ></div>
                          </div>
                        </div>
                        <span>Name : {dataVehicle && dataVehicle.name}</span>
                        <span>
                          Type : {dataVehicle && DEVICE_TYPE[dataVehicle.typeDevice]}
                        </span>
                        <span
                          style={{ cursor: "pointer"  }}
                          onClick={() => {
                              navigate(Url.vehiclesAll , {
                                replace : true,
                                state : {
                                  search : dataVehicle && dataVehicle.qrCode
                                }
                              })
                          }}
                        >
                          QR Code : {dataVehicle && dataVehicle.qrCode}
                        </span>
                        <span>
                          Last Connected :{" "}
                          {dataVehicle &&
                            moment(dataVehicle.lastConnect).format(
                              "YYYY/MM/DD",
                            )}
                        </span>
                        {!!order && (<span
                            style={{ cursor: "pointer"  }}
                            onClick={() => {
                              navigate(Url.ridesRunning , {
                                replace : true,
                                state : {
                                  search : order && order._id
                                }
                              })
                            }}
                        >Ride Id : {order && order._id}</span>)}
                        <span
                          style={{
                            display: "flex",
                            backgroundColor:
                              dataVehicle && dataVehicle.locked
                                ? "#24BB69"
                                : "#F44141",
                            color: "white",
                            maxWidth: "20%",
                            height: "30px",
                            justifyContent: "center",
                            borderRadius: "5px",
                            padding: "0.3rem 0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleActionLock(dataVehicle);
                            setDataVehicle((pre) => ({
                              ...pre,
                              locked: !pre.locked,
                            }));
                          }}
                        >
                          {dataVehicle && dataVehicle.locked ? (
                            <i className="fa-solid fa-lock-open"></i>
                          ) : (
                            <i className="fa-solid fa-lock"></i>
                          )}
                        </span>
                      </div>
                    </div>

                    {/*  */}
                    <div
                      className={`${!hidden ? "hidden" : ""}`}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <LeftCircleOutlined
                            className="arrowIcon"
                            onClick={() => setHidden(false)}
                          />
                          <span>
                            <strong>Action</strong>
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            height: "420px",
                            overflow: "auto",
                          }}
                        >
                          <span style={{ color: "#8b8b8b" }}>FLAGS</span>
                          {/*check list of flag*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Service Start</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Service End</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Rebalance Pickup/Drop Off</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Maintenance Pickup/Drop Off</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Agency Pickup/Drop Off</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Missing</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Battery Critical</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Battery Critical</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Unavailable</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Stolen</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>IOT Fault</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Rented</label>
                            <Switch defaultChecked={false} />
                          </div>
                          {/*  end*/}

                          <span style={{ color: "#8b8b8b" }}>BATTERY</span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label className="itemBattery">Critical</label>
                            <label className="flex-1">0% - 25%</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label className="itemBattery">Low</label>
                            <label className="flex-1">26% - 29%</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label className="itemBattery">Medium</label>
                            <label className="flex-1">30% - 49%</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label className="itemBattery">Normal</label>
                            <label className="flex-1">50% - 100%</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <span style={{ color: "#8b8b8b" }}>
                            LAST GPS SIGNAL
                          </span>
                          {/*check list of flag*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`0 < 2 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`2 < 5 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`5 < 10 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`10 < 24 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>More than 24 hours</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <span style={{ color: "#8b8b8b" }}>LAST USED</span>
                          {/*check list of flag*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Unlock vehicles</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`0 < 2 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`2 < 5 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`5 < 10 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>{`10 < 24 Hours`}</label>
                            <Switch defaultChecked={false} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>More than 24 hours</label>
                            <Switch defaultChecked={false} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "2rem",
              padding: "1rem",
            }}
          >
            <VehicleMarkerList />
          </div>
        </>
      )}
      {tab === "2" && (
        <>
          <div style={{ position: "relative" }}>
            <NaverMaps
              mapsName="heatMaps"
              ref={mapsRef}
              isDrawing={false}
              mapType={MapType.HYBRID}
              mapTypeControl={false}
              zoom={14}
              longitude={127.189585}
              latitude={36.845667}
              mapStyle={{
                width: "100%",
                height: 700,
                borderRadius: "5px",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "2rem",
                right: "2.5rem",
                backgroundColor: "white",
                padding: "0.3rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              <MenuOutlined
                style={{
                  color: "#FF5C6A",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "2rem",
              padding: "1rem",
            }}
          >
            <VehicleMarkerList />
          </div>
        </>
      )}
      {tab === "3" && (
        <>
          <div style={{ position: "relative" }}>
            <NaverMaps
              mapsName="timeline"
              ref={mapsRef}
              isDrawing={true}
              zoom={14}
              longitude={127.11224555969238}
              latitude={37.37544345085402}
              mapStyle={{
                width: "100%",
                height: 700,
                borderRadius: "5px",
              }}
              markers={[
                {
                  latitude: 36.8456,
                  longitude: 127.189585,
                },
                {
                  latitude: 36.845667,
                  longitude: 127.189585,
                },
              ]}
              rectangles={
                [
                  // {
                  //   bounds: [124.2220128, 37.1928595, 125.1587723, 37.8594302],
                  //   fillColor: "#bf0091",
                  //   fillOpacity: 0.4,
                  //   strokeWeight: 2,
                  //   strokeColor: "#bf0091",
                  // },
                ]
              }
              circles={
                [
                  // {
                  //   position: {
                  //     latitude: 36.8456,
                  //     longitude: 127.189585,
                  //   },
                  //   radius: 5000,
                  //   fillColor: "crimson",
                  //   fillOpacity: 0.8,
                  // },
                ]
              }
              polygons={[]}
              polylines={[]}
            />

            <div
              style={{
                position: "absolute",
                top: "2rem",
                right: "2.5rem",
                backgroundColor: "white",
                padding: "0.3rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              <MenuOutlined
                style={{
                  color: "#FF5C6A",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  const result = mapsRef.current.handleGetGeoJSON();
                  console.log({ result });
                }}
              />
            </div>
          </div>
        </>
      )}
      {tab === "4" && (
        <>
          <div style={{ position: "relative" }}>
            <NaverMaps
              mapsName="maps4"
              ref={mapsRef}
              isDrawing={true}
              zoom={14}
              longitude={127.11224555969238}
              latitude={37.37544345085402}
              mapStyle={{
                width: "100%",
                height: 700,
                borderRadius: "5px",
              }}
              markers={[
                {
                  latitude: 37.37544345085402,
                  longitude: 127.11224555969238,
                  onClick: () => {
                    console.log("markers clicked");
                  },
                },
                {
                  latitude: 37.37544345085402,
                  longitude: 127.189585,
                  onClick: () => {
                    console.log("markers 2 clicked");
                  },
                },
              ]}
              rectangles={
                [
                  // {
                  //   bounds: [124.2220128, 37.1928595, 125.1587723, 37.8594302],
                  //   fillColor: "#bf0091",
                  //   fillOpacity: 0.4,
                  //   strokeWeight: 2,
                  //   strokeColor: "#bf0091",
                  // },
                ]
              }
              circles={
                [
                  // {
                  //   position: {
                  //     latitude: 36.8456,
                  //     longitude: 127.189585,
                  //   },
                  //   radius: 5000,
                  //   fillColor: "crimson",
                  //   fillOpacity: 0.8,
                  // },
                ]
              }
              polygons={[
                {
                  paths: [
                    [
                      [127.11224555969238, 37.37544345085402],
                      [127.10791110992432, 37.37230584065902],
                      [127.10795402526855, 37.35975408751081],
                      [127.11576461791992, 37.359924641705476],
                      [127.12211608886719, 37.35931064479073],
                      [127.12293148040771, 37.36043630196386],
                      [127.12310314178465, 37.36354029942161],
                      [127.12456226348876, 37.365211629488016],
                      [127.11224555969238, 37.37544345085402],
                    ],
                  ],
                  clickable: true,
                  fillColor: "#ff0000",
                  fillOpacity: 0.3,
                  strokeColor: "#ff0000",
                  strokeOpacity: 0.6,
                  strokeWeight: 3,
                },
              ]}
              polylines={[
                {
                  path: [
                    [127.1148204803467, 37.359924641705476],
                    [127.11486339569092, 37.36343797188166],
                    [127.11473464965819, 37.368520071054576],
                    [127.1088123321533, 37.3685882848096],
                    [127.10876941680907, 37.37295383612657],
                    [127.11851119995116, 37.38001321351567],
                  ],
                },
              ]}
            />

            <div
              style={{
                position: "absolute",
                top: "2rem",
                right: "2.5rem",
                backgroundColor: "white",
                padding: "0.3rem 0.5rem",
                borderRadius: "5px",
              }}
            >
              <MenuOutlined
                style={{
                  color: "#FF5C6A",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  const result = mapsRef.current.handleGetGeoJSON();
                  console.log({ result });
                }}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "2rem",
              padding: "1rem",
            }}
          >
            <VehicleMarkerList />
          </div>
        </>
      )}
    </div>
  );
};

export default MapPage;
