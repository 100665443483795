import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";

export const analyticRideApi = {
  getChartRidePerHour(params: {
    startTime: string;
    endTime: string;
    fleet?: string;
    type: "RIDE" | "TOTAL_RIDE";
  }) {
    return axiosClient.getService(
      `${urlApi.ANALYTIC_RIDE}/chart-ride-per-hour`,
      { ...params },
    );
  },
  getChartUserSignUp(params: {
    startTime?: string;
    endTime?: string;
    fleet?: string;
    type: "RIDE" | "TOTAL_RIDE";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/chart-user-signup`, {
      ...params,
    });
  },
  getChartRideByDay(params: {
    startTime: string;
    endTime: string;
    fleet?: string;
    type: "RIDE" | "TOTAL_RIDE";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/chart-ride-by-day`, {
      ...params,
    });
  },
  getStatisticRide(params: {
    startTime: string;
    endTime: string;
    fleet?: string;
    type: "RIDE" | "TOTAL_RIDE";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/statistic-ride`, {
      ...params,
    });
  },
  getStatisticUser(params: {
    startLastMonth: string;
    endLastMonth: string;
    fleet?: string;
    type: "RIDE" | "TOTAL_RIDE";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/statistic-user`, {
      ...params,
    });
  },
  getStatisticTicket(params: {
    startTime: string;
    endTime: string;
    fleet?: string;
    type: "RIDE" | "TOTAL_RIDE";
  }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/statistic-ticket`, {
      ...params,
    });
  },
  getStatisticVehicle(params: { fleet?: string }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/statistic-vehicle`, {
      ...params,
    });
  },
  getChartRideFleet(params: { fleet?: string }) {
    return axiosClient.getService(`${urlApi.ANALYTIC_RIDE}/chart-ride-fleet`, {
      ...params,
    });
  },
};
