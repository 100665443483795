import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {User} from '../../models/userModel'
import {RootState} from "../../app/store";

interface UserState {
  loadding: boolean
  data: User | undefined
}

const initialState : UserState = {
  loadding: false,
  data : undefined
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getListUser(state) {
      
    },
    getInfo(state){
      state.loadding = true
    },
    getInfoSuccess(state , action : PayloadAction<User>){
      state.loadding = false
      state.data = action.payload
    },
    getInfoFail(state){
      state.loadding = false
    }
  },
})

export const userAction = userSlice.actions



export const selectInfo = (state: RootState) =>
    state.user.data


export const selectLoadingUser = (state: RootState) => state.user.loadding

export const userReducer = userSlice.reducer
