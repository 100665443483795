import React, {ReactNode} from 'react'
import './inforBoxHomePage.css'
import {RightCircleOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import { useTranslation } from 'react-i18next'
import {useNavigate} from "react-router-dom";

const InforBoxHomePage = (props: {
  icon: ReactNode
  title: string
  data?: any
  type: number
  navigatePath ? : any
  text_1 ? : string
  text_2 ? : string
  text_3 ? : string
  text_4 ? : string
  number_1 ? : any
  number_2 ? : any
  number_3 ? : any
  number_4 ? : any
  note ? : string
}) => {
  const navigate = useNavigate()
  return (
    <div className='ibhp_container' >
      <div className='ibhp_div1'>
        <span className='ibhp_span1'>{props.icon}</span>
        <span className='ibhp_span2'>{props.title}</span>
        <RightCircleOutlined className='ibhp_icon1' onClick={()=>{
          navigate(props.navigatePath)
        }} />
      </div>
      {props.type === 1 ? (
        <Row
          justify='center'
          align='middle'
          style={{height: '90px'}}
          className='ibhp_row1'
        >
          <div className='ibhp_div2'>
            <p className='ibhp_p1'>{props.number_1}</p>
            <p className='ibhp_p2'>{props.text_1}</p>
          </div>
        </Row>
      ) : (
        <Row style={{height: '90px'}} className='ibhp_row1'>
          <Col span={12} className='ibhp_col1'>
            <p className='ibhp_p1' style={{marginTop: 0}}>
              {props.number_1}
            </p>
            <p className='ibhp_p2' style={{marginTop: 0}}>
              {props.text_1}
            </p>
          </Col>
          <Col span={12} className='ibhp_col2' style={{marginTop: 0}}>
            <p className='ibhp_p1' style={{marginTop: 0}}>
              {props.number_2}
            </p>
            <p className='ibhp_p2' style={{marginTop: 0}}>
              {props.text_2}
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12} className='ibhp_col1'>
          <p className='ibhp_p1'>
            {props.number_3}
            {(props.note) && <span className='note'>{props.note}</span>}
          </p>
          <p className='ibhp_p2'>
            {props.text_3}

          </p>
        </Col>
        <Col span={12} className='ibhp_col2'>
          <p className='ibhp_p1'>{props.number_4}</p>
          <p className='ibhp_p2'>{props.text_4}</p>
        </Col>
      </Row>
    </div>
  )
}

export default InforBoxHomePage
