import { Col, Row, Select, Tooltip } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import { HexColorPicker } from "react-colorful";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  campaignAction,
  selectItemCount,
  selectListCampaign,
} from "../../../features/campaign/campaignSlice";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}
const Notification = () => {
  const dispatch = useAppDispatch();
  const itemCount = useAppSelector(selectItemCount);
  const listCampain = useAppSelector(selectListCampaign);
  const [size, setSize] = useState<SizeType>("middle");
  const [dataNotification, setDataNotification] = useState<any>();
  const [addCard, setAddCard] = useState(false);
  const [openAddCard, setOpenAddCard] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(15);
  const [keyword, setKeyword] = useState<string>("");
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
  };
  useEffect(() => {
    dispatch(
      campaignAction.getListCampaign({
        limit,
        page,
        keyword,
      })
    );
  }, [dispatch, limit, page, keyword]);
  console.log("listCampain", listCampain);

  return (
    <div>
      {!addCard ? (
        <>
          <Row style={{ width: "100%" }}>
            <Row
              align="middle"
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <span className="va_p_title">Campaign</span>
              <div style={{ display: "flex" }}>
                <BBtn
                  title="Add Option"
                  onClick={() => setAddCard(true)}
                  icon1={<i className="fa-solid fa-plus"></i>}
                />
                <span style={{ margin: "0 0.5rem" }}></span>
                <BBtn
                  title="Add Card"
                  onClick={() => setAddCard(true)}
                  icon1={<i className="fa-solid fa-plus"></i>}
                />
              </div>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={dataNotification ? 12 : 24}>
                <div className="org_div1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "1rem" }}>Show</span>
                    <Select
                      value={limit.toString()}
                      defaultValue={"15"}
                      onChange={(e) => setLimit(Number(e))}
                    >
                      <Option value="15">15</Option>
                      <Option value="25">25</Option>
                      <Option value="50">50</Option>
                    </Select>
                    <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                      Entries
                    </span>
                    <ISearch onChange={(e) => setKeyword(e.target.value)} />
                  </div>
                  <br />
                  <ITable
                    onClickRow={(params: any) => setDataNotification(params)}
                    column={[
                      {
                        title: "ID",
                        dataIndex: "_id",
                      },
                      {
                        title: "Title",
                        dataIndex: "title",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Message",
                        dataIndex: "message",
                        // render: (text: string) => (
                        //   <div
                        //     style={{
                        //       width: '60px',
                        //       height: '25px',
                        //       borderRadius: '5px',
                        //       backgroundColor: text,
                        //     }}
                        //   ></div>
                        // ),
                      },
                      {
                        title: "Sent Notification",
                        dataIndex: "sentNotification",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Filter",
                        dataIndex: "filter",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Amount",
                        dataIndex: "pricing",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Created",
                        dataIndex: "created",
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                    ]}
                    total={itemCount || 0}
                    data={listCampain || []}
                    pageSize={limit}
                    currentPage={page}
                    onChangePage={(page) => {
                      setPage(page);
                    }}
                  />
                </div>
              </Col>
              {dataNotification && (
                <Col
                  span={12}
                  style={{ backgroundColor: "white", padding: "1rem" }}
                >
                  <div>
                    <p className="feedbackOption-p-title">Edit Option</p>
                    <Row>
                      <Col span={12}>
                        <InputGroup
                          title="Title"
                          required
                          name="title"
                          onChange={() => {
                            console.log(111);
                          }}
                        />
                      </Col>
                      <Col span={12}></Col>

                      <Col span={12}>
                        <InputGroup
                          title="Color"
                          required
                          name="title"
                          onChange={(e) => {
                            console.log(2);
                          }}
                        />
                      </Col>
                      <Col span={12}></Col>
                      {/* {openColor && (
                      <Col>
                        <HexColorPicker
                          color={color}
                          onChange={setColor}
                          style={{width: '200px', height: '100px'}}
                        />
                      </Col>
                    )} */}
                    </Row>
                    <Row
                      style={{
                        borderTop: "1px solid #E1E1E1",
                        marginTop: "2rem",
                      }}
                      justify="space-between"
                      align="middle"
                    >
                      <BBtn
                        title="back"
                        onClick={() => setDataNotification(undefined)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          padding: "1rem 0",
                        }}
                      >
                        <BBtn title="Delete" />
                        <span style={{ width: "1rem" }}></span>
                        <BBtn title="Inactive" />
                        <span style={{ width: "1rem" }}></span>
                        <BBtn title="Save Changes" color="#39a547" />
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            </Row>
          </Row>
        </>
      ) : (
        <>
          <div className="va_p_title" style={{ margin: "1rem 0" }}>
            Feedback Option
          </div>
          <Col span={12} style={{ backgroundColor: "white", padding: "1rem" }}>
            <p className="feedbackOption-p-title">Add New Card</p>

            <Row>
              <Col span={12}>
                <InputGroup
                  title="Name on Card"
                  required
                  name="nameCard"
                  onChange={() => {
                    console.log(111);
                  }}
                  placeHolder="Cardholder name"
                />
              </Col>
              <Col span={12}></Col>

              <Col
                span={12}
                style={{ position: "relative", marginTop: "1rem" }}
              >
                <InputGroup
                  title="Card number"
                  required
                  name="cardNumber"
                  onChange={(e) => {
                    console.log(222);
                  }}
                  placeHolder="1234 1234 1234 1234"
                />
                <i
                  style={{
                    fontSize: "18px",
                    position: "absolute",
                    top: "38px",
                    right: "14px",
                  }}
                  className="fa-solid fa-credit-card"
                ></i>
              </Col>
              <Col span={12}></Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <InputGroup
                      name="Expiry"
                      required
                      title="Expiry"
                      onChange={() => console.log(1111)}
                      placeHolder="MM/YY"
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      name="CVC"
                      required
                      title="CVC"
                      onChange={() => console.log(1111)}
                      placeHolder="CVC"
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      name="Postal / Zip Code"
                      title="Postal / Zip Code"
                      onChange={() => console.log(1111)}
                      placeHolder="90210"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{ borderTop: "1px solid #E1E1E1", marginTop: "2rem" }}
              justify="space-between"
              align="middle"
            >
              <BBtn title="back" onClick={() => setAddCard(false)} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem 0",
                }}
              >
                <BBtn title="Add card" color="#0284FC" />
              </div>
            </Row>
            <Row>
              Your card will not be charged at this time, but will be set as
              default for future charges.
            </Row>
          </Col>
        </>
      )}
    </div>
  );
};

export default Notification;
