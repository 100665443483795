import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {analyticPaymentAction} from "./analyticPaymentSlice";
import {analyticPaymentApi} from "../../apis/analyticPaymentApi";
import {AnalyticPaymentModel} from "../../models/analyticPaymentModel";

function* getChartPayment(action: PayloadAction<{
  startTime: string;
  endTime: string;
  fleet?: string;
  type: "PAYMENT" | "TOTAL_PAYMENT";
}>) {
  try {
    const listPayment: {
      data: AnalyticPaymentModel[]
    } = yield call(analyticPaymentApi.getChartPayment, action.payload)
    yield put(analyticPaymentAction.getChartPaymentSuccess(listPayment.data))
  } catch (error) {
    console.log(error)
  }
}
function* getStatisticPayment(action: PayloadAction<{
  startTime: string;
  endTime: string;
  fleet?: string;
  type: "PAYMENT" | "TOTAL_PAYMENT";
}>) {
  try {
    const statistic: {
      data: {
        avgRevenuePerDay: number
        totalAmount: number,
        AvgTripRevenue:number,
        AvgLifetimeSpend:number,
        totalWalletSize: number,
        averageWalletSize: number,
        averageTopUp: number,
      }
    } = yield call(analyticPaymentApi.getStatisticPayment, action.payload)
    yield put(analyticPaymentAction.getStatisticPaymentSuccess(statistic.data))
  } catch (error) {
    console.log(error)
  }
}

export default function* analyticPaymentSaga() {
  yield takeEvery(analyticPaymentAction.getChartPayment.type, getChartPayment)
  yield takeEvery(analyticPaymentAction.getStatisticPayment.type, getStatisticPayment)
}
