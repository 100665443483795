import {FleetModel} from "./fleet.model";
import {User} from "./userModel";

export enum LOW_SPEED_TYPE {
    NONE = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

export interface AreaModel {
    _id: string
    type: string
    fleet: FleetModel
    name: string;
    stationUUID: string;
    speedMode: LOW_SPEED_TYPE;
    maxVehicle: number;
    minVehicle: number;
    totalPort: number;
    freePort: number;
    availableVehicles: number;
    visible: boolean;
    status: boolean;
    description: string;
    throttle: boolean;
    triggerAlarm: boolean;
    stationType: string;
    locationType?: string;
    location: {
        type: string;
        coordinates: any;
    };
    created: User;
    updated: User;
}