import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {IamportStoreModel} from "../../models/IamportStore.model";

export interface IamportStoreState {
  loadding: boolean
  listIamportStore: IamportStoreModel[] | undefined
  itemCount: number | undefined
  iamportStore : IamportStoreModel | undefined
}
const initialState: IamportStoreState = {
  loadding: false,
  listIamportStore: undefined,
  itemCount: undefined,
  iamportStore: undefined,
}

const iamportStoreSlice = createSlice({
  name: 'iamportStore',
  initialState,
  reducers: {
    getListIamportStore(state) {
      state.loadding = true
    },
    getListIamportStoreSuccess(state, action: PayloadAction<IamportStoreModel[]>) {
      state.listIamportStore = action.payload
      state.loadding = false
    },
    getListIamportStoreFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateIamportStore(
      state,
      action: PayloadAction<Partial<IamportStoreModel>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addIamportStore(
      state,
      action: PayloadAction<Partial<IamportStoreModel>>
    ) {
      state.loadding = true
    },
    detailIamportStore(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailIamportStoreSuccess(state, action : PayloadAction<IamportStoreModel> ) {
      state.loadding = true
      state.iamportStore = action.payload
    },
    deleteIamportStore(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
  },
})
export const iamportStoreAction = iamportStoreSlice.actions
export const selectItemCount = (state: RootState) => state.iamportStore.itemCount
export const selectListIamportStore = (state: RootState) => state.iamportStore.listIamportStore
export const selectIamportStore = (state: RootState) => state.iamportStore.iamportStore
export const selectLoading = (state: RootState) => state.iamportStore.loadding

export const iamportStoreReducer = iamportStoreSlice.reducer
