import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { vehicleModel } from "../models/vehicleModel";
import {PaginationModel, VehicleFilter} from "../models/paginationModel";

export const vehicleApi = {
  getAllVehicle(params: VehicleFilter) {
    return axiosClient.getService(`${urlApi.VEHICLE}`, { ...params });
  },
  detailVehicle(id: string) {
    return axiosClient.getService(`${urlApi.VEHICLE}/${id}`);
  },
  getTypeVehicle() {
    return axiosClient.getService(`${urlApi.VEHICLE}/type`);
  },
  addVehicle(params: Partial<vehicleModel>) {
    return axiosClient.postService(`${urlApi.VEHICLE}`, { ...params });
  },
  updateVehicle(params: Partial<vehicleModel>) {
    return axiosClient.updateService(
      `${urlApi.VEHICLE}/update-vehicle/${params._id}`,
      { ...params }
    );
  },
  deleteVehicle(id: string) {
    return axiosClient.deleteService(`${urlApi.VEHICLE}/${id}`);
  },
  updateFlag(params: Partial<vehicleModel>) {
    return axiosClient.updateService(
      `${urlApi.VEHICLE}/update-flag/${params._id}`,
      { ...params }
    );
  },
  updateConfigure(params: Partial<vehicleModel>) {
    return axiosClient.updateService(
      `${urlApi.VEHICLE}/update-configuration/${params._id}`,
      { ...params }
    );
  },
  actionLock(id: string) {
    return axiosClient.updateService(`${urlApi.VEHICLE}/action-lock/${id}`);
  },
  refreshLocation(id: string) {
    return axiosClient.postService(`${urlApi.VEHICLE}/refresh-location/${id}`);
  },
  getAllVehicleHistory(params: VehicleFilter) {
    return axiosClient.getService(`${urlApi.VEHICLE}/history-action`, { ...params });
  },
};
