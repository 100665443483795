import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { OrderModel } from "../../models/order.model";

export interface OrderState {
  loadding: boolean;
  listOrder: OrderModel[] | undefined;
  itemCount: number | undefined;
  order: OrderModel | undefined;
}
const initialState: OrderState = {
  loadding: false,
  listOrder: undefined,
  itemCount: undefined,
  order: undefined,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getListOrder(
      state,
      action: PayloadAction<{
        page: number | undefined;
        limit: number | undefined;
        keyword?: string;
        fleet?: string;
        _id?: string;
        status?: "ACTIVE" | "PAYMENT" | "ORDER" | "PAYMENT_SUCCESS" |'COMPLETE' | "STOP";
        "orderBy[createdAt]"?: "ASC" | "DESC";
      }>,
    ) {
      state.loadding = true;
    },
    getListOrderSuccess(state, action: PayloadAction<OrderModel[]>) {
      state.listOrder = action.payload;
      state.loadding = false;
    },
    getListOrderFail(state) {
      state.loadding = false;
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload;
    },
    stopRide(state, action: PayloadAction<{ id: string; lock: boolean }>) {
      state.loadding = true;
    },
    getOrderByVehicle(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    success(state) {
      state.loadding = false;
    },
    getOrderByVehicleSuccess(state, action: PayloadAction<OrderModel>) {
      state.loadding = false;
      state.order = action.payload;
    },
    fail(state, action: PayloadAction<any>) {
      state.loadding = false;
    },
    removeOrderFromState(state, action: PayloadAction<string>) {
      state.listOrder = state.listOrder.filter(
        (item) => item._id !== action.payload,
      );
    },
    addOrderFromState(state, action: PayloadAction<OrderModel>) {
      if (state.listOrder) {
        const listOrderId = state.listOrder
          ? state.listOrder.map((o) => o._id)
          : [];
        if (!listOrderId.includes(action.payload._id)) {
          state.listOrder.push(action.payload);
        }
      } else {
        state.listOrder = [action.payload];
      }
    },
  },
});
export const orderAction = orderSlice.actions;
export const selectItemCount = (state: RootState) => state.order.itemCount;
export const selectListOrder = (state: RootState) => state.order.listOrder;
export const selectOrder = (state: RootState) => state.order.order;
export const selectLoading = (state: RootState) => state.order.loadding;

export const orderReducer = orderSlice.reducer;
