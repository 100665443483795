import {Col, Row, Select, Tooltip} from "antd";
import {SizeType} from "antd/lib/config-provider/SizeContext";
import {Option} from "antd/lib/mentions";
import React, {useEffect, useState} from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ITable from "../../../components/table";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    orderAction,
    selectItemCount,
    selectListOrder,
} from "../../../features/order/orderSlice";
import {DEVICE_TYPE} from "../../../models/vehicleModel";
import moment from "moment";
import {OrderModel} from "../../../models/order.model";
import {useTranslation} from "react-i18next";
import {ForceStopDialog} from "../../../components/dialog/forceStopDialog";
import {useLocation} from "react-router-dom";
import {useRunningVehicle} from "../../../hooks/useRunningVehicle";

const Running = () => {
    const {state} = useLocation();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const listOrder = useAppSelector(selectListOrder);
    const itemCount = useAppSelector(selectItemCount);
    const [size, setSize] = useState<SizeType>("middle");
    const [limit, setLimit] = useState<number>(15);
    const [page, setPage] = useState<number>(1);
    const [keyword, setKeyword] = useState<string>(undefined);
    const [openForce, setOpenForce] = useState<boolean>(false);
    const [id, setId] = useState<string>(undefined);
    const handleChange = (value: string | string[]) => {
        setLimit(Number(value));
    };
    useEffect(() => {
        dispatch(
            orderAction.getListOrder({
                page,
                limit,
                keyword,
                status: "ACTIVE",
                "orderBy[createdAt]": "DESC",
                ...(state && state.search ? {_id: state.search} : {}),
            }),
        );
    }, [page, limit, keyword]);
    const handleForceStopWithLock = () => {
        if (id) {
            dispatch(orderAction.stopRide({id, lock: true}));
            setOpenForce(false)
            getListOrder()
        }
    };
    const handleForceStopWithoutLock = () => {
        if (id) {
            dispatch(orderAction.stopRide({id, lock: false}));
            setOpenForce(false)
            getListOrder()
        }
    };
    const getListOrder = () => {
        setTimeout(() => dispatch(orderAction.getListOrder({
            page,
            limit,
            keyword,
            status: "ACTIVE",
            "orderBy[createdAt]": "DESC",
            ...(state && state.search ? {_id: state.search} : {}),
        }),), 500)
    }
    useRunningVehicle();
    return (
        <div>
            <Row>
                <Row
                    align="middle"
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        margin: "1rem 0",
                    }}
                >
                    <span className="va_p_title">In Use</span>
                    <div style={{display: "flex"}}>
                        {/* <BBtn
              title='CSV'
              icon1={<i className='fa-solid fa-file-lines'></i>}
            />
            <span style={{margin: '0 0.5rem'}}></span> */}
                        <BBtn
                            title="All Filter"
                            icon1={<i className="fa-solid fa-caret-down"></i>}
                            icon1left
                        />
                    </div>
                </Row>
                <Col span={24} className="gutter-row">
                    <div className="org_div1">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <span style={{marginRight: "1rem"}}>Show</span>
                            <Select
                                size={size}
                                defaultValue={t("show").toString()}
                                onChange={handleChange}
                            >
                                <Option value="15">10</Option>
                                <Option value="25">20</Option>
                                <Option value="50">30</Option>
                                <Option value="50">40</Option>
                                <Option value="50">50</Option>
                            </Select>
                            <span style={{marginLeft: "1rem", marginRight: "3rem"}}>
                {t("entries")}
              </span>
                            <ISearch
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </div>
                        <br/>
                        <ITable
                            // onClickRow={(params: any) => setDataPayments(params)}
                            column={[
                                {
                                    title: "ID",
                                    dataIndex: "_id",
                                },
                                {
                                    title: "Rider ID",
                                    dataIndex: "customer",
                                    render: (text: Record<string, any>) => (text ? text._id : ""),
                                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                                },
                                {
                                    title: "Rider",
                                    dataIndex: "customer",
                                    render: (text: Record<string, any>) =>
                                        text ? text.lastName : "",
                                },
                                {
                                    title: "Vehicle",
                                    dataIndex: "vehicles",
                                    render: (text: Record<string, any>[]) =>
                                        text ? (
                                            <Tooltip placement="right" title="campusride">
                                                {text[0].name} <i className="fa fa-info-circle"></i>
                                            </Tooltip>
                                        ) : (
                                            ""
                                        ),
                                    // sorter: (a: any, b: any) => a.name.length - b.name.length,
                                },
                                {
                                    title: "Qr Code",
                                    dataIndex: "vehicles",
                                    render: (text: Record<string, any>[]) =>
                                        text ? text[0].qrCode : "",
                                },
                                {
                                    title: "Vehicle Type",
                                    dataIndex: "vehicles",
                                    render: (text: Record<string, any>[]) =>
                                        text ? DEVICE_TYPE[text[0].typeDevice] : "",
                                },
                                {
                                    title: "Odometer Distance (km)",
                                    dataIndex: "odometerDistance",
                                    render: (text: {}, record: OrderModel) =>
                                        record.odometerEndBooking - record.odometerBooking,
                                },
                                {
                                    title: "Ride Date",
                                    dataIndex: "createdAt",
                                    render: (text: string) =>
                                        moment(text).format("YYYY/MM/DD HH:mm:ss"),
                                },
                                {
                                    title: "Ride Duration",
                                    dataIndex: "rideDuration",
                                    render: (text: {}, record: OrderModel) => {
                                        const startTime = moment(record.startTime);
                                        const endTime = moment(record.endTime);
                                        const diff = moment
                                            .duration(endTime.diff(startTime))
                                            .asMilliseconds();
                                        return moment.utc(diff).format("HH:mm:ss");
                                    },
                                },
                                {
                                    title: "Close Ride",
                                    dataIndex: "closeRide",
                                    render: (text: any, record: OrderModel) => (
                                        <div style={{display: "flex"}}>
                                            <BBtn
                                                title="Force Stop"
                                                icon1={<i className="fa-solid fa-unlock-keyhole"></i>}
                                                color="#dd4b39"
                                                onClick={() => {
                                                    setOpenForce(true);
                                                    setId(record._id);
                                                }}
                                            />
                                            {/* <span style={{margin: '5px'}}></span>
                      <BBtn
                        title='Invoice'
                        icon1={<i className='fa-solid fa-file'></i>}
                        color='#24BB69'
                      /> */}
                                        </div>
                                    ),
                                },
                            ]}
                            total={itemCount || 0}
                            data={listOrder || []}
                            currentPage={page}
                            pageSize={limit}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <ForceStopDialog
                open={openForce}
                setOpen={() => {
                    setOpenForce(!openForce);
                    setId(undefined);
                }}
                handleForceStopWithLock={handleForceStopWithLock}
                handleForceStopWithoutLock={handleForceStopWithoutLock}
            />
        </div>
    );
};

export default Running;
