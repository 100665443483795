import { Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Images from "../../assets/gen";
import BBtn from "../../components/button";
import ISearch from "../../components/search";
import ISelect from "../../components/select";
import ITable from "../../components/table";
import { UserModel } from "../../models/user.model";
import { Url } from "../../routers/paths";
import { AppColors } from "../../utils/appColors";
import { EVENTS } from "../../utils/constants";

const data = [
  {
    id: 1,
    name: "Theme 1",
    status: "active",
    action: "Apply",
  }
];

const columns: ColumnsType<UserModel> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px;
  background: ${AppColors.background};

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .wrap-item {
      margin-bottom: 40px;
      margin-right: 100px;
    }

    .wrap-item2 {
      margin-right: 36px;
      margin-bottom: 20px;
      margin-bottom: 10px;
      width: 200px;
    }
  }

  .c-row {
    display: flex;
    flex-direction: row;
  }

  .flex-start {
    align-items: flex-start;
  }

  .c-column {
    display: flex;
    flex-direction: column;
  }

  .c-title {
    color: ${AppColors.textPrimary};
    font-weight: 600;
    font-size: 14px;
  }

  .flex-1 {
    flex: 1;
  }

  .center {
    align-items: center;
    justify-content: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${AppColors.textPrimary};
  }

  .w-500 {
    width: 500px;
  }

  .t-medium {
    font-size: 14px;
    font-weight: 500;
    color: ${AppColors.textPrimary};
  }

  .t-16 {
    font-size: 16px;
    font-weight: 500;
    color: ${AppColors.textPlaceholder};
  }

  .t-semibold {
    font-size: 14px;
    font-weight: 600;
    color: ${AppColors.textPrimary};
  }

  .sub-title {
    font-size: 18px;
    color: ${AppColors.textPrimary};
    font-weight: 500;
    text-align: center;
  }

  .content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 50px;

    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 1px;
      background: ${AppColors.divider};
    }

    .view-filter {
      padding-left: 100px;
      padding-right: 100px;
    }

    .view-list {
      padding: 24px;
      border: 1px solid ${AppColors.borderSecondary};
      height: calc(40vh);
      overflow: auto;

      .item {
        border-bottom: 1px solid ${AppColors.borderSecondary};
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }
  }
`;
const Themes = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className='c-row space-between'>
        <span className='title'>Themes</span>
        <BBtn
          iconLeft={Images.icAdd}
          title='Add Theme'
          onClick={() => navigate(Url.themesAdd)}
        />
      </div>
      <div style={{ height: 20 }} />

      <div className='content'>
        <div className='c-row center'>
          <span className='t-16'>Show</span>
          <div style={{ width: 16 }}></div>
          <div>
            <ISelect
              name=''
              onChange={() => {}}
              option={[
                  {text : '10' , value : '10'},
                  {text : '20' , value : '20'},
                  {text : '30' , value : '30'},
                  {text : '40' , value : '40'},
              ]}
              value={"10"}
              style={{
                borderColor: `${AppColors.borderSecondary}`,
              }}
              width={72}
            />
          </div>
          <div style={{ width: 16 }}></div>
          <span className='t-16'>Entries</span>
          <div style={{ width: 60 }}></div>
          <ISearch onChange={() => {}} />
        </div>
        <div style={{ height: 16 }}></div>

        <ITable
          onChangePage={(page) => {
            console.log("page", page);
          }}
          data={data.map((item, index) => {
            return { ...item, key: index };
          })}
          pageSize={100}
          total={500}
          column={columns}
        />
      </div>
    </Container>
  );
};

export default Themes;
