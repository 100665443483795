import moment from "moment";

export const Config = {
  HOST_API: process.env.REACT_APP_BASE_URL,
  SOCKET_API: process.env.REACT_APP_BASE_URL_SOCKET,
};

export enum LOCAL_STORAGE {
  TOKEN = "access_token",
}

export const PATH_NO_LAYOUT = ["/login"];
export const IS_LOGGED: boolean = true;

export interface TabSetting {
  id: number;
  title: string;
}

export const TAB_SETTING: TabSetting[] = [
  {
    id: 1,
    title: "Mobile",
  },
  {
    id: 2,
    title: "Dashboard",
  },
  {
    id: 3,
    title: "Mail",
  },
  {
    id: 4,
    title: "Vehicles",
  },
  {
    id: 5,
    title: "Events",
  },
  {
    id: 6,
    title: "Payments",
  },
  {
    id: 7,
    title: "System",
  },
];

export interface Event {
  id: number;
  title: string;
  color: string;
}

export const FlagImg:
  | {
      agencyPickup: string;
      batteryCritical: string;
      ioTFault: string;
      rented: string;
      maintenancePickup: string;
      missing: string;
      reBalancePickup: string;
      serviceStartEnd: string;
      stolen: string;
      unavailable: string;
      usingService: string;
      riding: string;
    }
  | any = {
  agencyPickup: "/img/agency_pick_up.svg",
  batteryCritical: "/img/battery_critical.svg",
  ioTFault: "/img/hardware_fault.svg",
  rented: "/img/keep.svg",
  maintenancePickup: "/img/maintenance.svg",
  missing: "/img/missing.svg",
  reBalancePickup: "/img/rebalance_pick_up.svg",
  serviceStartEnd: "/img/service_end.svg",
  stolen: "/img/stolen.svg",
  unavailable: "/img/unavailable.svg",
  logo: "/img/logo.webp",
  parked_vehicle: "/img/parked_vehicle.svg",
  usingService: "/img/parked_vehicle.svg",
  riding: "/img/riding.svg",
};

export const EVENTS: Event[] = [
  { id: 1, title: "Organisation Events", color: "#FF5050" },
  { id: 2, title: "Fleet Events", color: "#FF6600" },
  { id: 3, title: "Vehicle Events", color: "#FF0066" },
  { id: 4, title: "Marker Events", color: "#CC6699" },
  { id: 5, title: "Parking Area Events", color: "#3dd1e0" },
  { id: 6, title: "Restricted Area Events", color: "#b875a3" },
  { id: 7, title: "Service Area Events", color: "#cc6600" },
  { id: 8, title: "Slow Speed Events", color: "#7aa366" },
  { id: 9, title: "Custom Zone Events", color: "#cc0000" },
  { id: 10, title: "Station Events", color: "#990033" },
  { id: 11, title: "Rider Events", color: "#660033" },
  { id: 12, title: "Payment Gateway Events", color: "#993366" },
  { id: 13, title: "Tax Events", color: "#990099" },
  { id: 14, title: "Feedback option Events", color: "#cc0099" },
  { id: 15, title: "Campaign Events", color: "#ff3399" },
  { id: 16, title: "Payment Events", color: "#ff6666" },
  { id: 17, title: "Offer Events", color: "#ff9966" },
  { id: 18, title: "Billing Plan Events", color: "#ff9999" },
  { id: 19, title: "Promos", color: "#ff99cc" },
  { id: 20, title: "Ticket Events", color: "#ffcccc" },
  { id: 21, title: "Billing Events", color: "#cc99ff" },
  { id: 22, title: "Setting Events", color: "#ccccff" },
  { id: 23, title: "Theme Events", color: "#9999ff" },
  { id: 24, title: "Ride Events", color: "#3399ff" },
  { id: 25, title: "Wallet Events", color: "#6666ff" },
  { id: 26, title: "Banner Events", color: "#cc666f" },
];

export const dateFomat = (params: string) => {
  return moment(params).format("LLL");
};
export const getListData = (params: any) => {
  setTimeout((params: any) => params, 500);
};

export const SOCKET_EVENT = {
  STOP_RIDE: "stopRide",
  START_RIDE: "startRide",
  END_RIDE: "endRide",
};
