import {PayloadAction} from '@reduxjs/toolkit'
import {call, put, takeEvery} from 'redux-saga/effects'
import {billingPlanApi} from '../../apis/billingPlanApi'
import {BillingPlanModel} from '../../models/billingPlanModel'
import {PaginationModel} from '../../models/paginationModel'
import {billingPlanAction} from './billingPlanSlice'
import {message} from "antd";

function* getListBillingPlan(action: PayloadAction<PaginationModel>) {
  try {
    const billingPlan: {
      data: {
        nodes: BillingPlanModel[]
        itemCount: number
      }
    } = yield call(billingPlanApi.getListBillingPlan, action.payload)
    yield put(
      billingPlanAction.getListBillingPlanSuccess(billingPlan.data.nodes)
    )
    yield put(billingPlanAction.setItemCount(billingPlan.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}

function* createBillingPlan(action: PayloadAction<BillingPlanModel>) {
  try {
    const billingPlan :  {description: string} = yield call(billingPlanApi.createBillingPlan, action.payload)
    message.success(billingPlan.description).then()
    yield put(billingPlanAction.success())
  }
  catch (e) {
    console.log(e)
    yield put(billingPlanAction.fail())
  }
}
function* updateBillingPlan(action: PayloadAction<Partial<BillingPlanModel>>) {
  try {
    const billingPlan :  {description: string} = yield call(billingPlanApi.updateBillingPlan, action.payload)
    message.success(billingPlan.description).then()
    yield put(billingPlanAction.success())
  }
  catch (e) {
    console.log(e)
    yield put(billingPlanAction.fail())
  }
}
function* detailBillingPlan(action: PayloadAction<{id : string}>) {
  try {
    const billingPlan :  {description: string , data : BillingPlanModel} = yield call(billingPlanApi.detailBillingPlan, action.payload.id)
    message.success(billingPlan.description).then()
    yield put(billingPlanAction.detailBillingPlanSuccess(billingPlan.data))
  }
  catch (e) {
    console.log(e)
    yield put(billingPlanAction.fail())
  }
}
function* deleteBillingPlan(action: PayloadAction<{id : string}>) {
  try {
    const billingPlan :  {description: string } = yield call(billingPlanApi.deleteBillingPlan, action.payload.id)
    message.success(billingPlan.description).then()
    yield put(billingPlanAction.success())
  }
  catch (e) {
    console.log(e)
    yield put(billingPlanAction.fail())
  }
}

export default function* billingPlanSaga() {
  yield takeEvery(billingPlanAction.getListBillingPlan.type, getListBillingPlan)
  yield takeEvery(billingPlanAction.createBillingPlan.type, createBillingPlan)
  yield takeEvery(billingPlanAction.updateBillingPlan.type, updateBillingPlan)
  yield takeEvery(billingPlanAction.detailBillingPlan.type, detailBillingPlan)
  yield takeEvery(billingPlanAction.deleteBillingPlan.type, deleteBillingPlan)
}
