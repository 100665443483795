import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {PaginationModel} from '../../models/paginationModel'
import {TaxModel} from '../../models/taxModel'

export interface TaxState {
  loadding: boolean
  listTax: TaxModel[] | undefined
  itemCount: number | undefined
  tax : TaxModel | undefined
}
const initialState: TaxState = {
  loadding: false,
  listTax: undefined,
  itemCount: undefined,
  tax: undefined,
}

const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    getListTax(state, action: PayloadAction<PaginationModel>) {
      state.loadding = true
    },
    getListTaxSuccess(state, action: PayloadAction<TaxModel[]>) {
      state.listTax = action.payload
      state.loadding = false
    },
    getListTaxFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateTax(
      state,
      action: PayloadAction<{id: string; name: string; taxPercentage: number}>
    ) {
      state.loadding = true
    },
    updateTaxSuccess(state) {
      state.loadding = false
    },
    updateTaxFail(state) {
      state.loadding = false
    },
    addTax(
      state,
      action: PayloadAction<{name: string; taxPercentage: number}>
    ) {
      state.loadding = true
    },
    addTaxSuccess(state) {
      state.loadding = false
    },
    addTaxFail(state) {
      state.loadding = false
    },
    detailTax(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailTaxSuccess(state, action : PayloadAction<TaxModel> ) {
      state.loadding = true
      state.tax = action.payload
    },
    deleteTax(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
   deleteTaxSuccess(state) {
      state.loadding = false
    },
   deleteTaxFail(state) {
      state.loadding = false
    },
  },
})
export const taxAction = taxSlice.actions
export const selectItemCountTax = (state: RootState) => state.tax.itemCount
export const selectListTax = (state: RootState) => state.tax.listTax
export const selectTax = (state: RootState) => state.tax.tax
export const selectLoaddingTax = (state: RootState) => state.tax.loadding

export const taxReducer = taxSlice.reducer
