import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { BannerModel } from "../models/banner.model";

export const bannerApi = {
  getAllBanner(params: {
    page: number | undefined;
    limit: number | undefined;
    keyword?: string;
    status?: "ACTIVE" | "DE_ACTIVE";
    "orderBy[id]"?: "ASC" | "DESC";
    "orderBy[title]"?: "ASC" | "DESC";
  }) {
    return axiosClient.getService(`${urlApi.BANNER}`, { ...params });
  },
  createBanner(params: Partial<BannerModel>) {
    return axiosClient.postService(`${urlApi.BANNER}`, { ...params });
  },
  updateBanner(params: Partial<BannerModel>) {
    return axiosClient.updateService(`${urlApi.BANNER}/${params._id}`, {
      ...params,
    });
  },
  deleteBanner(id: string) {
    return axiosClient.deleteService(`${urlApi.BANNER}/${id}`);
  },
  detailBanner(id: string) {
    return axiosClient.getService(`${urlApi.BANNER}/${id}`);
  },
};
