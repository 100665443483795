import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {FeedbackModel} from "../models/feedback.model";

export const feedbackApi = {
    getAllFeedback(params: {
        page: number | undefined,
        limit: number | undefined,
        keyword?: string,
        fleet?: string;
        startTime?: Date;
        endTime?: Date;
        'orderBy[id]'?: 'ASC' | 'DESC',
        'orderBy[title]'?: 'ASC' | 'DESC',
    }) {
        return axiosClient.getService(`${urlApi.FEEDBACK}`, {...params})
    },
    createFeedback(params: Partial<FeedbackModel>) {
        return axiosClient.postService(`${urlApi.FEEDBACK}`, {...params})
    },
    updateFeedback(params: Partial<FeedbackModel>) {
        return axiosClient.updateService(`${urlApi.FEEDBACK}/${params._id}`, {...params})
    },
    deleteFeedback(id: string) {
        return axiosClient.deleteService(`${urlApi.FEEDBACK}/${id}`)
    },
    detailFeedback(id: string) {
        return axiosClient.getService(`${urlApi.FEEDBACK}/${id}`)
    },
}
