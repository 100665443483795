import { Select } from "antd";
import styled from "styled-components";
import ISelect from "../../components/select";
import { AppColors } from "../../utils/appColors";
import { EVENTS } from "../../utils/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px;
  background: ${AppColors.background};

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .wrap-item {
      margin-bottom: 40px;
      margin-right: 100px;
    }

    .wrap-item2 {
      margin-right: 36px;
      margin-bottom: 20px;
      margin-bottom: 10px;
      width: 200px;
    }
  }

  .c-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flex-start {
    align-items: flex-start;
  }

  .c-column {
    display: flex;
    flex-direction: column;
  }

  .c-title {
    color: ${AppColors.textPrimary};
    font-weight: 600;
    font-size: 14px;
  }

  .flex-1 {
    flex: 1;
  }

  .center {
    align-items: center;
    justify-content: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${AppColors.textPrimary};
  }

  .w-500 {
    width: 500px;
  }

  .t-medium {
    font-size: 14px;
    font-weight: 500;
    color: ${AppColors.textPrimary};
  }

  .t-semibold {
    font-size: 14px;
    font-weight: 600;
    color: ${AppColors.textPrimary};
  }

  .sub-title {
    font-size: 18px;
    color: ${AppColors.textPrimary};
    font-weight: 500;
    text-align: center;
  }

  .content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 50px;

    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 1px;
      background: ${AppColors.divider};
    }

    .view-filter {
      padding-left: 100px;
      padding-right: 100px;
    }

    .view-list {
      padding: 24px;
      border: 1px solid ${AppColors.borderSecondary};
      height: calc(40vh);
      overflow: auto;

      .item {
        border-bottom: 1px solid ${AppColors.borderSecondary};
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }
  }
`;
const Events = () => {
  return (
    <Container>
      <span className='title'>Event</span>
      <div style={{ height: 20 }} />
      <div className='content'>
        <div className='c-row view-filter'>
          <ISelect
            name='selectFleets'
            placeHolder='All Fleets'
            option={[{text : "1" , value : "1"}, {text : "2" , value : "2"}]}
            style={{ border: `1px solid ${AppColors.borderSecondary}` }}
            onChange={() => {}}
          />
          <div style={{ width: 60 }}></div>
          <ISelect
            name='selectEvents'
            placeHolder='Select Events'
            option={[{text : "1" , value : "1"}, {text : "2" , value : "2"}]}
            style={{ border: `1px solid ${AppColors.borderSecondary}` }}
            onChange={() => {}}
          />
          <div style={{ width: 60 }}></div>
          <ISelect
            name='allVehicles'
            placeHolder='All Vehicles'
            option={[{text : "1" , value : "1"}, {text : "2" , value : "2"}]}
            style={{ border: `1px solid ${AppColors.borderSecondary}` }}
            onChange={() => {}}
          />
        </div>
        <div style={{ height: 40 }}></div>
        <div className='view-list'>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
            return (
              <div key={index} className='item'>
                <span className='t-medium'>
                  2022/07/11, 13:47:26 :
                  <span style={{ color: AppColors.blue }}>Ride Events -</span>
                  <span>ended ride (</span>
                  <span style={{ color: AppColors.blue }}>45509</span>
                  <span>)</span>
                </span>
              </div>
            );
          })}
        </div>
        <div style={{ height: 40 }}></div>
        <div className='c-row center'>
          <span className='sub-title'>Event Legend</span>
        </div>
        <div className='wrap'>
          {EVENTS.map((item, index) => {
            return (
              <div key={index} className='c-row wrap-item2'>
                <div
                  style={{
                    background: item.color,
                    width: 36,
                    height: 24,
                    borderRadius: 4,
                    border: `1px solid ${AppColors.border}`,
                  }}
                ></div>
                <div style={{ width: 8 }}></div>
                <span className='t-medium'>{item.title}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Events;
