import styled from "styled-components";
import { Dropdown, Pagination, Space, Table, TableColumnsType } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AppColors } from "../../utils/appColors";
import {forwardRef, memo, useEffect, useImperativeHandle, useState, Key, SetStateAction , Dispatch} from "react";
import { useTranslation } from "react-i18next";
import { calculateColumnsWidth } from "../../utils/tableHelper";
import {
  DownOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .c-row {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    .text-show {
      color: ${AppColors.textPlaceholder};
    }
  }

  .ant-table-cell {
    padding-left: 100px;
  }

  .ant-table-thead {
    .thead {
      background: ${AppColors.textPrimary};
      color: white;
      font-weight: 600;
    }
    .thead::before {
      background-color: transparent !important;
      width: 0 !important;
    }
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
    background-color: #f6f6f6;
  }

  .thead {
    padding-left: 20px !important;
  }

  .ant-pagination-item {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    border: 1px solid ${AppColors.borderSecondary};
    div {
      color: ${AppColors.textPrimary};
    }
  }

  .ant-pagination-prev {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    background: white;
    border: 1px solid ${AppColors.borderSecondary};

    div {
      color: ${AppColors.textPlaceholder};
    }
  }

  .ant-pagination-next {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    background: white;
    border: 1px solid ${AppColors.borderSecondary};

    div {
      color: ${AppColors.textPlaceholder};
    }
  }

  .ant-pagination-jump-prev {
    border-radius: 4px;
    background: white;
    border: 1px solid ${AppColors.borderSecondary};
  }
  .ant-pagination-jump-next {
    border-radius: 4px;
    background: white;
    border: 1px solid ${AppColors.borderSecondary};
  }

  .ant-pagination-item-active {
    background: ${AppColors.red};
    border: none;
    div {
      color: #ffffff;
    }
  }
`;

export interface IProps {
  column: Array<any>;
  data: Array<any>;
  width?: number;
  pageSize: number;
  total: number;
  onClickRow?: (params: any) => void;
  onChangePage: (event: any) => void;
  rowSelection?: boolean;
  expandable?: boolean;
  expandedRowData?: Array<any>;
  expandedRowColumn?: Array<any>;
  currentPage?: number;
  selectedRowKeys ? : Key[]
  setSelectedRowKeys ? : Dispatch<SetStateAction<Key[]>>
}

interface ExpandedDataType {
  key: Key;
  date: string;
  name: string;
  upgradeNum: string;
}

const ITable = (props: IProps) => {
  const [dataColumn, setData] = useState<any>();
  // const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const onSelectChange = (newSelectedRowKeys: Key[] , selectedRows : any[]) => {
    if (newSelectedRowKeys.length){
      props.onClickRow({})
    }
    props.setSelectedRowKeys(newSelectedRowKeys);
  };
  const { t } = useTranslation();

  const rowSelection = {
    selectedRowKeys : props.selectedRowKeys || [],
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record._id === '', // Column configuration not to be checked
      name: record.name,
    }),
  };
  // const hasSelected = props.selectedRowKeys.length > 0;

  useEffect(() => {
    const newData = props.column.map((item, index) => {
      return {
        ...item,
        className: "thead",
      };
    });
    setData(newData);
  }, [props.column]);


  const dataTable = calculateColumnsWidth(props.column, props.data, 200);

  const expandedRowRender = () => {
    return (
      <Table
        columns={props.expandedRowColumn}
        dataSource={props.expandedRowData}
        pagination={false}
      />
    );
  };

  return (
    <Container>
      <Table
        expandable={
          props.expandable
            ? {
                expandedRowRender,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <img
                      alt="-"
                      onClick={(e) => onExpand(record, e)}
                      src="https://campusride-dashboard.joyridecity.bike/image/close.png"
                    />
                  ) : (
                    <img
                      alt="+"
                      onClick={(e) => onExpand(record, e)}
                      src="https://campusride-dashboard.joyridecity.bike/image/open.png"
                    />
                  ),
                defaultExpandedRowKeys: ["0"],
              }
            : {}
        }
        onExpand={(params, index) => console.log(params, index)}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        rowSelection={props.rowSelection ? rowSelection : undefined}
        columns={dataColumn}
        dataSource={props.data.map((item,index)=> ({...item, key : item._id ? item._id : index}))}
        scroll={{ x: props.width ? props.width : dataTable.tableWidth }}
        size="middle"
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              if (!props.selectedRowKeys || !props.selectedRowKeys.length){
              props.onClickRow && props.onClickRow(record);
              }
            }, // click row
          };
        }}
      />
      <div style={{ height: 16 }}></div>
      <div className="c-row">
        <div className="text-show">
          {t("SHOWING_ENTRIES", {
            from: !props.total
              ? 0
              : (Number(props.currentPage) - 1) * props.pageSize + 1 || 0,
            to:
              Number(props.currentPage) * props.pageSize > props.total
                ? props.total
                : Number(props.currentPage) * props.pageSize || 0,
            total: Number(props.total) || 0,
          })}
        </div>
        <Pagination
          showSizeChanger={false}
          pageSize={props.pageSize}
          current={props.currentPage}
          defaultCurrent={1}
          total={props.total}
          itemRender={(page, type, element) => {
            if (type === "page") {
              return <div>{page}</div>;
            } else if (type === "prev") {
              return <div>{t("PREVIOUS")}</div>;
            } else if (type === "next") {
              return <div>{t("NEXT")}</div>;
            } else {
              return <div style={{ fontWeight: "bold" }}>...</div>;
            }
          }}
          onChange={(page) => {
            setCurrentPage(page);
            props.onChangePage(page);
          }}
        />
      </div>
    </Container>
  );
};

export default memo(ITable);
