import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {AnalyticPaymentModel} from '../../models/analyticPaymentModel'

export interface AnalyticPaymentState {
    loadding: boolean
    listAnalyticPayment: AnalyticPaymentModel[] | undefined
    itemCount: number | undefined
    analyticPayment: AnalyticPaymentModel | undefined
    statisticPayment : {
        avgRevenuePerDay: number
        totalAmount: number,
        AvgTripRevenue:number,
        AvgLifetimeSpend:number,
        totalWalletSize: number,
        averageWalletSize: number,
        averageTopUp: number,
    } | undefined
}

const initialState: AnalyticPaymentState = {
    loadding: false,
    listAnalyticPayment: undefined,
    itemCount: undefined,
    analyticPayment: undefined,
    statisticPayment: undefined,
}

const analyticPaymentSlice = createSlice({
    name: 'analyticPayment',
    initialState,
    reducers: {
        getChartPayment(state, action: PayloadAction<{
            startTime: string;
            endTime: string;
            fleet?: string;
            type: "PAYMENT" | "TOTAL_PAYMENT";
        }>) {
            state.loadding = true
        },
        getChartPaymentSuccess(state, action: PayloadAction<AnalyticPaymentModel[]>) {
            state.listAnalyticPayment = action.payload
            state.loadding = false
        },
        getChartPaymentFail(state) {
            state.loadding = false
        },
        setItemCount(state, action: PayloadAction<number>) {
            state.itemCount = action.payload
        },
        getStatisticPayment(state, action: PayloadAction<{
            startTime: string;
            endTime: string;
            fleet?: string;
            type: "PAYMENT" | "TOTAL_PAYMENT";
        }>) {
            state.loadding = true
        },
        getStatisticPaymentSuccess(state , action : PayloadAction<{
            avgRevenuePerDay: number
            totalAmount: number,
            AvgTripRevenue:number,
            AvgLifetimeSpend:number,
            totalWalletSize: number,
            averageWalletSize: number,
            averageTopUp: number,
        }>){
            state.loadding = false
            state.statisticPayment = action.payload
        }
    },
})
export const analyticPaymentAction = analyticPaymentSlice.actions
export const selectItemCount = (state: RootState) => state.analyticPayment.itemCount
export const selectListAnalyticPayment = (state: RootState) => state.analyticPayment.listAnalyticPayment
export const selectAnalyticPayment = (state: RootState) => state.analyticPayment.analyticPayment
export const selectStatisticPayment = (state: RootState) => state.analyticPayment.statisticPayment
export const selectLoading = (state: RootState) => state.analyticPayment.loadding

export const analyticPaymentReducer = analyticPaymentSlice.reducer
