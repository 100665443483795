import {Url} from './paths'
import Home from '../pages/home'
import Login from '../pages/login'
import Map from '../pages/map'
import Org from '../pages/org'
import Setting from '../pages/setting'
import Permission from '../pages/permission'
import Role from '../pages/role'
import Fleets from '../pages/fleets'
import Events from '../pages/event'
import Themes from '../pages/theme'
import VehicleAll from '../pages/vehicles/all'
import ThemesAdd from '../pages/theme/add'
import VehicleReserved from '../pages/vehicles/reserved'
import VehiclesHistory from '../pages/vehicles/history'
import ParkingZones from '../pages/geofence/parkingZones'
import RestrictedZones from '../pages/geofence/restrictedZones'
import ServiceAreas from '../pages/geofence/serviceAreas'
import SlowSpeedZones from '../pages/geofence/slowSpeedZones'
import Stations from '../pages/geofence/stations'
import Riders from '../pages/riders'
import PaymentGateway from '../pages/pricing/paymentGateway'
import BillingPlan from '../pages/pricing/billingPlan'
import Tax from '../pages/pricing/tax'
import Promos from '../pages/pricing/promos'
import Offer from '../pages/pricing/offer'
import Tickets from '../pages/support/tickets'
import FeedbackOption from '../pages/support/feedbackOption'
import Notification from '../pages/campaigns/notification'
import Banner from '../pages/campaigns/banner'
import Payments from '../pages/payments'
import Completed from '../pages/Rides/completed'
import Running from '../pages/Rides/running'
import Versions from "../pages/versions";

const routes = [
  {
    path: Url.login,
    element: <Login />,
  },
  {
    path: Url.home,
    element: <Home />,
  },
  {
    path: Url.map,
    element: <Map />,
  },
  {
    path: Url.setting,
    element: <Setting />,
  },
  {
    path: Url.versions,
    element: <Versions />,
  },
  {
    path: '/',
    element: <Home />,
  },
  // {path: Url.org, element: <Org />},
  // {path: Url.role, element: <Role />},
  // {path: Url.permission, element: <Permission />},
  {
    path: Url.oOrg,
    element: <Org />,
  },
  {
    path: Url.oRole,
    element: <Role />,
  },
  {
    path: Url.oPermission,
    element: <Permission />,
  },
  // {
  //   path: Url.org,
  //   children: [
  //     {
  //       path: 'org',
  //       element: <Org />,
  //     },
  //     {
  //       path: 'role',
  //       element: <Role />,
  //     },
  //     {
  //       path: 'permission',
  //       element: <Permission />,
  //     },
  //   ],
  // },
  {path: Url.fleets, element: <Fleets />},
  {path: Url.events, element: <Events />},
  {
    path: Url.themes,
    children: [
      {
        path: '',
        element: <Themes />,
      },
      {
        path: Url.themesAdd,
        element: <ThemesAdd />,
      },
    ],
  },

  {
    path: Url.vehiclesAll,
    element: <VehicleAll />,
  },
  {
    path: Url.vehiclesReserved,
    element: <VehicleReserved />,
  },
  {
    path: Url.vehicleHistory,
    element: <VehiclesHistory />,
  },
  // {
  //   path: Url.vehicles,
  //   children: [
  //     {
  //       path: 'all',
  //       element: <VehicleAll />,
  //     },
  //     {
  //       path: 'reserved',
  //       element: <VehicleReserved />,
  //     },
  //     {
  //       path: 'history',
  //       element: <VehiclesHistory />,
  //     },
  //   ],
  // },

  {
    path: Url.geofenceParkingZones,
    element: <ParkingZones />,
  },
  {
    path: Url.geofenceRestrictedZones,
    element: <RestrictedZones />,
  },
  {
    path: Url.geofenceServiceAreas,
    element: <ServiceAreas />,
  },
  {
    path: Url.geofenceSlowSpeedZones,
    element: <SlowSpeedZones />,
  },
  {
    path: Url.geofenceStations,
    element: <Stations />,
  },

  // {
  //   path: Url.geofence,
  //   children: [
  //     {
  //       path: 'parkingZones',
  //       element: <ParkingZones />,
  //     },
  //     {
  //       path: 'restrictedZones',
  //       element: <RestrictedZones />,
  //     },
  //     {
  //       path: 'serviceAreas',
  //       element: <ServiceAreas />,
  //     },
  //     {
  //       path: 'slowSpeedZones',
  //       element: <SlowSpeedZones />,
  //     },
  //     {
  //       path: 'stations',
  //       element: <Stations />,
  //     },
  //   ],
  // },
  {path: Url.riders, element: <Riders />},

  {
    path: Url.pricingPaymentGateway,
    element: <PaymentGateway />,
  },
  {
    path: Url.pricingBillingPlan,
    element: <BillingPlan />,
  },
  {
    path: Url.pricingTax,
    element: <Tax />,
  },
  {
    path: Url.pricingPromos,
    element: <Promos />,
  },
  {
    path: Url.pricingOffer,
    element: <Offer />,
  },

  // {
  //   path: Url.pricing,
  //   children: [
  //     {
  //       path: 'paymentGateway',
  //       element: <PaymentGateway />,
  //     },
  //     {
  //       path: 'billingPlan',
  //       element: <BillingPlan />,
  //     },
  //     {
  //       path: 'tax',
  //       element: <Tax />,
  //     },
  //     {
  //       path: 'promos',
  //       element: <Promos />,
  //     },
  //     {
  //       path: 'offer',
  //       element: <Offer />,
  //     },
  //   ],
  // },

  {path: Url.supportTickets, element: <Tickets />},
  {path: Url.supportFeedbackOption, element: <FeedbackOption />},
  {path: Url.campaignsNotifications, element: <Notification />},
  {path: Url.campaignsBanner, element: <Banner />},
  {path: Url.payments, element: <Payments />},
  {path: Url.ridesCompleted, element: <Completed />},
  {path: Url.ridesRunning, element: <Running />},
]
export default routes
