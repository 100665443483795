import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'

export const orderApi = {
  getAllOrder(params: {
    page: number | undefined;
    limit: number | undefined;
    keyword?: string;
    fleet?: string;
    status?: "ACTIVE" | "PAYMENT" | "ORDER" | "PAYMENT_SUCCESS";
    "orderBy[createdAt]"?: "ASC" | "DESC";
  }) {
    return axiosClient.getService(`${urlApi.ORDER}`, { ...params });
  },
  stopRide(params :{ id: string, lock: boolean }) {
    return axiosClient.updateService(`${urlApi.ORDER}/stop/${params.id}`, { lock : params.lock});
  },
  getOrderByVehicle(vehicleId : string){
    return axiosClient.getService(`${urlApi.ORDER}/vehicle/${vehicleId}`)
  }
};
