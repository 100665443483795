import {Col, Radio, RadioChangeEvent, Row, Select, Steps} from 'antd'
import {SizeType} from 'antd/lib/config-provider/SizeContext'
import TextArea from 'antd/lib/input/TextArea'
import {Option} from 'antd/lib/mentions'
import React, {useState, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import BBtn from '../../../components/button'
import ISearch from '../../../components/search'
import ISelect from '../../../components/select'
import ITable from '../../../components/table'
import InputGroup from '../../../components/textInput'
import {
  billingPlanAction,
  selectItemCount,
  selectListBillingPlan,
} from '../../../features/billingPlan/billingPlanSlice'
import {BillingPlanModel} from '../../../models/billingPlanModel'
import {selectListTax, taxAction} from "../../../features/tax/taxSlice";

// const children: React.ReactNode[] = []
// for (let i = 10; i < 36; i++) {
//   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
// }

const BillingPlan = () => {
  const initialState : Partial<BillingPlanModel> = {
    _id: '',
    created: '',
    taxType: [],
    billingUnit: 0,
    currency: '',
    description: '',
    firstBillingUnitFree: 0,
    maxBillingPrice: 0,
    maximumToPupAmount: 0,
    minimumBalance: 0,
    minimumToPupAmount: 0,
    name: '',
    taxInclude: false,
    taxPercent: 0,
    nextBillingUnitFree: 0,
    pauseBillingUnit: 0,
    pauseFirstBillingUnit: 0,
    pauseNextBillingUnit: 0,
    perDayFee: 0,
    preAuthHoldAmount: 0,
    requiredDepositAmount: 0,
    status: false,
    taxRate: 0,
    vehiclesReservedPrice: 0,
    taxPercentage:0,
    deleted: false,
    createdAt: '',
    updatedAt: '',
    __v: 0,
    planTitle:'',
    isTax : false,
    isPreAuth : false,
    isCardMandatory : false,
    feePauseRide : false,
    fleets: []
  }
  const [dataBillingPlan, setDataBillingPlan] = useState<BillingPlanModel>(initialState as BillingPlanModel)
  const [addPricing, setAddPricing] = useState(false)
  const dispatch = useAppDispatch()
  const listBillingPlan = useAppSelector(selectListBillingPlan)
  const itemCount = useAppSelector(selectItemCount)
  const listTax = useAppSelector(selectListTax)
  const [limit, setLimit] = useState('15')
  const [page, setPage] = useState<Number>(1)
  const handleChange = (value: string) => {
    setLimit(value)
  }
  const [value, setValue] = useState(1)

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value)
    setDataBillingPlan({...dataBillingPlan,feePauseRide : e.target.value})
    // setValue(e.target.value)
  }

  const getListBillingPlan =() => {
    setTimeout(() => {
      dispatch(
          billingPlanAction.getListBillingPlan({
            limit: Number(limit),
            page: Number(page),
          })
      )
    },500)
  }
  const handleCreateBilling = () => {
    dataBillingPlan && dispatch(billingPlanAction.createBillingPlan(dataBillingPlan))
    getListBillingPlan()
  }
  const handleEdit = () => {
    dataBillingPlan && dispatch(billingPlanAction.updateBillingPlan(dataBillingPlan))
    getListBillingPlan()
  }

  useEffect(() => {
    dispatch(
      billingPlanAction.getListBillingPlan({
        limit: Number(limit),
        page: Number(page),
      })
    )
  }, [dispatch, limit, page])
   useEffect(() => {
     dispatch(taxAction.getListTax({limit : 10 , page :1}))
   },[dispatch])
  console.log('listbill', listBillingPlan)
  console.log('data', dataBillingPlan)
  const listSelect : React.ReactNode[] = listTax ? listTax.map((item,index)=><Option key={item._id}>{item._id}</Option>):[]
  const handleChangeTaxType = (value: string[]) => {
    const temp : any = {...dataBillingPlan,taxType: value}
    setDataBillingPlan(temp)
  }
  // useEffect(() => {}, dataBillingPlan)
  return (
    <div>
      <br />
      <Row justify='space-between'>
        <span className='va_p_title'>Pricing</span>
        <BBtn
          title='Add Pricing'
          icon1={<i className='fa-solid fa-plus'></i>}
          onClick={() => setAddPricing(true)}
        />
      </Row>

      <div style={{height: '1rem'}}></div>
      {!addPricing ? (
        <Row gutter={24}>
          <Col span={dataBillingPlan.name ? 12 : 24}>
            <div style={{backgroundColor: 'white', padding: '1rem'}}>
              <div
                style={{
                  display: dataBillingPlan.name ? 'initial' : 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '1rem 0',
                    // marginLeft: '1rem',
                  }}
                >
                  <span style={{marginRight: '1rem'}}>Show</span>
                  <Select
                    value={limit}
                    defaultValue={'10'}
                    onChange={handleChange}
                  >
                    <Option value='10'>15</Option>
                    <Option value='25'>25</Option>
                    <Option value='50'>50</Option>
                  </Select>
                  <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
                    Entries
                  </span>
                  <ISearch onChange={() => console.log(222)} />
                </div>
              </div>
              <div>
                <ITable
                  onClickRow={(params: any) => setDataBillingPlan(params)}
                  // rowSelection={true}
                  column={[
                    {
                      title: 'ID',
                      dataIndex: 'id',
                    },
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: 'Tax Inclusive',
                      dataIndex: 'taxInclude',
                      render: (text: boolean) => <>{text ? 'Yes' : 'No'}</>,
                    },
                    {
                      title: 'Status',
                      dataIndex: 'status',
                      render: (text: boolean) => (
                        <>{text ? 'active' : 'Deactive'}</>
                      ),
                    },
                  ]}
                  total={Number(itemCount)}
                  data={listBillingPlan || []}
                  pageSize={Number(limit)}
                  onChangePage={(page) => {
                    setPage(page)
                  }}
                  currentPage={Number(page)}
                />
              </div>
            </div>
          </Col>
          {dataBillingPlan.name && (
            <>
              <Col span={12}>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '1rem',
                  }}
                >
                  <Col span={24} className='gutter-row'>
                    <div>
                      <p className='org_p1'>Edit Pricing</p>
                      <Row gutter={16}>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title='Name'
                            name='name'
                            value={dataBillingPlan.name}
                            onChange={(e) =>
                              setDataBillingPlan({...dataBillingPlan, name: e})
                            }
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title='Billing Unit (mins)'
                            name='Billing Unit (mins)'
                            type='number'
                            value={dataBillingPlan.billingUnit.toString()}
                            onChange={(e) =>
                              setDataBillingPlan({
                                ...dataBillingPlan,
                                billingUnit: Number(e),
                              })
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            type='number'
                            required={true}
                            value={dataBillingPlan.firstBillingUnitFree.toString()}
                            title='First Unit Fee '
                            name='First Unit Fee '
                            onChange={(e) =>
                              setDataBillingPlan({
                                ...dataBillingPlan,
                                firstBillingUnitFree: Number(e),
                              })
                            }
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title='Next Unit Fee '
                            name='Next Unit Fee '
                            type='number'
                            value={dataBillingPlan.nextBillingUnitFree.toString()}
                            onChange={(e) =>
                              setDataBillingPlan({
                                ...dataBillingPlan,
                                nextBillingUnitFree: Number(e),
                              })
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <span className='org_address'>Tax</span>
                          <ISelect
                            option={[{text : 'yes' , value : 'yes'},{text : 'no' , value : 'no'} ]}
                            name='Tax'
                            value={dataBillingPlan.taxInclude ? 'yes' : 'no'}
                            onChange={(e) => {
                              if (e === 'yes') {
                                setDataBillingPlan({
                                  ...dataBillingPlan,
                                  taxInclude: true,
                                })
                              } else
                                setDataBillingPlan({
                                  ...dataBillingPlan,
                                  taxInclude: false,
                                })
                            }}
                            placeHolder='Tax'
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title='Tax Percentage'
                            name='Tax Percentage'
                            type='number'
                            value={dataBillingPlan.taxPercentage}
                            onChange={(e) =>
                              setDataBillingPlan({
                                ...dataBillingPlan,
                                taxPercentage: Number(e),
                              })
                            }
                          />
                        </Col>

                        <Col span={12}>
                          <span className='org_address'>Status</span>
                          <ISelect
                            option={[{text : 'Active' , value : 'Active'}, {text : 'Deactive' , value : 'Deactive'}]}
                            name='Status'
                            value={dataBillingPlan.status ? 'yes' : 'no'}
                            onChange={(e) => {
                              if (e === 'Active') {
                                setDataBillingPlan({
                                  ...dataBillingPlan,
                                  status: true,
                                })
                              } else
                                setDataBillingPlan({
                                  ...dataBillingPlan,
                                  status: false,
                                })
                            }}
                            placeHolder='Status'
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            title='Max Billing Price Per Ride'
                            name='Max Billing Price Per Ride'
                            type='number'
                            value={dataBillingPlan.maxBillingPrice.toString()}
                            onChange={(e) =>
                              setDataBillingPlan({
                                ...dataBillingPlan,
                                maxBillingPrice: Number(e),
                              })
                            }
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            required
                            title='Description'
                            name='Description'
                            value={dataBillingPlan.description}
                            onChange={(e) =>
                              setDataBillingPlan({
                                ...dataBillingPlan,
                                description: e,
                              })
                            }
                          />
                        </Col>
                        <Col style={{marginTop: '1rem'}} span={12}>
                          <span
                            className='org_address'
                            style={{marginRight: '1rem'}}
                          >
                            Is the pause ride fee same as above?
                          </span>
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={2}>No</Radio>
                          </Radio.Group>
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            title='Vehicle reservation price'
                            name='Vehicle reservation price'
                            value={dataBillingPlan?.vehiclesReservedPrice}
                            onChange={(e) =>
                                setDataBillingPlan({
                                  ...dataBillingPlan,
                                  vehiclesReservedPrice: Number(e),
                                })
                            }
                          />
                        </Col>

                        {value === 1 && (
                          <>
                            <Col span={12}>
                              <InputGroup
                                title='Plan Title'
                                name='Plan Title'
                                value={dataBillingPlan?.planTitle}
                                onChange={(e) =>
                                    setDataBillingPlan({
                                      ...dataBillingPlan,
                                      planTitle: e,
                                    })
                                }
                              />
                            </Col>
                            <Col span={12}></Col>
                          </>
                        )}

                        {value === 2 && (
                          <>
                            <Col span={12}>
                              <InputGroup
                                title='Pause Billing Unit(min)'
                                name='Pause Billing Unit(min)'
                                value={dataBillingPlan.pauseBillingUnit}
                                onChange={(e) =>
                                    setDataBillingPlan({
                                      ...dataBillingPlan,
                                      pauseBillingUnit: Number(e),
                                    })
                                }
                                placeHolder='Enter Pause Billing Unit'
                              />
                            </Col>
                            <Col span={12}>
                              <InputGroup
                                title='Pause First Unit Fee'
                                name='Pause First Unit Fee'
                                value={dataBillingPlan?.pauseFirstBillingUnit}
                                onChange={(e) =>
                                    setDataBillingPlan({
                                      ...dataBillingPlan,
                                      pauseFirstBillingUnit: Number(e),
                                    })
                                }
                                placeHolder='Enter Pause First Unit Fee'
                              />
                            </Col>
                            <Col span={12}>
                              <InputGroup
                                title='Pause Next Unit Fee'
                                name='Pause Next Unit Fee'
                                value={dataBillingPlan?.pauseNextBillingUnit}
                                onChange={(e) =>
                                    setDataBillingPlan({
                                      ...dataBillingPlan,
                                      pauseNextBillingUnit: Number(e),
                                    })
                                }
                                placeHolder='Enter Pause Next Unit Fee'
                              />
                            </Col>
                            <Col span={12}>
                              <InputGroup
                                title='Plan Title'
                                name='Plan Title'
                                value={dataBillingPlan?.planTitle}
                                onChange={(e) =>
                                    setDataBillingPlan({
                                      ...dataBillingPlan,
                                      planTitle: e,
                                    })
                                }
                                placeHolder='Enter Plan Title'
                              />
                            </Col>
                          </>
                        )}
                        <Col style={{marginTop: '1rem'}} span={12}>
                          <span
                            className='org_address'
                            style={{marginRight: '1rem'}}
                          >
                            Is Card Mandatory?
                          </span>
                          <Radio.Group>
                            <Radio value={3}>Yes</Radio>
                            <Radio value={4}>No</Radio>
                          </Radio.Group>
                        </Col>
                        <Col style={{marginTop: '1rem'}} span={12}>
                          <span
                            className='org_address'
                            style={{marginRight: '1rem'}}
                          >
                            Is Pre Auth Required?
                          </span>
                          <Radio.Group>
                            <Radio value={5}>Yes</Radio>
                            <Radio value={6}>No</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={24} style={{marginTop: '1rem'}}>
                          <p>
                            Please enter amount as per the Stripe minimum
                            supported currencies
                          </p>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
                      <br />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <BBtn
                          title='Back'
                          color='#FF5C6A'
                          onClick={() => setDataBillingPlan(initialState as BillingPlanModel)}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <BBtn title='Delete' color='#FF5C6A' />
                          <div style={{width: '16px'}}></div>
                          <BBtn title='Save' onClick={handleEdit} color='#24BB69' />
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col span={24} style={{marginTop: '3rem'}}>
                <div>
                  <div style={{display: 'flex'}}>
                    <p className='org_title'>Timeline</p>
                    {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                  </div>
                  <Row gutter={16} style={{paddingLeft: '2rem'}}>
                    <Col span={8}>
                      <Steps direction='vertical' current={1}>
                        <Steps.Step
                          icon={
                            <i
                              className='fa-solid fa-tag'
                              style={{
                                color: '#333',
                                fontSize: '40px',
                              }}
                            ></i>
                          }
                          title='Finished'
                          description='This is a description.'
                        />
                        <Steps.Step
                          icon={
                            <i
                              className='fa-solid fa-tag'
                              style={{
                                color: '#333',
                                fontSize: '40px',
                              }}
                            ></i>
                          }
                          title='Finished'
                          description='This is a description.'
                        />
                        <Steps.Step
                          icon={
                            <i
                              className='fa-solid fa-tag'
                              style={{
                                color: '#333',
                                fontSize: '40px',
                              }}
                            ></i>
                          }
                          title='Finished'
                          description='This is a description.'
                        />
                      </Steps>
                    </Col>
                  </Row>
                </div>
              </Col>
            </>
          )}
        </Row>
      ) : (
        <>
          <Col span={12}>
            <div
              style={{
                width: '100%',
                backgroundColor: 'white',
                padding: '1rem',
              }}
            >
              <Col span={24} className='gutter-row'>
                <div>
                  <p className='org_p1'>Add Pricing</p>
                  <Row gutter={16}>
                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title='Name'
                        name='Name'
                        value={dataBillingPlan?.name}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, name: e} as any)
                        }
                        placeHolder='Enter name'
                      />
                    </Col>

                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title='Billing Unit (mins)'
                        name='Billing Unit (mins)'
                        value={dataBillingPlan?.billingUnit}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, billingUnit: Number(e)} as any)
                        }
                        placeHolder='Enter billing unit'
                        type='number'
                      />
                    </Col>
                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title='First Unit Fee '
                        name='First Unit Fee '
                        value={dataBillingPlan?.firstBillingUnitFree}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, firstBillingUnitFree: Number(e)} as any)
                        }
                        placeHolder='Enter first unit fee'
                        type='number'
                      />
                    </Col>

                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title='Next Unit Fee '
                        name='Next Unit Fee '
                        value={dataBillingPlan?.nextBillingUnitFree}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, nextBillingUnitFree: Number(e)} as any)
                        }
                        placeHolder='Enter next unit fee'
                        type='number'
                      />
                    </Col>
                    <Col span={12}>
                      <span className='org_address'>Tax</span>
                      <ISelect
                        option={[{text :'yes', value : 'yes' },{text :'no', value : 'no' }]}
                        name='Tax'
                        value={dataBillingPlan?.isTax ? 'yes' : 'no'}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, isTax: e === 'yes'} as any)
                        }
                        placeHolder='Tax'
                      />
                    </Col>
                    <Col span={12}>
                      <InputGroup
                        required={true}
                        title='Tax Percentage'
                        name='Tax Percentage'
                        value={dataBillingPlan?.taxPercentage}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, taxPercentage: Number(e)} as any)
                        }
                        type='number'
                      />
                    </Col>

                    <Col span={12}>
                      <span className='org_address'>Status</span>
                      <ISelect
                        option={[{text :'Active', value : 'Active' },{text :'Deactive', value : 'Deactive' }]}
                        name='Status'
                        value={dataBillingPlan?.status ? 'Active' : 'Deactive'}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, status: e === 'Active'} as any)
                        }
                        placeHolder='Status'
                      />
                    </Col>

                    <Col span={12}>
                      <InputGroup
                        title='Max Billing Price Per Ride'
                        name='Max Billing Price Per Ride'
                        value={dataBillingPlan?.maxBillingPrice}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, maxBillingPrice: Number(e)} as any)
                        }
                        placeHolder='Enter max billing price'
                      />
                    </Col>

                    <Col span={12}>
                      <InputGroup
                        required
                        title='Description'
                        name='Description'
                        value={dataBillingPlan?.description}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, description: e} as any)
                        }
                      />
                    </Col>
                    <Col style={{marginTop: '1rem'}} span={12}>
                      <span
                        className='org_address'
                        style={{marginRight: '1rem'}}
                      >
                        Is the pause ride fee same as above?
                      </span>
                      <Radio.Group onChange={onChange} value={dataBillingPlan.feePauseRide}>
                        <Radio value={false}>Yes</Radio>
                        <Radio value={true}>No</Radio>
                      </Radio.Group>
                    </Col>
                    <Col span={12}>
                      <span className='org_address'>Tax Type *</span>
                      <Select
                        mode='multiple'
                        allowClear
                        style={{width: '100%'}}
                        placeholder='Please select'
                        // defaultValue={['a10', 'c12']}
                        onChange={handleChangeTaxType}
                      >
                        {listSelect}
                      </Select>
                    </Col>
                    <Col span={12}>
                      <InputGroup
                        title='Vehicle reservation price'
                        name='Vehicle reservation price'
                        type='number'
                        value={dataBillingPlan?.vehiclesReservedPrice}
                        onChange={(e) =>
                            setDataBillingPlan({...dataBillingPlan, vehiclesReservedPrice: Number(e)} as any)
                        }
                      />
                    </Col>

                    {!dataBillingPlan.feePauseRide ?  (
                      <>
                        <Col span={12}>
                          <InputGroup
                            title='Plan Title'
                            name='Plan Title'
                            value={dataBillingPlan?.planTitle}
                            onChange={(e) =>
                                setDataBillingPlan({...dataBillingPlan, planTitle: e} as any)
                            }
                          />
                        </Col>
                        <Col span={12}></Col>
                      </>
                    ) : (
                        <>
                          <Col span={12}>
                            <InputGroup
                                title='Pause Billing Unit(min)'
                                name='Pause Billing Unit(min)'
                                value={dataBillingPlan?.pauseBillingUnit}
                                onChange={(e) =>
                                    setDataBillingPlan({...dataBillingPlan, pauseBillingUnit: Number(e)} as any)
                                }
                                placeHolder='Enter Pause Billing Unit'
                            />
                          </Col>
                          <Col span={12}>
                            <InputGroup
                                title='Pause First Unit Fee'
                                name='Pause First Unit Fee'
                                value={dataBillingPlan?.pauseFirstBillingUnit}
                                onChange={(e) =>
                                    setDataBillingPlan({...dataBillingPlan, pauseFirstBillingUnit: Number(e)} as any)
                                }
                                placeHolder='Enter Pause First Unit Fee'
                            />
                          </Col>
                          <Col span={12}>
                            <InputGroup
                                title='Pause Next Unit Fee'
                                name='Pause Next Unit Fee'
                                value={dataBillingPlan?.pauseNextBillingUnit}
                                onChange={(e) =>
                                    setDataBillingPlan({...dataBillingPlan, pauseNextBillingUnit: Number(e)} as any)
                                }
                                placeHolder='Enter Pause Next Unit Fee'
                            />
                          </Col>
                          <Col span={12}>
                            <InputGroup
                                title='Plan Title'
                                name='Plan Title'
                                value={dataBillingPlan?.planTitle}
                                onChange={(e) =>
                                    setDataBillingPlan({...dataBillingPlan, planTitle: e} as any)
                                }
                                placeHolder='Enter Plan Title'
                            />
                          </Col>
                        </>
                    ) }

                    {/*{value === 2 && (*/}
                    {/*  <>*/}
                    {/*    <Col span={12}>*/}
                    {/*      <InputGroup*/}
                    {/*        title='Pause Billing Unit(min)'*/}
                    {/*        name='Pause Billing Unit(min)'*/}
                    {/*        value={dataBillingPlan?.pauseBillingUnit}*/}
                    {/*        onChange={(e) =>*/}
                    {/*            setDataBillingPlan({...dataBillingPlan, pauseBillingUnit: e} as any)*/}
                    {/*        }*/}
                    {/*        placeHolder='Enter Pause Billing Unit'*/}
                    {/*      />*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*      <InputGroup*/}
                    {/*        title='Pause First Unit Fee'*/}
                    {/*        name='Pause First Unit Fee'*/}
                    {/*        value={dataBillingPlan?.pauseFirstBillingUnit}*/}
                    {/*        onChange={(e) =>*/}
                    {/*            setDataBillingPlan({...dataBillingPlan, pauseFirstBillingUnit: e} as any)*/}
                    {/*        }*/}
                    {/*        placeHolder='Enter Pause First Unit Fee'*/}
                    {/*      />*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*      <InputGroup*/}
                    {/*        title='Pause Next Unit Fee'*/}
                    {/*        name='Pause Next Unit Fee'*/}
                    {/*        value={dataBillingPlan?.pauseNextBillingUnit}*/}
                    {/*        onChange={(e) =>*/}
                    {/*            setDataBillingPlan({...dataBillingPlan, pauseNextBillingUnit: e} as any)*/}
                    {/*        }*/}
                    {/*        placeHolder='Enter Pause Next Unit Fee'*/}
                    {/*      />*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*      <InputGroup*/}
                    {/*        title='Plan Title'*/}
                    {/*        name='Plan Title'*/}
                    {/*        value={dataBillingPlan?.planTitle}*/}
                    {/*        onChange={(e) =>*/}
                    {/*            setDataBillingPlan({...dataBillingPlan, planTitle: e} as any)*/}
                    {/*        }*/}
                    {/*        placeHolder='Enter Plan Title'*/}
                    {/*      />*/}
                    {/*    </Col>*/}
                    {/*  </>*/}
                    {/*)}*/}

                    <Col style={{marginTop: '1rem'}} span={12}>
                      <span
                        className='org_address'
                        style={{marginRight: '1rem'}}
                      >
                        Is Card Mandatory?
                      </span>
                      <Radio.Group  value ={dataBillingPlan?.isCardMandatory} onChange={(e)=>{
                        setDataBillingPlan({...dataBillingPlan, isCardMandatory: e.target.value} as any)
                      }
                      }>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>
                    <Col style={{marginTop: '1rem'}} span={12}>
                      <span
                        className='org_address'
                        style={{marginRight: '1rem'}}
                      >
                        Is Pre Auth Required?
                      </span>
                      <Radio.Group  value={dataBillingPlan?.isPreAuth} onChange={(e)=>{
                        setDataBillingPlan({...dataBillingPlan, isPreAuth: e.target.value} as any)
                      }
                      }>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>
                    <Col span={24} style={{marginTop: '1rem'}}>
                      <p>
                        Please enter amount as per the Stripe minimum supported
                        currencies
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <Row style={{borderTop: '1px solid #E1E1E1'}}></Row>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <BBtn
                      title='Back'
                      color='#FF5C6A'
                      onClick={() => {
                        setAddPricing(false)
                        setDataBillingPlan(initialState as BillingPlanModel)
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <BBtn title='Save' color='#24BB69' onClick={handleCreateBilling} />
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </>
      )}
    </div>
  )
}

export default BillingPlan
