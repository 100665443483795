import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {PaginationModel, VehicleFilter} from "../../models/paginationModel";
import { vehicleModel } from "../../models/vehicleModel";

export interface VehicleState {
  loadding: boolean;
  listVehicle: vehicleModel[] | undefined;
  listVehicleHistory ?: any[] | undefined;
  itemCountHistory ?: number;
  itemCount: number | undefined;
  vehicle: vehicleModel | undefined;
  type: { key: string; value: string }[] | undefined;
  error : any
}

const initialState: VehicleState = {
  loadding: false,
  listVehicle: undefined,
  listVehicleHistory: undefined,
  itemCountHistory : undefined,
  itemCount: undefined,
  vehicle: undefined,
  type: undefined,
  error : undefined
};

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    getListVehicle(state, action: PayloadAction<VehicleFilter>) {
      state.loadding = true;
    },
    getAllVehicleHistory(state, action: PayloadAction<VehicleFilter>) {
      state.loadding = true;
    },
    getListVehicleSuccess(state, action: PayloadAction<vehicleModel[]>) {
      state.listVehicle = action.payload;
      state.loadding = false;
    },
    getListVehicleFail(state) {
      state.loadding = false;
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload;
    },
    setItemCountHistory(state, action: PayloadAction<number>) {
      state.itemCountHistory = action.payload;
    },
    updateVehicle(state, action: PayloadAction<Partial<vehicleModel>>) {
      state.loadding = true;
    },
    updateFlag(state, action: PayloadAction<Partial<vehicleModel>>) {
      state.loadding = true;
    },
    updateConfigure(state, action: PayloadAction<Partial<vehicleModel>>) {
      state.loadding = true;
    },
    actionLock(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    success(state) {
      state.loadding = false;
    },
    fail(state, action : PayloadAction<any>) {
      state.loadding = false;
      state.error = action.payload
    },
    addVehicle(state, action: PayloadAction<Partial<vehicleModel>>) {
      state.loadding = true;
    },
    detailVehicle(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    detailVehicleSuccess(state, action: PayloadAction<vehicleModel>) {
      state.loadding = false;
      state.vehicle = action.payload;
    },
    deleteVehicle(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    getTypeVehicle(state) {
      state.loadding = true;
    },
    getTypeVehicleSuccess(
      state,
      action: PayloadAction<{ key: string; value: string }[]>,
    ) {
      state.loadding = false;
      state.type = action.payload;
    },
    resetError(state) {
      state.error = undefined
    },
    refreshLocation(state, action: PayloadAction<{ id: string }>) {
      state.loadding = true;
    },
    getListVehicleHistorySuccess(state, action: PayloadAction<any[]>) {
      state.listVehicleHistory = action.payload;
      state.loadding = false;
    },
    getListVehicleHistoryFail(state) {
      state.loadding = false;
    },
  },
});
export const vehicleAction = vehicleSlice.actions;
export const selectItemCount = (state: RootState) => state.vehicle.itemCount;
export const selectItemCountHistory = (state: RootState) => state.vehicle.itemCountHistory;
export const selectListVehicle = (state: RootState) =>
  state.vehicle.listVehicle;
export const selectListVehicleHistory = (state: RootState) =>
  state.vehicle.listVehicleHistory;
export const selectVehicle = (state: RootState) => state.vehicle.vehicle;
export const selectTypeVehicle = (state: RootState) => state.vehicle.type;
export const selectLoading = (state: RootState) => state.vehicle.loadding;
export const selectError = (state: RootState) => state.vehicle.error;

export const vehicleReducer = vehicleSlice.reducer;
