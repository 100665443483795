import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import {PaginationModel, VehicleFilter} from "../../models/paginationModel";
import { vehicleModel } from "../../models/vehicleModel";
import { vehicleAction } from "./vehicleSlice";
import { vehicleApi } from "../../apis/vehicleApi";

function* getListVehicle(action: PayloadAction<VehicleFilter>) {
  try {
    const listVehicle: {
      data: {
        nodes: vehicleModel[];
        itemCount: number;
      };
    } = yield call(vehicleApi.getAllVehicle, action.payload);
    yield put(vehicleAction.getListVehicleSuccess(listVehicle.data.nodes));
    yield put(vehicleAction.setItemCount(listVehicle.data.itemCount));
  } catch (error) {
    console.log(error);
  }
}
function* getAllVehicleHistory(action: PayloadAction<VehicleFilter>) {
  try {
    const listVehicle: {
      data: {
        nodes: any[];
        itemCount: number;
      };
    } = yield call(vehicleApi.getAllVehicleHistory, action.payload);
    yield put(vehicleAction.getListVehicleHistorySuccess(listVehicle.data.nodes));
    yield put(vehicleAction.setItemCountHistory(listVehicle.data.itemCount));
  } catch (error) {
    console.log(error);
  }
}
function* updateVehicle(action: PayloadAction<Partial<vehicleModel>>) {
  try {
    const vehicle: { description: string } = yield call(
      vehicleApi.updateVehicle,
      action.payload,
    );
    yield put(vehicleAction.success());
    message.success(vehicle.description).then();
  } catch (error: any) {
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* addVehicle(action: PayloadAction<Partial<vehicleModel>>) {
  try {
    const vehicle: { description: string } = yield call(
      vehicleApi.addVehicle,
      action.payload,
    );
    message.success(vehicle.description).then();
    yield put(vehicleAction.success());
  } catch (error: any) {
    // error.response.data.message &&
    //   message.error(error.response.data.message).then();
    // error.response.data.msg && message.error(error.response.data.msg).then();
    yield put(vehicleAction.fail(error));
    // Array.isArray(error.response.data.message) &&
    //   error.response.data.message.forEach((item: string) => {
    //     message.error(item).then();
    //   });
  }
}
function* detailVehicle(action: PayloadAction<{ id: string }>) {
  try {
    const vehicle: { description: string; data: vehicleModel } = yield call(
      vehicleApi.detailVehicle,
      action.payload.id,
    );
    yield put(vehicleAction.detailVehicleSuccess(vehicle.data));
    message.success(vehicle.description).then();
  } catch (error: any) {
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* getTypeVehicle() {
  try {
    const vehicle: {
      description: string;
      data: { key: string; value: string }[];
    } = yield call(vehicleApi.getTypeVehicle);
    yield put(vehicleAction.getTypeVehicleSuccess(vehicle.data));
  } catch (error: any) {
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* deleteVehicle(action: PayloadAction<{ id: string }>) {
  try {
    const vehicle: { description: string } = yield call(
      vehicleApi.deleteVehicle,
      action.payload.id,
    );
    message.success(vehicle.description).then();
    yield put(vehicleAction.success());
  } catch (error: any) {
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* updateFlag(action: PayloadAction<Partial<vehicleModel>>) {
  try {
    const vehicle: { description: string } = yield call(
      vehicleApi.updateFlag,
      action.payload,
    );
    yield put(vehicleAction.success());
    message.success(vehicle.description).then();
  } catch (error: any) {
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* updateConfigure(action: PayloadAction<Partial<vehicleModel>>) {
  try {
    const vehicle: { description: string } = yield call(
      vehicleApi.updateConfigure,
      action.payload,
    );
    yield put(vehicleAction.success());
    message.success(vehicle.description).then();
  } catch (error: any) {
    yield put(vehicleAction.fail(error));
    error.response.data.msg && message.error(error.response.data.msg).then();
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* actionLock(action: PayloadAction<{ id: string }>) {
  try {
    const vehicle: { description: string } = yield call(
      vehicleApi.actionLock,
      action.payload.id,
    );
    message.success(vehicle.description).then();
    yield put(vehicleAction.success());
  } catch (error: any) {
    error.response.data.message &&
      message.error(error.response.data.message).then();
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}

function* refreshLocation(action: PayloadAction<{ id: string }>) {
  try {
    const vehicle: { description: string } = yield call(
        vehicleApi.refreshLocation,
        action.payload.id,
    );
    message.success(vehicle.description).then();
    yield put(vehicleAction.success());
  } catch (error: any) {
    error.response.data.message &&
    message.error(error.response.data.message).then();
    yield put(vehicleAction.fail(error));
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then();
    });
  }
}

export default function* vehicleSaga() {
  yield takeEvery(vehicleAction.getListVehicle.type, getListVehicle);
  yield takeEvery(vehicleAction.getAllVehicleHistory.type, getAllVehicleHistory);
  yield takeEvery(vehicleAction.updateVehicle.type, updateVehicle);
  yield takeEvery(vehicleAction.addVehicle.type, addVehicle);
  yield takeEvery(vehicleAction.detailVehicle.type, detailVehicle);
  yield takeEvery(vehicleAction.deleteVehicle.type, deleteVehicle);
  yield takeEvery(vehicleAction.updateFlag.type, updateFlag);
  yield takeEvery(vehicleAction.updateConfigure.type, updateConfigure);
  yield takeEvery(vehicleAction.getTypeVehicle.type, getTypeVehicle);
  yield takeEvery(vehicleAction.actionLock.type, actionLock);
  yield takeEvery(vehicleAction.refreshLocation.type, refreshLocation);
}
