import {Url} from "../../routers/paths";

export const menuList : Record<string,string> = {
    '1' : Url.home,
    '2' : Url.oOrg,
    '3' : Url.oRole,
    '4' : Url.oPermission,
    '5' : Url.map,
    '6' : Url.vehiclesAll,
    '7' : Url.vehiclesReserved,
    '8' : Url.vehicleHistory,
    '9' : Url.geofenceParkingZones,
    '10' : Url.geofenceRestrictedZones,
    '11' : Url.geofenceServiceAreas,
    '12' : Url.geofenceSlowSpeedZones,
    '13' : Url.geofenceStations,
    '14' : Url.riders,
    '15' : Url.ridesCompleted,
    '16' : Url.ridesRunning,
    '17' : Url.payments,
    '18' : Url.pricingPaymentGateway,
    '19' : Url.pricingBillingPlan,
    '20' : Url.pricingTax,
    '21' : Url.pricingPromos,
    '22' : Url.pricingOffer,
    '23' : Url.supportTickets,
    '24' : Url.supportFeedbackOption,
    '25' : Url.campaignsNotifications,
    '26' : Url.campaignsBanner,
    '27' : Url.themes,
    '28' : Url.events,
    '29' : Url.setting,
    '30' : Url.versions,
    'sub2' : Url.fleets,
};

const swap = (json : Record<string, string>) => {
    const ret : Record<string, string> = {};
    for(let key in json){
        ret[json[key]] = key;
    }
    return ret;
}

export const menuListSwap : Record<string, string> = swap(menuList)