import { Modal } from "antd";
import React, {useEffect, useState, createElement, memo} from "react";
import BBtn from "../../button";
import "./mapVehicleDialog.css";
import moment from "moment";
import { FlagImg } from "../../../utils/constants";
import { vehicleModel } from "../../../models/vehicleModel";
import NaverMaps from "../../naverMaps";
import {renderToStaticMarkup} from "react-dom/server";
import CustomMaps from "../../customMaps";

function MapVehicleDialog(props: {
  open: boolean;
  setOpen: () => void;
  long: number;
  lat: number;
  lastConnect?: Date;
  refreshLocation?: () => void;
  data?: vehicleModel;
}) {
  const [url, setUrl] = useState<string>(FlagImg[`serviceStartEnd`]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleRefreshLocation = () => {
    props.refreshLocation && props.refreshLocation();
  };

  useEffect(() => {
    if (props.data["serviceStartEnd"] && props.data.locked) {
      setUrl(FlagImg[`parked_vehicle`]);
    }
    if (props.data["serviceStartEnd"] && !props.data.locked) {
      setUrl(
        FlagImg["riding"],
      );
    }
    if (props.data["missing"]) {
      setUrl(FlagImg[`missing`]);
    }
    if (props.data["stolen"]) {
      setUrl(FlagImg[`stolen`]);
    }
    if (props.data["agencyPickup"]) {
      setUrl(FlagImg[`agencyPickup`]);
    }
    if (props.data["batteryCritical"]) {
      setUrl(FlagImg[`batteryCritical`]);
    }
    if (props.data["ioTFault"]) {
      setUrl(FlagImg[`ioTFault`]);
    }
    if (props.data["maintenancePickup"]) {
      setUrl(FlagImg[`maintenancePickup`]);
    }
    if (props.data["reBalancePickup"]) {
      setUrl(FlagImg[`reBalancePickup`]);
    }
    if (props.data["rented"]) {
      setUrl(FlagImg[`rented`]);
    }
    if (props.data["usingService"]) {
      setUrl(FlagImg[`usingService`]);
    }
  }, [props.data]);


  return (
    <>
      <Modal
        title="Vehicle Map"
        open={props.open}
        confirmLoading={confirmLoading}
        okText={<></>}
        cancelText={<></>}
        onCancel={() => props.setOpen()}
        width={600}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: 500,
          }}
        >
          <div
            style={{
              height: "60px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <span>{`Location Time : ${moment(props.lastConnect).format(
              "YYYY/MM/DD HH:mm:ss",
            )}`}</span>
            <BBtn
              title="Refresh Location"
              color="#3c8dbc"
              icon1={<i className="fa fa-refresh"></i>}
              onClick={handleRefreshLocation}
            />
          </div>
          <CustomMaps
            mapsName="mapVehicleDialog"
            isDrawing={false}
            mapStyle={{ flex: 1, borderRadius: "5px" }}
            latitude={props.lat}
            longitude={props.long}
            zoom={16}
            markers={[
              {
                icon: {
                  url,
                  size: {
                    width: 40,
                    height: 40,
                  },
                },
                // animation :naver.maps.Animation.BOUNCE,
                onClick : () => {
                  console.log(123)
                },
                clickable: true,
                title : `${props.data.imei} ${moment(props.lastConnect).format(
                    "YYYY/MM/DD HH:mm:ss",
                )}`,
                longitude: props.long,
                latitude: props.lat,
              },
            ]}
          />
        </div>
      </Modal>
    </>
  );
}

export default memo(MapVehicleDialog)