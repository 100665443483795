import {Col, Row} from 'antd'
import React, {ReactNode} from 'react'
import Images from '../../assets/gen'
import './cardCompleted.css'

const CardCompleted = (props: {
  name: string
  value: string
  icon: React.ReactNode
}) => {
  return (
    <Row
      style={{
        width: '100%',
        border: '1px solid #E1E1E1',
        padding: '1rem',
        borderRadius: '5px',
        backgroundColor: 'white',
      }}
    >
      <Col span={4} style={{fontSize: '26px', color: '#FF5C6A'}}>
        {/* <img src={Images.diagram} alt='diagram' /> */}
        {props.icon}
      </Col>
      <Col span={20}>
        <p className='cardGeneralFleet_p' style={{margin: 0}}>
          {props.value}
        </p>
        <p className='cardGeneralFleet_p1' style={{margin: 0}}>
          {props.name}
        </p>
      </Col>
    </Row>
  )
}

export default CardCompleted
