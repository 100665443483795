import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { PaginationModel } from "../models/paginationModel";
import {VersionModel} from "../models/version.model";

export const versionApi = {
  getVersion() {
    return axiosClient.getService(`${urlApi.VERSION}`);
  },
  updateVersion(params: Partial<VersionModel>) {
    return axiosClient.postService(`${urlApi.VERSION}`, {
      ...params,
    });
  },
};
