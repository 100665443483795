import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import { roleApi } from "../../apis/roleApi";
import { PermissionsModel } from "../../models/permissionsModel";
import { RoleModel } from "../../models/roleModel";
import { roleAction } from "./roleSlice";

function* getListRole() {
  try {
    const listRole: { data: RoleModel[] } = yield call(roleApi.getListRole);
    yield put(roleAction.getListRoleSuccess(listRole.data));
  } catch (error) {
    console.log(error);
  }
}

function* getlistRolePagination(
  action: PayloadAction<{
    page: number;
    limit: number;
    keyword?: string;
    "orderBy[id]"?: "ASC" | "DESC";
    "orderBy[name]"?: "ASC" | "DESC";
  }>
) {
  try {
    const listRole: {
      description: string;
      data: { nodes: RoleModel[]; itemCount: number };
    } = yield call(roleApi.getlistRolePagination, action.payload);
    yield put(roleAction.getlistRolePaginationSuccess(listRole.data.nodes));
    yield put(roleAction.setItemCount(listRole.data.itemCount));
  } catch (error) {
    console.log(error);
  }
}

function* updateRole(action: PayloadAction<{ id: string; name: string }>) {
  try {
    const role: { description: string } = yield call(
      roleApi.updateRole,
      action.payload
    );
    message.success(role.description);
  } catch (error) {
    console.log(error);
  }
}
function* addRole(action: PayloadAction<{ name: string }>) {
  try {
    const role: { description: string } = yield call(
      roleApi.addRole,
      action.payload
    );
    message.success(role.description);
  } catch (error) {
    console.log(error);
  }
}

function* getListPermissions() {
  try {
    const listPermissions: { data: PermissionsModel[] } = yield call(
      roleApi.getlistPermissions
    );
    yield put(roleAction.getAllPermisstionSuccess(listPermissions.data));
  } catch (error) {
    console.log(error);
  }
}
function* updatePermissions(
  action: PayloadAction<{ id: string; permission_id: string; type: boolean }>
) {
  try {
    const permission: { description: string } = yield call(
      roleApi.updatePermissions,
      action.payload
    );
    yield getListRole();
    message.success(permission.description);
  } catch (error) {
    console.log(error);
  }
}

export default function* roleSaga() {
  yield takeEvery(roleAction.getListRole.type, getListRole);
  yield takeEvery(roleAction.updateRole.type, updateRole);
  yield takeEvery(roleAction.addRole.type, addRole);
  yield takeEvery(roleAction.getAllPermisstion.type, getListPermissions);
  yield takeEvery(roleAction.updatePermissions.type, updatePermissions);
  yield takeEvery(roleAction.getlistRolePagination.type, getlistRolePagination);
}
