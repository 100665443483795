import {Col, Select} from 'antd'
import {SizeType} from 'antd/lib/config-provider/SizeContext'
import {Option} from 'antd/lib/mentions'
import React, {useState} from 'react'
import BBtn from '../../../components/button'
import ISearch from '../../../components/search'
import ITable from '../../../components/table'

const children: React.ReactNode[] = []
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
}
const VehicleReserved = () => {
  const [size, setSize] = useState<SizeType>('middle')
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`)
  }
  return (
    <div>
      <br />
      <div className='role_div1'>
        <p className='role_title'>Reserved Vehicles</p>
        <BBtn
          title='All Fleets'
          color='#FF5C6A'
          icon1={<i className='fa-solid fa-sort-down'></i>}
          icon1left={true}
        />
      </div>
      <Col span={24} className='gutter-row'>
        <div className='org_div1'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{marginRight: '1rem'}}>Show</span>
            <Select size={size} defaultValue={'Show'} onChange={handleChange}>
              {children}
            </Select>
            <span style={{marginLeft: '1rem', marginRight: '3rem'}}>
              Entries
            </span>
            <ISearch onChange={() => console.log(222)} />
          </div>
          <br />
          <ITable
            // onClickRow={(params: any) => setDataFleet(params)}
            column={[
              {
                title: 'User',
                dataIndex: 'user',
              },
              {
                title: 'Vehicle ID',
                dataIndex: 'vehicleID',
                // sorter: (a: any, b: any) => a.name.length - b.name.length,
              },
              {
                title: 'Vehicle Name',
                dataIndex: 'vehicleName',
              },

              {
                title: 'Qr Code',
                dataIndex: 'qrCode',
                // render: (text: string) => (
                //   <span
                //     style={{
                //       backgroundColor:
                //         text === 'Public' ? '#1EA85D' : '#F2B71E',
                //       color: 'white',
                //       padding: '0.2rem 0.5rem',
                //       borderRadius: '2px',
                //     }}
                //   >
                //     {text}
                //   </span>
                // ),
              },
              {
                title: 'Reservation Start Date',
                dataIndex: 'reservationStartDate',
              },
              {
                title: 'Battery(%)',
                dataIndex: 'battery',
              },
              {
                title: 'Enabled Flags',
                dataIndex: 'enabledFlags',
              },
              {
                title: 'Release',
                dataIndex: 'release',
              },
            ]}
            total={100}
            data={[
              {
                key: '1',
                id: '1',
                name: 'sadsda',
                supportedEmail: 'alma.lawson@gmail.com',
                visibility: 'Private',
                location: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
                billing: 'Joyride plan',
                createdDate: '2014/12/24 23:12:00',
              },
            ]}
            pageSize={20}
            onChangePage={(page) => {
              console.log('page', page)
            }}
          />
        </div>
      </Col>
    </div>
  )
}

export default VehicleReserved
