import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import { feedbackOptionAction } from "./feedbackOptionSlice";
import { feedbackOptionApi } from "../../apis/feedbackOptionApi";
import { FeedbackOptionModel } from "../../models/feedbackOption.model";
import { PaginationModel } from "../../models/paginationModel";

function* getListFeedbackOption(action: PayloadAction<PaginationModel>) {
  try {
    const listFeedbackOption: {
      data: {
        nodes: FeedbackOptionModel[];
        itemCount: number;
      };
    } = yield call(feedbackOptionApi.getAllFeedbackOption, action.payload);
    yield put(
      feedbackOptionAction.getListFeedbackOptionSuccess(
        listFeedbackOption.data.nodes
      )
    );
    yield put(
      feedbackOptionAction.setItemCount(listFeedbackOption.data.itemCount)
    );
  } catch (error) {
    console.log(error);
  }
}
function* updateFeedbackOption(
  action: PayloadAction<Partial<FeedbackOptionModel>>
) {
  try {
    const feedbackOption: { description: string } = yield call(
      feedbackOptionApi.updateFeedbackOption,
      action.payload
    );
    yield put(feedbackOptionAction.success());
    message.success(feedbackOption.description).then();
  } catch (error: any) {
    yield put(feedbackOptionAction.fail());
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* addFeedbackOption(
  action: PayloadAction<Partial<FeedbackOptionModel>>
) {
  try {
    const feedbackOption: { description: string } = yield call(
      feedbackOptionApi.createFeedbackOption,
      action.payload
    );
    message.success(feedbackOption.description).then();
    yield put(feedbackOptionAction.success());
  } catch (error: any) {
    yield put(feedbackOptionAction.fail());
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* detailFeedbackOption(action: PayloadAction<{ id: string }>) {
  try {
    const feedbackOption: { description: string; data: FeedbackOptionModel } =
      yield call(feedbackOptionApi.detailFeedbackOption, action.payload.id);
    yield put(
      feedbackOptionAction.detailFeedbackOptionSuccess(feedbackOption.data)
    );
    message.success(feedbackOption.description).then();
  } catch (error: any) {
    yield put(feedbackOptionAction.fail());
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}
function* deleteFeedbackOption(action: PayloadAction<{ id: string }>) {
  try {
    const feedbackOption: { description: string } = yield call(
      feedbackOptionApi.deleteFeedbackOption,
      action.payload.id
    );
    console.log("feedbackOption", feedbackOption);

    message.success(feedbackOption.description).then();
    yield put(feedbackOptionAction.success());
  } catch (error: any) {
    yield put(feedbackOptionAction.fail());
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then();
      });
  }
}

export default function* feedbackOptionSaga() {
  yield takeEvery(
    feedbackOptionAction.getListFeedbackOption.type,
    getListFeedbackOption
  );
  yield takeEvery(
    feedbackOptionAction.updateFeedbackOption.type,
    updateFeedbackOption
  );
  yield takeEvery(
    feedbackOptionAction.addFeedbackOption.type,
    addFeedbackOption
  );
  yield takeEvery(
    feedbackOptionAction.detailFeedbackOption.type,
    detailFeedbackOption
  );
  yield takeEvery(
    feedbackOptionAction.deleteFeedbackOption.type,
    deleteFeedbackOption
  );
}
