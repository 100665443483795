import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {User} from "../models/userModel";

export const riderApi = {
    getAllRider(params: {
        page: number | undefined,
        limit: number | undefined,
        keyword?: string,
        fleet?: string,
        payment_card?: boolean,
        status?: 'ACTIVE' | 'DE_ACTIVE',
        'orderBy[id]'?: 'ASC' | 'DESC',
        'orderBy[name]'?: 'ASC' | 'DESC',
    }) {
        return axiosClient.getService(`${urlApi.RIDER}`, {...params})
    },
    updateRider(params: Partial<User>) {
        return axiosClient.updateService(`${urlApi.RIDER}/${params._id}`, {...params})
    },
    deleteRider(id: string) {
        return axiosClient.deleteService(`${urlApi.RIDER}/${id}`)
    },
    restoreRider(id: string) {
        return axiosClient.updateService(`${urlApi.RIDER}/restore/${id}`)
    },
    getCsv(params: {
        page: number | undefined,
        limit: number | undefined,
        keyword?: string,
        fleet?: string,
        payment_card?: boolean,
        status?: 'ACTIVE' | 'DE_ACTIVE',
        'orderBy[id]'?: 'ASC' | 'DESC',
        'orderBy[name]'?: 'ASC' | 'DESC',
    }) {
        return axiosClient.getService(`${urlApi.RIDER}/export-csv`, {...params})
    },
}
