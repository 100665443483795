import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {VersionModel} from "../../models/version.model";
import {versionAction} from "./versionSlice";
import {versionApi} from "../../apis/versionApi";

function* updateVersion(
    action: PayloadAction<Partial<VersionModel>>
) {
    try {
        const version: {description: string} = yield call(
            versionApi.updateVersion,
            action.payload
        )
        yield put (versionAction.success())
        message.success(version.description).then()
    } catch (error: any) {
        yield put (versionAction.fail())
        Array.isArray(error.response.data.message) &&
        error.response.data.message.forEach((item: string) => {
            message.error(item).then()
        })
    }
}

function* detailVersion() {
    try {
        const version: {description: string , data : VersionModel} = yield call(versionApi.getVersion)
        yield put (versionAction.detailVersionSuccess(version.data))
    } catch (error: any) {
        yield put (versionAction.fail())
        Array.isArray(error.response.data.message) &&
        error.response.data.message.forEach((item: string) => {
            message.error(item).then()
        })
    }
}



export default function* versionSaga() {
    yield takeEvery(versionAction.updateVersion.type, updateVersion)
    yield takeEvery(versionAction.detailVersion.type, detailVersion)
}
