import React, {FC, useState} from "react";
import {AreaModel, LOW_SPEED_TYPE} from "../../../../models/area.model";
import {useAppDispatch} from "../../../../app/hooks";
import {Checkbox, Col, message, Row, Switch} from "antd";
import {areaAction} from "../../../../features/area/areaSlice";
import ISelect from "../../../../components/select";
import InputGroup from "../../../../components/textInput";
import TextArea from "antd/lib/input/TextArea";
import BBtn from "../../../../components/button";

interface InputAddAreaSlowProps {
    listOptionFleet: { text: string, value: any }[]
    handleGetPolygon: () => any
    setAddSlowArea: React.Dispatch<React.SetStateAction<boolean>>
    getListArea: () => void
}
export const InputAddAreaSlow: FC<InputAddAreaSlowProps> = ({getListArea, setAddSlowArea,listOptionFleet,handleGetPolygon}) => {
    const [dataSlowArea, setDataSlowArea] = useState<AreaModel>({} as AreaModel);
    const dispatch = useAppDispatch();
    const handleAdd = () => {
        const location = handleGetPolygon()
        if (Object.keys(dataSlowArea).length){
            const dataAdd = {
                ...dataSlowArea,
                type : "SLOW" ,
                triggerAlarm: !!dataSlowArea.triggerAlarm,
                throttle: !!dataSlowArea.throttle,
                fleet: dataSlowArea.fleet._id,
                coordinates :location&& location.length ? [location[0].geometry.coordinates[0].concat([location[0].geometry.coordinates[0][0]])] : [[[]]]
            }
            if (location &&location.length > 1){
                message.error("Can not create more than one polygon").then();
            }
            dispatch(areaAction.addArea(dataAdd))
            getListArea()
            setAddSlowArea(false)
            setDataSlowArea({} as AreaModel)
        }
    }
    return (
        <div style={{ backgroundColor: "white", padding: "1rem" }}>
            <p className="role_title">Add Slow Speed Zones</p>
            <Row gutter={16}>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Fleet</p>
                    <ISelect
                        name="Fleet"
                        option={listOptionFleet}
                        onChange={(e) =>
                            setDataSlowArea((prestate) => ({
                                ...prestate,
                                fleet: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12}>
                    <InputGroup
                        required={true}
                        title="Name"
                        name="Name"
                        onChange={(e) =>
                            setDataSlowArea((prestate) => ({
                                ...prestate,
                                name: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Type</p>
                    <ISelect
                        name="Type"
                        option={[
                            { text: "circle", value: "circle" },
                            {
                                text: "Point",
                                value: "Point",
                            },
                            { text: "Polygon", value: "Polygon" },
                        ]}
                        onChange={(e) =>
                            setDataSlowArea((prestate) => ({
                                ...prestate,
                                locationType: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12} style={{ paddingTop: "0.4rem" }}>
                    <p className="fleets-p1">Speed Mode</p>
                    <ISelect
                        name="Speed Mode"
                        option={[
                            { text: "NONE", value: LOW_SPEED_TYPE.NONE },
                            { text: "LOW", value: LOW_SPEED_TYPE.LOW },
                            { text: "MEDIUM", value: LOW_SPEED_TYPE.MEDIUM },
                            { text: "HIGH", value: LOW_SPEED_TYPE.HIGH },
                        ]}
                        onChange={(e) =>
                            setDataSlowArea((prestate) => ({
                                ...prestate,
                                speedMode: e,
                            }))
                        }
                    />
                </Col>
                <Col span={12} style={{ paddingTop: "1rem" }}>
                    <p className="fleets-p1">
                        Vehicles inside the restricted boundary should
                    </p>
                    <div>
                        <Checkbox
                            onChange={(e) =>
                                setDataSlowArea((prestate) => ({
                                    ...prestate,
                                    triggerAlarm: e.target.checked,
                                }))
                            }
                        >
                            Trigger Alarm
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <span className="org_address">Description</span>
                    <TextArea
                        maxLength={100}
                        style={{ height: 60, marginTop: "5px" }}
                        onChange={(e) =>
                            setDataSlowArea((prestate) => ({
                                ...prestate,
                                description: e.target.value,
                            }))
                        }
                    />
                </Col>
            </Row>
            <br />
            <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <BBtn
                    title="Back"
                    color="#FF5C6A"
                    onClick={() => setAddSlowArea(false)}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {/*<BBtn title='Deactivate' color='#FF5C6A' />*/}
                    {/*<div style={{width: '16px'}}></div>*/}
                    {/*<BBtn title='Delete' color='#FF5C6A' />*/}
                    {/*<div style={{width: '16px'}}></div>*/}
                    <BBtn
                        title="Save Changes"
                        onClick={handleAdd}
                        color="#24BB69"
                    />
                </div>
            </div>
        </div>
    );
};