import {
  Col,
  DatePicker,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Steps,
  Switch,
  Tooltip,
} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import {
  promotionAction,
  selectItemCount,
  selectListPromotion,
} from "../../../features/promotion/promotionSlice";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const Promos = () => {
  const dispatch = useAppDispatch();
  const listPromotions = useAppSelector(selectListPromotion);
  const itemCount = useAppSelector(selectItemCount);
  const [dataPromos, setDataPromos] = useState<any>();
  const [addPromos, setAddPromos] = useState(false);
  const [size, setSize] = useState<SizeType>("middle");
  const [dataPromoUser, setDataPromoUser] = useState<any>();
  const [dataPromoBatches, setDataPromoBatches] = useState<any>();
  const [limit, setLimit] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<any>();
  const dateFormat = "YYYY/MM/DD";
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
  };
  const [value, setValue] = useState(1);
  const onChangeSwitch = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  console.log(11111, dataPromoUser);
  useEffect(() => {
    dispatch(
      promotionAction.getListPromotion({
        limit,
        page,
        keyword,
      })
    );
  }, [limit, dispatch, page, keyword]);

  return (
    <div>
      <br />
      <Row justify="space-between">
        <span className="va_p_title">Promos</span>
        {!addPromos && (
          <BBtn
            title="Add Promos"
            icon1={<i className="fa-solid fa-plus"></i>}
            onClick={() => setAddPromos(true)}
          />
        )}
      </Row>

      <div style={{ height: "1rem" }}></div>
      {!addPromos ? (
        <Row gutter={24}>
          <Col span={dataPromos ? 12 : 24}>
            <div style={{ backgroundColor: "white", padding: "1rem" }}>
              <div
                style={{
                  display: dataPromos ? "initial" : "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1rem 0",
                    // marginLeft: '1rem',
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    value={limit.toString()}
                    defaultValue={"15"}
                    onChange={(e) => setLimit(Number(e))}
                  >
                    <Option value="15">15</Option>
                    <Option value="25">25</Option>
                    <Option value="50">50</Option>
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={(e) => setKeyword(e.target.value)} />
                </div>
              </div>
              <div>
                <ITable
                  onClickRow={(params: any) => setDataPromos(params)}
                  // rowSelection={true}
                  column={[
                    {
                      title: "Promo ID",
                      dataIndex: "id",
                    },
                    {
                      title: "Name",
                      dataIndex: "name",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "Type",
                      dataIndex: "type",
                    },
                    {
                      title: "Promo Code",
                      dataIndex: "promoCode",
                    },
                    {
                      title: "Promo Price",
                      dataIndex: "promoPrice",
                    },
                    {
                      title: "Date",
                      dataIndex: "date",
                    },
                  ]}
                  total={itemCount || 0}
                  data={listPromotions || []}
                  pageSize={limit}
                  currentPage={page}
                  onChangePage={(page) => {
                    setPage(page);
                  }}
                />
              </div>
            </div>
          </Col>
          {dataPromos && (
            <>
              <Col span={24} style={{ marginTop: "2rem" }}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    padding: "1rem",
                  }}
                >
                  <Col span={24} className="gutter-row">
                    <div>
                      <p className="org_p1">Edit Promos</p>
                      <Row gutter={16}>
                        <Col span={12}>
                          <span className="org_address">Promo Type *</span>
                          <ISelect
                            option={[
                              { text: "free rides", value: "free rides" },
                              { text: "free pass", value: "free pass" },
                            ]}
                            name="promoType"
                            onChange={() => console.log(1222)}
                            placeHolder="Promo Type"
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title="Name"
                            name="Name"
                            onChange={() => console.log(111)}
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            iInfor="Enter description to be displayed in the app"
                            required={true}
                            title="Promo Description"
                            name="promoDescription"
                            onChange={() => console.log(111)}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title="Promo Status"
                            name="promoStatus"
                            onChange={() => console.log(111)}
                          />
                        </Col>

                        <Col span={12}>
                          <span
                            className="org_address"
                            style={{ marginTop: "10px" }}
                          >
                            Promo Start Time *
                          </span>

                          <DatePicker
                            defaultValue={moment("2015/01/01", dateFormat)}
                            format={dateFormat}
                            style={{
                              width: "100%",
                              height: "40px",
                              marginTop: "4px",
                            }}
                          />
                        </Col>

                        <Col span={12}>
                          <span
                            className="org_address"
                            style={{ marginTop: "10px" }}
                          >
                            Promo End Time *
                          </span>

                          <DatePicker
                            defaultValue={moment("2015/01/01", dateFormat)}
                            format={dateFormat}
                            style={{
                              width: "100%",
                              height: "40px",
                              marginTop: "4px",
                            }}
                          />
                        </Col>

                        <Col span={12}>
                          <span className="org_address">Fleet</span>
                          <ISelect
                            option={[
                              { text: "Fleet1", value: "Fleet1" },
                              { text: "Fleet2", value: "Fleet2" },
                            ]}
                            name="fleet"
                            onChange={() => console.log(1222)}
                            placeHolder="fleet"
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            title="Promo Query strings"
                            name="promoQuerystrings"
                            onChange={() => console.log(111)}
                            placeHolder="Enter Promo Query strings"
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            iInfor="Total number of promos to be generated (validity * daily used)"
                            required
                            title="Max Used"
                            name="Max Used "
                            placeHolder="Enter max use"
                            onChange={() => console.log(111)}
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            iInfor="Number of promos to be used on a single ride (1 promo = 1 billing unit) 
                            Example: Billing unit = 30mins, Max Single Used = 2 will give user 60mins of free ride time"
                            required
                            title="Max Single Used "
                            name="Max Single Used "
                            placeHolder="Enter Max Single Used "
                            onChange={() => console.log(111)}
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            iInfor="Number of free rides in a day (if = 3, 
                              from Max Single Used example, user will get 3 rides of 
                              60mins each in a day for the validity duration defined)"
                            required
                            title="Daily Used"
                            name="Daily Used"
                            placeHolder="Enter Daily Used"
                            onChange={() => console.log(111)}
                          />
                        </Col>
                        <Col span={12}></Col>

                        <Col span={12}>
                          <span className="org_address">Show On App</span>
                          <ISelect
                            option={[
                              { text: "yes", value: "yes" },
                              { text: "no", value: "no" },
                            ]}
                            name="Show On App"
                            onChange={() => console.log(1222)}
                            placeHolder="Show On App"
                          />
                        </Col>

                        <Col span={12}>
                          <InputGroup
                            iInfor="Add the price to be paid for this promo"
                            required
                            title="Promo Price"
                            name="Promo Price"
                            placeHolder="Enter Promo Price"
                            onChange={() => console.log(111)}
                          />
                        </Col>

                        <Col style={{ marginTop: "1rem" }} span={12}>
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>Single</Radio>
                            <Radio value={2}>Multiple</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={12}></Col>

                        <Col span={12}>
                          {value === 1 && (
                            <InputGroup
                              title="Promo Code"
                              name="Promo Code"
                              placeHolder="Enter Promo Code"
                              onChange={() => console.log(111)}
                            />
                          )}
                          {value === 2 && (
                            <InputGroup
                              title="Generate Batch Code"
                              name="Generate Batch Code"
                              placeHolder="Enter Generate Batch Code"
                              onChange={() => console.log(111)}
                            />
                          )}
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                          <span className="org_address">
                            Usage Validity{" "}
                            <Tooltip
                              placement="right"
                              title="Promo would be valid for this many days from the time of purchase."
                            >
                              <i className="fa fa-info-circle"></i>
                            </Tooltip>
                          </span>
                          <ISelect
                            option={[
                              { text: "1 Day", value: "1 Day" },
                              { text: "2 Days", value: "2 Days" },
                            ]}
                            name="day"
                            onChange={() => console.log(1222)}
                            placeHolder="1 Day"
                          />
                        </Col>
                        <Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="org_address">
                            Promo expires at Promo End Time{" "}
                            <Tooltip
                              placement="right"
                              title="Promo would be valid for this many days from the time of purchase."
                            >
                              <i className="fa fa-info-circle"></i>
                            </Tooltip>
                          </span>
                          <Switch defaultChecked onChange={onChangeSwitch} />
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <BBtn
                          title="Back"
                          color="#FF5C6A"
                          onClick={() => setDataPromos(undefined)}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <BBtn title="Delete" color="#FF5C6A" />
                          <div style={{ width: "16px" }}></div>
                          <BBtn title="Save" color="#24BB69" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col span={24} style={{ margin: "3rem 0rem" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="va_p_title">Promo User</span>
                  <BBtn color="#00c853" title="Get CSV" />
                </div>

                <div style={{ marginBottom: "1rem" }}></div>
                <div style={{ backgroundColor: "white", padding: "1rem" }}>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "1rem 0",
                        // marginLeft: '1rem',
                      }}
                    >
                      <span style={{ marginRight: "1rem" }}>Show</span>
                      <Select
                        size={size}
                        defaultValue={"Show"}
                        onChange={handleChange}
                      >
                        {children}
                      </Select>
                      <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                        Entries
                      </span>
                      <ISearch onChange={() => console.log(222)} />
                    </div>
                  </div>
                  <div>
                    <ITable
                      onClickRow={(params: any) => setDataPromoUser(params)}
                      // rowSelection={true}
                      column={[
                        {
                          title: "User",
                          dataIndex: "user",
                        },
                        {
                          title: "phone number",
                          dataIndex: "phoneNumber",
                          // sorter: (a: any, b: any) => a.name.length - b.name.length,
                        },
                        {
                          title: "Purchase Date",
                          dataIndex: "purchaseDate",
                        },
                      ]}
                      total={100}
                      data={[
                        {
                          key: "60",
                          user: "user1",
                          phoneNumber: "0986786762",
                          purchaseDate: "2020/07/22 17:00:11",
                        },
                        {
                          key: "61",
                          user: "user2",
                          phoneNumber: "0986786762",
                          purchaseDate: "2020/07/22 17:00:11",
                        },
                      ]}
                      pageSize={20}
                      onChangePage={(page) => {
                        console.log("page", page);
                      }}
                      expandable
                      expandedRowColumn={[
                        {
                          title: "ID",
                          dataIndex: "ID",
                        },
                        {
                          title: "Ride ID",
                          dataIndex: "rideID",
                          // sorter: (a: any, b: any) => a.name.length - b.name.length,
                        },
                        {
                          title: "Claim Date",
                          dataIndex: "claimDate",
                        },
                      ]}
                      expandedRowData={[
                        {
                          key: "1",
                          ID: "1",
                          rideID: "123",
                          claimDate: "2020/07/22 17:00:11",
                        },
                        {
                          key: "2",
                          ID: "1313",
                          rideID: "1232121",
                          claimDate: "2020/07/22 17:00:11",
                        },
                      ]}
                    />
                  </div>
                </div>
              </Col>

              <Col span={24} style={{ marginTop: "3rem" }}>
                <div>
                  <div style={{ display: "flex" }}>
                    <p className="org_title">Timeline</p>
                    {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                  </div>
                  <Row gutter={16} style={{ paddingLeft: "2rem" }}>
                    <Col span={8}>
                      <Steps direction="vertical" current={1}>
                        <Steps.Step
                          icon={
                            <i
                              className="fa-solid fa-tag"
                              style={{
                                color: "#333",
                                fontSize: "40px",
                              }}
                            ></i>
                          }
                          title="Finished"
                          description="This is a description."
                        />
                        <Steps.Step
                          icon={
                            <i
                              className="fa-solid fa-tag"
                              style={{
                                color: "#333",
                                fontSize: "40px",
                              }}
                            ></i>
                          }
                          title="Finished"
                          description="This is a description."
                        />
                        <Steps.Step
                          icon={
                            <i
                              className="fa-solid fa-tag"
                              style={{
                                color: "#333",
                                fontSize: "40px",
                              }}
                            ></i>
                          }
                          title="Finished"
                          description="This is a description."
                        />
                      </Steps>
                    </Col>
                  </Row>
                </div>
              </Col>
            </>
          )}
        </Row>
      ) : (
        <>
          <Col span={24}>
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "1rem",
              }}
            >
              <Col span={24} className="gutter-row">
                <div>
                  <p className="org_p1">Add Promos</p>
                  <Col span={24} style={{ marginTop: "2rem" }}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Col span={24} className="gutter-row">
                        <div>
                          <Row gutter={16}>
                            <Col span={12}>
                              <span className="org_address">Promo Type *</span>
                              <ISelect
                                option={[
                                  { text: "free rides", value: "free rides" },
                                  { text: "free pass", value: "free pass" },
                                ]}
                                name="promoType"
                                onChange={() => console.log(1222)}
                                placeHolder="Promo Type"
                              />
                            </Col>
                            <Col span={12}>
                              <InputGroup
                                required={true}
                                title="Name"
                                name="Name"
                                onChange={() => console.log(111)}
                              />
                            </Col>

                            <Col span={12}>
                              <InputGroup
                                iInfor="Enter description to be displayed in the app"
                                required={true}
                                title="Promo Description"
                                name="promoDescription"
                                onChange={() => console.log(111)}
                              />
                            </Col>
                            <Col span={12}>
                              <InputGroup
                                required={true}
                                title="Promo Status"
                                name="promoStatus"
                                onChange={() => console.log(111)}
                              />
                            </Col>

                            <Col span={12}>
                              <span
                                className="org_address"
                                style={{ marginTop: "10px" }}
                              >
                                Promo Start Time *
                              </span>

                              <DatePicker
                                defaultValue={moment("2015/01/01", dateFormat)}
                                format={dateFormat}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  marginTop: "4px",
                                }}
                              />
                            </Col>

                            <Col span={12}>
                              <span
                                className="org_address"
                                style={{ marginTop: "10px" }}
                              >
                                Promo End Time *
                              </span>

                              <DatePicker
                                defaultValue={moment("2015/01/01", dateFormat)}
                                format={dateFormat}
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  marginTop: "4px",
                                }}
                              />
                            </Col>

                            <Col span={12}>
                              <span className="org_address">Fleet</span>
                              <ISelect
                                option={[
                                  { text: "Fleet1", value: "Fleet1" },
                                  { text: "Fleet2", value: "Fleet2" },
                                ]}
                                name="fleet"
                                onChange={() => console.log(1222)}
                                placeHolder="fleet"
                              />
                            </Col>

                            <Col span={12}>
                              <InputGroup
                                title="Promo Query strings"
                                name="promoQuerystrings"
                                onChange={() => console.log(111)}
                                placeHolder="Enter Promo Query strings"
                              />
                            </Col>

                            <Col span={12}>
                              <InputGroup
                                iInfor="Total number of promos to be generated (validity * daily used)"
                                required
                                title="Max Used"
                                name="Max Used "
                                placeHolder="Enter max use"
                                onChange={() => console.log(111)}
                              />
                            </Col>

                            <Col span={12}>
                              <InputGroup
                                iInfor="Number of promos to be used on a single ride (1 promo = 1 billing unit) 
                            Example: Billing unit = 30mins, Max Single Used = 2 will give user 60mins of free ride time"
                                required
                                title="Max Single Used "
                                name="Max Single Used "
                                placeHolder="Enter Max Single Used "
                                onChange={() => console.log(111)}
                              />
                            </Col>

                            <Col span={12}>
                              <InputGroup
                                iInfor="Number of free rides in a day (if = 3, 
                              from Max Single Used example, user will get 3 rides of 
                              60mins each in a day for the validity duration defined)"
                                required
                                title="Daily Used"
                                name="Daily Used"
                                placeHolder="Enter Daily Used"
                                onChange={() => console.log(111)}
                              />
                            </Col>
                            <Col span={12}></Col>

                            <Col span={12}>
                              <span className="org_address">Show On App</span>
                              <ISelect
                                option={[
                                  { text: "yes", value: "yes" },
                                  { text: "no", value: "no" },
                                ]}
                                name="Show On App"
                                onChange={() => console.log(1222)}
                                placeHolder="Show On App"
                              />
                            </Col>

                            <Col span={12}>
                              <InputGroup
                                iInfor="Add the price to be paid for this promo"
                                required
                                title="Promo Price"
                                name="Promo Price"
                                placeHolder="Enter Promo Price"
                                onChange={() => console.log(111)}
                              />
                            </Col>

                            {/* <Col style={{marginTop: '1rem'}} span={12}>
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>Single</Radio>
                            <Radio value={2}>Multiple</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={12}></Col> */}

                            <Col span={12}>
                              {value === 1 && (
                                <InputGroup
                                  title="Promo Code"
                                  name="Promo Code"
                                  placeHolder="Enter Promo Code"
                                  onChange={() => console.log(111)}
                                />
                              )}
                              {value === 2 && (
                                <InputGroup
                                  title="Generate Batch Code"
                                  name="Generate Batch Code"
                                  placeHolder="Enter Generate Batch Code"
                                  onChange={() => console.log(111)}
                                />
                              )}
                            </Col>
                            <Col span={12}></Col>
                            <Col span={12}>
                              <span className="org_address">
                                Usage Validity{" "}
                                <Tooltip
                                  placement="right"
                                  title="Promo would be valid for this many days from the time of purchase."
                                >
                                  <i className="fa fa-info-circle"></i>
                                </Tooltip>
                              </span>
                              <ISelect
                                option={[
                                  { text: "1 Day", value: "1 Day" },
                                  { text: "2 Days", value: "2 Days" },
                                ]}
                                name="day"
                                onChange={() => console.log(1222)}
                                placeHolder="1 Day"
                              />
                            </Col>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span className="org_address">
                                Promo expires at Promo End Time{" "}
                                <Tooltip
                                  placement="right"
                                  title="Promo would be valid for this many days from the time of purchase."
                                >
                                  <i className="fa fa-info-circle"></i>
                                </Tooltip>
                              </span>
                              <Switch
                                defaultChecked
                                onChange={onChangeSwitch}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <br />
                  <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <BBtn
                      title="Back"
                      color="#FF5C6A"
                      onClick={() => setAddPromos(false)}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <BBtn title="Save" color="#24BB69" />
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </>
      )}
      <Col span={24} style={{ marginTop: "3rem" }}>
        <span className="va_p_title">Promo Batches</span>
        <div style={{ marginBottom: "1rem" }}></div>
        <div style={{ backgroundColor: "white", padding: "1rem" }}>
          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1rem 0",
                // marginLeft: '1rem',
              }}
            >
              <span style={{ marginRight: "1rem" }}>Show</span>
              <Select size={size} defaultValue={"Show"} onChange={handleChange}>
                {children}
              </Select>
              <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                Entries
              </span>
              <ISearch onChange={() => console.log(222)} />
            </div>
          </div>
          <div>
            <ITable
              onClickRow={(params: any) => setDataPromoUser(params)}
              // rowSelection={true}
              column={[
                {
                  title: "Name",
                  dataIndex: "name",
                },
                {
                  title: "Type",
                  dataIndex: "type",
                  // sorter: (a: any, b: any) => a.name.length - b.name.length,
                },
                {
                  title: "Total",
                  dataIndex: "total",
                },
                {
                  title: "CSV",
                  dataIndex: "CSV",
                },
              ]}
              total={0}
              data={[]}
              currentPage={1}
              pageSize={20}
              onChangePage={(page) => {
                console.log("page", page);
              }}
            />
          </div>
        </div>
      </Col>
    </div>
  );
};

export default Promos;
