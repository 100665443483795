import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {settingAction} from "./settingSlice";
import {settingApi} from "../../apis/settingApi";
import {SettingModel} from "../../models/setting.model";

function* getListSetting() {
  try {
    const listSetting: {
      data: SettingModel[]
    } = yield call(settingApi.getAllSetting)
    yield put(settingAction.getListSettingSuccess(listSetting.data))
  } catch (error) {
    console.log(error)
  }
}
function* updateSetting(
  action: PayloadAction<Partial<SettingModel>>
) {
  try {
    const setting: {description: string} = yield call(
      settingApi.updateSetting,
      action.payload
    )
    yield put (settingAction.success())
    message.success(setting.description).then()
  } catch (error: any) {
    yield put (settingAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* addSetting(action: PayloadAction<Partial<SettingModel>>) {
  try {
    const setting: {description: string} = yield call(settingApi.createSetting, action.payload)
    message.success(setting.description).then()
    yield put (settingAction.success())
  } catch (error: any) {
    yield put (settingAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* detailSetting(action: PayloadAction<{id : string}>) {
  try {
    const setting: {description: string , data : SettingModel} = yield call(settingApi.detailSetting, action.payload.id)
    yield put (settingAction.detailSettingSuccess(setting.data))
    message.success(setting.description).then()
  } catch (error: any) {
    yield put (settingAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* deleteSetting(action: PayloadAction<{id : string}>) {
  try {
    const setting: {description: string } = yield call(settingApi.deleteSetting, action.payload.id)
    message.success(setting.description).then()
    yield put (settingAction.success())
  } catch (error: any) {
    yield put (settingAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}


export default function* settingSaga() {
  yield takeEvery(settingAction.getListSetting.type, getListSetting)
  yield takeEvery(settingAction.updateSetting.type, updateSetting)
  yield takeEvery(settingAction.addSetting.type, addSetting)
  yield takeEvery(settingAction.detailSetting.type, detailSetting)
  yield takeEvery(settingAction.deleteSetting.type, deleteSetting)
}
