import {
  Col,
  DatePicker,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Steps,
} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BBtn from "../../../components/button";
import ISearch from "../../../components/search";
import ISelect from "../../../components/select";
import ITable from "../../../components/table";
import InputGroup from "../../../components/textInput";
import Map, { Marker } from "react-map-gl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  offerAction,
  selectItemCount,
  selectListOffer,
} from "../../../features/offer/offerSlice";
import { FleetModel } from "../../../models/fleet.model";
import { OfferModel } from "../../../models/offer.model";
import {
  fleetAction,
  selectListFleet,
} from "../../../features/fleet/fleetSlice";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const Offers = () => {
  const dispatch = useAppDispatch();
  const listOffer = useAppSelector(selectListOffer);
  const listFleet = useAppSelector(selectListFleet);
  const itemCount = useAppSelector(selectItemCount);
  const [dataOffers, setDataOffers] = useState<OfferModel>(
    {} as unknown as OfferModel
  );
  const [addOffers, setAddOffers] = useState(false);
  const dateFormat = "YYYY/MM/DD";
  const [size, setSize] = useState<SizeType>("middle");
  const [image, setImage] = useState<string>("");
  const [limit, setLimit] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<any>();
  const [fleet, setFleet] = useState<string>("");
  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);
  useEffect(() => {
    dispatch(
      offerAction.getListOffer({
        limit,
        page,
        keyword,
      })
    );
  }, [limit, dispatch, page, keyword]);
  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 15,
      })
    );
  }, [dispatch]);
  const getListOffer = () => {
    setTimeout(
      () =>
        dispatch(
          offerAction.getListOffer({
            limit,
            page,
            keyword,
          })
        ),
      1000
    );
  };
  const handleEditOffer = () => {
    Object.keys(dataOffers).length &&
      dispatch(
        offerAction.updateOffer(Object.assign({ ...dataOffers }, { fleet }))
      );
    getListOffer();
  };

  function handleChangeInput(e: any) {
    let url = URL.createObjectURL(e.target.files[0]);
    setImage(url);
    console.log(url);
  }
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  console.log(11, image);
  console.log("dataOffers", dataOffers);
  const listOptionFleet = listFleet?.map((item) => {
    return {
      value: item._id,
      text: item.name,
    };
  });

  return (
    <div>
      <br />

      {!addOffers ? (
        <>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "1rem" }}
          >
            <span className="va_p_title">Offers</span>
            <BBtn
              title="Add Offers"
              icon1={<i className="fa-solid fa-plus"></i>}
              onClick={() => setAddOffers(true)}
            />
          </Row>
          <Row gutter={24}>
            <div style={{ height: "1rem" }}></div>
            <Col span={Object.keys(dataOffers).length ? 12 : 24}>
              <div style={{ backgroundColor: "white", padding: "1rem" }}>
                <div
                  style={{
                    display: Object.keys(dataOffers).length
                      ? "initial"
                      : "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "1rem 0",
                      // marginLeft: '1rem',
                    }}
                  >
                    <span style={{ marginRight: "1rem" }}>Show</span>
                    <Select
                      value={limit.toString()}
                      defaultValue={"15"}
                      onChange={(e) => setLimit(Number(e))}
                    >
                      <Option value="15">15</Option>
                      <Option value="25">25</Option>
                      <Option value="50">50</Option>
                    </Select>
                    <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                      Entries
                    </span>
                    <ISearch onChange={(e) => setKeyword(e.target.value)} />
                  </div>
                </div>
                <div>
                  <ITable
                    onClickRow={(params: any) => setDataOffers(params)}
                    // rowSelection={true}
                    column={[
                      {
                        title: "ID",
                        dataIndex: "_id",
                      },
                      {
                        title: "Fleet",
                        dataIndex: "fleet",
                        render: (text: FleetModel) => {
                          return text.name;
                        },
                        // sorter: (a: any, b: any) => a.name.length - b.name.length,
                      },
                      {
                        title: "Title",
                        dataIndex: "title",
                      },
                      {
                        title: "Category",
                        dataIndex: "category",
                      },
                    ]}
                    total={itemCount || 0}
                    data={listOffer || []}
                    pageSize={limit}
                    currentPage={page}
                    onChangePage={(page) => {
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            </Col>
            {!!Object.keys(dataOffers).length && (
              <>
                <Col span={12}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      padding: "1rem",
                    }}
                  >
                    <Col span={24} className="gutter-row">
                      <div>
                        <p className="org_p1">Edit Offers</p>
                        <Row gutter={16}>
                          <Col span={12}>
                            <span className="org_address">Fleet </span>
                            <ISelect
                              option={listOptionFleet || []}
                              name="Fleet "
                              value={dataOffers.fleet.name}
                              onChange={(e) => setFleet(e)}
                              placeHolder="Tax"
                            />
                          </Col>
                          <Col span={12}>
                            <InputGroup
                              required={true}
                              title="Title"
                              name="Title"
                              value={dataOffers.title}
                              onChange={(e) =>
                                setDataOffers((pre) => ({ ...pre, title: e }))
                              }
                            />
                          </Col>

                          <Col span={12}>
                            <span className="org_address">Description</span>
                            <TextArea
                              maxLength={100}
                              value={dataOffers.description}
                              style={{ height: 40, marginTop: "5px" }}
                              onChange={(e) =>
                                setDataOffers((pre) => ({
                                  ...pre,
                                  description: e.target.value,
                                }))
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <InputGroup
                              required={true}
                              title="Latitude"
                              name="latitude"
                              value={dataOffers.location.coordinates[1]}
                              onChange={() => console.log(111)}
                              disabled
                            />
                          </Col>

                          <Col span={12}>
                            <InputGroup
                              required={true}
                              title="Longitude"
                              name="longitude"
                              value={dataOffers.location.coordinates[0]}
                              onChange={() => console.log(111)}
                              disabled
                            />
                          </Col>
                          <Col span={12}>
                            <span className="org_address">Start Time *</span>
                            <div style={{ height: "4px" }}></div>
                            <DatePicker
                              style={{ width: "100%", height: "40px" }}
                              defaultValue={moment(
                                dataOffers.startTime,
                                dateFormat
                              )}
                              format={dateFormat}
                            />
                          </Col>

                          <Col span={12}>
                            <span className="org_address">End Time *</span>
                            <div style={{ height: "4px" }}></div>
                            <DatePicker
                              style={{ width: "100%", height: "40px" }}
                              defaultValue={moment(
                                dataOffers.endTime,
                                dateFormat
                              )}
                              format={dateFormat}
                            />
                          </Col>

                          <Col span={12}>
                            <span className="org_address">Category </span>
                            <ISelect
                              option={[
                                { text: "Discount", value: "Discount" },
                                { text: "Advert", value: "Advert" },
                                { text: "Service", value: "Service" },
                                { text: "Event", value: "Event" },
                                { text: "Other", value: "Other" },
                              ]}
                              value={dataOffers.category}
                              name="Category"
                              onChange={(e) =>
                                setDataOffers((pre) => ({
                                  ...pre,
                                  category: e,
                                }))
                              }
                              placeHolder="Category"
                            />
                          </Col>
                          <Col span={12}>
                            <span className="org_address">Image </span>
                            <div style={{ height: "5px" }}></div>
                            <input
                              style={{
                                width: "100%",
                              }}
                              accept="image/*"
                              type="file"
                              name="upload-photo"
                              onChange={handleChangeInput}
                            />
                          </Col>

                          <Col span={12}>
                            <InputGroup
                              title="Website"
                              name="Website"
                              value={dataOffers.website}
                              onChange={(e) =>
                                setDataOffers((pre) => ({ ...pre, website: e }))
                              }
                            />
                          </Col>

                          <Col span={12}>
                            <span className="org_address">Note</span>
                            <TextArea
                              maxLength={100}
                              style={{ height: 40, marginTop: "5px" }}
                              value={dataOffers.note}
                              onChange={(e) =>
                                setDataOffers((pre) => ({
                                  ...pre,
                                  note: e.target.value,
                                }))
                              }
                            />
                          </Col>
                          <Col>
                            <p
                              className="org_address"
                              style={{ marginTop: "0.5rem" }}
                            >
                              Thumbnail Image
                            </p>
                            {image && (
                              <img
                                alt="img"
                                src={image}
                                style={{ width: "100px" }}
                              />
                            )}
                          </Col>
                        </Row>
                        <br />
                        <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <BBtn
                            title="Back"
                            color="#FF5C6A"
                            onClick={() =>
                              setDataOffers({} as unknown as OfferModel)
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <BBtn title="Delete" color="#FF5C6A" />
                            <div style={{ width: "16px" }}></div>
                            <BBtn
                              title="Save"
                              onClick={handleEditOffer}
                              color="#24BB69"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row gutter={24}>
            <Col span={24} style={{ marginBottom: "1rem" }}>
              <span className="va_p_title">Offers</span>
            </Col>
            <Col span={12}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "1rem",
                }}
              >
                <Col span={24} className="gutter-row">
                  <div>
                    <p className="org_p1">Add Offers</p>
                    <Row gutter={16}>
                      <Col span={12}>
                        <span className="org_address">Fleet </span>
                        <ISelect
                          option={listOptionFleet || []}
                          name="Fleet"
                          onChange={(e) => setFleet(e)}
                          placeHolder="Fleet"
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title="Title"
                          name="Title"
                          value={dataOffers.title}
                          onChange={(e) =>
                            setDataOffers((pre) => ({ ...pre, title: e }))
                          }
                        />
                      </Col>

                      <Col span={12}>
                        <span className="org_address">Description</span>
                        <TextArea
                          maxLength={100}
                          style={{ height: 40, marginTop: "5px" }}
                          value={dataOffers.description}
                          onChange={(e) =>
                            setDataOffers((pre) => ({
                              ...pre,
                              description: e.target.value,
                            }))
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title="Latitude"
                          name="latitude"
                          value={lat}
                          onChange={(e) => setLat(e)}
                          disabled
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title="Longitude"
                          name="longitude"
                          value={long}
                          onChange={(e) => setLong(e)}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <span className="org_address">Start Time *</span>
                        <div style={{ height: "4px" }}></div>
                        <DatePicker
                          style={{ width: "100%", height: "40px" }}
                          defaultValue={moment(
                            dataOffers.startTime,
                            dateFormat
                          )}
                          format={dateFormat}
                        />
                      </Col>

                      <Col span={12}>
                        <span className="org_address">End Time *</span>
                        <div style={{ height: "4px" }}></div>
                        <DatePicker
                          style={{ width: "100%", height: "40px" }}
                          defaultValue={moment(dataOffers.endTime, dateFormat)}
                          format={dateFormat}
                        />
                      </Col>

                      <Col span={12}>
                        <span className="org_address">Category </span>
                        <ISelect
                          name="Category"
                          option={[
                            { text: "Discount", value: "Discount" },
                            { text: "Advert", value: "Advert" },
                            { text: "Service", value: "Service" },
                            { text: "Event", value: "Event" },
                            { text: "Other", value: "Other" },
                          ]}
                          onChange={(e) =>
                            setDataOffers((pre) => ({ ...pre, category: e }))
                          }
                          placeHolder="Category"
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          required={true}
                          title="Radius KM"
                          name="RadiusKm"
                          onChange={() => console.log(111)}
                          disabled
                        />
                      </Col>

                      <Col span={12}>
                        <span className="org_address">Image </span>
                        <div style={{ height: "5px" }}></div>
                        <input
                          style={{
                            width: "100%",
                          }}
                          accept="image/*"
                          type="file"
                          name="upload-photo"
                          onChange={handleChangeInput}
                        />
                      </Col>

                      <Col span={12}>
                        <InputGroup
                          title="Website"
                          name="Website"
                          onChange={() => console.log(111)}
                        />
                      </Col>

                      <Col span={12}>
                        <span className="org_address">Note</span>
                        <TextArea
                          maxLength={100}
                          style={{ height: 40, marginTop: "5px" }}
                          onChange={() => console.log(111)}
                        />
                      </Col>
                      <Col>
                        <p
                          className="org_address"
                          style={{ marginTop: "0.5rem" }}
                        >
                          Thumbnail Image
                        </p>
                        {image && (
                          <img
                            alt="img"
                            src={image}
                            style={{ width: "100px" }}
                          />
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <BBtn
                        title="Back"
                        color="#FF5C6A"
                        onClick={() => setAddOffers(false)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <BBtn title="Save Changes" color="#24BB69" />
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            <Col span={12}>
              <Map
                mapboxAccessToken={process.env.REACT_APP_MAP_BOX_TOKEN}
                initialViewState={{
                  longitude: -122.4,
                  latitude: 37.8,
                  zoom: 14,
                }}
                style={{
                  width: "100%",
                  height: 400,
                  borderRadius: "5px",
                }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                // children={
                //   <Marker
                //     longitude={campaignDetail.longitude}
                //     latitude={campaignDetail.latitude}
                //     anchor='bottom'
                //   >
                //     <PlaceOutlinedIcon style={{color: 'red'}} />
                //   </Marker>
                // }
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Offers;
