import { Col, Row, Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import BBtn from "../../components/button";
import CardPayment from "../../components/cardPayment";
import { ChartCustom } from "../../components/chart";
import ISearch from "../../components/search";
import ITable from "../../components/table";
import {
  selectListAnalyticPayment,
  selectStatisticPayment,
} from "../../features/analyticPayment/analyticPaymentSlice";

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const Payments = () => {
  const dispatch = useAppDispatch();
  const chartPayment = useAppSelector(selectListAnalyticPayment);
  const statisticPayment = useAppSelector(selectStatisticPayment);
  const [buttonActive, setButtonActive] = useState("Wallet Debit");
  const [dataPayments, setDataPayments] = useState<any>();
  const [size, setSize] = useState<SizeType>("middle");
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
  };
  return (
    <div>
      <Row align="middle" justify="space-between" style={{ marginTop: "2rem" }}>
        <p className="role_title">Payments</p>
        <div style={{ display: "flex" }}>
          <BBtn
            title="CAD"
            icon1={<i className="fa-solid fa-sort-down"></i>}
            icon1left
          />
          <span style={{ margin: "0 0.5rem" }}></span>
          <BBtn
            title="All Fleet"
            icon1={<i className="fa-solid fa-sort-down"></i>}
            icon1left
          />
        </div>
      </Row>
      <Row gutter={24} style={{ marginTop: "1rem" }}>
        <Col span={8} style={{ marginBottom: "24px" }}>
          <CardPayment name="Average Revenue per day" value="0 CAD" />
        </Col>
        <Col span={8}>
          <CardPayment name="Average Trip Revenue" value="0 CAD" />
        </Col>
        <Col span={8}>
          <CardPayment name="Average Top Up" value="0 CAD" />
        </Col>
        <Col span={8}>
          <CardPayment name="Total Wallet Size" value="0 CAD" />
        </Col>
        <Col span={8}>
          <CardPayment name="Average Wallet Size" value="0 CAD" />
        </Col>
        <Col span={8}>
          <CardPayment name="Average Lifetime Spend" value="0 CAD" />
        </Col>
      </Row>
      <Row style={{ margin: "2rem 0" }}>
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <BBtn
              title="Filter"
              icon1={<i className="fa-solid fa-sort-down"></i>}
              icon1left
            />
          </div>
          <ChartCustom
            labels={[
              "2022-06-07",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
              "2022-06-07",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
              "2022-06-07",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
              "2022-06-07",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
              "2022-06-07",
              "2022-06-09",
              "2022-06-011",
              "2022-06-013",
              "2022-06-15",
            ]}
            datasets={[
              {
                label: "Total User",
                data: [
                  33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65, 33, 53, 85,
                ],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "#758D96",
              },
            ]}
            type="Line"
            title="Revenue"
          />
        </Col>
      </Row>
      <Row justify="center">
        <BBtn
          title="Wallet Debit"
          styleText={{
            color: buttonActive === "Wallet Debit" ? "white" : "black",
          }}
          color={buttonActive === "Wallet Debit" ? "#FF5C6A" : "white"}
          onClick={() => setButtonActive("Wallet Debit")}
        />
        <BBtn
          title="Wallet Credit"
          styleText={{
            color: buttonActive === "Wallet Credit" ? "white" : "black",
          }}
          color={buttonActive === "Wallet Credit" ? "#FF5C6A" : "white"}
          onClick={() => setButtonActive("Wallet Credit")}
        />
        <BBtn
          title="User Deposit"
          styleText={{
            color: buttonActive === "User Deposit" ? "white" : "black",
          }}
          color={buttonActive === "User Deposit" ? "#FF5C6A" : "white"}
          onClick={() => setButtonActive("User Deposit")}
        />
      </Row>

      {buttonActive === "Wallet Credit" && (
        <>
          <Row>
            <Row
              align="middle"
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <span className="va_p_title">Wallet Credit</span>
              <div style={{ display: "flex" }}>
                <BBtn
                  title="CSV"
                  icon1={<i className="fa-solid fa-file-lines"></i>}
                />
                <span style={{ margin: "0 0.5rem" }}></span>
                <BBtn
                  title="Filter"
                  icon1={<i className="fa-solid fa-caret-down"></i>}
                  icon1left
                />
              </div>
            </Row>
            <Col span={24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    size={size}
                    defaultValue={"Show"}
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={() => console.log(222)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataPayments(params)}
                  column={[
                    {
                      title: "Invoice ID",
                      dataIndex: "invoiceID",
                    },
                    {
                      title: "Tax ID",
                      dataIndex: "taxID",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "User Name",
                      dataIndex: "userName",
                    },
                    {
                      title: "Ride ID",
                      dataIndex: "rideID",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "Payment type",
                      dataIndex: "paymentType",
                    },
                    {
                      title: "Amount",
                      dataIndex: "amount",
                    },
                    {
                      title: "Date",
                      dataIndex: "date",
                    },
                    {
                      title: "Deposit",
                      dataIndex: "deposit",
                    },
                    {
                      title: "Invoice",
                      dataIndex: "invoice",
                    },
                    // {
                    //   title: 'Refund / Invoice',
                    //   dataIndex: 'refundInvoice',
                    //   render: (text: any) => (
                    //     <div style={{display: 'flex'}}>
                    //       <BBtn
                    //         title='Refund'
                    //         icon1={<i className='fa-solid fa-reply'></i>}
                    //       />
                    //       <span style={{margin: '5px'}}></span>
                    //       <BBtn
                    //         title='Invoice'
                    //         icon1={<i className='fa-solid fa-file'></i>}
                    //         color='#24BB69'
                    //       />
                    //     </div>
                    //   ),
                    // },
                  ]}
                  total={100}
                  data={
                    [
                      // {
                      //   key: '1',
                      //   invoiceID: 'INV-451664953405',
                      //   taxID: '-',
                      //   User: '_ amraa',
                      //   rideID: '46668',
                      //   planType: 'Joyride plan',
                      //   promo: '-',
                      //   amount: '0 CAD',
                      //   date: '2022/10/05 14:47:57',
                      //   reason: '',
                      //   refundedBy: '',
                      //   refundInvoice: '',
                      // },
                    ]
                  }
                  pageSize={20}
                  onChangePage={(page) => {
                    console.log("page", page);
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      )}

      {buttonActive === "Wallet Debit" && (
        <>
          <Row>
            <Row
              align="middle"
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <span className="va_p_title">Wallet Debit</span>
              <div style={{ display: "flex" }}>
                <BBtn
                  title="CSV"
                  icon1={<i className="fa-solid fa-file-lines"></i>}
                />
                <span style={{ margin: "0 0.5rem" }}></span>
                <BBtn
                  title="Filter"
                  icon1={<i className="fa-solid fa-caret-down"></i>}
                  icon1left
                />
              </div>
            </Row>
            <Col span={24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    size={size}
                    defaultValue={"Show"}
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={() => console.log(222)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataPayments(params)}
                  column={[
                    {
                      title: "Invoice ID",
                      dataIndex: "invoiceID",
                    },
                    {
                      title: "Tax ID",
                      dataIndex: "taxID",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "User",
                      dataIndex: "User",
                    },
                    {
                      title: "Ride ID",
                      dataIndex: "rideID",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: "Plan Type",
                      dataIndex: "planType",
                    },
                    {
                      title: "Promo",
                      dataIndex: "promo",
                    },
                    {
                      title: "Amount",
                      dataIndex: "amount",
                    },
                    {
                      title: "Date",
                      dataIndex: "date",
                    },
                    {
                      title: "Reason",
                      dataIndex: "reason",
                    },
                    {
                      title: "Refunded By",
                      dataIndex: "refundedBy",
                    },
                    {
                      title: "Refund / Invoice",
                      dataIndex: "refundInvoice",
                      render: (text: any) => (
                        <div style={{ display: "flex" }}>
                          <BBtn
                            title="Refund"
                            icon1={<i className="fa-solid fa-reply"></i>}
                          />
                          <span style={{ margin: "5px" }}></span>
                          <BBtn
                            title="Invoice"
                            icon1={<i className="fa-solid fa-file"></i>}
                            color="#24BB69"
                          />
                        </div>
                      ),
                    },
                  ]}
                  total={100}
                  data={[
                    {
                      key: "1",
                      invoiceID: "INV-451664953405",
                      taxID: "-",
                      User: "_ amraa",
                      rideID: "46668",
                      planType: "Joyride plan",
                      promo: "-",
                      amount: "0 CAD",
                      date: "2022/10/05 14:47:57",
                      reason: "",
                      refundedBy: "",
                      refundInvoice: "",
                    },
                  ]}
                  pageSize={20}
                  onChangePage={(page) => {
                    console.log("page", page);
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
      {buttonActive === "User Deposit" && (
        <>
          <Row>
            <Row
              align="middle"
              style={{
                width: "100%",
                justifyContent: "space-between",
                margin: "1rem 0",
              }}
            >
              <span className="va_p_title">User Deposit</span>
              <div style={{ display: "flex" }}>
                <BBtn
                  title="CSV"
                  icon1={<i className="fa-solid fa-file-lines"></i>}
                />
              </div>
            </Row>
            <Col span={24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>Show</span>
                  <Select
                    size={size}
                    defaultValue={"Show"}
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    Entries
                  </span>
                  <ISearch onChange={() => console.log(222)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataPayments(params)}
                  column={[
                    {
                      title: "User",
                      dataIndex: "user",
                    },
                    {
                      title: "Deposit",
                      dataIndex: "deposit",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },

                    {
                      title: "Date",
                      dataIndex: "date",
                    },
                    {
                      title: "Action",
                      dataIndex: "action",
                    },
                    // {
                    //   title: 'Refund / Invoice',
                    //   dataIndex: 'refundInvoice',
                    //   render: (text: any) => (
                    //     <div style={{display: 'flex'}}>
                    //       <BBtn
                    //         title='Refund'
                    //         icon1={<i className='fa-solid fa-reply'></i>}
                    //       />
                    //       <span style={{margin: '5px'}}></span>
                    //       <BBtn
                    //         title='Invoice'
                    //         icon1={<i className='fa-solid fa-file'></i>}
                    //         color='#24BB69'
                    //       />
                    //     </div>
                    //   ),
                    // },
                  ]}
                  total={100}
                  data={
                    [
                      // {
                      //   key: '1',
                      //   invoiceID: 'INV-451664953405',
                      //   taxID: '-',
                      //   User: '_ amraa',
                      //   rideID: '46668',
                      //   planType: 'Joyride plan',
                      //   promo: '-',
                      //   amount: '0 CAD',
                      //   date: '2022/10/05 14:47:57',
                      //   reason: '',
                      //   refundedBy: '',
                      //   refundInvoice: '',
                      // },
                    ]
                  }
                  pageSize={20}
                  onChangePage={(page) => {
                    console.log("page", page);
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Payments;
