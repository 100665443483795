import {Button, Modal} from 'antd'
import React, {useState} from 'react'
import BBtn from '../../button'
import ISelect from '../../select'
import './addMultipleVehicles.css'

export function AddMultipleVehiclesDialog(props: {
  open: boolean
  setOpen: () => void
}) {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }

  const handleCancel = () => {
    props.setOpen()
  }

  return (
    <>
      <Modal
        title='Add Multiple Vehicles'
        open={props.open}
        confirmLoading={confirmLoading}
        okText={<></>}
        cancelText={<></>}
        onCancel={() => props.setOpen()}
      >
        <div
          style={{
            padding: '1.5rem',
          }}
        >
          <p className='addMultipleVehicles_p1'>Fleet</p>
          <ISelect
            option={[{text : '12' , value : '12'},{text : '34' , value : '34'}]}
            onChange={() => console.log(222)}
            name='fleet'
          />
          <p className='addMultipleVehicles_p1'>CSV File</p>
          <input type='file' />
        </div>

        <div
          style={{
            padding: '1.5rem',
            backgroundColor: '#ECF0F3',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <BBtn title='Sample CSV' color='#24BB69' />
            <BBtn title='Import' color='#24BB69' />
          </div>
          <br />
          <p style={{width: '100%', margin: 0}}>
            Joyride 서비스 계약에 따라 차량은 추가된 달 전체에 대해 요금이
            청구됩니다.
          </p>
        </div>
      </Modal>
    </>
  )
}
