import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import BBtn from "../../components/button";
import ITable from "../../components/table";
import InputGroup from "../../components/textInput";
import "./org.css";
import { useTranslation } from "react-i18next";
import Images from "../../assets/gen";
import ISearch from "../../components/search";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  orgAction,
  selectItemCount,
  selectItemCountUserOrg,
  selectListOrg,
  selectListUserOrg,
} from "../../features/org/orgSlice";
import { OrgModel } from "../../models/org.model";
import { UserOrg } from "../../models/userOrg.model";
import { RoleModel } from "../../models/roleModel";
import { roleAction, selectListRole } from "../../features/role/roleSlice";
import { User } from "../../models/userModel";
import {
  countryAction,
  selectListCountry,
} from "../../features/country/countrySlice";
import { fleetAction, selectListFleet } from "../../features/fleet/fleetSlice";

const Org = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listOrg = useAppSelector(selectListOrg);
  const listRole = useAppSelector(selectListRole);
  const listUserOrg = useAppSelector(selectListUserOrg);
  const listCountry = useAppSelector(selectListCountry);
  const listFleet = useAppSelector(selectListFleet);
  const itemCount = useAppSelector(selectItemCount);
  const itemCountUserOrg = useAppSelector(selectItemCountUserOrg);
  const [dataOrg, setDataOrg] = useState<OrgModel>();
  const [dataOrgUser, setDataOrgUser] = useState<Partial<UserOrg>>();
  const [limit, setLimit] = useState<number>(15);
  const [limitUser, setLimitUser] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [pageUser, setPageUser] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>();
  const [keywordUser, setKeywordUser] = useState<string>();
  const [orgID, setOrgID] = useState<string>("");
  const [addUser, setAddUser] = useState<boolean>(false);
  useEffect(() => {
    dispatch(
      orgAction.getListOrgPagination({
        page,
        keyword,
        limit,
      })
    );
  }, [dispatch, page, limit, keyword]);
  useEffect(() => {
    dispatch(
      orgAction.getListUserOrgPagination({
        page: pageUser,
        keyword: keywordUser,
        limit: limitUser,
        organisation_id : orgID
      })
    );
  }, [dispatch, pageUser, limitUser, keywordUser,orgID]);
  useEffect(() => {
    dispatch(
      roleAction.getlistRolePagination({
        page: 1,
        limit: 15,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(countryAction.getListCountry());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 15,
      })
    );
  }, [dispatch]);
  const listSelect: React.ReactNode[] = listRole
    ? listRole.map((item, index) => <Option key={item._id}>{item.name}</Option>)
    : [];
  const listSelectCountry: React.ReactNode[] = listCountry
    ? listCountry.map((item, index) => (
        <Option key={item._id}>{item.name}</Option>
      ))
    : [];
  const listSelectFleet: React.ReactNode[] = listFleet
    ? listFleet.map((item, index) => (
        <Option key={item._id}>{item.name}</Option>
      ))
    : [];
  const getListUserOrgPagination = () => {

    setTimeout(() => {
      dispatch(
        orgAction.getListUserOrgPagination({
          page: pageUser,
          limit: limitUser,
          keyword: keywordUser,
          organisation_id: orgID
        })
      );
    }, 500);
  };

  const getListOrgPagination = () => {
    setTimeout(() => {
      dispatch(
        orgAction.getListOrgPagination({
          page,
          limit,
          keyword,
        })
      );
    }, 500);
  };

  const handleEditUserOrg = () => {
    console.log("dataOrgUser", dataOrgUser);
    dataOrgUser && dispatch(orgAction.updateUserOrg(dataOrgUser));
    getListUserOrgPagination();
  };
  const handleAddUserOrg = () => {
    if (dataOrg && dataOrgUser) {
      const newData = Object.assign(dataOrgUser, {
        organisation: dataOrg._id,
      });
      console.log("newData", newData);
      dataOrgUser && dispatch(orgAction.addUserOrg(newData));
      getListUserOrgPagination();
    }
    getListUserOrgPagination();
  };
  const handleEditOrg = () => {
    if (dataOrg && dataOrg.fleet && dataOrg.country) {
      const { fleet, country, ...rest } = dataOrg;
      const newData: any = rest;
      if (typeof dataOrg.fleet !== "object") {
        newData["fleet"] = dataOrg.fleet;
      }
      if (typeof dataOrg.country !== "object") {
        newData["country"] = dataOrg.country;
      }
      dispatch(orgAction.updateOrg(newData));
      getListOrgPagination();
    }
    getListOrgPagination();
  };

  return (
    <div>
      <br />
      <p className="org_title">Org</p>
      <Row gutter={24}>
        <Col span={dataOrg ? 12 : 24} className="gutter-row">
          <div className="org_div1">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "1rem" }}>{t("show")}</span>
              <Select
                value={limit}
                defaultValue={15}
                onChange={(e) => setLimit(Number(e))}
              >
                <Option value="15">15</Option>
                <Option value="25">25</Option>
                <Option value="50">50</Option>
              </Select>
              <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                {t("entries")}
              </span>
              <ISearch onChange={(e) => setKeyword(e.target.value)} />
            </div>
            <br />
            <ITable
              onClickRow={(params: any) => {
                setDataOrg(params)
                setOrgID(params._id)
              }}
              column={[
                {
                  title: "ID",
                  dataIndex: "_id",
                  // render: (text: string) => <a>{text}</a>,
                  sorter: (a: any, b: any) => a.id - b.id,
                },
                {
                  title: t("name"),
                  dataIndex: "name",
                  sorter: (a: any, b: any) => a.name.length - b.name.length,
                },
                {
                  title: t("created"),
                  dataIndex: "createdAt",
                    render: (text : Date)=> {
                        return <span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>;
                    }
                },
              ]}
              total={itemCount || 100}
              data={listOrg || []}
              currentPage={page}
              pageSize={limit || 15}
              onChangePage={(page) => {
                setPage(page);
              }}
            />
          </div>
        </Col>
        {dataOrg && (
          <Col span={12} className="gutter-row">
            <div className="org_div1">
              <p className="org_p1">{t("Page.Org.editOrg")}</p>
              <Row gutter={16}>
                <Col span={12}>
                  <InputGroup
                    value={dataOrg?.name}
                    required={true}
                    title={t("name")}
                    name="name"
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, name: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <span className="org_address">{t("Page.Org.country")}</span>
                  <Select
                    showSearch
                    style={{ width: "100%", marginTop: "4px" }}
                    size="middle"
                    value={dataOrg?.country.name}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, country: e } as any)
                      )
                    }
                    // placeholder='Search to Select'
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare(
                          (optionB!.children as unknown as string).toLowerCase()
                        )
                    }
                  >
                    {listSelectCountry}
                  </Select>
                </Col>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("Page.Org.alias")}
                    name="alias"
                    value={dataOrg?.alias}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, alias: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.type")}
                    name="type"
                    // value={dataOrg?.alias}
                    onChange={() => console.log(111)}
                  />
                </Col>
                <Col span={12}>
                  <span className="org_address">{t("Page.Org.address")}</span>
                  <TextArea
                    maxLength={100}
                    value={dataOrg?.address}
                    style={{ height: 40, marginTop: "5px" }}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, address: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.city")}
                    name="city"
                    value={dataOrg?.city}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, city: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.zipCode")}
                    name="postalZip"
                    value={dataOrg?.zipCode}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, zipCode: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.taxID")}
                    name="taxId"
                    value={dataOrg?.taxNo}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, taxNo: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.registrationNo")}
                    name="taxId"
                    value={dataOrg?.regNo}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, regNo: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <span className="org_address">
                    {t("Page.Org.defaultFleet")}
                  </span>
                  <Select
                    showSearch
                    style={{ width: "100%", marginTop: "4px" }}
                    size="middle"
                    value={dataOrg?.fleet?.name}
                    onChange={(e) =>
                      setDataOrg(
                        (prevState) => ({ ...prevState, fleet: e } as any)
                      )
                    }
                    // placeholder='Search to Select'
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare(
                          (optionB!.children as unknown as string).toLowerCase()
                        )
                    }
                  >
                    {listSelectFleet}
                  </Select>
                </Col>
              </Row>
              <br />
              <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BBtn
                  title={t("button.back")}
                  color="#FF5C6A"
                  onClick={() => setDataOrg(undefined)}
                />
                <BBtn
                  title={t("button.saveChange")}
                  color="#24BB69"
                  onClick={handleEditOrg}
                />
              </div>
            </div>
          </Col>
        )}

        {dataOrg && (
          <Col span={dataOrgUser || addUser ? 16 : 24} className="gutter-row">
            <div className="org_div2">
              <p className="org_title">{t("Page.Org.orgUsers")}</p>
              {!addUser && !dataOrgUser && (
                <BBtn
                  color="#FF5C6A"
                  title={t("Page.Org.addUser")}
                  onClick={() => setAddUser(true)}
                />
              )}
            </div>
            <div className="org_div1">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "1rem" }}>{t("show")}</span>
                <Select
                  value={limitUser}
                  defaultValue={15}
                  onChange={(e) => setLimitUser(Number(e))}
                >
                  <Option value="15">15</Option>
                  <Option value="25">25</Option>
                  <Option value="50">50</Option>
                </Select>
                <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                  {t("entries")}
                </span>
                <ISearch onChange={(e) => setKeywordUser(e.target.value)} />
              </div>
              <br />
              <ITable
                onClickRow={(params: any) => setDataOrgUser(params)}
                column={[
                  {
                    title: "ID",
                    dataIndex: "id",
                    // render: (text: string) => <a>{text}</a>,
                    sorter: (a: any, b: any) => a.id - b.id,
                  },
                  {
                    title: t("name"),
                    dataIndex: "fullName",
                    sorter: (a: any, b: any) => a.name.length - b.name.length,
                  },
                  {
                    title: t("orgName"),
                    dataIndex: "organisation",
                    render: (text: Partial<OrgModel>) => {
                      let newText = "";
                      if (text && text.name) {
                        newText = text.name;
                      }
                      return <span>{newText}</span>;
                    },
                  },
                  {
                    title: t("email"),
                    dataIndex: "email",
                  },
                  {
                    title: t("role"),
                    dataIndex: "roles",
                    render: (text: Partial<RoleModel[]>) => {
                      const newText = text.map((item) => item?.name);
                      return <span>{newText.toString()}</span>;
                    },
                  },
                  {
                    title: t("createdBy"),
                    dataIndex: "created",
                    render: (text: Partial<User>) => {
                      let newText = "";
                      if (text && text.fullName) {
                        newText = text.fullName;
                      }
                      return <span>{newText}</span>;
                    },
                  },
                  {
                    title: t("lastLogin"),
                    dataIndex: "lastLogin",
                  },
                  {
                    title: t("action"),
                    dataIndex: "status",
                    render: (text: string) => (
                      <span
                        style={{
                          backgroundColor:
                            text === "ACTIVE" ? "#1EA85D" : "#FF5C6A",
                          color: "white",
                          padding: "0.6rem 1rem",
                          borderRadius: "2px",
                        }}
                      >
                        {text === "ACTIVE" ? (
                          <i className="fa fa-check"></i>
                        ) : (
                          <i className="fa fa-ban"></i>
                        )}
                      </span>
                    ),
                  },
                ]}
                total={itemCountUserOrg || 100}
                data={listUserOrg || []}
                pageSize={limitUser}
                currentPage={pageUser}
                onChangePage={(page) => {
                  setPageUser(page);
                }}
              />
            </div>
          </Col>
        )}
        {dataOrgUser && !addUser && (
          <Col span={8} className="gutter-row" style={{ marginTop: "3.2rem" }}>
            <div className="org_div1">
              <p className="org_p1">{t("Page.Org.editUser")}</p>
              <Row gutter={16}>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("Page.Org.firstName")}
                    name="firstName"
                    value={dataOrgUser?.firstName}
                    onChange={(e) =>
                      setDataOrgUser(
                        (prevState) => ({ ...prevState, firstName: e } as any)
                      )
                    }
                  />
                </Col>

                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("Page.Org.lastName")}
                    value={dataOrgUser?.lastName}
                    name="lastName"
                    onChange={(e) =>
                      setDataOrgUser(
                        (prevState) => ({ ...prevState, lastName: e } as any)
                      )
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.phoneNumber")}
                    name="phoneNumber"
                    value={dataOrgUser?.phoneNumber}
                    onChange={(e) =>
                      setDataOrgUser(
                        (prevState) => ({ ...prevState, phoneNumber: e } as any)
                      )
                    }
                  />
                </Col>

                <Col span={12}>
                  {/*<InputGroup*/}
                  {/*  title={t('role')}*/}
                  {/*  name='role'*/}
                  {/*  onChange={() => console.log(111)}*/}
                  {/*/>*/}
                  <span className="org_address">{t("role")}</span>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    defaultValue={dataOrgUser?.roles?.map((role) => role._id)}
                    onChange={(e) =>
                      setDataOrgUser(
                        (prevState) => ({ ...prevState, roles: e } as any)
                      )
                    }
                  >
                    {listSelect}
                  </Select>
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("email")}
                    value={dataOrgUser?.email}
                    name="email"
                    onChange={(e) =>
                      setDataOrgUser(
                        (prevState) => ({ ...prevState, email: e } as any)
                      )
                    }
                  />
                </Col>
                {/*<Col span={12}>*/}
                {/*  <InputGroup*/}
                {/*    title='Role'*/}
                {/*    name='role'*/}
                {/*    onChange={() => console.log(111)}*/}
                {/*  />*/}
                {/*</Col>*/}
              </Row>
              <br />
              <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BBtn
                  title={t("button.back")}
                  color="#FF5C6A"
                  onClick={() => setDataOrgUser(undefined)}
                />
                <BBtn
                  title={t("button.saveChange")}
                  color="#24BB69"
                  onClick={handleEditUserOrg}
                />
              </div>
            </div>
          </Col>
        )}
        {addUser && (
          <Col span={8} className="gutter-row" style={{ marginTop: "3.2rem" }}>
            <div className="org_div1">
              <p className="org_p1">{t("Page.Org.addUser")}</p>
              <Row gutter={16}>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("Page.Org.firstName")}
                    name="firstName"
                    value={dataOrgUser?.firstName}
                    onChange={(e) => {
                      if (dataOrgUser) {
                        setDataOrgUser(
                          (prevState) => ({ ...prevState, firstName: e } as any)
                        );
                      } else {
                        setDataOrgUser({ firstName: e });
                      }
                    }}
                  />
                </Col>

                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("Page.Org.lastName")}
                    value={dataOrgUser?.lastName}
                    name="lastName"
                    onChange={(e) => {
                      if (dataOrgUser) {
                        setDataOrgUser(
                          (prevState) => ({ ...prevState, lastName: e } as any)
                        );
                      } else {
                        setDataOrgUser({ lastName: e });
                      }
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    title={t("Page.Org.phoneNumber")}
                    name="phoneNumber"
                    value={dataOrgUser?.phoneNumber}
                    onChange={(e) => {
                      if (dataOrgUser) {
                        setDataOrgUser(
                          (prevState) =>
                            ({ ...prevState, phoneNumber: e } as any)
                        );
                      } else {
                        setDataOrgUser({ phoneNumber: e });
                      }
                    }}
                  />
                </Col>

                <Col span={12}>
                  <span className="org_address">{t("role")}</span>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    defaultValue={dataOrgUser?.roles?.map((role) => role._id)}
                    onChange={(e) => {
                      if (dataOrgUser) {
                        setDataOrgUser(
                          (prevState) => ({ ...prevState, roles: e } as any)
                        );
                      } else {
                        setDataOrgUser({ roles: e } as any);
                      }
                    }}
                  >
                    {listSelect}
                  </Select>
                </Col>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("email")}
                    value={dataOrgUser?.email}
                    name="email"
                    onChange={(e) => {
                      if (dataOrgUser) {
                        setDataOrgUser(
                          (prevState) => ({ ...prevState, email: e } as any)
                        );
                      } else {
                        setDataOrgUser({ email: e });
                      }
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputGroup
                    required={true}
                    title={t("password")}
                    type="password"
                    value={dataOrgUser?.password}
                    name="password"
                    onChange={(e) => {
                      if (dataOrgUser) {
                        setDataOrgUser(
                          (prevState) => ({ ...prevState, password: e } as any)
                        );
                      } else {
                        setDataOrgUser({ password: e });
                      }
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BBtn
                  title={t("button.back")}
                  color="#FF5C6A"
                  onClick={() => {
                    setAddUser(false);
                    setDataOrgUser(undefined);
                  }}
                />
                <BBtn
                  title={t("button.saveChange")}
                  color="#24BB69"
                  onClick={handleAddUserOrg}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Org;
