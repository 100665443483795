import axiosClient from './axiosClient'
import {urlApi} from './urlConfig'
import {OrgModel} from "../models/org.model";
import {UserOrg} from "../models/userOrg.model";

export const orgApi = {
    getListOrg() {
        return axiosClient.getService(`${urlApi.ORG}/no-pagination`)
    },
    getListOrgPagination(params: {
        page: number
        limit: number
        keyword?: string
        'orderBy[id]'?: 'ASC' | 'DESC'
        'orderBy[name]'?: 'ASC' | 'DESC'
    }) {
        return axiosClient.getService(`${urlApi.ORG}`, {
            ...params,
        })
    },
    updateOrg(params: OrgModel) {
        return axiosClient.updateService(`${urlApi.ORG}/${params._id}`, {
            ...params
        })
    },
    addOrg(params: OrgModel) {
        return axiosClient.postService(`${urlApi.ROLE}`, {...params})
    },
    getListUserOrgPagination(params: {
        page: number
        limit: number
        keyword?: string
        organisation_id : string
        'orderBy[id]'?: 'ASC' | 'DESC'
        'orderBy[name]'?: 'ASC' | 'DESC'
    }) {
        return axiosClient.getService(`${urlApi.USER}`,{...params})
    },
    updateUserOrg(params: UserOrg ){
        return axiosClient.updateService(`${urlApi.USER}/${params.id}`, {...params})
    },
    addUserOrg(params: UserOrg) {
        return axiosClient.postService(`${urlApi.USER}`, {...params})
    },
}
