import styled from "styled-components";
import { AppColors } from "../../utils/appColors";

const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 8px 12px;
  img {
    width: 18px;
    height: 18px;
  }
  img:hover {
    cursor: pointer;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }

  span:hover {
    cursor: pointer;
  }
`;

export interface IProps {
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  icon?: string;
  title?: string;
  color?: string;
  icon1?: React.ReactNode;
  onClick?: (event: any) => void;
  styleText?: React.CSSProperties;
  icon1left?: boolean;
  boderColor?: string;
  noBorderRadius?: boolean;
  align?: "start" | "center" | "end";
}

const BBtn = (props: IProps) => {
  const _buildType1 = () => {
    return <img src={props.icon} alt="" />;
  };

  const _buildType2 = () => {
    return (
      <>
        {props.iconLeft && (
          <img style={{ marginRight: 8 }} src={props.iconLeft} alt="" />
        )}
        <span
          style={
            props.styleText
              ? props.styleText
              : {
                  alignItems: "center",
                  display: "flex",
                }
          }
        >
          {props.icon1 && !props.icon1left && (
            // <span
            //   style={{color: 'white', fontSize: '18px', margin: '0 0.25rem'}}
            // >
            <> {props.icon1} &nbsp;</>
            // </span>
          )}
          {props.title}
          {props.icon1 && props.icon1left && <>&nbsp; {props.icon1} </>}
        </span>
        {props.iconRight && (
          <img style={{ marginLeft: 8 }} src={props.iconRight} alt="" />
        )}
      </>
    );
  };
  return (
    <Container
      onClick={props.onClick}
      style={{
        borderRadius: !props.noBorderRadius ? "4px" : "0px",
        background: props.color || AppColors.red,
        border: `1px solid ${props.boderColor}`,
        marginInline:
          props.align === "center"
            ? "auto"
            : props.align === "end"
            ? "auto 0"
            : "inherit",
      }}
      disabled={props.disabled || false}
    >
      {props.icon ? _buildType1() : _buildType2()}
    </Container>
  );
};

export default BBtn;
