import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import BBtn from "../../button";
import ISelect from "../../select";
import InputGroup from "../../textInput";
import "./addVehicleDialog.css";
import {
  selectTypeVehicle,
  vehicleAction,
} from "../../../features/vehicle/vehicleSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fleetAction,
  selectListFleet,
} from "../../../features/fleet/fleetSlice";
import { vehicleModel } from "../../../models/vehicleModel";

export function AddVehicleDialog(props: {
  open: boolean;
  setOpen: () => void;
  setClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const typeVehicle = useAppSelector(selectTypeVehicle);
  const listFleet = useAppSelector(selectListFleet);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState<Partial<vehicleModel>>({
    typeDevice: "",
    name: "",
    imei: "",
    xiaomiRfid: "",
    qrCode: "",
    iccid: "",
    macAddress: "",
    bluetoothKey: "",
    vehicleSN: "",
    deviceNotes: "",
    vehicleLockCode: "",
  });
  const [fleet, setFleet] = useState("");
  useEffect(() => {
    dispatch(vehicleAction.getTypeVehicle());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page: 1,
        limit: 15,
      }),
    );
  }, [dispatch]);
  const optionType = typeVehicle?.map((item) => {
    return { text: item.value, value: item.key };
  });
  const listOptionFleet = listFleet?.map((item) => {
    return {
      text: item.name,
      value: item,
    };
  });
  const handleAddVehicle = () => {
    const dataAdd = Object.assign(data, { fleet });
    console.log("dataAdd", dataAdd);
    dispatch(vehicleAction.addVehicle(dataAdd));
    props.setClose()
  };

  return (
    <>
      <Modal
        title="Add Vehicle"
        open={props.open}
        confirmLoading={confirmLoading}
        okText={<></>}
        cancelText={<></>}
        onCancel={() => props.setOpen()}
        width={1000}
      >
        <Row style={{ padding: "24px" }} gutter={24}>
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <p className="addVehicleDialog_p1">Vehicle Type</p>
            <ISelect
              option={optionType}
              onChange={(e) => setData((pre) => ({ ...pre, typeDevice: e }))}
              name="Vehicle Type"
            />
          </Col>
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <InputGroup
              name="Vehicle Name"
              onChange={(e) => setData((pre) => ({ ...pre, name: e }))}
              title="Vehicle Name"
              required
            />
          </Col>
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <p className="addVehicleDialog_p1">Fleet</p>
            <ISelect
              option={listOptionFleet}
              onChange={(e) => setFleet(e._id)}
              name="fleet"
            />
          </Col>
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <InputGroup
              name="IMEI"
              onChange={(e) => setData((pre) => ({ ...pre, imei: e }))}
              title="IMEI"
              required
              placeHolder="Enter IMEI"
            />
          </Col>
          {/*<Col span={8} style={{ marginTop: "0.5rem" }}>*/}
          {/*  <InputGroup*/}
          {/*    name="Segway RFID"*/}
          {/*    onChange={(e) => setData(pre=> ({...pre ,xiaomiRfid : e}))}*/}
          {/*    title="Segway RFID"*/}
          {/*    placeHolder="Enter Xiaomi RFID"*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <InputGroup
              name="QR Code"
              onChange={(e) => setData((pre) => ({ ...pre, qrCode: e }))}
              title="QR Code"
              required
              placeHolder="Enter QR code"
            />
          </Col>
          {/*<Col span={8} style={{ marginTop: "0.5rem" }}>*/}
          {/*  <InputGroup*/}
          {/*    name="Sim ICCID Number"*/}
          {/*    onChange={(e) => setData(pre=> ({...pre ,iccid : e}))}*/}
          {/*    title="Sim ICCID Number"*/}
          {/*    placeHolder="Enter sim iccid number"*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <InputGroup
              name="Mac Address"
              onChange={(e) => setData((pre) => ({ ...pre, macAddress: e }))}
              title="Mac Address"
              placeHolder="Enter mac address"
            />
          </Col>
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <InputGroup
              name="Device Notes"
              onChange={(e) => setData((pre) => ({ ...pre, deviceNotes: e }))}
              title="Device Notes"
              placeHolder="Enter device notes"
            />
          </Col>
          <Col span={8} style={{ marginTop: "0.5rem" }}>
            <InputGroup
              name="Vehicle SN"
              onChange={(e) => setData((pre) => ({ ...pre, vehicleSN: e }))}
              title="Vehicle SN"
              placeHolder="Enter Vehicle SN"
            />
          </Col>
          {/*<Col span={8} style={{ marginTop: "0.5rem" }}>*/}
          {/*  <InputGroup*/}
          {/*    name="Vehicle Lock Code"*/}
          {/*    onChange={(e) => setData(pre=> ({...pre ,vehicleLockCode : e}))}*/}
          {/*    title="Vehicle Lock Code "*/}
          {/*    required*/}
          {/*    placeHolder="Enter lock code"*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            backgroundColor: "#ECF0F3",
            justifyContent: "space-around",
            padding: "1.5rem",
          }}
        >
          <p style={{ width: "60%" }}>
            As per the Joyride service agreement, vehicles will be billed for
            the full month in which they are added.
          </p>
          <BBtn title="Save" color="#24BB69" onClick={handleAddVehicle} />
        </div>
      </Modal>
    </>
  );
}
