import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {authenticationApi} from '../../apis/authenticationApi'
import {User} from '../../models/userModel'
import {authAction, LoginPayload} from './authSlice'

function* login(action: PayloadAction<LoginPayload>) {
  try {
    const user: {
      description: String
      status: Number
      data: {
        access_token: string
        refresh_token: string
        user: User
      }
    } = yield call(authenticationApi.login, action.payload)
    yield put(authAction.loginSuccess(user.data))
  } catch (err: any) {
    if (err.response.data.message) {
      message.error(err.response.data.message[0])
    } else {
      message.error(err.response.data.error)
    }
  }
}

export default function* authSaga() {
  yield takeEvery(authAction.login.type, login)
}
