import { Col, Row, Select, Steps, Switch, TimePicker, Tooltip } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import BBtn from "../../components/button";
import CardGeneralFleet from "../../components/cardGeneralFleet";
import ISearch from "../../components/search";
import ISelect from "../../components/select";
import ITable from "../../components/table";
import InputGroup from "../../components/textInput";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Marker } from "react-map-gl";
import "./fleets.css";
import { useTranslation } from "react-i18next";
import { FleetModel } from "../../models/fleet.model";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Clock from "react-live-clock";
import {
  fleetAction,
  selectItemCount,
  selectListFleet,
} from "../../features/fleet/fleetSlice";
import { BillingPlanModel } from "../../models/billingPlanModel";
import {
  countryAction,
  selectListCountry,
} from "../../features/country/countrySlice";
import {
  billingPlanAction,
  selectListBillingPlan,
} from "../../features/billingPlan/billingPlanSlice";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import useReverseGeocode from "../../utils/useReverseGeocode";
import { log } from "console";
import { DeleteDialog } from "../../components/dialog/deleteDialog";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const Fleets = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listFleet = useAppSelector(selectListFleet);
  const itemCount = useAppSelector(selectItemCount);
  const listCountry = useAppSelector(selectListCountry);
  const listBillingPlan = useAppSelector(selectListBillingPlan);
  const [dataFleet, setDataFleet] = useState<Partial<FleetModel>>();
  const [openAdd, setOpenAdd] = useState(false);
  const [tab, setTab] = useState<string>("Edit");
  const [size, setSize] = useState<SizeType>("middle");
  const [lng, setLng] = useState<number>(127);
  const [lat, setLat] = useState<number>(37.5);
  const [zoom, setZoom] = useState(16);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>();
  const [openInGeneral, setOpenInGeneral] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const address = useReverseGeocode(lat, lng);

  useEffect(() => {
    dispatch(
      fleetAction.getListFleet({
        page,
        limit,
        keyword,
      })
    );
  }, [dispatch, limit, page, keyword]);
  useEffect(() => {
    dispatch(countryAction.getListCountry());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      billingPlanAction.getListBillingPlan({
        limit: 15,
        page: 1,
      })
    );
  }, [dispatch, limit, page]);
  const listSelectCountry: React.ReactNode[] = listCountry
    ? listCountry.map((item, index) => (
        <Option key={item._id}>{item.name}</Option>
      ))
    : [];
  const listSelectBilling: React.ReactNode[] = listBillingPlan
    ? listBillingPlan.map((item, index) => (
        <Option key={item._id}>{item.name}</Option>
      ))
    : [];
  const listOptionCountry = listCountry
    ? listCountry.map((item) => {
        return {
          text: item.name,
          value: item,
        };
      })
    : [];
  const listOptionBilling = listBillingPlan
    ? listBillingPlan.map((item) => {
        return {
          text: item.name,
          value: item,
        };
      })
    : [];
  const getListFleet = () => {
    setTimeout(() => {
      dispatch(
        fleetAction.getListFleet({
          page,
          limit,
          keyword,
        })
      );
    }, 500);
  };
  const onChange = (checked: boolean) => {
    setDataFleet((prevState) => ({ ...prevState, isOperation: checked }));
  };
  const handleAddFleet = () => {
    if (dataFleet) {
      const dataAdd: any = {
        ...dataFleet,
        location: {
          ...dataFleet.location,
          coordinates: [lng, lat],
          type: "Point",
          name: address,
        },
      };

      dispatch(fleetAction.addFleet(dataAdd));
      getListFleet();
    }
  };
  const handleEdit = () => {
    dataFleet &&
      dispatch(
        fleetAction.updateBasic(
          Object.assign(dataFleet, {
            country: dataFleet.country?._id,
            billingPlan: dataFleet.billingPlan?._id,
          })
        )
      );
    getListFleet();
  };
  const handleEditMailConfig = () => {
    if (dataFleet) {
      const dataUpdate: any = {
        ...dataFleet.mailConfiguration,
        _id: dataFleet._id,
      };
      dispatch(fleetAction.updateConfigMail(dataUpdate));
      getListFleet();
    }
    getListFleet();
  };

  const handleDelete = () => {
    dataFleet &&
      dataFleet._id &&
      dispatch(fleetAction.deleteFleet(dataFleet._id));

    setDataFleet(undefined);
    getListFleet();
  };
  return (
    <div>
      <br />
      <div className="role_div1">
        <p className="role_title">{t("Page.Fleets.fleets")}</p>
        <BBtn
          color="#FF5C6A"
          title={t("Page.Fleets.addFleet")}
          onClick={() => {
            setOpenAdd(true);
            setDataFleet(undefined);
          }}
        />
      </div>

      <Row gutter={24}>
        {openAdd ? (
          <>
            <Col span={12} className="gutter-row">
              <div className="org_div1">
                <p className="org_p1">{t("Page.Fleets.addFleet")}</p>
                <Row gutter={16}>
                  <Col span={12}>
                    <InputGroup
                      placeHolder={t("Page.Fleets.phName")}
                      required={true}
                      title={t("name")}
                      name="name"
                      value={dataFleet?.name}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) => ({ ...prevState, name: e } as any)
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <p className="fleets-p1">{t("Page.Fleets.country")}</p>
                    <Select
                      showSearch
                      style={{ width: "100%", marginTop: "6px" }}
                      size="large"
                      value={dataFleet?.country?.name}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) => ({ ...prevState, country: e } as any)
                        )
                      }
                      // placeholder='Search to Select'
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA!.children as unknown as string)
                          .toLowerCase()
                          .localeCompare(
                            (
                              optionB!.children as unknown as string
                            ).toLowerCase()
                          )
                      }
                    >
                      {listSelectCountry}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      placeHolder={t("Page.Fleets.phAlertEmail")}
                      required={true}
                      title={t("Page.Fleets.alertEmail")}
                      name="alertEmail"
                      value={dataFleet?.alertEmail}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) =>
                            ({ ...prevState, alertEmail: e } as any)
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <p className="fleets-p1">{t("Page.Fleets.visibility")}</p>
                    <ISelect
                      name="visibility"
                      option={[
                        { text: "PUBLIC", value: "PUBLIC" },
                        { text: "PRIVATE", value: "PRIVATE" },
                      ]}
                      value={dataFleet?.visibility}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) =>
                            ({ ...prevState, visibility: e } as any)
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      placeHolder={t("Page.Fleets.phLatitude")}
                      disabled
                      required={true}
                      title={t("Page.Fleets.latitude")}
                      name="latitude"
                      value={lat}
                      onChange={(e) => {
                        console.log(e);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <InputGroup
                      placeHolder={t("Page.Fleets.phLongitude")}
                      disabled
                      required={true}
                      title={t("Page.Fleets.longitude")}
                      name="longitude"
                      value={lng}
                      onChange={(e) => {
                        console.log(e);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <span className="org_address">
                      {t("Page.Fleets.location")}
                    </span>
                    <TextArea
                      maxLength={100}
                      style={{ height: 40, marginTop: "5px" }}
                      value={address}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) =>
                            ({
                              ...prevState,
                              location: {
                                ...prevState?.location,
                                name: e.target.value,
                              },
                            } as any)
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <p className="fleets-p1">
                      {t("Page.Fleets.billing")}
                      <span style={{ color: "red" }}>&nbsp;*</span>
                    </p>
                    <Select
                      showSearch
                      style={{ width: "100%", marginTop: "6px" }}
                      size="large"
                      value={dataFleet?.billingPlan?.name}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) =>
                            ({ ...prevState, billingPlan: e } as any)
                        )
                      }
                      // placeholder='Search to Select'
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA!.children as unknown as string)
                          .toLowerCase()
                          .localeCompare(
                            (
                              optionB!.children as unknown as string
                            ).toLowerCase()
                          )
                      }
                    >
                      {listSelectBilling}
                    </Select>
                  </Col>

                  <Col span={12}>
                    <p className="fleets-p1">
                      {t("Page.Fleets.vehicleReservationMinutes")}
                    </p>
                    <ISelect
                      name="vehicleReservationMinutes"
                      option={[
                        { text: "1", value: "1" },
                        { text: "5", value: "5" },
                        { text: "10", value: "10" },
                        { text: "15", value: "15" },
                        { text: "20", value: "20" },
                        { text: "25", value: "25" },
                      ]}
                      value={dataFleet?.reservationMinutes?.toString()}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) =>
                            ({
                              ...prevState,
                              reservationMinutes: Number(e),
                            } as any)
                        )
                      }
                    />
                  </Col>

                  <Col span={12}>
                    <p className="fleets-p1">
                      {t("Page.Fleets.isParkingOptional")}
                    </p>
                    <ISelect
                      name="isParkingOptional"
                      option={[
                        { text: "No", value: "No" },
                        { text: "Yes", value: "Yes" },
                      ]}
                      value={dataFleet?.isParkingOptional ? "Yes" : "No"}
                      onChange={(e) =>
                        setDataFleet(
                          (prevState) =>
                            ({
                              ...prevState,
                              isParkingOptional: e === "Yes",
                            } as any)
                        )
                      }
                    />
                  </Col>
                </Row>
                <br />
                <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: "1rem" }}
                >
                  <p className="fleets-title">
                    {t("Page.Fleets.setupOperationHours")}
                    <i className="fa fa-info-circle"></i>
                  </p>
                  <Switch defaultChecked onChange={onChange} />
                </Row>
                <br />
                <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                <br />
                <p className="fleets-p1">{t("Page.Fleets.companyDetail")}</p>
                <p className="fleets-p1">
                  {t("Page.Fleets.useOrganizationDetails")}
                </p>
                <br />
                <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <BBtn
                    title={t("button.back")}
                    color="#FF5C6A"
                    onClick={() => {
                      setOpenAdd(false);
                      setDataFleet(undefined);
                    }}
                  />
                  <BBtn
                    title={t("button.saveChange")}
                    color="#24BB69"
                    onClick={handleAddFleet}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <Map
                mapboxAccessToken={process.env.REACT_APP_MAP_BOX_TOKEN}
                initialViewState={{
                  longitude: 127,
                  latitude: 37.5,
                  zoom: 14,
                }}
                style={{
                  width: "100%",
                  height: 400,
                  borderRadius: "5px",
                }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                children={
                  <Marker longitude={lng} latitude={lat} anchor="bottom">
                    <i
                      className="fa-duotone fa-location-pin"
                      style={{ color: "red" }}
                    ></i>
                    {/*<DownOutlined  style={{color: 'red'}} />*/}
                  </Marker>
                }
                onClick={(e) => {
                  // setDataFleet(prevState => ({...prevState }))
                  setLat(Number(e.lngLat.lat));
                  setLng(Number(e.lngLat.lng));
                  console.log("point", e);
                }}
              />
            </Col>
          </>
        ) : (
          <>
            <Col span={24} className="gutter-row">
              <div className="org_div1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "1rem" }}>{t("show")}</span>
                  <Select
                    value={limit}
                    defaultValue={15}
                    onChange={(e) => setLimit(Number(e))}
                  >
                    <Option value="10">10</Option>
                    <Option value="25">25</Option>
                    <Option value="50">50</Option>
                  </Select>
                  <span style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                    {t("entries")}
                  </span>
                  <ISearch onChange={(e) => setKeyword(e.target.value)} />
                </div>
                <br />
                <ITable
                  onClickRow={(params: any) => setDataFleet(params)}
                  column={[
                    {
                      title: "ID",
                      dataIndex: "_id",
                    },
                    {
                      title: t("name"),
                      dataIndex: "name",
                      // sorter: (a: any, b: any) => a.name.length - b.name.length,
                    },
                    {
                      title: t("Page.Fleets.supportedEmail"),
                      dataIndex: "mailConfiguration",
                        render: (text: FleetModel["mailConfiguration"]) => {
                            let newText = "";
                            if (text && text.mail) {
                                newText = text.mail;
                            }
                            return <span>{newText}</span>;
                        },

                    },

                    {
                      title: t("Page.Fleets.visibility"),
                      dataIndex: "visibility",
                      render: (text: string) => (
                        <span
                          style={{
                            backgroundColor:
                              text === "PUBLIC" ? "#1EA85D" : "#F2B71E",
                            color: "white",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "2px",
                          }}
                        >
                          {text}
                        </span>
                      ),
                    },
                    {
                      title: t("Page.Fleets.location"),
                      dataIndex: "location",
                      render: (text: any) => {
                        let newText = "";
                        if (text && text.name) {
                          newText = text.name;
                        }
                        return <span>{newText}</span>;
                      },
                    },
                    {
                      title: t("Page.Fleets.billing"),
                      dataIndex: "billingPlan",
                      render: (text: Partial<BillingPlanModel>) => {
                        let newText = "";
                        if (text && text.name) {
                          newText = text.name;
                        }
                        return <span>{newText}</span>;
                      },
                    },
                    {
                      title: t("Page.Fleets.createdDate"),
                      dataIndex: "createdAt",
                      render: (text : Date)=> {
                          return <span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>;
                      }
                    },
                  ]}
                  total={itemCount || 100}
                  data={listFleet || []}
                  pageSize={limit}
                  currentPage={page}
                  onChangePage={(page) => {
                    setPage(page);
                  }}
                />
              </div>
            </Col>

            {dataFleet && (
              <>
                <Col span={24} className="gutter-row">
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <BBtn
                      title={t("Page.Fleets.Tab.edit")}
                      color={tab === "Edit" ? "#2C405A" : "#FF5C6A"}
                      onClick={() => setTab("Edit")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.general")}
                      color={tab === "General" ? "#2C405A" : "#FF5C6A"}
                      onClick={() => setTab("General")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.company")}
                      color={tab === "Company" ? "#2C405A" : "#FF5C6A"}
                      // onClick={() => setTab('Company')}
                      onClick={() => setTab("Company")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.mailConfiguration")}
                      color={
                        tab === "Mail Configuration" ? "#2C405A" : "#FF5C6A"
                      }
                      onClick={() => setTab("Mail Configuration")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.vehicleConfiguration")}
                      color={
                        tab === "Vehicle Configuration" ? "#2C405A" : "#FF5C6A"
                      }
                      onClick={() => setTab("Vehicle Configuration")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.timeline")}
                      color={tab === "Timeline" ? "#2C405A" : "#FF5C6A"}
                      onClick={() => setTab("Timeline")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.operation")}
                      color={tab === "Operation" ? "#2C405A" : "#FF5C6A"}
                      onClick={() => setTab("Operation")}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <BBtn
                      title={t("Page.Fleets.Tab.preRideScreen")}
                      color={tab === "Pre Ride Screen" ? "#2C405A" : "#FF5C6A"}
                      onClick={() => setTab("Pre Ride Screen")}
                    />
                  </div>
                </Col>
                {tab === "Edit" && (
                  <Col span={24} className="gutter-row">
                    <div className="org_div1">
                      <Row gutter={[24, 24]}>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("name")}
                            name="name"
                            value={dataFleet.name}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                name: e,
                              }))
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <p className="fleets-p2">
                            {t("Page.Fleets.country")}
                          </p>
                          <div style={{ height: "6px" }}></div>
                          <ISelect
                            name="country"
                            option={listOptionCountry}
                            value={dataFleet.country?.name}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                country: e,
                              }))
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Fleets.alertEmail")}
                            name="alertEmail"
                            value={dataFleet.alertEmail}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                alertEmail: e,
                              }))
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <p className="fleets-p2">
                            {t("Page.Fleets.visibility")}
                          </p>
                          <div style={{ height: "6px" }}></div>
                          <ISelect
                            name="visibility"
                            option={[
                              { text: "PUBLIC", value: "PUBLIC" },
                              { text: "PRIVATE", value: "PRIVATE" },
                            ]}
                            value={dataFleet.visibility}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                visibility: e,
                              }))
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            disabled
                            required={true}
                            title={t("Page.Fleets.latitude")}
                            name="latitude"
                            value={dataFleet.location?.coordinates[1]}
                            onChange={() => console.log(111)}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            disabled
                            required={true}
                            title={t("Page.Fleets.longitude")}
                            name="longitude"
                            value={dataFleet.location?.coordinates[0]}
                            onChange={() => console.log(111)}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={false}
                            title={t("Page.Fleets.location")}
                            name="location"
                            value={dataFleet.location?.name}
                            onChange={(e) =>
                              setDataFleet(
                                (prevState) =>
                                  ({
                                    ...prevState,
                                    location: {
                                      ...prevState?.location,
                                      name: e,
                                    },
                                  } as any)
                              )
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <p className="fleets-p2">
                            {t("Page.Fleets.billing")}
                          </p>
                          <div style={{ height: "6px" }}></div>
                          <ISelect
                            name="billing"
                            option={listOptionBilling}
                            value={dataFleet.billingPlan?.name}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                billingPlan: e,
                              }))
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <p className="fleets-p1">
                            {t("Page.Fleets.vehicleReservationMinutes")}
                          </p>
                          <ISelect
                            name="vehicleReservationMinutes"
                            option={[
                              { text: "1", value: "1" },
                              { text: "5", value: "5" },
                              { text: "10", value: "10" },
                              { text: "15", value: "15" },
                              { text: "20", value: "20" },
                              { text: "25", value: "25" },
                              { text: "30", value: "30" },
                              { text: "40", value: "40" },
                              { text: "50", value: "50" },
                            ]}
                            value={dataFleet.reservationMinutes?.toString()}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                reservationMinutes: Number(e),
                              }))
                            }
                          />
                        </Col>

                        <Col span={12}>
                          <p className="fleets-p1">
                            {t("Page.Fleets.isParkingOptional")}
                          </p>
                          <ISelect
                            name="isParkingOptional"
                            option={[
                              { text: "No", value: "No" },
                              { text: "Yes", value: "Yes" },
                            ]}
                            value={dataFleet.isParkingOptional ? "Yes" : "No"}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                isParkingOptional: e === "Yes",
                              }))
                            }
                          />
                        </Col>

                        <Col span={12}>
                          <p className="fleets-p1">
                            {t("Page.Fleets.isDockingRequired")}
                          </p>
                          <ISelect
                            name="isDockingRequired"
                            option={[
                              { text: "No", value: "No" },
                              { text: "Yes", value: "Yes" },
                            ]}
                            value={dataFleet.isParkingOptional ? "Yes" : "No"}
                            onChange={(e) =>
                              setDataFleet((prevState) => ({
                                ...prevState,
                                isParkingOptional: e === "Yes",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                      <br />
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <BBtn
                          title={t("button.delete")}
                          color="#FF5C6A"
                          onClick={() => setOpenDelete(true)}
                        />
                        <span style={{ width: "10px" }}></span>
                        <BBtn
                          title={t("button.saveChange")}
                          color="#24BB69"
                          onClick={handleEdit}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {tab === "General" && (
                  <Col span={24}>
                    <div
                      style={{ backgroundColor: "white", padding: "2rem 1rem" }}
                    >
                      <Row gutter={24}>
                        <Col span={6} style={{ margin: "0.5rem 0 " }}>
                          <CardGeneralFleet
                            icon={<i className="fa-solid fa-users"></i>}
                            name={t("Page.Fleets.vehicle")}
                            value="0"
                          />
                        </Col>
                        <Col span={6} style={{ margin: "0.5rem 0 " }}>
                          <CardGeneralFleet
                            icon={<i className="fa-solid fa-users"></i>}
                            name={t("Page.Fleets.ride")}
                            value="0"
                          />
                        </Col>
                        <Col span={6} style={{ margin: "0.5rem 0 " }}>
                          <CardGeneralFleet
                            icon={<i className="fa-solid fa-users"></i>}
                            name={t("Page.Fleets.revenue")}
                            value="0"
                          />
                        </Col>
                        <Col span={6} style={{ margin: "0.5rem 0 " }}>
                          <CardGeneralFleet
                            icon={<i className="fa-solid fa-users"></i>}
                            name={t("Page.Fleets.promos")}
                            value="0"
                          />
                        </Col>
                        <Col span={6} style={{ margin: "0.5rem 0 " }}>
                          <CardGeneralFleet
                            icon={<i className="fa-solid fa-users"></i>}
                            name={t("Page.Fleets.uniqueFleetCodes")}
                            value="0"
                          />
                        </Col>
                        <Col span={6} style={{ margin: "0.5rem 0 " }}>
                          <CardGeneralFleet
                            icon={<i className="fa-solid fa-users"></i>}
                            name={t("Page.Fleets.commonFleetCodeUsers")}
                            value="0"
                          />
                        </Col>
                      </Row>
                      <div className="fleet-vehicle_table">
                        <h3>Fleet Vehicles</h3>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <span style={{ marginRight: "1rem" }}>
                            {t("show")}
                          </span>
                          <Select
                            value={limit}
                            defaultValue={15}
                            onChange={(e) => setLimit(Number(e))}
                          >
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                          </Select>
                          <span
                            style={{ marginLeft: "1rem", marginRight: "3rem" }}
                          >
                            {t("entries")}
                          </span>
                          <ISearch
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                        <ITable
                          onClickRow={(params: any) => setDataFleet(params)}
                          column={[
                            {
                              title: "ID",
                              dataIndex: "_id",
                            },
                            {
                              title: t("name"),
                              dataIndex: "name",
                              // sorter: (a: any, b: any) => a.name.length - b.name.length,
                            },
                            {
                              title: t("Page.Fleets.supportedEmail"),
                              dataIndex: "mailConfiguration",
                                render: (text: FleetModel["mailConfiguration"])=> {
                                    console.log('text',text)
                                    let newText = "";
                                    if (text && text.mail) {
                                        newText = text.mail;
                                    }
                                    return <span>{newText}</span>;
                                }
                            },

                            {
                              title: t("Page.Fleets.visibility"),
                              dataIndex: "visibility",
                              render: (text: string) => (
                                <span
                                  style={{
                                    backgroundColor:
                                      text === "PUBLIC" ? "#1EA85D" : "#F2B71E",
                                    color: "white",
                                    padding: "0.2rem 0.5rem",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {text}
                                </span>
                              ),
                            },
                            {
                              title: t("Page.Fleets.location"),
                              dataIndex: "location",
                              render: (text: any) => {
                                let newText = "";
                                if (text && text.name) {
                                  newText = text.name;
                                }
                                return <span>{newText}</span>;
                              },
                            },
                            {
                              title: t("Page.Fleets.billing"),
                              dataIndex: "billingPlan",
                              render: (text: Partial<BillingPlanModel>) => {
                                let newText = "";
                                if (text && text.name) {
                                  newText = text.name;
                                }
                                return <span>{newText}</span>;
                              },
                            },
                            {
                              title: t("Page.Fleets.createdDate"),
                              dataIndex: "createdAt",
                                render: (text : Date)=> {
                                    return <span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>;
                                }
                            },
                          ]}
                          total={itemCount || 100}
                          data={listFleet || []}
                          pageSize={limit}
                          currentPage={page}
                          onChangePage={(page) => {
                            setPage(page);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {tab === "Company" && (
                  <Col span={24} className="gutter-row">
                    <div className="org_div1">
                      <Row gutter={16}>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Org.address")}
                            name="address"
                            onChange={() => console.log(111)}
                            placeHolder={t("Page.Fleets.phAddress")}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Org.city")}
                            name="city"
                            placeHolder={t("Page.Fleets.phCity")}
                            onChange={() => console.log(111)}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Org.zipCode")}
                            name="postal/ZipCode"
                            onChange={() => console.log(111)}
                            placeHolder={t("Page.Fleets.phZipCode")}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Org.taxID")}
                            name="taxID"
                            onChange={() => console.log(111)}
                            placeHolder={t("Page.Fleets.phTaxId")}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Org.registrationNo")}
                            name="registrationNo"
                            onChange={() => console.log(111)}
                            placeHolder={t("Page.Fleets.phRegistrationNo")}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                      <br />
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <BBtn
                          title={t("button.delete")}
                          color="#FF5C6A"
                          onClick={() => setOpenAdd(false)}
                        />
                        <span style={{ width: "10px" }}></span>
                        <BBtn title={t("button.saveChange")} color="#24BB69" />
                      </div>
                    </div>
                  </Col>
                )}

                {tab === "Mail Configuration" && (
                  <Col span={24} className="gutter-row">
                    <div className="org_div1">
                      <Row gutter={16}>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Fleets.supportMailHost")}
                            name="supportMailHost"
                            value={dataFleet.mailConfiguration?.host}
                            onChange={(e) =>
                              setDataFleet(
                                (prevState) =>
                                  ({
                                    ...prevState,
                                    mailConfiguration: {
                                      ...prevState?.mailConfiguration,
                                      host: e,
                                    },
                                  } as any)
                              )
                            }
                            placeHolder={t("Page.Fleets.phSupportMailHost")}
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Fleets.supportMailPort")}
                            name="supportMailPort"
                            placeHolder={t("Page.Fleets.phSupportMailPort")}
                            value={dataFleet.mailConfiguration?.port}
                            onChange={(e) =>
                              setDataFleet(
                                (prevState) =>
                                  ({
                                    ...prevState,
                                    mailConfiguration: {
                                      ...prevState?.mailConfiguration,
                                      port: Number(e),
                                    },
                                  } as any)
                              )
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Fleets.supportEmail")}
                            name="supportEmail"
                            placeHolder={t("Page.Fleets.phSupportEmail")}
                            value={dataFleet.mailConfiguration?.mail}
                            onChange={(e) =>
                              setDataFleet(
                                (prevState) =>
                                  ({
                                    ...prevState,
                                    mailConfiguration: {
                                      ...prevState?.mailConfiguration,
                                      mail: e,
                                    },
                                  } as any)
                              )
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Fleets.supportMailPassword")}
                            name="supportMailPassword"
                            type="password"
                            placeHolder={t("Page.Fleets.phSupportMailPassword")}
                            value={dataFleet.mailConfiguration?.password}
                            onChange={(e) =>
                              setDataFleet(
                                (prevState) =>
                                  ({
                                    ...prevState,
                                    mailConfiguration: {
                                      ...prevState?.mailConfiguration,
                                      password: e,
                                    },
                                  } as any)
                              )
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <InputGroup
                            required={true}
                            title={t("Page.Fleets.supportMailEncryption")}
                            name="supportMailEncryption"
                            placeHolder={t(
                              "Page.Fleets.phSupportMailEncryption"
                            )}
                            value={dataFleet.mailConfiguration?.encryption}
                            onChange={(e) =>
                              setDataFleet(
                                (prevState) =>
                                  ({
                                    ...prevState,
                                    mailConfiguration: {
                                      ...prevState?.mailConfiguration,
                                      encryption: e,
                                    },
                                  } as any)
                              )
                            }
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ borderTop: "1px solid #E1E1E1" }}></Row>
                      <br />
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <BBtn
                          title={t("button.delete")}
                          color="#FF5C6A"
                          onClick={() => setOpenAdd(false)}
                        />
                        <span style={{ width: "10px" }}></span>
                        <BBtn
                          title={t("button.saveChange")}
                          color="#24BB69"
                          onClick={handleEditMailConfig}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {tab === "Vehicle Configuration" && (
                  <Col span={24} className="gutter-row">
                    <div className="org_div1">
                      <p className="fleets-title">
                        {t("Page.Fleets.filterByType")}
                      </p>
                      <Row gutter={16}>
                        <Col span={8}>
                          <ISelect
                            option={[
                              { text: "filter1", value: "filter1" },
                              { text: "filter2", value: "filter2" },
                            ]}
                            name="filterByType"
                            onChange={() => {
                              console.log(1111);
                            }}
                          />
                          <p>{t("Page.Fleets.vehiclesConfigDescription")}</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}

                {tab === "Timeline" && (
                  <Col span={24} className="gutter-row">
                    <div className="org_div1">
                      <div style={{ display: "flex" }}>
                        <p className="fleets-title">
                          {t("Page.Fleets.Tab.timeline")}
                        </p>
                        {/* <Select
                          defaultValue='lucy'
                          style={{width: 200}}
                          onChange={handleChange}
                        >
                          <Option value='jack'>Jack</Option>
                          <Option value='lucy'>Lucy</Option>
                          <Option value='Yiminghe'>yiminghe</Option>
                        </Select> */}
                      </div>
                      <Row gutter={16} style={{ paddingLeft: "2rem" }}>
                        <Col span={8}>
                          <Steps direction="vertical" current={1}>
                            <Steps.Step
                              icon={
                                <i
                                  className="fa-solid fa-tag"
                                  style={{
                                    color: "#333",
                                    fontSize: "40px",
                                  }}
                                ></i>
                              }
                              title="Finished"
                              description="This is a description."
                            />
                            <Steps.Step
                              icon={
                                <i
                                  className="fa-solid fa-tag"
                                  style={{
                                    color: "#333",
                                    fontSize: "40px",
                                  }}
                                ></i>
                              }
                              title="Finished"
                              description="This is a description."
                            />
                            <Steps.Step
                              icon={
                                <i
                                  className="fa-solid fa-tag"
                                  style={{
                                    color: "#333",
                                    fontSize: "40px",
                                  }}
                                ></i>
                              }
                              title="Finished"
                              description="This is a description."
                            />
                          </Steps>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}
              </>
            )}
            {tab === "Operation" && (
              <Col span={24} className="gutter-row">
                <div className="org_div1">
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ width: "80%", marginTop: "1rem" }}
                  >
                    <p className="fleets-title">
                      {t("Page.Fleets.setupOperationHours")}
                      <i className="fa fa-info-circle"></i>
                    </p>
                    <Switch defaultChecked onChange={onChange} />
                  </Row>
                  <div className="decription">
                    <p>
                      Toggle ON the days on which you want to run the operations
                      on this fleet and Enter the Start Time and End Time.
                      Service flag of the vehicles that are part of this fleet
                      will be turned ON at Start Time if no other flags are ON
                      and turned OFF at the End Time. You can add up to 2 time
                      ranges in the same day.
                    </p>
                    <p>
                      NOTE : Time is set based on the timezone of the
                      organization. Please adjust accordingly if you have fleets
                      in multiple timezones. Organization local time is&nbsp;
                      <Clock
                        format={"HH:mm:ss"}
                        ticking={true}
                        timezone={"Asia/Seoul"}
                      />
                    </p>
                  </div>
                  <div className="configs">
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                  </div>
                  <BBtn
                    title={t("button.saveChange")}
                    color="#24BB69"
                    align="end"
                  />
                </div>
              </Col>
            )}
            {tab === "Pre Ride Screen" && (
              <Col span={24} className="gutter-row">
                <div className="org_div1">
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ width: "80%", marginTop: "1rem" }}
                  >
                    <p className="fleets-title">
                      {t("Page.Fleets.showPreRideScreen")}
                      <i className="fa fa-info-circle"></i>
                    </p>
                    <Switch defaultChecked onChange={onChange} />
                  </Row>
                  <div className="preRideUrl">
                    <div>
                      <span
                        style={{
                          marginRight: "0.5rem",
                          display: "inline-block",
                        }}
                      >
                        Pre Ride URL
                      </span>
                      <Tooltip placement="right" title="acbjvhgj">
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <InputGroup
                      title=""
                      name="supportEmail"
                      placeHolder={t("Page.Fleets.phSupportEmail")}
                      onChange={() => {
                        console.log(1111);
                      }}
                    />
                  </div>
                  <div className="preRideType">
                    <div>
                      <span
                        style={{
                          marginRight: "0.5rem",
                          display: "inline-block",
                        }}
                      >
                        Rider Type
                      </span>
                      <Tooltip placement="right" title="acbjvhgj">
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <InputGroup
                      title=""
                      name="supportEmail"
                      placeHolder={t("Page.Fleets.phSupportEmail")}
                      onChange={() => {
                        console.log(1111);
                      }}
                    />
                  </div>

                  <div className="configs">
                    <div>
                      <span
                        style={{
                          marginRight: "0.5rem",
                          display: "inline-block",
                        }}
                      >
                        Time Setting
                      </span>
                      <Tooltip placement="right" title="acbjvhgj">
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                    <div className="config">
                      <div className="config-status">
                        <span>Monday</span>
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                      <div className="config-time">
                        <span>Open</span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                        <span style={{ paddingLeft: 12 }}></span>
                        <TimePicker
                          defaultValue={moment("00:00:00", "HH:mm:ss")}
                          size="large"
                        />
                      </div>
                      <div className="config-action">
                        <BBtn
                          title="Add Hours"
                          color="#24BB69"
                          icon1={<i className="fa-solid fa-plus"></i>}
                        />
                      </div>
                    </div>
                  </div>
                  <BBtn
                    title={t("button.saveChange")}
                    color="#24BB69"
                    align="end"
                  />
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
      <DeleteDialog
        open={openDelete}
        setOpen={() => setOpenDelete(!openDelete)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default Fleets;
