/**
 * WARNING: this file is generated. Don't change it manually.
 */
export default class Images {
    static readonly arrowLeft = require("../../../src/assets/images/arrow-left.png");
    static readonly avatarDefault = require("../../../src/assets/images/avatar_default.png");
    static readonly chevronDown = require("../../../src/assets/images/chevron-down.png");
    static readonly diagram = require("../../../src/assets/images/diagram.png");
    static readonly gift = require("../../../src/assets/images/gift.png");
    static readonly headerIcon = require("../../../src/assets/images/header-icon.png");
    static readonly headerIcon1 = require("../../../src/assets/images/header-icon1.png");
    static readonly helpImg1 = require("../../../src/assets/images/help-img1.png");
    static readonly helpImg2 = require("../../../src/assets/images/help-img2.png");
    static readonly helpImg3 = require("../../../src/assets/images/help-img3.png");
    static readonly helpImg4 = require("../../../src/assets/images/help-img4.png");
    static readonly icAdd = require("../../../src/assets/images/ic-add.png");
    static readonly icSave = require("../../../src/assets/images/ic-save.png");
    static readonly searchNormal = require("../../../src/assets/images/search-normal.png");
    static readonly sms = require("../../../src/assets/images/sms.png");
    static readonly block = require("../../../src/assets/images/block.png");
    static readonly scooter = require("../../../src/assets/images/Scutter.png");
    static readonly arrowLeftBorder = require("../../../src/assets/images/ArrowLeft.svg");
}
