import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";
import { orgApi } from "../../apis/orgApi";
import { orgAction } from "./orgSlice";
import { OrgModel } from "../../models/org.model";
import { UserOrg } from "../../models/userOrg.model";

function* getListOrg() {
  try {
    const listOrg: { data: OrgModel[] } = yield call(orgApi.getListOrg);
    // const listOrg: {data: OrgModel[]} = {data : []}
    yield put(orgAction.getListOrgSuccess(listOrg.data));
  } catch (error) {
    console.log(error);
  }
}

function* getListOrgPagination(
  action: PayloadAction<{
    page: number;
    limit: number;
    keyword?: string;
    "orderBy[id]"?: "ASC" | "DESC";
    "orderBy[name]"?: "ASC" | "DESC";
  }>
) {
  try {
    const listOrg: {
      description: string;
      data: { nodes: OrgModel[]; itemCount: number };
    } = yield call(orgApi.getListOrgPagination, action.payload);
    yield put(orgAction.getListOrgPaginationSuccess(listOrg.data.nodes));
    yield put(orgAction.setItemCount(listOrg.data.itemCount));
  } catch (error) {
    console.log(error);
  }
}

function* updateOrg(action: PayloadAction<OrgModel>) {
  try {
    const org: { description: string } = yield call(
      orgApi.updateOrg,
      action.payload
    );
    yield put(orgAction.updateOrgSuccess());
    message.success(org.description).then();
  } catch (error) {
    yield put(orgAction.updateOrgFail());
    console.log(error);
  }
}
function* addOrg(action: PayloadAction<OrgModel>) {
  try {
    const org: { description: string } = yield call(
      orgApi.addOrg,
      action.payload
    );
    yield put(orgAction.addOrgSuccess());
    message.success(org.description).then();
  } catch (error) {
    yield put(orgAction.addOrgFail());
    console.log(error);
  }
}

function* getListUserOrgPagination(
  action: PayloadAction<{
    page: number;
    limit: number;
    keyword?: string;
    organisation_id : string
    "orderBy[id]"?: "ASC" | "DESC";
    "orderBy[name]"?: "ASC" | "DESC";
  }>
) {
  try {
    const listUserOrg: {
      description: string;
      data: { nodes: UserOrg[]; itemCount: number };
    } = yield call(orgApi.getListUserOrgPagination, action.payload);
    yield put(
      orgAction.getListUserOrgPaginationSuccess(listUserOrg.data.nodes)
    );
    yield put(orgAction.setItemCountUserOrg(listUserOrg.data.itemCount));
  } catch (error) {
    yield put(orgAction.getListUserOrgPaginationFail());
    console.log(error);
  }
}

function* updateUserOrg(action: PayloadAction<UserOrg>) {
  try {
    const userOrg: { description: string } = yield call(
      orgApi.updateUserOrg,
      action.payload
    );
    yield put(orgAction.updateUserOrgSuccess());
    message.success(userOrg.description).then();
  } catch (error: any) {
    yield put(orgAction.updateUserOrgFail());
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item);
      });
    console.log(error);
  }
}
function* addUserOrg(action: PayloadAction<UserOrg>) {
  try {
    const org: { description: string } = yield call(
      orgApi.addUserOrg,
      action.payload
    );
    yield put(orgAction.addUserOrgSuccess());
    message.success(org.description).then();
  } catch (error : any) {
    console.log(error);
    yield put(orgAction.addUserOrgFail());
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}

export default function* orgSaga() {
  yield takeEvery(orgAction.getListOrg.type, getListOrg);
  yield takeEvery(orgAction.updateOrg.type, updateOrg);
  yield takeEvery(orgAction.addOrg.type, addOrg);
  yield takeEvery(orgAction.getListOrgPagination.type, getListOrgPagination);
  yield takeEvery(
    orgAction.getListUserOrgPagination.type,
    getListUserOrgPagination
  );
  yield takeEvery(orgAction.addUserOrg.type, addUserOrg);
  yield takeEvery(orgAction.updateUserOrg.type, updateUserOrg);
}
