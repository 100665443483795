import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
import {PaginationModel} from '../../models/paginationModel'
import {BannerModel} from "../../models/banner.model";

export interface BannerState {
  loadding: boolean
  listBanner: BannerModel[] | undefined
  itemCount: number | undefined
  banner : BannerModel | undefined
}
const initialState: BannerState = {
  loadding: false,
  listBanner: undefined,
  itemCount: undefined,
  banner: undefined,
}

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getListBanner(state, action: PayloadAction<{
      page: number | undefined,
      limit: number | undefined,
      keyword?: string,
      status?: 'ACTIVE' | 'DE_ACTIVE',
      'orderBy[id]'?: 'ASC' | 'DESC',
      'orderBy[title]'?: 'ASC' | 'DESC',
    }>) {
      state.loadding = true
    },
    getListBannerSuccess(state, action: PayloadAction<BannerModel[]>) {
      state.listBanner = action.payload
      state.loadding = false
    },
    getListBannerFail(state) {
      state.loadding = false
    },
    setItemCount(state, action: PayloadAction<number>) {
      state.itemCount = action.payload
    },
    updateBanner(
      state,
      action: PayloadAction<Partial<BannerModel>>
    ) {
      state.loadding = true
    },
    success(state) {
      state.loadding = false
    },
    fail(state) {
      state.loadding = false
    },
    addBanner(
      state,
      action: PayloadAction<Partial<BannerModel>>
    ) {
      state.loadding = true
    },
    detailBanner(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
    detailBannerSuccess(state, action : PayloadAction<BannerModel> ) {
      state.loadding = true
      state.banner = action.payload
    },
    deleteBanner(state, action : PayloadAction<{id :string}> ) {
      state.loadding = true
    },
  },
})
export const bannerAction = bannerSlice.actions
export const selectItemCount = (state: RootState) => state.banner.itemCount
export const selectListBanner = (state: RootState) => state.banner.listBanner
export const selectBanner = (state: RootState) => state.banner.banner
export const selectLoading = (state: RootState) => state.banner.loadding

export const bannerReducer = bannerSlice.reducer
