import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {countryAction} from './countrySlice'
import {CountryModel} from "../../models/country.model";
import {CountryApi} from "../../apis/countryApi";

function* getListCountry() {
  try {
    const listOrg: {data: CountryModel[]} = yield call(CountryApi.getListCountry)
    yield put(countryAction.getListCountrySuccess(listOrg.data))
  } catch (error) {
    yield put(countryAction.getListCountryFail())
    console.log(error)
  }
}




export default function* countrySaga() {
  yield takeEvery(countryAction.getListCountry.type, getListCountry)
}
