import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {iamportStoreAction} from "./iamportStoreSlice";
import {IamportStoreModel} from "../../models/IamportStore.model";
import {IamportStoreApi} from "../../apis/iamportStoreApi";

function* getListIamportStore() {
  try {
    const listIamportStore: {
      data: IamportStoreModel[]
    } = yield call(IamportStoreApi.getAllIamportStore)
    yield put(iamportStoreAction.getListIamportStoreSuccess(listIamportStore.data))
  } catch (error) {
    console.log(error)
  }
}
function* updateIamportStore(
  action: PayloadAction<Partial<IamportStoreModel>>
) {
  try {
    const IamportStore: {description: string} = yield call(
      IamportStoreApi.updateIamportStore,
      action.payload
    )
    yield put (iamportStoreAction.success())
    message.success(IamportStore.description).then()
  } catch (error: any) {
    yield put (iamportStoreAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* addIamportStore(action: PayloadAction<Partial<IamportStoreModel>>) {
  try {
    const IamportStore: {description: string} = yield call(IamportStoreApi.createIamportStore, action.payload)
    message.success(IamportStore.description).then()
    yield put (iamportStoreAction.success())
  } catch (error: any) {
    yield put (iamportStoreAction.fail())
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item).then()
      })
  }
}
function* detailIamportStore(action: PayloadAction<{id : string}>) {
  try {
    const IamportStore: {description: string , data : IamportStoreModel} = yield call(IamportStoreApi.detailIamportStore, action.payload.id)
    yield put (iamportStoreAction.detailIamportStoreSuccess(IamportStore.data))
    message.success(IamportStore.description).then()
  } catch (error: any) {
    yield put (iamportStoreAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}
function* deleteIamportStore(action: PayloadAction<{id : string}>) {
  try {
    const IamportStore: {description: string } = yield call(IamportStoreApi.deleteIamportStore, action.payload.id)
    message.success(IamportStore.description).then()
    yield put (iamportStoreAction.success())
  } catch (error: any) {
    yield put (iamportStoreAction.fail())
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item).then()
    })
  }
}


export default function* iamportStoreSaga() {
  yield takeEvery(iamportStoreAction.getListIamportStore.type, getListIamportStore)
  yield takeEvery(iamportStoreAction.updateIamportStore.type, updateIamportStore)
  yield takeEvery(iamportStoreAction.addIamportStore.type, addIamportStore)
  yield takeEvery(iamportStoreAction.detailIamportStore.type, detailIamportStore)
  yield takeEvery(iamportStoreAction.deleteIamportStore.type, deleteIamportStore)
}
