import {PayloadAction} from '@reduxjs/toolkit'
import {message} from 'antd'
import {call, put, takeEvery} from 'redux-saga/effects'
import {taxApi} from '../../apis/taxApi'
import {PaginationModel} from '../../models/paginationModel'
import {TaxModel} from '../../models/taxModel'
import {taxAction} from './taxSlice'

function* getListTax(action: PayloadAction<PaginationModel>) {
  try {
    const listTax: {
      data: {
        nodes: TaxModel[]
        itemCount: number
      }
    } = yield call(taxApi.getListTax, {limit: 10, page: 1})
    yield put(taxAction.getListTaxSuccess(listTax.data.nodes))
    yield put(taxAction.setItemCount(listTax.data.itemCount))
  } catch (error) {
    console.log(error)
  }
}
function* updateTax(
  action: PayloadAction<{id: string; name: string; taxPercentage: number}>
) {
  try {
    const tax: {description: string} = yield call(
      taxApi.updateTax,
      action.payload
    )
    message.success(tax.description)
  } catch (error: any) {
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item)
      })
  }
}
function* addTax(action: PayloadAction<{name: string; taxPercentage: number}>) {
  try {
    const tax: {description: string} = yield call(taxApi.addTax, action.payload)
    message.success(tax.description)
  } catch (error: any) {
    Array.isArray(error.response.data.message) &&
      error.response.data.message.forEach((item: string) => {
        message.error(item)
      })
  }
}
function* detailTax(action: PayloadAction<{id : string}>) {
  try {
    const tax: {description: string , data : TaxModel} = yield call(taxApi.detailTax, action.payload.id)
    yield put (taxAction.detailTaxSuccess(tax.data))
    message.success(tax.description).then()
  } catch (error: any) {
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item)
    })
  }
}
function* deleteTax(action: PayloadAction<{id : string}>) {
  try {
    const tax: {description: string } = yield call(taxApi.deleteTax, action.payload.id)
    message.success(tax.description).then()
  } catch (error: any) {
    Array.isArray(error.response.data.message) &&
    error.response.data.message.forEach((item: string) => {
      message.error(item)
    })
  }
}

export default function* taxSaga() {
  yield takeEvery(taxAction.getListTax.type, getListTax)
  yield takeEvery(taxAction.updateTax.type, updateTax)
  yield takeEvery(taxAction.addTax.type, addTax)
  yield takeEvery(taxAction.detailTax.type, detailTax)
  yield takeEvery(taxAction.deleteTax.type, deleteTax)
}
