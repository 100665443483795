import {all} from 'redux-saga/effects'
import authSaga from '../features/auth/authSaga'
import billingPlanSaga from '../features/billingPlan/billingPlanSaga'
import roleSaga from '../features/role/roleSaga'
import taxSaga from '../features/tax/taxSaga'
import orgSaga from '../features/org/orgSaga'
import countrySaga from "../features/country/countrySaga";
import fleetSaga from "../features/fleet/fleetSaga";
import userSaga from "../features/users/userSaga";
import promotionSaga from "../features/promotion/promotionSaga";
import vehicleSaga from "../features/vehicle/vehicleSaga";
import riderSaga from "../features/rider/riderSaga";
import bannerSaga from "../features/banner/bannerSaga";
import settingSaga from "../features/setting/settingSaga";
import areaSaga from "../features/area/areaSaga";
import campaignSaga from "../features/campaign/campaignSaga";
import offerSaga from "../features/offer/offerSaga";
import orderSaga from "../features/order/orderSaga";
import feedbackOptionSaga from "../features/feedbackOption/feedbackOptionSaga";
import feedbackSaga from "../features/feedback/feedbackSaga";
import paymentHistorySaga from "../features/paymentHistory/paymentHistorySaga";
import iamportStoreSaga from "../features/iamportStore/iamportStoreSaga";
import analyticPaymentSaga from "../features/analyticPayment/analyticPaymentSaga";
import analyticRideSaga from "../features/analyticRide/analyticRideSaga";
import versionSaga from "../features/version/versionSaga";

export default function* rootSaga() {
    yield all([
        authSaga(),
        roleSaga(),
        billingPlanSaga(),
        taxSaga(),
        orgSaga(),
        countrySaga(),
        fleetSaga(),
        userSaga(),
        promotionSaga(),
        vehicleSaga(),
        riderSaga(),
        bannerSaga(),
        settingSaga(),
        areaSaga(),
        campaignSaga(),
        offerSaga(),
        orderSaga(),
        feedbackOptionSaga(),
        feedbackSaga(),
        paymentHistorySaga(),
        iamportStoreSaga(),
        analyticPaymentSaga(),
        analyticRideSaga(),
        versionSaga()
    ])
}
