import { Radio, Space, Switch } from "antd";
import styled from "styled-components";
import { AppColors } from "../../../utils/appColors";
import BBtn from "../../../components/button";
import InputGroup from "../../../components/textInput";
import { SettingModel } from "../../../models/setting.model";
import { settingAction } from "../../../features/setting/settingSlice";
import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks";

interface IProps {
  setting: Partial<SettingModel>;
  onUpdate: () => void;
}
const TabMail = (props: IProps) => {
  const dispatch = useAppDispatch();
  const initState = props.setting.mail || {};
  const [data, setData] = useState<SettingModel["mail"]>(
    initState as unknown as SettingModel["mail"]
  );
  const [disableEdit, setDisableEdit] = useState<{
    mailHost: boolean;
    mailPort: boolean;
    mailUserName: boolean;
    mailPassword: boolean;
    mailEncryption: boolean;
  }>({
    mailHost: true,
    mailPort: true,
    mailUserName: true,
    mailPassword: true,
    mailEncryption: true,
  });

  /**
   * The function takes an object with a value and a key, and if the props.setting.mail exists, it sets
   * the data to the props.setting.mail with the key and value.
   * @param params - {
   */
  const hanldeChange = (params: {
    value: any;
    key: keyof SettingModel["mail"];
  }) => {
    if (props.setting.mail) {
      setData({ ...props.setting.mail, [params.key]: params.value });
    }
  };
  /**
   * HandleEdit is a function that takes a parameter of type keyof SettingModel["mail"] and
   * update settings
   * @param params - keyof SettingModel["mail"]
   */
  const handleEdit = (params: keyof SettingModel["mail"]) => {
    if (disableEdit[params]) {
      setDisableEdit((pre) => ({ ...pre, [params]: false }));
    } else {
      props.setting.mail &&
        dispatch(
          settingAction.updateSetting({
            mail: data,
            _id: props.setting._id,
          })
        );
      setDisableEdit((pre) => ({ ...pre, [params]: true }));
      props.onUpdate();
    }
  };

  return (
    <div className="c-row">
      <div className="content">
        <div className="sub-title">Mail Configuration</div>
        <div className="divider"></div>
        <div className="c-row">
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEdit.mailHost}
              onChange={(e) => hanldeChange({ key: "mailHost", value: e })}
              name="Mail Host"
              title="Mail Host"
              value={props.setting.mail?.mailHost}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["mailHost"] ? "Edit" : "Save"}
              onClick={() => handleEdit("mailHost")}
              color={AppColors.green}
            />
          </div>
          <div style={{ width: 30 }}></div>
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEdit.mailPort}
              onChange={(e) =>
                hanldeChange({ key: "mailPort", value: Number(e) })
              }
              name="Mail Port"
              title="Mail Port"
              value={props.setting.mail?.mailPort}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["mailPort"] ? "Edit" : "Save"}
              onClick={() => handleEdit("mailPort")}
              color={AppColors.green}
            />
          </div>
        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEdit.mailUserName}
              onChange={(e) => hanldeChange({ key: "mailUserName", value: e })}
              name="Mail User Name"
              title="Mail User Name"
              value={props.setting.mail?.mailUserName}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["mailUserName"] ? "Edit" : "Save"}
              onClick={() => handleEdit("mailUserName")}
              color={AppColors.green}
            />
          </div>
          <div style={{ width: 30 }}></div>
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEdit.mailPassword}
              onChange={(e) => hanldeChange({ key: "mailPassword", value: e })}
              name="Mail Password"
              type="password"
              title="Mail Password"
              value={props.setting.mail?.mailPassword}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["mailPassword"] ? "Edit" : "Save"}
              onClick={() => handleEdit("mailPassword")}
              color={AppColors.green}
            />
          </div>
        </div>
        <div style={{ height: 20 }}></div>
        <div className="c-row">
          <div className="c-row w-500">
            <InputGroup
              disabled={disableEdit.mailEncryption}
              onChange={(e) =>
                hanldeChange({ key: "mailEncryption", value: e })
              }
              name="Mail Encryption"
              title="Mail Encryption"
              value={props.setting.mail?.mailEncryption}
            />
            <div style={{ width: 10 }}></div>
            <BBtn
              title={disableEdit["mailEncryption"] ? "Edit" : "Save"}
              onClick={() => handleEdit("mailEncryption")}
              color={AppColors.green}
            />
          </div>
        </div>
        <div style={{ height: 20 }}></div>
      </div>
    </div>
  );
};

export default TabMail;
