import { FleetModel } from "./fleet.model";
import { User } from "./userModel";

export interface vehicleModel {
  _id: string;
  name: string;
  online: boolean;
  available: boolean;
  qrCode: string;
  model: string;
  platformCode: string;
  typeDevice: string;
  iotType: string;
  battery: number;
  odometer: number;
  speedMode: number;
  vehicleSN: string;
  bluetoothKey: string;
  macAddress: string;
  iccid: string;
  imei: string;
  fleet: FleetModel;
  created: User;
  throttle: boolean;
  location: Record<string, any>;
  agencyPickup: boolean;
  serviceStartEnd: boolean;
  batteryCritical: boolean;
  maximumSpeedMode: Record<string, any>;
  maintenancePickup: boolean;
  ioTFault: boolean;
  rented: boolean;
  locked: boolean;
  reBalancePickup: boolean;
  stolen: boolean;
  missing: boolean;
  usingService: boolean;
  configuration: {
    lowSpeed: number;
    mediumSpeed: number;
    highSpeed: number;
    locationTimeUpdateOffRide: number;
    locationTimeUpdateOnRide: number;
  };
  firmwareVersion: string;
  firmwareKey: string;
  firmwareType: string;
  deviceNotes: string;
  lastRide: Date;
  lastConnect: Date;
  lastLock: Date;
  createdAt: Date;
  port: string;
  gpsImei: string;
  xiaomiRfid: string;
  axaIotId: string;
  vehicleLockCode: string;
  comoduleIotId: string;
}

export const DEVICE_TYPE : Record<string, string> = {
  ACTION_NEXUS_EBIKE_IOT: "ACTON Nexus eBike IoT",
  ACTION_NEXUS_EBIKE: "ACTON Nexus eBike",
  AXA: "AXA",
  AXA_ERL2: "AXA ERL2",
  AXA_LOCK_WITH_AXA_IOT: "AXA LOCK WITH AXA IOT",
  CO_SCOOTER_WITH_CO_MODULE_IOT: "CO SCOOTER WITH CO MODULE IOT",
  INVERSE_SCOOTER_WITH_CLOUD_BOX_4G_IOT:
    "INVERSE SCOOTER WITH CLOUD BOX 4G IOT",
  SEGWAY_SCOOTER: "SEGWAY SCOOTER",
  SEGWAY_SCOOTER_WITH_SEGWAY_IOT: "SEGWAY SCOOTER WITH SEGWAY IOT",
  SEGWAY_ES4_WITH_CO_MODULE_IOT: "SEGWAY ES4 WITH CO MODULE IOT",
  SEGWAY_MAX_WITH_CO_MODULE_IOT: "SEGWAY MAX WITH CO MODULE IOT",
  XIAOMI_M365_IOT: "XIAOMI M365 IOT",
  OGB: "OGB",
  OGB_IOT: "OGB IOT",
  OGG: "OGG",
  OKAI_EBIKE: "OKAI EBIKE",
  OKAI_SCOOTER_IOT: "OKAI SCOOTER IOT",
  OKAI_SCOOTER_ES100_WITH_OKAI_IOT: "OKAI SCOOTER ES100 WITH OKAI IOT",
  OKAI_SCOOTER_ES200_WITH_OKAI_IOT: "OKAI SCOOTER ES200 WITH OKAI IOT",
  OKAI_SCOOTER_ES300_WITH_OKAI_IOT: "OKAI SCOOTER ES300 WITH OKAI IOT",
  OKAI_SCOOTER_ES400_WITH_OKAI_IOT: "OKAI SCOOTER ES400 WITH OKAI IOT",
  OKAI_ES200_WITH_CO_MODULE_IOT: "OKAI ES200 WITH CO MODULE IOT",
  OKAI_ES400_WITH_CO_MODULE_IOT: "OKAI ES400 WITH CO MODULE IOT",
  ON_BIKE_SHARE: "ON BIKE SHARE",
  OMI_SCOOTER_WITH_OMI_IOT: "OMI SCOOTER WITH OMI IOT",
  NINEBOT: "NINEBOT",
};