import React, {useEffect, useState} from 'react'
import {Col, Row, Input, Button} from 'antd'
import './login.css'
import i18next from 'i18next'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {
  authAction,
  selectDataAuth,
  selectLoginStatus,
} from '../../features/auth/authSlice'
import {useNavigate} from 'react-router-dom'

const Login = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const loginStatus = useAppSelector(selectLoginStatus)
  const loginData = useAppSelector(selectDataAuth)
  const login = () => {
    if (email && password) {
      dispatch(authAction.login({email: email, password: password}))
    }
  }
  console.log(7777, loginData)
  useEffect(() => {
    if (loginStatus) {
      if (loginData) {
        localStorage.setItem('access_token', loginData.access_token)
      }
      navigate('/home')
    }
  }, [loginStatus, navigate, loginData])

  return (
    <>
      <div className='login-container'>
        <div className='login-form'>
          <p className='login-p1'>{t('Login.login')}</p>
          <p className='login-p2'>{t('Login.sign')}</p>
          <Row className='login-row'>
            <Col span={22}>
              <Input
                className='login-input'
                bordered={false}
                placeholder={t('Login.email')}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col span={2}>
              <img src='/img/login-email.svg' alt='' />
            </Col>
          </Row>
          <Row className='login-row'>
            <Col span={22}>
              <Input
                className='login-input'
                bordered={false}
                placeholder={t('Login.password')}
                onChange={(e) => setPassword(e.target.value)}
                type='password'
              />
            </Col>
            <Col span={2}>
              <img src='/img/login-pw.svg' alt='' />
            </Col>
          </Row>
          <Button className='login-buuton' onClick={() => login()}>
            {t('Login.login')}
          </Button>
        </div>
      </div>

      {/* <Button type='primary'>Primary Button</Button>
      <Button>Default Button</Button>
      <Button type='dashed'>Dashed Button</Button>
      <br />
      <Button type='text'>Text Button</Button>
      <Button type='link'>Link Button</Button>
      <Button></Button> */}
    </>
  )
}

export default Login
