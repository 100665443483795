import axiosClient from "./axiosClient";
import { urlApi } from "./urlConfig";
import { FleetModel } from "../models/fleet.model";

export const FleetApi = {
  getListFleet(params: {
    page: number;
    limit: number;
    keyword?: string;
    "orderBy[id]"?: "ASC" | "DESC";
    "orderBy[name]"?: "ASC" | "DESC";
  }) {
    return axiosClient.getService(`${urlApi.FLEET}`, params);
  },
  detailFleet(id: string) {
    return axiosClient.getService(`${urlApi.FLEET}/${id}`);
  },
  createFleet(params: Partial<FleetModel>) {
    return axiosClient.postService(`${urlApi.FLEET}`, { ...params });
  },
  updateBasic(params: Partial<FleetModel>) {
    return axiosClient.updateService(`${urlApi.FLEET}/basic/${params._id}`, {
      ...params,
    });
  },
  updateCompany(params: Partial<FleetModel>) {
    return axiosClient.updateService(`${urlApi.FLEET}/company/${params._id}`, {
      ...params,
    });
  },
  updateConfigMail(params: Pick<FleetModel,"mailConfiguration"|"_id">) {
    return axiosClient.updateService(
      `${urlApi.FLEET}/config-mail/${params._id}`,
      { ...params }
    );
  },
  updateOrganisation(params: Partial<FleetModel>) {
    return axiosClient.updateService(
      `${urlApi.FLEET}/organisation/${params._id}`,
      { ...params }
    );
  },
  updatePreRideScreen(params: Partial<FleetModel>) {
    return axiosClient.updateService(
      `${urlApi.FLEET}/pre-ride-screen/${params._id}`,
      { ...params }
    );
  },
  updateOperationHour(params: Partial<FleetModel>) {
    return axiosClient.updateService(
      `${urlApi.FLEET}/operation-hour/{${params._id}`,
      { ...params }
    );
  },
  deleteFleet(id: string) {
    return axiosClient.deleteService(`${urlApi.FLEET}/${id}`);
  },
};
